import { defineMessages } from 'react-intl';

const messages = defineMessages({
  snapshot: {
    id: 'App.ResidentProfile.Snapshot.Snapshot',
    defaultMessage: 'Snapshot',
  },
  assignedTo: {
    id: 'App.ResidentProfile.Snapshot.AssignedTo',
    defaultMessage: 'Assigned to: ',
  },
  unitStatus: {
    id: 'App.ResidentProfile.Snapshot.unitStatus',
    defaultMessage: 'Unit Status: ',
  },
  notAssigned: {
    id: 'App.ResidentProfile.Snapshot.notAssigned',
    defaultMessage: 'Not Assigned',
  },
  noticeToVacate: {
    id: 'App.ResidentProfile.Snapshot.NoticeToVacate',
    defaultMessage: 'Notice to Vacate',
  },
  currentLedgerBalance: {
    id: 'App.ResidentProfile.Snapshot.CurrentLedgerBalance',
    defaultMessage: 'Current Ledger Balance: ',
  },
  numberOfLateFees: {
    id: 'App.ResidentProfile.Snapshot.NumberOfLateFees',
    defaultMessage: 'Number of months with late fees: ',
  },
  leaseEndDate: {
    id: 'App.ResidentProfile.Snapshot.LeaseEndDate',
    defaultMessage: 'Lease End Date: ',
  },
  quoteLeaseRenewal: {
    id: 'App.ResidentProfile.Snapshot.QuoteLeaseRenewal',
    defaultMessage: 'Quote Lease Renewal',
  },
  doNotRenew: {
    id: 'App.ResidentProfile.Snapshot.DoNotRenew',
    defaultMessage: 'Do Not Renew?',
  },
  moveInDate: {
    id: 'App.ResidentProfile.Snapshot.MoveInDate',
    defaultMessage: 'Move-In Date: ',
  },
  anticipated: {
    id: 'App.ResidentProfile.Snapshot.Anticipated',
    defaultMessage: 'Anticipated ',
  },
  moveOutDate: {
    id: 'App.ResidentProfile.Snapshot.MoveOutDate',
    defaultMessage: 'Move-Out Date: ',
  },
  renewLease: {
    id: 'App.ResidentProfile.Snapshot.RenewLeases',
    defaultMessage: 'Complete Renewal',
  },
  transferLease: {
    id: 'App.ResidentProfile.Snapshot.transferLease',
    defaultMessage: 'Complete Transfer',
  },
  processMoveOut: {
    id: 'App.ResidentProfile.Snapshot.ProcessMoveOut',
    defaultMessage: 'Process Move-Out',
  },
  finalAccountStatement: {
    id: 'App.ResidentProfile.Snapshot.finalAccountStatement',
    defaultMessage: 'Final Account Statement',
  },
  finalAccountStatementDisabled: {
    id: 'App.ResidentProfile.Snapshot.finalAccountStatementDisabled',
    defaultMessage:
      '*Final Account Statement will be active once Ledger has been reviewed and confirmed.',
  },
  underEviction: {
    id: 'App.ResidentProfile.Snapshot.underEviction',
    defaultMessage: 'Under Eviction',
  },
  doNotAccept: {
    id: 'App.ResidentProfile.Snapshot.doNotAccept',
    defaultMessage: 'Do Not Accept',
  },
  certifiedOnly: {
    id: 'App.ResidentProfile.Snapshot.certifiedOnly',
    defaultMessage: 'Certified Only',
  },
  doNotAcceptPartialPayments: {
    id: 'App.ResidentProfile.Snapshot.doNotAcceptPartialPayments',
    defaultMessage: 'Do Not Accept Partial Payments',
  },
  finalAccountStatementAlreadyGenerated: {
    id: 'App.ResidentProfile.Snapshot.FinalAccountStatementAlreadyGenerated',
    defaultMessage: 'Final Account Statement has already been generated',
  },
  subsidyOverdraft: {
    id: 'App.ResidentProfile.Snapshot.SubsidyOverdraft',
    defaultMessage:
      'Cannot create final account statement as this prior resident has a Subsidy balance.',
  },
  propertyMoveInDate: {
    id: 'App.ResidentProfile.Snapshot.propertyMoveInDate',
    defaultMessage: 'Property Move-In Date:',
  },
  leaseRenewalStatus: {
    id: 'App.ResidentProfile.Snapshot.leaseRenewalStatus',
    defaultMessage: 'Lease Renewal Status:',
  },
  pending: {
    id: 'App.ResidentProfile.Snapshot.pending',
    defaultMessage: 'Pending',
  },
  completed: {
    id: 'App.ResidentProfile.Snapshot.completed',
    defaultMessage: 'Completed',
  },
  quoteRenewal: {
    id: 'App.ResidentProfile.Snapshot.quoteRenewal',
    defaultMessage: 'Quote Renewal',
  },
  cancelRenewal: {
    id: 'App.ResidentProfile.Snapshot.cancelRenewal',
    defaultMessage: 'Cancel Renewal',
  },
  cancelTransfer: {
    id: 'App.ResidentProfile.Snapshot.cancelTransfer',
    defaultMessage: 'Cancel Transfer',
  },
  yes: {
    id: 'App.ResidentProfile.Snapshot.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ResidentProfile.Snapshot.no',
    defaultMessage: 'No',
  },
  rentStartDate: {
    id: 'App.ResidentProfile.Snapshot.rentStartDate',
    defaultMessage: 'Rent Start Date:',
  },
  autoEmailMonthlyInvoice: {
    id: 'App.ResidentProfile.Snapshot.autoEmailMonthlyInvoice',
    defaultMessage: 'Auto Email Monthly Invoice',
  },
  numberOfNSFFees: {
    id: 'App.ResidentProfile.Snapshot.numberOfNSFFees',
    defaultMessage: 'Number of months with NSF fees: ',
  },
  fasExcel: {
    id: 'App.ResidentProfile.Snapshot.fasExcel',
    defaultMessage: 'FAS Excel',
  },
  fasPdf: {
    id: 'App.ResidentProfile.Snapshot.fasPDF',
    defaultMessage: 'FAS PDF',
  },
  failedFasDocumentDownload: {
    id: 'App.ResidentProfile.Snapshot.failedFasDocumentDownload',
    defaultMessage: 'This file is not available to display',
  },
  disableMoveOutHouseholdRentableItemsAfterMoveOutDate: {
    id: 'App.ResidentProfile.Snapshot.disableMoveOutHouseholdRentableItemsAfterMoveOutDate',
    defaultMessage:
      'Cannot process move-out until all Rentable Items have been ended on or ' +
      'before the Anticipated Move-Out Date',
  },
  disableMoveOutOpenReOpenedRDCertifications: {
    id: 'App.ResidentProfile.Snapshot.disableMoveOutOpenReOpenedRDCertifications',
    defaultMessage:
      'There is an re-opened certification. Cancel or re-complete the cert to process the move-out.',
  },
  reverseFinalAccountStatementButton: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementButton',
    defaultMessage: 'Reverse Final Account Statement',
  },
  reverseFinalAccountStatementModalLine1: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine1',
    defaultMessage:
      'You are about to reverse the Final Account Statement (FAS) generated on ',
  },
  reverseFinalAccountStatementModalLine2: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine2',
    defaultMessage: 'Completing this action will result in the following:',
  },
  reverseFinalAccountStatementModalLine3: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine3',
    defaultMessage:
      'All transactions posted during the FAS process will be reversed.',
  },
  reverseFinalAccountStatementModalLine4: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine4',
    defaultMessage:
      'Transactions posted after the FAS was generated will not be reversed from the ledger.',
  },
  reverseFinalAccountStatementModalLine5: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine5',
    defaultMessage: 'You cannot undo this action once it is complete.',
  },
  reverseFinalAccountStatementModalLine6: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalLine6',
    defaultMessage: 'Are you sure you want to reverse the FAS?',
  },
  reverseFinalAccountStatementModalTitle1: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalTitle1',
    defaultMessage: 'Warning!',
  },
  reverseFinalAccountStatementModalTitle2: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementModalTitle2',
    defaultMessage: 'Reverse Final Account Statement',
  },
  reverseFinalAccountStatementInProgressTooltip: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementInProgressTooltip',
    defaultMessage: 'A reversal process is currently in progress.',
  },
  reverseFinalAccountStatementRequestedToastrTitle: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementToastrRequestedTitle',
    defaultMessage: 'Request Accepted',
  },
  reverseFinalAccountStatementRequestedToastrMessage: {
    id: 'App.ResidentProfile.Snapshot.reverseFinalAccountStatementToastrRequestedMessage',
    defaultMessage: 'The final account statement reversal is in progress.',
  },
});

export default messages;
