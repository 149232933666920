import { UnitHistory } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useUnitHistory } from './hooks';

function UnitHistoryContainer({
  intl,
  unitId,
  floorPlanId,
  history,
  propertyId,
}) {
  const props = useUnitHistory({ floorPlanId, unitId, history, propertyId });
  return <UnitHistory {...props} intl={intl} />;
}

export default UnitHistoryContainer;
