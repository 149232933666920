import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import FormatPhone from '../../../components/FormatPhone';
import messages from './messages';
import React from 'react';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import styled from 'styled-components';
import type { Applicant } from '../../CreateApplication/types';
import { filter, sortBy, toLower, compose, path, pathOr, propOr } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { Header } from '../../../components/ProfileVisualComponents';
import { Link } from 'react-router-dom';
import { Panel, Col } from 'react-bootstrap';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  applicants: Array<Applicant>,
  isResident: boolean,
  unitId: string,
  unitNumber: string,
  isCommercial: boolean,
};

const ProfilePanel = styled(Panel).attrs({
  className: 'block block-profile block-white-shadow',
})``;

export const filterFRApplicants = (applicants: Array<Applicant>) => {
  const currentApplicants = applicants.filter(
    (applicant) =>
      applicant.resident && applicant.resident.status === 'Current',
  );

  const isFRA = (x) => x.applicantType.financiallyResponsible;

  const financialResponsibles = filter(isFRA, currentApplicants);
  const sortFRAByName = sortBy(
    compose(toLower, path(['applicantCustomer', 'customer', 'firstName'])),
  );
  // $FlowFixMe
  return sortFRAByName(financialResponsibles);
};

const WordBreakSpan = styled.span`
  word-break: break-all;
`;

const ResidentProfileDetails = ({
  applicants,
  isResident,
  unitNumber,
  unitId,
  isCommercial,
}: Props) => {
  const visibleApplicants = filterFRApplicants(applicants);

  return (
    <ProfilePanel>
      <Header>
        <div className="row resident-unit-container">
          <div className="col-xs-12 col-sm-12">
            <Typography variant={'h3'}>
              {isResident ? (
                !isCommercial ? (
                  <FormattedMessage {...messages.currentResident} />
                ) : (
                  <FormattedMessage {...messages.currentTenant} />
                )
              ) : !isCommercial ? (
                <FormattedMessage {...messages.priorResident} />
              ) : (
                <FormattedMessage {...messages.priorTenant} />
              )}
            </Typography>
            <i className="icon et-home" />
            <Typography variant={'inputLabel'}>
              <FormattedMessage {...messages.unitNumber} />
              :&nbsp;
            </Typography>
            {unitNumber && (
              <Link to={getUrlWithSelectedPropertyId(`/unit/${unitId}`)}>
                {unitNumber}
              </Link>
            )}
          </div>
        </div>
        <div className="row">
          <hr />
        </div>
        <div className="row">
          {visibleApplicants.map((applicant, i) => {
            // $FlowFixMe
            const customer = pathOr(
              {},
              ['applicantCustomer', 'customer'],
              applicant,
            );
            const emailAddress = propOr('', 'emailAddress', customer);
            const firstName = propOr('', 'firstName', customer);
            const lastName = propOr('', 'lastName', customer);
            const phoneNumber = propOr('', 'phoneNumber', customer);
            const preferredName = propOr('', 'preferredName', customer);
            return (
              <Col key={i} xs={12} sm={12}>
                <Typography variant={'h2'}>
                  <DashesIfNullOrUndefined data={firstName} />{' '}
                  {!isCommercial ? (
                    <DashesIfNullOrUndefined data={lastName} />
                  ) : null}
                </Typography>
                <ul className="details-list">
                  {!isCommercial ? null : (
                    <li>
                      <Typography variant={'inputLabel'}>
                        <FormattedMessage {...messages.dba} />: &nbsp;
                      </Typography>
                      <DashesIfNullOrUndefined data={preferredName} />
                    </li>
                  )}
                  <li>
                    <Typography variant={'inputLabel'}>
                      <FormattedMessage {...messages.phone} />:{' '}
                    </Typography>
                    <FormatPhone phoneNumber={phoneNumber} />
                  </li>
                  <li>
                    <Typography variant={'inputLabel'}>
                      <FormattedMessage {...messages.email} />:{' '}
                    </Typography>
                    <WordBreakSpan>
                      <a href={`mailto:${emailAddress}`}>
                        <DashesIfNullOrUndefined data={emailAddress} />
                      </a>
                    </WordBreakSpan>
                  </li>
                </ul>
              </Col>
            );
          })}
        </div>
      </Header>
    </ProfilePanel>
  );
};

export default ResidentProfileDetails;
