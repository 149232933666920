export const GET_ALL_CONTACT_TYPES = 'app/app/GET_ALL_CONTACT_TYPES';
export const GET_ALL_CONTACT_TYPES_SUCCESS =
  'app/app/GET_ALL_CONTACT_TYPES_SUCCESS';
export const GET_ALL_CONTACT_TYPES_ERROR =
  'app/app/GET_ALL_CONTACT_TYPES_ERROR';

export const GET_ALL_REFERRAL_TYPES = 'app/app/GET_ALL_REFERRAL_TYPES';
export const GET_ALL_REFERRAL_TYPES_SUCCESS =
  'app/app/GET_ALL_REFERRAL_TYPES_SUCCESS';
export const GET_ALL_REFERRAL_TYPES_ERROR =
  'app/app/GET_ALL_REFERRAL_TYPES_ERROR';

export const GET_PROSPECT_ASSIGNEES = 'app/app/GET_PROSPECT_ASSIGNEES';
export const GET_PROSPECT_ASSIGNEES_SUCCESS =
  'app/app/GET_PROSPECT_ASSIGNEES_SUCCESS';
export const GET_PROSPECT_ASSIGNEES_ERROR =
  'app/app/GET_PROSPECT_ASSIGNEES_ERROR';

export const GET_WORK_ORDER_ASSIGNEES = 'app/app/GET_WORK_ORDER_ASSIGNEES';
export const GET_WORK_ORDER_ASSIGNEES_SUCCESS =
  'app/app/GET_WORK_ORDER_ASSIGNEES_SUCCESS';
export const GET_WORK_ORDER_ASSIGNEES_ERROR =
  'app/app/GET_WORK_ORDER_ASSIGNEES_ERROR';

export const GET_ALL_ASSIGNEES = 'app/app/GET_ALL_ASSIGNEES';
export const GET_ALL_ASSIGNEES_SUCCESS = 'app/app/GET_ALL_ASSIGNEES_SUCCESS';
export const GET_ALL_ASSIGNEES_ERROR = 'app/app/GET_ALL_ASSIGNEES_ERROR';

export const GET_ALL_PET_TYPES = 'app/app/GET_ALL_PET_TYPES';
export const GET_ALL_PET_TYPES_SUCCESS = 'app/app/GET_ALL_PET_TYPES_SUCCESS';
export const GET_ALL_PET_TYPES_ERROR = 'app/app/GET_ALL_PET_TYPES_ERROR';

export const GET_ALL_PET_BREEDS = 'app/app/GET_ALL_PET_BREEDS';
export const GET_ALL_PET_BREEDS_SUCCESS = 'app/app/GET_ALL_PET_BREEDS_SUCCESS';
export const GET_ALL_PET_BREEDS_ERROR = 'app/app/GET_ALL_PET_BREEDS_ERROR';

export const GET_ALL_PROSPECT_STATUS = 'app/app/GET_ALL_PROSPECT_STATUS';
export const GET_ALL_PROSPECT_STATUS_SUCCESS =
  'app/app/GET_ALL_PROSPECT_STATUS_SUCCESS';
export const GET_ALL_PROSPECT_STATUS_ERROR =
  'app/app/GET_ALL_PROSPECT_STATUS_ERROR';

export const GET_ALL_WORK_ORDER_STATUS = 'app/app/GET_ALL_WORK_ORDER_STATUS';
export const GET_ALL_WORK_ORDER_STATUS_SUCCESS =
  'app/app/GET_ALL_WORK_ORDER_STATUS_SUCCESS';
export const GET_ALL_WORK_ORDER_STATUS_ERROR =
  'app/app/GET_ALL_WORK_ORDER_STATUS_ERROR';

export const GET_ALL_ACTIVITY_TYPES = 'app/app/GET_ALL_ACTIVITY_TYPES';
export const GET_ALL_ACTIVITY_TYPES_SUCCESS =
  'app/app/GET_ALL_ACTIVITY_TYPES_SUCCESS';
export const GET_ALL_ACTIVITY_TYPES_ERROR =
  'app/app/GET_ALL_ACTIVITY_TYPES_ERROR';

export const GET_ALL_NAVIGATION_OPTIONS = 'app/app/GET_ALL_NAVIGATION_OPTIONS';
export const GET_ALL_NAVIGATION_OPTIONS_SUCCESS =
  'app/app/GET_ALL_NAVIGATION_OPTIONS_SUCCESS';
export const GET_ALL_NAVIGATION_OPTIONS_ERROR =
  'app/app/GET_ALL_NAVIGATION_OPTIONS_ERROR';

export const LOG_OUT = 'app/app/LOG_OUT';

export const SELECT_PROPERTY = 'app/app/SELECT_PROPERTY';
export const SELECT_PORTFOLIO_SUMMARY = 'app/app/SELECT_PORTFOLIO_SUMMARY';
export const UPDATE_SELECTED_PROPERTY = 'app/app/UPDATE_SELECTED_PROPERTY';

export const UPDATE_CURRENT_USER = 'app/app/UPDATE_CURRENT_USER';

export const GET_ALL_NAME_SUFFIXES = 'app/app/GET_ALL_NAME_SUFFIXES';
export const GET_ALL_NAME_SUFFIXES_SUCCESS =
  'app/app/GET_ALL_NAME_SUFFIXES_SUCCESS';
export const GET_ALL_NAME_SUFFIXES_ERROR =
  'app/app/GET_ALL_NAME_SUFFIXES_ERROR';

export const GET_ALL_PROPERTY_CLASSES = 'app/app/GET_ALL_PROPERTY_CLASSES';
export const GET_ALL_PROPERTY_CLASSES_SUCCESS =
  'app/app/GET_ALL_PROPERTY_CLASSES_SUCCESS';
export const GET_ALL_PROPERTY_CLASSES_ERROR =
  'app/app/GET_ALL_PROPERTY_CLASSES_ERROR';

export const GET_ALL_RELATIONSHIPS = 'app/app/GET_ALL_RELATIONSHIPS';
export const GET_ALL_RELATIONSHIPS_SUCCESS =
  'app/app/GET_ALL_RELATIONSHIPS_SUCCESS';

export const GET_ALL_AFFORDABLE_RELATIONSHIPS =
  'app/app/GET_ALL_AFFORDABLE_RELATIONSHIPS';
export const GET_ALL_AFFORDABLE_RELATIONSHIPS_SUCCESS =
  'app/app/GET_ALL_AFFORDABLE_RELATIONSHIPS_SUCCESS';
export const GET_ALL_AFFORDABLE_RELATIONSHIPS_ERROR =
  'app/app/GET_ALL_AFFORDABLE_RELATIONSHIPS_ERROR';

export const GET_ALL_RELATIONSHIPS_ERROR =
  'app/app/GET_ALL_RELATIONSHIPS_ERROR';

export const GET_ALL_APPLICANT_TYPES = 'app/app/GET_ALL_APPLICANT_TYPES';
export const GET_ALL_APPLICANT_TYPES_SUCCESS =
  'app/app/GET_ALL_APPLICANT_TYPES_SUCCESS';
export const GET_ALL_APPLICANT_TYPES_ERROR =
  'app/app/GET_ALL_APPLICANT_TYPES_ERROR';

export const GET_ALL_FLOOR_PLANS = 'app/app/GET_ALL_FLOOR_PLANS';
export const GET_ALL_FLOOR_PLANS_SUCCESS =
  'app/app/GET_ALL_FLOOR_PLANS_SUCCESS';
export const GET_ALL_FLOOR_PLANS_ERROR = 'app/app/GET_ALL_FLOOR_PLANS_ERROR';

export const GET_ALL_COUNTRIES = 'app/app/GET_ALL_COUNTRIES';
export const GET_ALL_COUNTRIES_SUCCESS = 'app/app/GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_ERROR = 'app/app/GET_ALL_COUNTRIES_ERROR';

export const GET_ALL_STATES = 'app/app/GET_ALL_STATES';
export const GET_ALL_STATES_SUCCESS = 'app/app/GET_ALL_STATES_SUCCESS';
export const GET_ALL_STATES_ERROR = 'app/app/GET_ALL_STATES_ERROR';

export const GET_ALL_INCOME_TYPES = 'app/app/GET_ALL_INCOME_TYPES';
export const GET_ALL_INCOME_TYPES_SUCCESS =
  'app/app/GET_ALL_INCOME_TYPES_SUCCESS';
export const GET_ALL_INCOME_TYPES_ERROR = 'app/app/GET_ALL_INCOME_TYPES_ERROR';

export const GET_ALL_SPECIAL_NEEDS_DESIGNATION =
  'app/app/GET_ALL_SPECIAL_NEEDS_DESIGNATION';
export const GET_ALL_SPECIAL_NEEDS_DESIGNATION_SUCCESS =
  'app/app/GET_ALL_SPECIAL_NEEDS_DESIGNATION_SUCCESS';
export const GET_ALL_SPECIAL_NEEDS_DESIGNATION_ERROR =
  'app/app/GET_ALL_SPECIAL_NEEDS_DESIGNATION_ERROR';

export const GET_ALL_EMPLOYMENT_STATUS = 'app/app/GET_ALL_EMPLOYMENT_STATUS';
export const GET_ALL_EMPLOYMENT_STATUS_SUCCESS =
  'app/app/GET_ALL_EMPLOYMENT_STATUS_SUCCESS';
export const GET_ALL_EMPLOYMENT_STATUS_ERROR =
  'app/app/GET_ALL_EMPLOYMENT_STATUS_ERROR';

export const GET_ALL_WORK_ORDER_ISSUES = 'app/app/GET_ALL_WORK_ORDER_ISSUES';
export const GET_ALL_WORK_ORDER_ISSUES_SUCCESS =
  'app/app/GET_ALL_WORK_ORDER_ISSUES_SUCCESS';
export const GET_ALL_WORK_ORDER_ISSUES_ERROR =
  'app/app/GET_ALL_WORK_ORDER_ISSUES_ERROR';

export const GET_ALL_UNIT_LOCATIONS = 'app/app/GET_ALL_UNIT_LOCATIONS';
export const GET_ALL_UNIT_LOCATIONS_SUCCESS =
  'app/app/GET_ALL_UNIT_LOCATIONS_SUCCESS';
export const GET_ALL_UNIT_LOCATIONS_ERROR =
  'app/app/GET_ALL_UNIT_LOCATIONS_ERROR';

export const GET_ALL_COMMON_AREA_LOCATIONS =
  'app/app/GET_ALL_COMMON_AREA_LOCATIONS';
export const GET_ALL_COMMON_AREA_LOCATIONS_SUCCESS =
  'app/app/GET_ALL_COMMON_AREA_LOCATIONS_SUCCESS';
export const GET_ALL_COMMON_AREA_LOCATIONS_ERROR =
  'app/app/GET_ALL_COMMON_AREA_LOCATIONS_ERROR';

export const GET_ALL_ENTRY_PERMISSIONS = 'app/app/GET_ALL_ENTRY_PERMISSIONS';
export const GET_ALL_ENTRY_PERMISSIONS_SUCCESS =
  'app/app/GET_ALL_ENTRY_PERMISSIONS_SUCCESS';
export const GET_ALL_ENTRY_PERMISSIONS_ERROR =
  'app/app/GET_ALL_ENTRY_PERMISSIONS_ERROR';

export const GET_ALL_PRIORITY_LEVELS = 'app/app/GET_ALL_PRIORITY_LEVELS';
export const GET_ALL_PRIORITY_LEVELS_SUCCESS =
  'app/app/GET_ALL_PRIORITY_LEVELS_SUCCESS';
export const GET_ALL_PRIORITY_LEVELS_ERROR =
  'app/app/GET_ALL_PRIORITY_LEVELS_ERROR';

export const GET_ALL_MOVE_OUT_REASONS = 'app/app/GET_ALL_MOVE_OUT_REASONS';
export const GET_ALL_MOVE_OUT_REASONS_SUCCESS =
  'app/app/GET_ALL_MOVE_OUT_REASONS_SUCCESS';
export const GET_ALL_MOVE_OUT_REASONS_ERROR =
  'app/app/GET_ALL_MOVE_OUT_REASONS_ERROR';

export const SHOW_EXTEND_USER_SESSION_MODAL =
  'app/app/SHOW_EXTEND_USER_SESSION_MODAL';
export const HIDE_EXTEND_USER_SESSION_MODAL =
  'app/app/HIDE_EXTEND_USER_SESSION_MODAL';

export const GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY =
  'app/app/GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY';
export const GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_SUCCESS =
  'app/app/GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_SUCCESS';
export const GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_FAILURE =
  'app/app/GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_FAILURE';

export const GET_SELECTED_PROPERTY_TRANSACTION_CODES =
  'app/app/GET_SELECTED_PROPERTY_TRANSACTION_CODES';
export const GET_SELECTED_PROPERTY_TRANSACTION_CODES_SUCCESS =
  'app/app/GET_SELECTED_PROPERTY_TRANSACTION_CODES_SUCCESS';
export const GET_SELECTED_PROPERTY_TRANSACTION_CODES_FAILURE =
  'app/app/GET_SELECTED_PROPERTY_TRANSACTION_CODES_FAILURE';

export const GET_SELECTED_PROPERTY_SUBJOURNALS =
  'app/app/GET_SELECTED_PROPERTY_SUBJOURNALS';
export const GET_SELECTED_PROPERTY_SUBJOURNALS_SUCCESS =
  'app/app/GET_SELECTED_PROPERTY_SUBJOURNALS_SUCCESS';
export const GET_SELECTED_PROPERTY_SUBJOURNALS_FAILURE =
  'app/app/GET_SELECTED_PROPERTY_SUBJOURNALS_FAILURE';

export const GET_SELECTED_PROPERTY_FISCAL_PERIOD =
  'app/app/GET_SELECTED_PROPERTY_FISCAL_PERIOD';
export const GET_SELECTED_PROPERTY_FISCAL_PERIOD_SUCCESS =
  'app/app/GET_SELECTED_PROPERTY_FISCAL_PERIOD_SUCCESS';
export const GET_SELECTED_PROPERTY_FISCAL_PERIOD_FAILURE =
  'app/app/GET_SELECTED_PROPERTY_FISCAL_PERIOD_FAILURE';

export const GET_SELECTED_PROPERTY_BANK_ACCOUNTS =
  'app/app/GET_SELECTED_PROPERTY_BANK_ACCOUNTS';
export const GET_SELECTED_PROPERTY_BANK_ACCOUNTS_SUCCESS =
  'app/app/GET_SELECTED_PROPERTY_BANK_ACCOUNTS_SUCCESS';
export const GET_SELECTED_PROPERTY_BANK_ACCOUNTS_FAILURE =
  'app/app/GET_SELECTED_PROPERTY_BANK_ACCOUNTS_FAILURE';

export const GET_WRITEOFFS_FOR_CUSTOMER = 'app/app/GET_WRITEOFFS_FOR_CUSTOMER';
export const GET_WRITEOFFS_FOR_CUSTOMER_SUCCESS =
  'app/app/GET_WRITEOFFS_FOR_CUSTOMER_SUCCESS';
export const GET_WRITEOFFS_FOR_CUSTOMER_ERROR =
  'app/app/GET_WRITEOFFS_FOR_CUSTOMER_ERROR';

export const ADD_WRITE_OFFS_FOR_CUSTOMER =
  'app/app/ADD_WRITE_OFFS_FOR_CUSTOMER';

export const GET_ALL_APPLICANT_CANCELLATION_REASONS =
  'app/app/GET_ALL_APPLICANT_CANCELLATION_REASONS';
export const GET_ALL_APPLICANT_CANCELLATION_REASONS_ERROR =
  'app/app/GET_ALL_APPLICANT_CANCELLATION_REASONS_ERROR';
export const GET_ALL_APPLICANT_CANCELLATION_REASONS_SUCCESS =
  'app/app/GET_ALL_APPLICANT_CANCELLATION_REASONS_SUCCESS';

export const PROMPT_PRINT_DIALOG = 'app/app/PROMPT_PRINT_DIALOG';

export const PROMPT_TOASTER = 'app/app/PROMPT_TOASTER';

export const SOCKET_CONNECT = 'app/SOCKET_CONNECT';

export const SOCKET_DISCONNECT = 'app/SOCKET_DISCONNECT';

export const SOCKET_RE_AUTH = 'app/SOCKET_RE_AUTH';

export const GET_COMPLIANCE_STATUSES = 'app/app/GET_COMPLIANCE_STATUSES';
export const GET_COMPLIANCE_STATUSES_SUCCESS =
  'app/app/GET_COMPLIANCE_STATUSES_SUCCESS';
export const GET_COMPLIANCE_STATUSES_ERROR =
  'app/app/GET_COMPLIANCE_STATUSES_ERROR';

export const GET_RENTAL_ASSISTANCE_SOURCES =
  'app/app/GET_RENTAL_ASSISTANCE_SOURCES';
export const GET_RENTAL_ASSISTANCE_SOURCES_SUCCESS =
  'app/app/GET_RENTAL_ASSISTANCE_SOURCES_SUCCESS';
export const GET_RENTAL_ASSISTANCE_SOURCES_ERROR =
  'app/app/GET_RENTAL_ASSISTANCE_SOURCES_ERROR';

export const GET_UTILITY_ALLOWANCES = 'app/app/GET_UTILITY_ALLOWANCES';
export const GET_UTILITY_ALLOWANCES_SUCCESS =
  'app/app/GET_UTILITY_ALLOWANCES_SUCCESS';
export const GET_UTILITY_ALLOWANCES_ERROR =
  'app/app/GET_UTILITY_ALLOWANCES_ERROR';

export const GET_ALL_PRORATE_METHODS = 'app/app/GET_ALL_PRORATE_METHODS';
export const GET_ALL_PRORATE_METHODS_SUCCESS =
  'app/app/GET_ALL_PRORATE_METHODS_SUCCESS';
export const GET_ALL_PRORATE_METHODS_ERROR =
  'app/app/GET_ALL_PRORATE_METHODS_ERROR';

export const GET_ALL_MOVE_OUT_PRORATE_METHODS =
  'app/app/GET_ALL_MOVE_OUT_PRORATE_METHODS';
export const GET_ALL_MOVE_OUT_PRORATE_METHODS_SUCCESS =
  'app/app/GET_ALL_MOVE_OUT_PRORATE_METHODS_SUCCESS';
export const GET_ALL_MOVE_OUT_PRORATE_METHODS_ERROR =
  'app/app/GET_ALL_MOVE_OUT_PRORATE_METHODS_ERROR';

export const GET_ALL_UTILITY_ALLOWANCE_TYPES =
  'app/app/GET_ALL_UTILITY_ALLOWANCE_TYPES';
export const GET_ALL_UTILITY_ALLOWANCE_TYPES_SUCCESS =
  'app/app/GET_ALL_UTILITY_ALLOWANCE_TYPES_SUCCESS';
export const GET_ALL_UTILITY_ALLOWANCE_TYPES_ERROR =
  'app/app/GET_ALL_UTILITY_ALLOWANCE_TYPES_ERROR';

export const GET_PROPERTY_PAYMENT_PROVIDER =
  'app/app/GET_PROPERTY_PAYMENT_PROVIDER';
export const GET_PROPERTY_PAYMENT_PROVIDER_SUCCESS =
  'app/app/GET_PROPERTY_PAYMENT_PROVIDER_SUCCESS';
export const GET_PROPERTY_PAYMENT_PROVIDER_ERROR =
  'app/app/GET_PROPERTY_PAYMENT_PROVIDER_ERROR';

export const GET_ALL_HOUSING_SITUATIONS = 'app/app/GET_ALL_HOUSING_SITUATIONS';
export const GET_ALL_HOUSING_SITUATIONS_SUCCESS =
  'app/app/GET_ALL_HOUSING_SITUATIONS_SUCCESS';
export const GET_ALL_HOUSING_SITUATIONS_ERROR =
  'app/app/GET_ALL_HOUSING_SITUATIONS_ERROR';
