import React from 'react';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import {
  canGenerateLease,
  getRenewalAndTransferInfo,
} from '../../../utils/lease-helpers.js';
import { getSelectedPropertyClassType } from '../../App/selectors';
import type { GlobalState } from '../../App/types';

import ResidentButtons from './ResidentButtons';
import RenewalButtons from './RenewalButtons';
import TransferButtons from './TransferButtons';
import messages from './messages';
import { getFormSyncWarnings, isValid, isDirty } from 'redux-form';

type Props = {
  applicationId: string,
  doNotRenew: boolean,
  formDirty: boolean,
  history: Function,
  handleLeaseChange: Function,
  handleRenewal: Function,
  handleTransfer: Function,
  handleTransferComplete: Function,
  hasOpenAffordableQualifications: boolean,
  isDisabledRenewal: boolean,
  isDisabledTransfer: boolean,
  isResident: boolean,
  lease: Object,
  leasesOptions: Array<Object>,
  propertyClassType: string,
  removeLease: Function,
  residentId: string,
  selectPreviousLease: Function,
  submittingRenewal: boolean,
  updateLeaseToNewRenewalLease: Function,
  startTransferMessage?: string,
  startRenewalMessage?: string,
  underEviction: boolean,
  noticeToVacate: Object | null,
  canComplete: boolean,
  isDirty: boolean,
  flags: Object,
  application: Object,
};

export const ResidentHeader = ({
  applicationId,
  doNotRenew,
  history,
  handleLeaseChange,
  handleRenewal,
  handleTransfer,
  handleTransferComplete,
  hasOpenAffordableQualifications,
  isDisabledRenewal,
  isDisabledTransfer,
  isResident,
  lease,
  leasesOptions,
  propertyClassType,
  removeLease,
  residentId,
  selectPreviousLease,
  submittingRenewal,
  updateLeaseToNewRenewalLease,
  startTransferMessage,
  startRenewalMessage,
  underEviction,
  noticeToVacate,
  canComplete,
  isDirty,
  flags,
  application,
}: Props) => {
  const { affordableTransfers } = flags ?? {};
  const applicationPropertyClassType = application?.propertyClass?.name;
  const isAffordableApplication = applicationPropertyClassType === 'Affordable';
  const transferEnabled = affordableTransfers
    ? ['Affordable', 'Conventional'].includes(
        applicationPropertyClassType ?? propertyClassType,
      )
    : [propertyClassType, applicationPropertyClassType].includes(
        'Conventional',
      );
  // $FlowFixMe
  const { isOngoingTransfer, isOngoingRenewal } =
    getRenewalAndTransferInfo(lease);
  const showTransferDisabledReason =
    transferEnabled &&
    isDisabledTransfer &&
    !isOngoingTransfer &&
    !isOngoingRenewal;
  const leaseRenewalHandler = handleRenewal(updateLeaseToNewRenewalLease);
  const showResidentButtons =
    isResident && !lease.isRenewal && !lease.isTransfer;
  const showRenewalButtons = isResident && lease.isRenewal;
  const showTransferButtons = isResident && lease.isTransfer;
  const disableAffordableTransfer =
    affordableTransfers &&
    isAffordableApplication &&
    hasOpenAffordableQualifications;

  return (
    <Row className="padbottom10">
      <Col md={6}>
        <Row>
          <Col xs={6}>
            <h2>
              <i className="et-clipboard text-blue padright10" />
              <FormattedMessage {...messages.leaseBasicsLabel} />
            </h2>
          </Col>
          <Col xs={5}>
            <select
              className="form-control"
              onChange={handleLeaseChange}
              value={lease.id}
            >
              {leasesOptions.map((option) => (
                <option
                  key={option.value || 'undefinedValue'}
                  value={option.value}
                  disabled={option.disabled ? option.disabled : false}
                >
                  {option.text}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Col>
      {showResidentButtons && (
        <ResidentButtons
          disableAffordableTransfer={disableAffordableTransfer}
          handleTransfer={handleTransfer}
          isDisabledRenewal={isDisabledRenewal}
          isDisabledTransfer={isDisabledTransfer}
          leaseRenewalHandler={leaseRenewalHandler}
          submittingRenewal={submittingRenewal}
          showTransferDisabledReason={showTransferDisabledReason}
          transferEnabled={transferEnabled}
          startTransferMessage={startTransferMessage}
          startRenewalMessage={startRenewalMessage}
          noticeToVacate={noticeToVacate}
        />
      )}
      {showRenewalButtons && (
        <RenewalButtons
          applicationId={applicationId}
          doNotRenew={doNotRenew}
          underEviction={underEviction}
          history={history}
          lease={lease}
          removeLease={removeLease}
          residentId={residentId}
          selectPreviousLease={selectPreviousLease}
          canComplete={canComplete}
          formDirty={isDirty}
        />
      )}
      {showTransferButtons && (
        <TransferButtons
          applicationId={applicationId}
          doNotRenew={doNotRenew}
          underEviction={underEviction}
          handleTransferComplete={handleTransferComplete}
          lease={lease}
          removeLease={removeLease}
          residentId={residentId}
          selectPreviousLease={selectPreviousLease}
          canComplete={canComplete}
          househouldId={application?.householdId}
          formDirty={isDirty}
        />
      )}
    </Row>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const formSyncWarnings = getFormSyncWarnings('FormLeaseDataTab')(state);
  const formIsValid = isValid('FormLeaseDataTab')(state);

  return {
    propertyClassType: getSelectedPropertyClassType(state),
    canComplete: canGenerateLease(formSyncWarnings, formIsValid),
    isDirty: isDirty('FormLeaseDataTab')(state),
  };
};

export default withLDConsumer()(connect(mapStateToProps)(ResidentHeader));
