import { DateRangePicker } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  InputLabel,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  CalStartIcon,
  CalEndIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';

export default function CreateCentralizedReportForm({
  dateRangeStart,
  dateRangeEnd,
  setDateRangeStart,
  setDateRangeEnd,
}) {
  return (
    <>
      <Box>
        <Box
          sx={{
            marginBottom: 3,
          }}
        >
          <DateRangePicker
            FromProps={{
              name: 'dateRangeStart',
              value: dateRangeStart,
              onAccept: (date) => setDateRangeStart(date),
              onChange: (date) => setDateRangeStart(date),
              handleClear: () => setDateRangeStart(null),
              label: 'From',
              helperText: 'Required',
              disableFuture: true,
              CalendarIcon: () => <CalStartIcon htmlColor={grey.dark} />,
              placeholder: 'MM/DD/YYYY',
            }}
            ToProps={{
              name: 'dateRangeEnd',
              value: dateRangeEnd,
              onAccept: (date) => setDateRangeEnd(date),
              onChange: (date) => setDateRangeEnd(date),
              handleClear: () => setDateRangeEnd(null),
              label: 'To',
              helperText: '',
              disableFuture: true,
              CalendarIcon: () => <CalEndIcon htmlColor={grey.dark} />,
              placeholder: 'MM/DD/YYYY',
            }}
          />
        </Box>
        <InputLabel>
          <Box
            sx={{
              paddingBottom: 1,
            }}
          >
            Format
          </Box>
          <Box
            sx={{
              paddingLeft: 1,
            }}
          >
            <Checkbox
              checked
              disabled
              label={
                <Box
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  CSV
                </Box>
              }
            />
          </Box>
        </InputLabel>
      </Box>
    </>
  );
}
