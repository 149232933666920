import React, { Component } from 'react';
import { toPng } from 'html-to-image';
import { bindActionCreators } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Tabs } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import Loadable from 'react-loadable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isNil, not, pathOr } from 'ramda';
import {
  getAllAssignees,
  selectPortfolioSummary,
  promptToaster,
  logOut,
} from '../App/actions';
import { assignApplication } from '../ApplicationProfile/actions';
import { assignProspect } from '../ManageProspects/actions';
import { ASSIGN_TYPE } from './TaskTab/constants';
import * as homeActions from './actions';
import { TaskTab } from './TaskTab/';
import { default as TaskTabDeprecated } from './TaskTabDeprecated/';
import ComplianceOverviewOLD from '../ComplianceOverview_DEPRECATED';
import ComplianceOverview from '../ComplianceOverview';
import ComplianceOverviewV2 from '../ComplianceOverview/indexV2';
import PropertyComplianceTab from './PropertyComplianceTab';
import CalendarTab from './CalendarTab/';
import type { Activity } from '../ProspectProfile/types';
import type { Property, User, UserPermission } from '../App/types';
import type { TaskInformation } from './types';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import Overview from '../Overview';
import Spinner from '../../components/GifSpinner';
import KpiService from '../../services/kpiService';
// $FlowFixMe
import FileSaver from 'file-saver';
import ReputationMgmtTab from './ReputationMgmtTab';
import type { FeatureFlags } from '../../types';
import PortfolioV2 from '../Portfolio/indexV2';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './messages';
import { toastr } from 'react-redux-toastr';

const LoadableLeasingActivity = Loadable({
  loader: () => import('../LeasingActivity'),
  loading: Spinner,
});

const LoadablePortfolio = Loadable({
  loader: () => import('../Portfolio'),
  loading: Spinner,
});

const LoadablePropertyInfo = Loadable({
  loader: () => import('../ViewProperty'),
  loading: Spinner,
});

type StateProps = {
  activities: Array<Activity>,
  assignees: Array<User>,
  currentPermissions: Array<UserPermission>,
  currentUser: User,
  selectedProperty?: Property,
  selectedAssignee: string,
  activeKey: string,
  taskInformation: TaskInformation,
};

type State = {
  isTasksLoading: boolean,
};

type InjectedProps = {
  actions: Object,
  history: Object,
  intl: any,
};

export const HomeTabs = {
  OVERVIEW: '1',
  TASK: '2',
  CALENDAR: '3',
  LEASING_ACTIVITY: '4',
  COMPLIANCE: '5', // compliance overview, when no property is selected
  PROPERTY_INFO: '6',
  PROPERTY_COMPLIANCE: '7', // compliance when a property is selected (set asides)
  REPUTATION_MGMT: '8',
};

const reputationMgmtPermissionsForTab = [
  'rep-mgmt-view-ratings-and-reviews',
  'rep-mgmt-respond-and-delete-reviews',
  'rep-mgmt-manage-rating-goal',
];

export class HomePage extends Component<StateProps & InjectedProps, State> {
  constructor(props: State & Props) {
    super(props);
    this.state = {
      isTasksLoading: false,
      isExportingPDF: false,
    };
  }

  loadTabData(key) {
    switch (key) {
      case HomeTabs.TASK:
        this.props.actions.getTaskInformation(this.toggleTasksLoadingState);
        break;
      case HomeTabs.CALENDAR:
        this.props.actions.setSelectedAssignee('all');
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    this.props.actions.getAllAssignees();

    // Get tab from URL
    const urlParams = new URLSearchParams(window.location.search);
    let key = urlParams.get('tab');

    // If no tab is found in the URL, use default one
    if (!key) key = HomeTabs.OVERVIEW;
    this.switchTabURL(key);
    this.props.actions.setActiveKey(key);
    this.loadTabData(key);
  }

  componentDidUpdate(prevProps: any) {
    const queryString = this.getQueryString();
    if (queryString.tab && queryString.tab !== this.props.activeKey) {
      this.props.actions.setActiveKey(queryString.tab);
    }
    if (
      this.props.activeKey === HomeTabs.COMPLIANCE &&
      this.props.selectedProperty
    ) {
      this.props.actions.selectPortfolioSummary();
      this.props.actions.setActiveKey(HomeTabs.COMPLIANCE);
    }
    if (prevProps.selectedProperty !== this.props.selectedProperty) {
      this.props.actions.setActiveKey(HomeTabs.OVERVIEW);
      this.props.actions.clearTaskInformation();
      this.props.actions.getAllAssignees();
    }
  }

  toggleTasksLoadingState: any = (condition) => {
    this.setState({ isTasksLoading: condition });
  };

  getQueryString: any = () => {
    return parse(
      // $FlowFixMe
      pathOr('', ['location', 'search'], this.props).replace('?', ''),
    );
  };

  handleChangeAssignee: any = (event: any) => {
    this.props.actions.getAllActivitiesByAssignee(event.target.value);
    this.props.actions.setSelectedAssignee(event.target.value);
  };

  handleTaskSummaryOnAssigneeChange: any = (
    assignedToId: string,
    applicationOrProspectId: string,
    type: Object,
  ) => {
    if (type === ASSIGN_TYPE.APPLICATION) {
      this.props.actions.assignApplication({
        id: applicationOrProspectId,
        assignedToId,
        // $FlowFixMe
        propertyId: pathOr(null, ['selectedProperty', 'id'], this.props),
        tasks: true,
      });
    } else {
      this.props.actions.assignProspect({
        id: applicationOrProspectId,
        assignedToId,
        // $FlowFixMe
        propertyId: pathOr(null, ['selectedProperty', 'id'], this.props),
        tasks: true,
      });
    }
  };

  generateAssignees: any = () => {
    return this.props && this.props.assignees
      ? this.props.assignees.map((assignee) => ({
          value: assignee.id,
          text: `${assignee.firstName} ${assignee.lastName}`,
          disabled: false,
        }))
      : [];
  };

  switchTabURL: any = (key) => {
    const selectedPropertyId =
      this.props.match?.params?.propertyId ||
      (this.props.selectedProperty?.id ?? '');
    const propertyIdURLString = selectedPropertyId
      ? `/property/${selectedPropertyId}/`
      : '';
    const url = `${propertyIdURLString}?tab=${key}`;
    this.props.history.replace(url);
  };

  handleOnSelect: any = (_, key: string) => {
    this.props.actions.setActiveKey(key);
    this.props.actions.clearTaskInformation();
    this.switchTabURL(key);
    this.loadTabData(key);
  };

  generateScreenShotPdf = async () => {
    this.setState({ isExportingPDF: true });

    const wrapper = document.getElementById('1a');
    const selectedPropertyName = this.props.selectedProperty?.name ?? '';
    const pdfWidth = 1950;

    const initialWrapperStyles = {
      width: wrapper.style.width,
      height: wrapper.style.height,
    };

    wrapper.style.width = `${pdfWidth}px`;
    await new Promise((resolve) => setTimeout(resolve, 300));
    const pdfHeight = wrapper.scrollHeight;
    wrapper.style.height = `${pdfHeight}px`;

    // wait for the wrapper to be resized
    await new Promise((resolve) => setTimeout(resolve, 500));

    const imageData = await toPng(wrapper, { backgroundColor: '#FFFFFF' });

    // restore the original styles
    wrapper.style.width = initialWrapperStyles.width;
    wrapper.style.height = initialWrapperStyles.height;

    try {
      const kpiService = new KpiService();
      const response = await kpiService.downloadKpiHomeScreenPrint(
        imageData,
        pdfWidth,
        pdfHeight,
      );
      FileSaver.saveAs(response, `${selectedPropertyName}.pdf`);
    } catch (error) {
      toastr.error('Error downloading PDF', error.message);
    }

    this.setState({ isExportingPDF: false });
  };

  render(): any {
    // $FlowFixMe
    const { activeKey, currentUser } = this.props;
    const flags: FeatureFlags = this.props.flags;
    const assignees = this.generateAssignees();

    if (!this.props.currentPermissions || !currentUser) {
      this.props.actions.logOut();
      return null;
    }

    const hasPermissions = this.props.currentPermissions.some(
      (p) => p.scope === 'activity-read',
    );
    const hasCompliancePermission = this.props.currentPermissions.some(
      (p) => p.scope === 'compliance-overview-read',
    );
    const hasPropertyCompliancePermission = this.props.currentPermissions.some(
      (p) => p.scope === 'compliance-tab-view',
    );
    const hasSelectedProperty = not(isNil(this.props.selectedProperty));
    const hasSelectedPropertyAndPermissions = !!(
      hasSelectedProperty && hasPermissions
    );
    // $FlowFixMe
    const usersProperties = pathOr([], ['properties'], currentUser);
    // $FlowFixMe
    const selectedPropertyClass = pathOr(
      '',
      ['selectedProperty', 'propertyClass', 'name'],
      this.props,
    );

    const reputationMgmtFeatureFlagIsOn = flags.reputationMgmtRelease1;
    const hideFortressDashboardFlagIsOn = flags?.hideFortressDashboard;
    const enablePortfolioOverviewKPIs = flags?.portfolioOverviewKpIs;
    const hasAtLeastOneReputationMgmtPermission =
      this.props.currentPermissions.find((p) =>
        reputationMgmtPermissionsForTab.includes(p.scope),
      ) !== undefined;

    // Property Compliance Tab only for Mixed and Affordable properties, if user has correct permission
    const showPropertyComplianceTab =
      hasSelectedPropertyAndPermissions &&
      hasPropertyCompliancePermission &&
      ['Affordable', 'Mixed'].includes(selectedPropertyClass);

    const isReputationMgmtTabShown =
      hasSelectedProperty &&
      reputationMgmtFeatureFlagIsOn &&
      hasAtLeastOneReputationMgmtPermission;

    const isActive = {
      OVERVIEW: HomeTabs.OVERVIEW === activeKey,
      TASK: HomeTabs.TASK === activeKey,
      CALENDAR: HomeTabs.CALENDAR === activeKey,
      LEASING_ACTIVITY: HomeTabs.LEASING_ACTIVITY === activeKey,
      COMPLIANCE: HomeTabs.COMPLIANCE === activeKey,
      PROPERTY_INFO: HomeTabs.PROPERTY_INFO === activeKey,
      PROPERTY_COMPLIANCE: HomeTabs.PROPERTY_COMPLIANCE === activeKey,
      REPUTATION_MGMT: HomeTabs.REPUTATION_MGMT === activeKey,
    };

    const tabs = [
      {
        value: HomeTabs.OVERVIEW,
        label: <FormattedMessage {...messages.overview} />,
      },
      {
        value: HomeTabs.COMPLIANCE,
        label: <FormattedMessage {...messages.compliance} />,
      },
      {
        value: HomeTabs.TASK,
        label: <FormattedMessage {...messages.task} />,
      },
      {
        value: HomeTabs.CALENDAR,
        label: <FormattedMessage {...messages.calendar} />,
      },
      {
        value: HomeTabs.LEASING_ACTIVITY,
        label: <FormattedMessage {...messages.leasingActivity} />,
      },
      {
        value: HomeTabs.PROPERTY_INFO,
        label: <FormattedMessage {...messages.propertyInfo} />,
      },
      {
        value: HomeTabs.PROPERTY_COMPLIANCE,
        label: <FormattedMessage {...messages.propertyCompliance} />,
      },
      {
        value: HomeTabs.REPUTATION_MGMT,
        label: <FormattedMessage {...messages.reputationMgmtTabTitle} />,
      },
    ].filter(({ value }) => {
      if (HomeTabs.TASK === value || HomeTabs.PROPERTY_INFO === value) {
        return hasSelectedProperty;
      }

      if (HomeTabs.CALENDAR === value || HomeTabs.LEASING_ACTIVITY === value) {
        return hasSelectedPropertyAndPermissions;
      }

      if (HomeTabs.COMPLIANCE === value) {
        return hasCompliancePermission && !hasSelectedProperty;
      }

      if (HomeTabs.PROPERTY_COMPLIANCE === value) {
        return showPropertyComplianceTab;
      }

      if (HomeTabs.REPUTATION_MGMT === value) {
        return isReputationMgmtTabShown;
      }

      return true;
    });

    return (
      <Box height={'100%'} sx={{ marginTop: 2 }}>
        <Tabs
          id="home-tab"
          onChange={this.handleOnSelect}
          value={activeKey}
          tabs={tabs}
          variant={'scrollable'}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        />
        <Box
          id={'home-tab-content'}
          sx={({ breakpoints }) => ({
            p: 2,
            [breakpoints.down('md')]: {
              display: 'flex',
              flexDirection: 'column',
            },
          })}
        >
          {isActive.OVERVIEW && (
            <>
              {hasSelectedProperty && (
                <SubmitButton
                  isSubmitting={this.state.isExportingPDF}
                  sx={({ breakpoints }) => ({
                    [breakpoints.up('md')]: {
                      right: 16,
                      zIndex: 1101,
                      position: 'fixed',
                      top: 43,
                    },
                    [breakpoints.down('md')]: {
                      marginLeft: 'auto',
                      marginBottom: 1,
                    },
                  })}
                  startIcon={<DownloadIcon />}
                  onClick={this.generateScreenShotPdf}
                >
                  PDF
                </SubmitButton>
              )}
              {hideFortressDashboardFlagIsOn &&
              hasSelectedPropertyAndPermissions ? (
                <Typography
                  variant={'h2'}
                  align={'center'}
                  width={'100%'}
                  padding={8}
                >
                  This page is currently under maintenance. <br />
                  We apologize for any inconvenience.
                </Typography>
              ) : (
                <>
                  {hasSelectedPropertyAndPermissions && (
                    <Overview
                      history={this.props.history}
                      activeKey={this.props.activeKey}
                      tabKey={HomeTabs.OVERVIEW}
                      isExportingPDF={this.state.isExportingPDF}
                    />
                  )}
                </>
              )}
              {hasPermissions &&
                !hasSelectedProperty &&
                usersProperties.length > 1 &&
                (enablePortfolioOverviewKPIs ? (
                  <PortfolioV2 />
                ) : (
                  <LoadablePortfolio />
                ))}
            </>
          )}
          {hasCompliancePermission &&
            !hasSelectedProperty &&
            isActive.COMPLIANCE &&
            (flags.complianceOverviewNewTable ? (
              <ComplianceOverviewV2 />
            ) : flags.deleteDocumentsFeature ? (
              <ComplianceOverview
                isActive={isActive.COMPLIANCE}
                organizationId={this.props.currentUser.organizationId}
                promptToaster={this.props.actions.promptToaster}
              />
            ) : (
              <ComplianceOverviewOLD
                isActive={isActive.COMPLIANCE}
                organizationId={this.props.currentUser.organizationId}
                promptToaster={this.props.actions.promptToaster}
              />
            ))}
          {isActive.TASK &&
            (flags.taskSummarySort ? (
              <TaskTab
                tasks={this.props.taskInformation}
                isTasksLoading={this.state.isTasksLoading}
                assignees={assignees}
                onChangeAssignee={this.handleTaskSummaryOnAssigneeChange}
                selectedPropertyClass={selectedPropertyClass}
                intl={this.props.intl}
              />
            ) : (
              <TaskTabDeprecated
                tasks={this.props.taskInformation}
                isTasksLoading={this.state.isTasksLoading}
                assignees={assignees}
                onChangeAssignee={this.handleTaskSummaryOnAssigneeChange}
                selectedPropertyClass={selectedPropertyClass}
                intl={this.props.intl}
              />
            ))}
          {isActive.CALENDAR && hasSelectedPropertyAndPermissions && (
            <CalendarTab
              activities={this.props.activities}
              assignees={assignees}
              intl={this.props.intl}
              onChangeAssignee={this.handleChangeAssignee}
              selectedAssignee={this.props.selectedAssignee}
              store={this.context.store}
            />
          )}
          {isActive.LEASING_ACTIVITY && hasSelectedPropertyAndPermissions && (
            <LoadableLeasingActivity />
          )}
          {hasSelectedProperty && isActive.PROPERTY_INFO && (
            <LoadablePropertyInfo history={this.props.history} />
          )}
          {showPropertyComplianceTab && isActive.PROPERTY_COMPLIANCE && (
            <PropertyComplianceTab />
          )}
          {isReputationMgmtTabShown && isActive.REPUTATION_MGMT && (
            <ReputationMgmtTab />
          )}
        </Box>
      </Box>
    );
  }
}

HomePage.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = ({ app, home }: any): StateProps => {
  return {
    assignees: app.allAssignees,
    currentUser: app.currentUser?.user,
    currentPermissions: app.currentUser?.permissions,
    selectedProperty: app.selectedProperty,
    activities: home.activities,
    hasSelectedProperty: home.hasSelectedProperty,
    selectedAssignee: home.selectedAssignee,
    activeKey: home.activeKey,
    taskInformation: home.taskInformation,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...homeActions,
      assignApplication,
      assignProspect,
      getAllAssignees,
      selectPortfolioSummary,
      promptToaster,
      logOut,
    },
    dispatch,
  );
  return { actions };
}

const InjectedHomePage = injectIntl(HomePage);

// $FlowFixMe
export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedHomePage),
);
