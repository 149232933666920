import type {
  NavigationOption,
  UserPermission,
} from '../../containers/App/types';
import { innerJoin, intersection, isNil, pathOr } from 'ramda';
import { featureFlagNameMap } from './constants';

export const filterNavigationOptionsByPermissionsPropertyClass = ({
  navigationOptions,
  selectedProperty,
  userPermissions,
  selectedPropertySubjournals,
  paymentProvider,
  flags,
}): Array<NavigationOption> => {
  const propertyClass = pathOr('', ['propertyClass', 'name'], selectedProperty);

  const permissionsFilter = innerJoin(
    (userPermission: UserPermission, navOptionScope: string) =>
      userPermission.scope === navOptionScope &&
      (!userPermission.propertyLevel || !isNil(selectedProperty)),
    userPermissions,
  );

  const propertyClassFilter = (option: Object): boolean => {
    return !(option.affordableOnly && propertyClass === 'Conventional');
  };
  const hasCommercialFloorPlans = pathOr(
    'NONE',
    ['hasCommercialFloorPlans'],
    selectedProperty,
  );
  const commercialFloorPlanFilter = (option: Object): boolean => {
    if (
      option.name === 'Manage Commercial Rent Roll' ||
      option.name === 'Add Commercial Applicant'
    ) {
      return (
        hasCommercialFloorPlans === 'ALL' || hasCommercialFloorPlans === 'SOME'
      );
    }
    if (option.name === 'Manage Rent Roll') {
      return (
        hasCommercialFloorPlans === 'NONE' || hasCommercialFloorPlans === 'SOME'
      );
    }
    if (
      option.name === 'Manage Prospects' ||
      option.name === 'Add Prospect' ||
      option.name === 'Manage Prior Residents'
    ) {
      return hasCommercialFloorPlans === 'ALL' ? false : true;
    }
    if (option.name === 'Manage Prior Tenants') {
      return hasCommercialFloorPlans !== 'ALL' ? false : true;
    }

    return true;
  };
  const subsidySubjournalFilter = (option) => {
    if (option.name === 'Manage Subsidy Payments') {
      const hasSubsidySubjournal = selectedPropertySubjournals.filter(
        (subjournal) => {
          const subjournalType =
            subjournal?.masterSubjournalType?.description ?? null;
          return subjournalType === 'Subsidy';
        },
      );
      return hasSubsidySubjournal.length ? true : false;
    }
    return true;
  };
  const paymentProviderFilter = (option) => {
    if (option.name === 'Manage Online Payments') {
      return paymentProvider?.paymentProvider === 'FORTRESS';
    }
    return true;
  };
  const selectedPropertyFilter = (option) => {
    if (option.name === 'Prorate Calculator') {
      // Prorate calculator needs a selected property since it uses prop tx codes
      return !isNil(selectedProperty);
    }
    return true;
  };

  const featureFlagFilter = (option) => {
    let optionName = option.name;
    if (option.portfolioSummaryOnly) {
      optionName = optionName + '-portfolioSummaryOnly';
    }
    let featureFlagName = featureFlagNameMap[optionName];
    const enabled = flags[featureFlagName] ?? true;
    return enabled;
  };

  const checkPermissionsOnlyFilter = innerJoin(
    (userPermission: UserPermission, navOptionScope: string) =>
      userPermission.scope === navOptionScope,
    userPermissions,
  );

  const filteredOptions = navigationOptions.map((option) => {
    const children = option.children.filter((subOption) => {
      const filteredByPermissions =
        (subOption.portfolioSummaryOnly
          ? checkPermissionsOnlyFilter(subOption.scope)
          : permissionsFilter(subOption.scope)
        )?.length > 0 || subOption.scope?.length === 0;

      const filteredbyPropertyClass = propertyClassFilter(subOption);
      const filteredbyCommercialFloorPlan =
        commercialFloorPlanFilter(subOption);
      const filteredByPropertySubjournals = subsidySubjournalFilter(subOption);
      const filteredByPaymentProvider = paymentProviderFilter(subOption);
      const filteredByFeatureFlags = featureFlagFilter(subOption);
      return (
        filteredByPermissions &&
        filteredbyPropertyClass &&
        filteredbyCommercialFloorPlan &&
        filteredByPropertySubjournals &&
        filteredByPaymentProvider &&
        filteredByFeatureFlags
      );
    });
    return {
      ...option,
      children,
    };
  });

  const filteredForPortfolioSummary = (option) => {
    if (option.portfolioSummaryOnly) {
      if (!selectedProperty?.id) return true;
      if (selectedProperty?.id) return false;
    }
    return true;
  };

  const checkUserPermissions = (scope: Array<string>) => {
    const userScopes = userPermissions.map((permission) => permission.scope);
    return intersection(scope, userScopes).length > 0;
  };

  const options = filteredOptions.filter((option) => {
    const filteredBySelectedProperty = selectedPropertyFilter(option);
    const filteredByFeatureFlags = featureFlagFilter(option);
    let filteredByPermissions =
      permissionsFilter(option.scope).length > 0 || option.scope.length === 0;

    const showInPortfolioSummaryOnly = filteredForPortfolioSummary(option);

    if (option.portfolioSummaryOnly) {
      filteredByPermissions =
        checkPermissionsOnlyFilter(option.scope).length > 0 ||
        option.scope.length === 0;
    }

    return (
      filteredByFeatureFlags &&
      filteredByPermissions &&
      filteredBySelectedProperty &&
      showInPortfolioSummaryOnly
    );
  });

  const optionsWithNewPropertyURL = options.map((op) => {
    const selectedPropertyId = selectedProperty?.id;
    const selectedPropertyUrl = selectedPropertyId
      ? `/property/${selectedPropertyId}`
      : '';

    if (op.children) {
      op.children = op.children.map((childOp) => {
        const childUrlWithPropertyId = `${selectedPropertyUrl}${childOp.url}`;
        const url = childOp.hasPropertyURL
          ? childUrlWithPropertyId
          : childOp.url;
        return { ...childOp, url };
      });
    }
    const urlWithPropertyId = op.url ? `${selectedPropertyUrl}${op.url}` : null;
    const url = op.hasPropertyURL ? urlWithPropertyId : op.url;
    return {
      ...op,
      url,
    };
  });

  const newNavigationOptions = [...optionsWithNewPropertyURL];
  const { centralizedWorkOrders } = flags;

  if (
    centralizedWorkOrders &&
    !selectedProperty &&
    checkUserPermissions(['workorder-read'])
  ) {
    newNavigationOptions.push({
      children: [
        {
          icon: null,
          id: '1',
          name: 'Manage Work Orders',
          openTab: false,
          order: 2,
          scope: ['workorder-read'],
          translations: {
            en: 'Manage Work Orders',
            es: 'Manage Work Orders',
          },
          url: '/work-orders',
          parent: '1',
        },
      ],
      icon: 'et-services',
      name: 'Maintenance',
      openTab: false,
      order: 3,
      scope: [],
      translations: { en: 'Maintenance', es: 'Maintenance' },
    });
  }

  return newNavigationOptions;
};

export const determineNavigationToOnClick = ({
  url,
  openTab,
  setOpen,
  isXs,
}) => {
  if (openTab && url) {
    return {
      onClick: () => {
        if (isXs) {
          setOpen(false);
        }
      },
      target: '_blank',
      href: url,
    };
  } else if (url) {
    return {
      onClick: () => {
        if (isXs) {
          setOpen(false);
        }
      },
      to: url,
    };
  }
  return null;
};

export const getOptionNotificationsBadgeType = (
  twoWayNotifications,
  optionName,
) => {
  const getBadgeType = (category) => {
    const { hasNewMessages, hasUnresolvedConversations } =
      twoWayNotifications[category];
    if (hasNewMessages) return 'new';
    if (hasUnresolvedConversations) return 'unresolved';
    return null;
  };
  const getBadgeTypeForGroup = (categories) => {
    const badgeTypes = categories.map((category) => getBadgeType(category));
    if (badgeTypes.includes('new')) return 'new';
    if (badgeTypes.includes('unresolved')) return 'unresolved';
    return null;
  };

  if (optionName === 'Manage Prospects') return getBadgeType('prospects');
  if (optionName === 'Manage Applications') return getBadgeType('applications');
  if (optionName === 'Manage Rent Roll') return getBadgeType('rentRoll');
  if (optionName === 'Manage Prior Residents')
    return getBadgeType('priorResidents');
  if (optionName === 'Sales')
    return getBadgeTypeForGroup(['prospects', 'applications']);
  if (optionName === 'People')
    return getBadgeTypeForGroup(['rentRoll', 'priorResidents']);

  return null;
};
