import { useMemo, useCallback } from 'react';
import { useQuery } from 'react-query';
import AllExpiringLeasesService from '../../services/allExpiringLeasesService';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { PROPERTY_PATH_MAP, HEADERS } from './constants';
import * as utils from '../ManageLeaseExpirations/utils';
import {
  appendFilterTextToCSV,
  processDataToCSV,
} from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import {
  formatDateDB,
  formatDateDisplayLocal,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import moment from 'moment';
import { dateDiffInDays } from '../../utils/date-helpers';
import componentMessages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';
import useUniqueTableName from '../../hooks/useUniqueTableName';

const messages = { ...componentMessages };

export const useAllManageLeaseExpirations = ({
  NAME,
  organizationId,
  selectedMetric,
}) => {
  const name = useUniqueTableName(NAME);

  const formatMessage = useFormatMessage();
  const { data, isLoading } = useFetchAllManageLeaseExpirations({
    organizationId,
  });
  const results = useMemo(() => data?.rows ?? [], [data?.rows]);

  const quickFilterResults = useMemo(() => {
    const today = new Date();
    const thisMonth = today.toLocaleString('en-US', { month: 'long' });
    const nextMonth = moment()
      .add(1, 'months')
      .toDate()
      .toLocaleString('en-US', { month: 'long' });
    const nextTwoMonths = moment()
      .add(2, 'months')
      .toDate()
      .toLocaleString('en-US', { month: 'long' });
    const nextThreeMonths = moment()
      .add(3, 'months')
      .toDate()
      .toLocaleString('en-US', { month: 'long' });

    switch (selectedMetric) {
      case formatMessage(messages.leases):
        const monthToMonthResults = results.filter(
          (item) =>
            dateDiffInDays(new Date(), moment(item.leaseEndDate).toDate()) < 0,
        );
        return monthToMonthResults;
      case thisMonth:
        const thisMonthsResults = results.filter(
          (item) =>
            moment(item.leaseEndDate).format('YYYY-MM') ===
              moment().format('YYYY-MM') &&
            dateDiffInDays(new Date(), moment(item.leaseEndDate).toDate()) >= 0,
        );
        return thisMonthsResults;
      case nextMonth:
        const nextMonthsResults = results.filter(
          (item) =>
            moment(item.leaseEndDate).format('YYYY-MM') ===
            moment().add(1, 'months').format('YYYY-MM'),
        );
        return nextMonthsResults;
      case nextTwoMonths:
        const nextTwoMonthsResults = results.filter(
          (item) =>
            moment(item.leaseEndDate).format('YYYY-MM') ===
            moment().add(2, 'months').format('YYYY-MM'),
        );
        return nextTwoMonthsResults;
      case nextThreeMonths:
        const nextThreeMonthsResults = results.filter(
          (item) =>
            moment(item.leaseEndDate).format('YYYY-MM') ===
            moment().add(3, 'months').format('YYYY-MM'),
        );
        return nextThreeMonthsResults;

      default:
        return results;
    }
  }, [results, selectedMetric, formatMessage]);

  const { propertyNameOptions } = useGetFilterOptions({
    results: quickFilterResults,
  });

  const headers = useHeaders({ propertyNameOptions });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'leaseEndDate',
    initialOrder: 'ASC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: quickFilterResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const rows = useRows({
    sortedAndFilteredResults,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
  });

  const metrics = useCalculateMetrics({ sortedAndFilteredResults });

  return {
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    onCSVButtonClick,
    totalCount: results?.length ?? 0,
    metrics,
  };
};

export const useCalculateMetrics = ({ sortedAndFilteredResults }) => {
  const formatMessage = useFormatMessage();
  return useMemo(() => {
    const today = new Date();
    const nextMonthMoment = moment(today).add(1, 'months');
    const secondMonthMoment = moment(today).add(2, 'months');
    const thirdMonthMoment = moment(today).add(3, 'months');
    const todayDate = formatDateDisplayLocal(today);
    const yesterdayDate = formatDateDisplayLocal(moment().subtract(1, 'days'));
    const currentMonth = today.toLocaleString('en-US', { month: 'long' });
    const nextMonth = nextMonthMoment
      .toDate()
      .toLocaleString('en-US', { month: 'long' });
    const secondMonth = secondMonthMoment
      .toDate()
      .toLocaleString('en-US', { month: 'long' });
    const thirdMonth = thirdMonthMoment
      .toDate()
      .toLocaleString('en-US', { month: 'long' });
    const lastDayOfCurrentMonth = formatDateDisplayLocal(
      moment(today).endOf('month'),
    );
    const firstDayOfNextMonth = formatDateDisplayLocal(
      nextMonthMoment.startOf('month'),
    );
    const lastDayNextMonth = formatDateDisplayLocal(
      nextMonthMoment.endOf('month'),
    );
    const firstDayOfSecondMonth = formatDateDisplayLocal(
      secondMonthMoment.startOf('month'),
    );
    const lastDayOfSecondMonth = formatDateDisplayLocal(
      secondMonthMoment.endOf('month'),
    );
    const firstDayOfThirdMonth = formatDateDisplayLocal(
      thirdMonthMoment.startOf('month'),
    );
    const lastDayOfThirdMonth = formatDateDisplayLocal(
      thirdMonthMoment.endOf('month'),
    );

    const baseMessage = formatMessage(messages.leaseEndDateBaseMessage);
    const monthToMonthTitle = formatMessage(messages.monthToMonth);
    const defaultTitle = formatMessage(messages.leasesExpiringIn);
    const onOrBefore = formatMessage(messages.onOrBefore);
    const between = formatMessage(messages.between);
    const to = formatMessage(messages.to);

    const items = Array.isArray(sortedAndFilteredResults)
      ? sortedAndFilteredResults
      : [];
    const monthToMonthCount = items.filter(
      (item) =>
        dateDiffInDays(new Date(), moment(item.leaseEndDate).toDate()) < 0,
    ).length;

    const currentMonthCount = items.filter(
      (item) =>
        moment(item.leaseEndDate).format('YYYY-MM') ===
          moment().format('YYYY-MM') &&
        dateDiffInDays(new Date(), moment(item.leaseEndDate).toDate()) >= 0,
    ).length;

    const nextMonthCount = items.filter(
      (item) =>
        moment(item.leaseEndDate).format('YYYY-MM') ===
        nextMonthMoment.format('YYYY-MM'),
    ).length;

    const secondMonthCount = items.filter(
      (item) =>
        moment(item.leaseEndDate).format('YYYY-MM') ===
        secondMonthMoment.format('YYYY-MM'),
    ).length;

    const thirdMonthCount = items.filter(
      (item) =>
        moment(item.leaseEndDate).format('YYYY-MM') ===
        thirdMonthMoment.format('YYYY-MM'),
    ).length;

    return [
      {
        title: monthToMonthTitle,
        subtitle: formatMessage(messages.leases),
        count: monthToMonthCount,
        description: `${baseMessage} ${onOrBefore} ${yesterdayDate}`,
      },
      {
        title: defaultTitle,
        subtitle: currentMonth,
        count: currentMonthCount,
        description: `${baseMessage} ${between} ${todayDate} ${to} ${lastDayOfCurrentMonth}`,
      },
      {
        title: defaultTitle,
        subtitle: nextMonth,
        count: nextMonthCount,
        description: `${baseMessage} ${between} ${firstDayOfNextMonth} ${to} ${lastDayNextMonth}`,
      },
      {
        title: defaultTitle,
        subtitle: secondMonth,
        count: secondMonthCount,
        description: `${baseMessage} ${between} ${firstDayOfSecondMonth} ${to} ${lastDayOfSecondMonth}`,
      },
      {
        title: defaultTitle,
        subtitle: thirdMonth,
        count: thirdMonthCount,
        description: `${baseMessage} ${between} ${firstDayOfThirdMonth} ${to} ${lastDayOfThirdMonth}`,
      },
    ];
  }, [sortedAndFilteredResults, formatMessage]);
};

export const useFetchAllManageLeaseExpirations = ({ organizationId }) => {
  const allExpiringLeasesService = useMemo(
    () => new AllExpiringLeasesService(),
    [],
  );
  const abortController = new AbortController();
  const queryKey = [];
  const options = { signal: abortController.signal };
  return useQuery(
    ['AllExpiringLeases', queryKey],
    () =>
      allExpiringLeasesService.getAllExpiringLeases({
        options,
        organizationId,
      }),
    { enabled: true },
  );
};

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = { propertyNameOptions: [] };

    if (results) {
      results.forEach(({ propertyName }) => {
        options.propertyNameOptions.push(propertyName);
      });

      options.propertyNameOptions = Array.from(
        new Set(options.propertyNameOptions),
      )
        .sort()
        .map((propertyName) => ({
          text: propertyName,
          value: propertyName?.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};

const useHeaders = ({ propertyNameOptions }) => {
  return useMemo(() => {
    return HEADERS.map((header) => {
      if (header.id === 'propertyName')
        header.filterOptions = propertyNameOptions;
      return header;
    });
  }, [propertyNameOptions]);
};

export const useRows = ({ sortedAndFilteredResults }) => {
  return useMemo(
    () =>
      sortedAndFilteredResults?.map((row) => {
        const {
          isRenewal,
          isRenewalComplete,
          leaseRenewalStatus,
          propertyName,
          propertyId,
          unit,
          unitId,
          floorPlan,
          floorPlanId,
          firstName,
          lastName,
          residentId,
          moveInDate,
          leaseStartDate,
          leaseEndDate,
          noticeToVacate,
          doNotRenew,
          underEviction,
          gprMarketRent,
          priorLeasedRent,
          currentLeasedRent,
          otherUnitFees,
          balance,
          quotingRent,
        } = row;

        return {
          propertyName: {
            value: propertyName,
          },
          leaseRenewalStatus: {
            value: (
              <span>
                {utils.getRenewalStatus({
                  isRenewal,
                  isRenewalComplete,
                  isCsv: false,
                })}
              </span>
            ),
            variant: 'description',
            tooltipContent: leaseRenewalStatus,
            sx: {
              justifyContent: 'center',
              display: 'inline-flex',
            },
          },
          unit: {
            variant: 'link',
            to: `/property/${propertyId}/unit/${unitId}?prev=unavailable`,
            target: '_blank',
            value: unit,
          },
          floorPlan: {
            variant: 'link',
            to: `/property/${propertyId}/floorplan/${floorPlanId}?prev=unavailable`,
            target: '_blank',
            value: floorPlan,
          },
          resident: {
            variant: 'link',
            to: `/property/${propertyId}/resident/${residentId}`,
            target: '_blank',
            value:
              firstName?.length || lastName?.length
                ? `${lastName.replaceAll(/[,\s]/g, '')}, ${firstName.replaceAll(
                    /[,\s]/g,
                    '',
                  )}`
                : '',
            tooltipContent:
              firstName?.length || lastName?.length
                ? `${lastName.replaceAll(' ', '')} ${firstName.replaceAll(
                    ' ',
                    '',
                  )}`.replaceAll(/[,\r\n]/g, '')
                : '',
          },
          moveInDate: {
            variant: 'date',
            value: moveInDate ?? '',
          },
          leaseStartDate: {
            variant: 'date',
            value: leaseStartDate ?? '',
          },
          leaseEndDate: {
            variant: 'date',
            value: leaseEndDate ?? '',
          },
          daysUntilLeaseEnd: {
            variant: 'number',
            value: dateDiffInDays(new Date(), new Date(moment(leaseEndDate))),
          },
          noticeToVacate: {
            variant: 'boolean',
            value: noticeToVacate,
            iconName: 'CheckIcon',
            fontSize: 'large',
          },
          doNotRenew: {
            variant: 'boolean',
            value: doNotRenew,
            iconName: 'AlertInfoIcon',
            color: 'error',
            fontSize: 'medium',
          },
          underEviction: {
            variant: 'boolean',
            value: underEviction,
            iconName: 'AlertInfoIcon',
            color: 'error',
          },
          gprMarketRent: {
            variant: 'currency',
            value: gprMarketRent,
          },
          priorLeasedRent: {
            variant: 'currency',
            value: priorLeasedRent,
          },
          currentLeasedRent: {
            variant: 'currency',
            value: currentLeasedRent,
          },
          otherUnitFees: {
            variant: 'currency',
            value: otherUnitFees,
          },
          balance: {
            variant: 'currency',
            value: balance,
          },
          quotingRent: {
            variant: 'currency',
            value: quotingRent,
          },
          actions: {
            variant: 'menu',
            name: 'actionsMenu',
            iconName: 'BoltIcon',
            options: [
              {
                text: 'Quote Lease Renewal',
                value: residentId,
                onClick: () => {
                  window.open(
                    `property/${propertyId}/generate-renewal-offer/${residentId}?prev=unavailable`,
                    '_blank',
                  );
                },
              },
            ],
          },
        };
      }),
    [sortedAndFilteredResults],
  );
};

const useCSVPDFExport = ({ hasAnyFilters, filteredHeaders, rows }) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = [];
    for (const { label } of filteredHeaders) {
      if (label === 'Actions') continue;
      csvHeaders.push(
        typeof label === 'string' ? label : label.props?.defaultMessage,
      );
    }

    const csvRows = rows.map((row) => {
      const {
        leaseRenewalStatus,
        resident,
        noticeToVacate,
        doNotRenew,
        underEviction,
      } = row;
      const csvRow = {
        ...row,
        leaseRenewalStatus: {
          ...leaseRenewalStatus,
          value: (leaseRenewalStatus.tooltipContent ?? '').replace(
            'Not Started',
            '',
          ),
        },
        resident: {
          ...resident,
          value: resident.tooltipContent,
        },
        noticeToVacate: {
          ...noticeToVacate,
          value: noticeToVacate.value ? 'Yes' : '',
          variant: 'description',
        },
        doNotRenew: {
          ...doNotRenew,
          value: doNotRenew.value ? 'Yes' : '',
          variant: 'description',
        },
        underEviction: {
          ...underEviction,
          value: underEviction.value ? 'Yes' : '',
          variant: 'description',
        },
      };

      return csvRow;
    });

    const processedCSVRows = processDataToCSV({
      rows: csvRows,
      filteredHeaders,
    });

    const csv = appendFilterTextToCSV({
      headers: csvHeaders.join(','),
      rows: processedCSVRows,
      hasAnyFilters,
    });

    download(
      csv,
      `AllManageLeaseExpiration_${formatDateDB(new Date())}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [hasAnyFilters, filteredHeaders, rows]);

  return { onCSVButtonClick };
};
