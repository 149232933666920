import React, { useCallback, useEffect, useMemo, useState } from 'react';
import WorkOrderTable from './WorkOrderTable';
import WorkOrderService from '../../../services/workOrderService';

type Props = {
  intl: Object,
  unitId: string,
  householdId: string,
};

const SortType = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const LIMIT_PER_PAGE = 20;

const headerDefinitions = [
  {
    id: 'statusDesc',
    label: 'Status',
    sortable: true,
  },
  {
    id: 'createdAt',
    label: 'Creation Date',
    sortable: true,
  },
  {
    id: 'issueDescription',
    label: 'Issue Description',
  },
  {
    id: 'priorityLevelDesc',
    label: 'Priority',
    sortable: true,
  },
  {
    id: 'daysOpen',
    label: 'Days Open',
    sortable: true,
  },
  {
    id: 'finishedDate',
    label: 'Completion Date',
    sortable: true,
  },
];
function WorkOrderTab({ intl, householdId, unitId }: Props) {
  const service = useMemo(() => new WorkOrderService(), []);
  const [workOrderResponse, setWorkOrdersResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState(SortType.DESC);
  const [orderBy, setOrderBy] = useState('createdAt');

  const fetchWorkOrders = useCallback(
    async (pageNumber: number, sortColumn: string, order: string) => {
      if (householdId && unitId) {
        try {
          setIsLoading(true);
          const response = await service.getAllWorkOrdersByHousehold({
            householdId: householdId,
            unitId: unitId,
            page: pageNumber,
            limit: LIMIT_PER_PAGE,
            sort: sortColumn,
            order: order,
          });
          setWorkOrdersResponse(response);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      }
    },
    [householdId, service, unitId],
  );

  const handleSort = (id: string, order?: typeof SortType) => {
    setOrder(order);
    setOrderBy(id);
  };

  useEffect(() => {
    fetchWorkOrders(currentPage, orderBy, order);
  }, [currentPage, orderBy, order, fetchWorkOrders]);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <WorkOrderTable
      isLoading={isLoading}
      headers={headerDefinitions}
      intl={intl}
      workOrders={workOrderResponse.results}
      paginationMeta={workOrderResponse.meta}
      onPageChange={onPageChange}
      page={currentPage}
      perPage={LIMIT_PER_PAGE}
      handleSortChange={handleSort}
      orderBy={orderBy}
      order={order}
    />
  );
}

export default WorkOrderTab;
