// $FlowFixMe
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import AffordableFloorPlanPricingService from '../../services/floorPlanPricingService';
import LeaseService from '../../services/leaseService';
import ResidentService from '../../services/residentService';
import { parseLeaseMonthlyReqFees } from '../../utils/lease-helpers';

export const useAsyncFloorPlanPricing = (
  floorplanId: string | null,
  isAffordable: boolean,
  selectedProperty: Object,
) => {
  const [affordableFloorplanPricing, setFloorPlanPricing] = useState();

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchAffordableFloorplanPricings = async () => {
      if (!affordableFloorplanPricing && floorplanId && isAffordable) {
        const { id: propertyId, organizationId } = selectedProperty;
        const pricingService = new AffordableFloorPlanPricingService();
        const options = { signal: abortController.signal };
        const pricingRes = await pricingService.getAll(
          organizationId,
          propertyId,
          floorplanId,
          options,
        );
        setFloorPlanPricing(pricingRes);
      }
    };

    if (subscribed) {
      fetchAffordableFloorplanPricings();
    }

    return () => {
      subscribed = false;
    };
  }, [
    floorplanId,
    isAffordable,
    selectedProperty,
    affordableFloorplanPricing,
    setFloorPlanPricing,
  ]);

  return affordableFloorplanPricing || {};
};

export const useAsyncMonthlyLeaseRequiredFees = (
  leaseId: string,
  lockedFees: string,
) => {
  const [subscribed, setSubscribed] = useState(true);
  const [fetch, setFetch] = useState(true);
  const [leaseMonthlyRequiredFees, setLeaseMonthlyRequiredFees] =
    useState(null);

  const updateRequiredFeesList = () => setFetch(true);
  const fetchLeaseMonthlyRequiredFees = useCallback(async () => {
    setSubscribed(true);
    if (leaseId || fetch || lockedFees) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const leaseService = new LeaseService();
      const options = { signal: abortController.signal };
      let leaseMonthlyRequiredFees = [];

      leaseMonthlyRequiredFees = leaseId
        ? await leaseService.getMonthlyRequiredFeesForLease(leaseId, options)
        : [];

      if (subscribed) {
        const parsedRequiredFees = parseLeaseMonthlyReqFees(
          leaseMonthlyRequiredFees,
        );
        setLeaseMonthlyRequiredFees(parsedRequiredFees);
        setFetch(false);
      }
    }
  }, [leaseId, fetch, subscribed, lockedFees]);

  useEffect(() => {
    fetchLeaseMonthlyRequiredFees();
    return () => {
      setSubscribed(false);
    };
  }, [fetchLeaseMonthlyRequiredFees]);

  return {
    leaseMonthlyRequiredFees: leaseMonthlyRequiredFees || [],
    updateRequiredFeesList: updateRequiredFeesList,
    requiredFeesUpdated: !fetch,
  };
};

export const useAsyncEditMonthlyRequiredFees = (
  organizationId: string,
  propertyId: string,
  leaseId: string,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitUpdateMonthlyRequiredFees = async ({
    removedMonthlyFees,
    requiredMonthlyFees,
  }: Object) => {
    setIsSubmitting(true);

    try {
      const leaseService = new LeaseService();
      const response = await leaseService.updateMonthlyRequiredFeesForLease(
        organizationId,
        propertyId,
        leaseId,
        requiredMonthlyFees,
        removedMonthlyFees,
      );
      setIsSubmitting(false);
      setUpdated(true);

      toastr({
        message: 'Non-rent charges Updated',
        title: 'Success',
      });

      if (response.error) {
        return true;
      }

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    submitUpdateMonthlyRequiredFees,
  };
};
export const useAsyncHasOpenAffordableQualification = (
  organizationId: string,
  propertyId: string,
  residentId: string,
  isAffordable: boolean,
) => {
  const service = useMemo(() => new ResidentService(), []);
  const query = useQuery(
    ['resident', 'hasOpenAffordableQualification'],
    () =>
      service.getHouseholdHasOpenCerts(organizationId, propertyId, residentId),
    {
      enabled: isAffordable,
      refetchInterval: 60000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    },
  );

  return {
    isLoading: query.isLoading,
    hasOpenAffordableQualifications: query.data,
  };
};
