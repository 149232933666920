import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ResidentProfile.Title',
    defaultMessage: 'Fortress - Resident profile',
  },
  activityTab: {
    id: 'App.ResidentProfile.ActivityTab',
    defaultMessage: 'Activity',
  },
  documentsTab: {
    id: 'App.ResidentProfile.DocumentsTab',
    defaultMessage: 'Documents',
  },
  applicationTab: {
    id: 'App.ResidentProfile.ApplicationTab',
    defaultMessage: 'Lease App',
  },
  fasSuccessNotification: {
    id: 'App.Socket.FAS.notification.success',
    defaultMessage: 'Final Account Statement download ready',
  },
  fasErrorNotification: {
    id: 'App.Socket.FAS.notification.error',
    defaultMessage:
      'An error occurred while generating the Final Account Statement',
  },
  leaseDataTab: {
    id: 'App.ResidentProfile.LeaseDataTab',
    defaultMessage: 'Lease Data',
  },
  ledgerTab: {
    id: 'App.ResidentProfile.ledgerTab',
    defaultMessage: 'Ledger',
  },
  successHeader: {
    id: 'App.ResidentProfile.SuccessHeader',
    defaultMessage: 'Success',
  },
  successHeaderDeleteActivity: {
    id: 'App.ResidentProfile.successHeaderDeleteActivity',
    defaultMessage: 'Success',
  },
  successHeaderApplication: {
    id: 'App.ResidentProfile.successHeaderApplication',
    defaultMessage: 'Success',
  },
  successDescriptionNotes: {
    id: 'App.ResidentProfile.successDescriptionNotes',
    defaultMessage: 'The note was saved.',
  },
  successNTVSaveDescription: {
    id: 'App.ApplicationProfile.successNTVSaveDescription',
    defaultMessage: 'Notice to vacate updated.',
  },
  successNTVDeleteDescription: {
    id: 'App.ApplicationProfile.successNTVDeleteDescription',
    defaultMessage: 'Notice to vacate removed.',
  },
  errorHeader: {
    id: 'App.ResidentProfile.ErrorHeader',
    defaultMessage: 'Error',
  },
  pendingDownload: {
    id: 'App.ResidentProfile.PendingDownload',
    defaultMessage: 'Downloading Lease',
  },
  pendingDownloadHeader: {
    id: 'App.ResidentProfile.PendingDownloadHeader',
    defaultMessage: 'Pending',
  },
  chooseOption: {
    id: 'App.ResidentProfile.chooseOption',
    defaultMessage: 'Choose an option',
  },
  prospectInformation: {
    id: 'App.ResidentProfile.PeopleTab',
    defaultMessage: 'Prospect Information',
  },
  cancelConfirmation: {
    id: 'App.ResidentProfile.CancelConfirmation',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  finalDecision: {
    id: 'App.ResidentProfile.FinalDecision',
    defaultMessage: 'Final Screening decision saved.',
  },
  confirmMoveOut: {
    id: 'App.ResidentProfile.ConfirmMoveOut',
    defaultMessage:
      'Are you sure you want to move this Resident out of the Unit?',
  },
  confirmMoveOutTenant: {
    id: 'App.ResidentProfile.ConfirmMoveOutTenant',
    defaultMessage:
      'Are you sure you want to move this Tenant out of the Unit?',
  },
  successDescription: {
    id: 'App.ResidentProfile.SuccessDescription',
    defaultMessage: 'This Resident has been moved out of the Unit.',
  },
  successDescriptionTenant: {
    id: 'App.ResidentProfile.SuccessDescriptionTenant',
    defaultMessage: 'This Tenant has been moved out of the Unit.',
  },
  successDescriptionTransfer: {
    id: 'App.ResidentProfile.SuccessDescriptionTransfer',
    defaultMessage: 'A transfer lease was created for the resident.',
  },
  successEditResidentDescription: {
    id: 'App.ResidentProfile.successEditResidentDescription',
    defaultMessage: 'Profile information has been successfully saved.',
  },
  doNotRenewSuccessDescription: {
    id: 'App.ResidentProfile.DoNotRenewSuccessDescription',
    defaultMessage: 'Do Not Renew status updated.',
  },
  fasReadinessSuccessDescription: {
    id: 'App.ResidentProfile.FasReadinessSuccessDescription',
    defaultMessage: 'Customer Final Account Statement readiness updated.',
  },
  underEvictionSuccessDescription: {
    id: 'App.ResidentProfile.underEvictionSuccessDescription',
    defaultMessage: 'Under Eviction status updated.',
  },
  successMonthlyChargeSaved: {
    id: 'App.ResidentProfile.successMonthlyChargeSaved',
    defaultMessage: 'Monthly Transaction saved successfully.',
  },
  successMonthlyChargeTitle: {
    id: 'App.ResidentProfile.successMonthlyChargeTitle',
    defaultMessage: 'Success',
  },
  successRenewalLease: {
    id: 'App.ResidentProfile.successRenewalLease',
    defaultMessage: 'Lease renewal successfully',
  },
  successRenewalLeaseTitle: {
    id: 'App.ResidentProfile.SuccessRenewalLeaseTitle',
    defaultMessage: 'Success',
  },
  renewalSaved: {
    id: 'App.ResidentProfile.renewalSaved',
    defaultMessage: 'Renewal Lease Data Saved in Lease Data Tab',
  },
  validateStartDate: {
    id: 'App.ResidentProfile.validateStartDate',
    defaultMessage:
      'Lease Renewal Start Date on Lease Data Tab is after Current Lease End Date',
  },
  removeLeaseComplete: {
    id: 'App.ResidentProfile.removeLeaseComplete',
    defaultMessage: 'Lease removed successfully',
  },
  qualificationTab: {
    id: 'App.ResidentProfile.QualificationTab',
    defaultMessage: 'Qualification',
  },
  qualificationHistoryTab: {
    id: 'App.ResidentProfile.QualificationHistoryTab',
    defaultMessage: 'Qualification History',
  },
  leaseUploaded: {
    id: 'App.ResidentProfile.LeaseUploaded',
    defaultMessage: 'Signed Renewal Lease Uploaded to Documents',
  },
  autoEmailMonthlyInvoice: {
    id: 'App.ResidentProfile.AutoEmailMonthlyInvoice',
    defaultMessage: 'Auto Email Monthly Invoice Updated.',
  },
  camTab: {
    id: 'App.ResidentProfile.CamTab',
    defaultMessage: 'CAM',
  },
  successRenewalProrateDocumentCreated: {
    id: 'App.ResidentProfile.successRenewalProrateDocumentCreated',
    defaultMessage: 'The Renewal Prorate Letter has been created.',
  },
  successRenewalProrateDocumentCreatedTitle: {
    id: 'App.ResidentProfile.successRenewalProrateDocumentCreatedTitle',
    defaultMessage: 'Document created',
  },
  errorRenewalProrateDocumentCreated: {
    id: 'App.ResidentProfile.errorRenewalProrateDocumentCreated',
    defaultMessage: 'The Renewal Lease was not affected.',
  },
  errorRenewalProrateDocumentCreatedTitle: {
    id: 'App.ResidentProfile.errorRenewalProrateDocumentCreatedTitle',
    defaultMessage: 'The Renewal Prorate Letter could not be created',
  },
  texting: {
    id: 'App.ResidentProfile.Texting',
    defaultMessage: 'Texting',
  },
  unableUpdateMoveInDateAffordable: {
    id: 'App.ResidentProfile.unableUpdateMoveInDateAffordable',
    defaultMessage: 'Move-In Dates cannot be changed for affordable units',
  },
});

export default messages;
