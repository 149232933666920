import * as ActionTypes from './constants';
import type { Action } from '../App/types';
import type { Unit } from '../ViewUnit/types';
import type {
  ApplicationStatus,
  DoNotRenew,
  FasReady,
  NoticeToVacate,
  ResidentAllLeases,
  ResidentMoveOutInfo,
} from './types.js';

export function getOneResident(residentId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_RESIDENT,
    payload: residentId,
  };
}

export function getOneResidentSuccess(resident: Object): Action<Object> {
  return {
    type: ActionTypes.GET_ONE_RESIDENT_SUCCESS,
    payload: resident,
  };
}

export function updateApplicantApprovedSucess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPROVE_APPLICANT_SUCCESS,
    payload: undefined,
  };
}
export function updateApplicantCancelSucess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_CANCEL_APPLICANT_SUCCESS,
    payload: undefined,
  };
}

export function getOneResidentError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_RESIDENT_ERROR,
    payload: error,
  };
}

export function updateDoNotRenew(
  customers: Array<Object>,
  doNotRenewStatus: boolean,
): Action<DoNotRenew> {
  return {
    type: ActionTypes.UPDATE_DO_NOT_RENEW,
    payload: { customers, doNotRenewStatus },
  };
}

export function updateDoNotRenewSuccess(doNotRenew: boolean): Action<boolean> {
  return {
    type: ActionTypes.UPDATE_DO_NOT_RENEW_SUCCESS,
    payload: doNotRenew,
  };
}

export function updateFasReady(
  leaseId: string,
  fasReadyStatus: boolean,
): Action<FasReady> {
  return {
    type: ActionTypes.UPDATE_FAS_READY,
    payload: { leaseId, fasReadyStatus },
  };
}

export function updateFasReadySuccess(fasReady: boolean): Action<boolean> {
  return {
    type: ActionTypes.UPDATE_FAS_READY_SUCCESS,
    payload: fasReady,
  };
}

export function updateUnderEviction(
  customers: Array<Object>,
  underEvictionStatus: boolean,
  leaseId: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_UNDER_EVICTION,
    payload: { customers, underEvictionStatus, leaseId },
  };
}

export function updateUnderEvictionSuccess(
  underEviction: boolean,
): Action<boolean> {
  return {
    type: ActionTypes.UPDATE_UNDER_EVICTION_SUCCESS,
    payload: underEviction,
  };
}

export function cleanLoadedResident(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_RESIDENT,
    payload: undefined,
  };
}

export function moveOutResident(
  moveOutData: ResidentMoveOutInfo,
  residentId: string,
): Action<any> {
  return {
    type: ActionTypes.MOVE_OUT_RESIDENT,
    payload: {
      moveOutData,
      residentId,
    },
  };
}

export function moveOutResidentSuccess(): Action<any> {
  return {
    type: ActionTypes.MOVE_OUT_RESIDENT_SUCCESS,
    payload: undefined,
  };
}

export function saveNoticeToVacate(
  noticeToVacate: NoticeToVacate,
  refreshActivityTable?: Function,
): Action<NoticeToVacate> {
  return {
    type: ActionTypes.SAVE_NOTICE_TO_VACATE,
    payload: { noticeToVacate, refreshActivityTable },
  };
}

export function saveNoticeToVacateSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_NOTICE_TO_VACATE_SUCCESS,
    payload: undefined,
  };
}

export function saveNoticeToVacateError(error: Error): Action<Error> {
  return {
    type: ActionTypes.SAVE_NOTICE_TO_VACATE_ERROR,
    payload: error,
  };
}

export function deleteNoticeToVacate(
  noticeToVacate: NoticeToVacate,
  refreshActivityTable?: Function,
): Action<NoticeToVacate> {
  return {
    type: ActionTypes.DELETE_NOTICE_TO_VACATE,
    payload: { noticeToVacate, refreshActivityTable },
  };
}

export function deleteNoticeToVacateSuccess(): Action<any> {
  return {
    type: ActionTypes.DELETE_NOTICE_TO_VACATE_SUCCESS,
    payload: undefined,
  };
}

export function deleteNoticeToVacateError(error: Error): Action<Error> {
  return {
    type: ActionTypes.DELETE_NOTICE_TO_VACATE_ERROR,
    payload: error,
  };
}

export function getMonthlyTransactionsResident(leaseId: string): Action<any> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS_RESIDENT,
    payload: leaseId,
  };
}

export function getMonthlyTransactionsResidentSuccess(
  payload: any,
): Action<any> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS,
    payload,
  };
}

export function getMonthlyTransactionsResidentError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS_RESIDENT_ERROR,
    payload: error,
  };
}

export function saveMonthlyChargeResident(
  leaseId: string,
  residentId: string,
  transaction: Object,
  leaseData: Object,
  updateTransactionsCb: Function,
): Action<any> {
  return {
    type: ActionTypes.SAVE_MONTHLY_CHARGE_RESIDENT,
    payload: {
      leaseId,
      residentId,
      transaction,
      leaseData,
      updateTransactionsCb,
    },
  };
}

export function saveMonthlyChargeResidentSuccess(leaseId: string): Action<any> {
  return {
    type: ActionTypes.SAVE_MONTHLY_CHARGE_RESIDENT_SUCCESS,
    payload: leaseId,
  };
}

export function setMonthlyOptionResident(option: string): Action<any> {
  return {
    type: ActionTypes.SET_MONTHLY_OPTION_RESIDENT,
    payload: option,
  };
}

export function getAllApplicationStatus(): Action<any> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS,
    payload: undefined,
  };
}

export function getAllApplicationStatusSuccess(
  applicationStatus: Array<ApplicationStatus>,
): Action<Array<ApplicationStatus>> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS_SUCCESS,
    payload: applicationStatus,
  };
}

export function getAllApplicationStatusError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS_ERROR,
    payload: error,
  };
}

export function getScreeningStatus(applicationId: string): Action<any> {
  return {
    type: ActionTypes.GET_RESIDENT_SCREENING_STATUS,
    payload: { applicationId },
  };
}

export function getResidentScreeningStatusSuccess(
  results: Object,
): Action<any> {
  return {
    type: ActionTypes.GET_RESIDENT_SCREENING_STATUS_SUCCESS,
    payload: results,
  };
}

export function getResidentScreeningStatusError(error: Object): Action<any> {
  return {
    type: ActionTypes.GET_RESIDENT_SCREENING_STATUS_ERROR,
    payload: error,
  };
}

export function renewLease(
  residentId: string,
  previousLeaseId: string,
  lease: Object,
  updateCurrentLeaseCb: Function,
): Action<any> {
  return {
    type: ActionTypes.RENEW_LEASE,
    payload: {
      residentId,
      previousLeaseId,
      lease,
      updateCurrentLeaseCb,
    },
  };
}

export function renewLeaseSuccess(
  residentId: string,
  updateCurrentLeaseCb: Function,
): Action<any> {
  return {
    type: ActionTypes.RENEW_LEASE_SUCCESS,
    payload: {
      residentId,
      updateCurrentLeaseCb,
    },
  };
}

export function renewLeaseFail(): Action<any> {
  return {
    type: ActionTypes.RENEW_LEASE_FAIL,
    payload: undefined,
  };
}

export function transferLease({
  residentId,
  applicationId,
  unitId,
  userId,
}: Object): Action<any> {
  return {
    type: ActionTypes.TRANSFER_LEASE,
    payload: {
      residentId,
      applicationId,
      unitId,
      userId,
    },
  };
}

export function transferLeaseSuccess(residentId: string): Action<any> {
  return {
    type: ActionTypes.TRANSFER_LEASE_SUCCESS,
    payload: residentId,
  };
}

export function transferLeaseFail(): Action<any> {
  return {
    type: ActionTypes.TRANSFER_LEASE_FAIL,
    payload: undefined,
  };
}

export function saveLeaseDataTabResident(
  leaseId: string,
  lease: Object,
  residentId: Object,
  refreshActivityTable?: Function,
): Action<any> {
  return {
    type: ActionTypes.SAVE_LEASE_DATA_TAB_RESIDENT,
    payload: {
      leaseId,
      lease,
      residentId,
      refreshActivityTable,
    },
  };
}

export function saveLeaseDataTabResidentSuccess(
  residentId: string,
): Action<any> {
  return {
    type: ActionTypes.SAVE_LEASE_DATA_TAB_RESIDENT_SUCCESS,
    payload: residentId,
  };
}

export function updateMoveInDate(payload: ResidentAllLeases): Action<any> {
  return {
    type: ActionTypes.UPDATE_RESIDENT_MOVE_IN_DATE,
    payload,
  };
}

export function updatePriorResidentMoveOutDate(
  payload: ResidentAllLeases,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_PRIOR_RESIDENT_MOVE_OUT_DATE,
    payload,
  };
}

export function updatePropertyMoveInDate(
  payload: ResidentAllLeases,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_RESIDENT_PROPERTY_MOVE_IN_DATE,
    payload,
  };
}

export function updateLabelEndDateResident(label: string): Action<any> {
  return {
    type: ActionTypes.UPDATE_LABEL_END_DATE_RESIDENT,
    payload: label,
  };
}

export function getUnitById(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID,
    payload,
  };
}

export function getUnitByIdError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID_ERROR,
    payload: error,
  };
}

export function getUnitByIdSuccess(unit: Unit): Action<Unit> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID_SUCCESS,
    payload: unit,
  };
}

export function generateFutureLeaseDocument(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GENERATE_FUTURE_LEASE_DOCUMENT,
    payload,
  };
}

export function generateFutureLeaseDocumentSuccess(
  payload: Object,
): Action<any> {
  return {
    type: ActionTypes.GENERATE_FUTURE_LEASE_DOCUMENT_SUCCESS,
    payload,
  };
}

export function generateFutureLeaseDocumentError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GENERATE_FUTURE_LEASE_DOCUMENT_ERROR,
    payload: error,
  };
}

export const manualWriteOff = () => ({
  type: ActionTypes.MANUAL_WRITE_OFF,
});

export function removeLease(
  applicationId: string,
  leaseId: string,
  residentId: string,
  prospectId: string,
): Action<any> {
  return {
    type: ActionTypes.REMOVE_LEASE,
    payload: {
      applicationId,
      leaseId,
      residentId,
      prospectId,
    },
  };
}

export function removeLeaseSuccess(residentId: string): Action<any> {
  return {
    type: ActionTypes.REMOVE_LEASE_SUCCESS,
    payload: residentId,
  };
}

export function updateResident(residentInfo: Object): Action<any> {
  const { id, type, residentId, residentUpdates } = residentInfo;
  return {
    type: ActionTypes.UPDATE_RESIDENT,
    payload: {
      id,
      type,
      residentId,
      residentUpdates,
    },
  };
}

export function approveApplicant(
  id: string,
  applicationId: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPROVE_APPLICANT,
    payload: { id, applicationId },
  };
}

export function cancelApplicant(
  id: string,
  applicationId: string,
  cancelReasonId: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_CANCEL_APPLICANT,
    payload: { id, applicationId, cancelReasonId },
  };
}

export function setRecompleteStarted(
  applicationId: string,
  voucherEffectiveDate: any,
  residentId: string,
  showQualTab: Function,
): Action<any> {
  return {
    type: ActionTypes.SET_RECOMPLETE_STARTED,
    payload: {
      applicationId,
      voucherEffectiveDate,
      residentId,
      showQualTab,
    },
  };
}

export function setRecertStarted(
  applicationId: string,
  voucherEffectiveDate: any,
  residentId: string,
  showQualTab: Function,
): Action<any> {
  return {
    type: ActionTypes.SET_RECERT_FLAG_STARTED,
    payload: {
      applicationId,
      voucherEffectiveDate,
      residentId,
      showQualTab,
    },
  };
}

export function sendResidentPayLeaseEmail(customerId: string): Action<any> {
  return {
    type: ActionTypes.SEND_RESIDENT_PAYLEASE_EMAIL,
    payload: customerId,
  };
}

export function sendResidentPortalClaimEmail(payload: Object): Action<any> {
  return {
    type: ActionTypes.SEND_RESIDENT_PORTAL_CLAIM_EMAIL,
    payload,
  };
}

export function updateAutoEmailMonthlyInvoice(
  householdId: string,
  autoEmailMonthlyInvoiceStatus: boolean,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_AUTO_EMAIL_MONTHLY_INVOICE,
    payload: { householdId, autoEmailMonthlyInvoiceStatus },
  };
}
export function updateAutoEmailMonthlyInvoiceSuccess(
  autoEmailMonthlyInvoice: boolean,
): Action<boolean> {
  return {
    type: ActionTypes.UPDATE_AUTO_EMAIL_MONTHLY_INVOICE_SUCCESS,
    payload: autoEmailMonthlyInvoice,
  };
}

export function getFirstUnitMoveInAfterPriorResident(
  residentId: string,
): Action<any> {
  return {
    type: ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT,
    payload: residentId,
  };
}

export function getFirstUnitMoveInAfterPriorResidentSuccess(
  date: any,
): Action<any> {
  return {
    type: ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_SUCCESS,
    payload: date,
  };
}

export function getFirstUnitMoveInAfterPriorResidentError(
  err: any,
): Action<any> {
  return {
    type: ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_SUCCESS,
    payload: err,
  };
}

/**
 * Global Socket Actions
 */
export function handleSocketFasSuccessEvent(payload: Object): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_FAS_SUCCESS,
    payload,
  };
}

export function handleSocketFasErrorEvent(payload: Object): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_FAS_ERROR,
    payload,
  };
}

export function fetchCamPools(householdId: string): Action<any> {
  return {
    type: ActionTypes.FETCH_CAM_POOLS,
    payload: { householdId },
  };
}

export function fetchCamPoolsSuccess(payload: Object): Action<any> {
  return {
    type: ActionTypes.FETCH_CAM_POOLS_SUCCESS,
    payload,
  };
}

export function fetchCamPoolsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.FETCH_CAM_POOLS_ERROR,
    error: true,
    payload: error,
  };
}

export function handleSocketFasReversalSuccessEvent(
  payload: Object,
): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_FAS_REVERSAL_SUCCESS,
    payload,
  };
}

export function handleSocketFasReversalErrorEvent(
  payload: Object,
): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_FAS_REVERSAL_ERROR,
    payload,
  };
}
