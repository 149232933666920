import {
  Button,
  Card,
  Divider,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Grid,
  SingleSelect,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { red } from '@fortress-technology-solutions/fortress-component-library/design';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { propOr } from 'ramda';
import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import FormatPhone from '../../../components/FormatPhone';
import { removeNonZipCharacters } from '../../../utils';
import { validateZipCode } from '../../../utils/validations';
import { validateEmail } from '../../../utils/validations';
import { StateSelect } from '../../ManageBuildings/BuildingsTable/StateSelect';
import type { State } from '../../ManageBuildings/types';
import {
  determineLinkColumn,
  determinePortalStatus,
} from '../../ManageProperties/utils';
import { GPRModal } from './GPRModal';
import { PhoneNumberInput } from './PhoneNumberInput';
import { TextInput } from './TextInput';
import { useUpdateGPR } from './hooks';
import SetupGridItem from '../SetupGridItem';
import messages from './messages';
import OnOffTypography from '../OnOffTypography';

type Props = {
  propertyDetails: Object,
  orgPropUrl: string,
  editMode: boolean,
  onChange: Function,
  states: State[],
  disabled: boolean,
  intl: Object,
  promptToaster: Function,
  organizationId: string,
  propertyRoles: Object,
  roleAssignments: Object,
  handleAssignmentChange: Function,
};

const BasicDetails = (props: Props) => {
  const { contactUsWidget, propertyRoleAssignments } = useFlags();

  const {
    propertyDetails,
    orgPropUrl,
    editMode: editModeProp,
    onChange,
    states,
    disabled,
    intl,
    promptToaster,
    organizationId,
    propertyRoles,
    roleAssignments,
    handleAssignmentChange,
  } = props;

  const propertyRoleOne =
    propertyRoleAssignments && propertyRoles.length > 0
      ? propertyRoles?.[0]
      : null;
  const propertyRoleTwo =
    propertyRoleAssignments && propertyRoles.length > 1
      ? propertyRoles?.[1]
      : null;
  const propertyRoleThree =
    propertyRoleAssignments && propertyRoles.length > 2
      ? propertyRoles?.[2]
      : null;

  function getRoleOptions(role) {
    const options = role.assignmentOptions.map((option) => ({
      value: option.id,
      text: option.name,
      disabled: false,
    }));
    options.unshift({ value: null, text: ' ---- No Selection ----' });
    return options;
  }

  const {
    name,
    shortName,
    id,
    onboardingQueue,
    portalLatestOnboardingGroupId,
    portalLatestOnboardingById,
    portalUpdatedAt,
    phoneNumber,
    fax,
    email,
    address1,
    address2,
    city,
    state,
    zip,
    owner,
    propertyClass,
    status,
    isResidentPortalActive,
    isElectronicLeaseSigningActive,
    legalEntity,
    pfp,
    isTwoWayCommunicationActive,
    textingPhoneNumber,
  } = propertyDetails;

  const property = {
    isResidentPortalActive,
    onboardingQueue,
    portalLatestOnboardingGroupId,
    portalLatestOnboardingById,
    portalUpdatedAt,
    isElectronicLeaseSigningActive,
  };

  const portalStatus = determinePortalStatus(property);

  const editMode = editModeProp && !disabled;

  const [showGPRModal, setShowGPRModal] = useState(false);

  const [propertyChangeHistoryLog, setPropertyChangeHistoryLog] = useState(
    propertyDetails.propertyChangeHistoryLog,
  );

  useEffect(() => {
    setPropertyChangeHistoryLog(propertyDetails.propertyChangeHistoryLog);
  }, [propertyDetails]);

  const openGPRModal = () => {
    setShowGPRModal(true);
  };

  const [gpr, setGPR, updateGPR, gprIsLoading] = useUpdateGPR(
    propertyDetails.gpr,
    id,
    organizationId,
    promptToaster,
    intl,
    () => setShowGPRModal(false),
    setPropertyChangeHistoryLog,
  );

  const onHideGPRModal = () => {
    setShowGPRModal(false);
    setGPR(gpr);
  };

  return (
    <>
      <GPRModal
        show={showGPRModal}
        onHide={onHideGPRModal}
        intl={intl}
        promptToaster={promptToaster}
        organizationId={organizationId}
        propertyId={id}
        gpr={gpr}
        updateGPR={updateGPR}
        propertyChangeHistoryLog={propertyChangeHistoryLog}
        isLoading={gprIsLoading}
      />
      <Card sx={{ padding: 2 }}>
        <Typography variant={'h3'}>
          <FormattedMessage {...messages.basicDetails} />
        </Typography>

        <Divider sx={{ marginY: 2 }} />

        <Grid container spacing={2}>
          <SetupGridItem
            label={
              <>
                <FormattedMessage {...messages.propertyName} />*
              </>
            }
          >
            {editMode ? (
              <TextInput
                name="name"
                value={name}
                editMode={editMode}
                onChange={onChange}
                required={true}
                placeholder="Property Name"
              />
            ) : (
              <Typography>{name}</Typography>
            )}
          </SetupGridItem>

          <SetupGridItem label={<FormattedMessage {...messages.shortName} />}>
            <Typography>{shortName}</Typography>
          </SetupGridItem>

          <SetupGridItem
            label={
              <>
                <FormattedMessage {...messages.email} />*
              </>
            }
          >
            {editMode ? (
              <TextInput
                name="email"
                value={email}
                editMode={editMode}
                onChange={onChange}
                required={true}
                type="email"
                validateFunction={(value) => value && validateEmail(value)}
                message={messages.invalidEmailAddress}
                placeholder="Email Address"
              />
            ) : (
              <Typography>{email}</Typography>
            )}
          </SetupGridItem>

          <SetupGridItem label={<FormattedMessage {...messages.propertyId} />}>
            <Typography>{id}</Typography>
          </SetupGridItem>

          <SetupGridItem label={<FormattedMessage {...messages.phone} />}>
            {editMode ? (
              <PhoneNumberInput
                name="phoneNumber"
                value={phoneNumber}
                editMode={editMode}
                onChange={onChange}
                required={false}
                placeholder="Phone Number"
              />
            ) : (
              <Typography>
                <FormatPhone phoneNumber={phoneNumber} />
              </Typography>
            )}
          </SetupGridItem>

          <SetupGridItem
            label={<FormattedMessage {...messages.propertyType} />}
          >
            <Typography>{propertyClass}</Typography>
          </SetupGridItem>

          {isTwoWayCommunicationActive && (
            <SetupGridItem
              label={<FormattedMessage {...messages.textingPhoneNumber} />}
            >
              {textingPhoneNumber ? (
                <Typography>
                  <FormatPhone phoneNumber={textingPhoneNumber} />
                </Typography>
              ) : (
                <Typography color={red.main}>
                  {intl.formatMessage(messages.missingConfiguration)}
                </Typography>
              )}
            </SetupGridItem>
          )}

          <SetupGridItem label={<FormattedMessage {...messages.status} />}>
            <Typography>{status}</Typography>
          </SetupGridItem>

          <SetupGridItem label={<FormattedMessage {...messages.fax} />}>
            {editMode ? (
              <PhoneNumberInput
                name="fax"
                value={fax}
                editMode={editMode}
                onChange={onChange}
                required={false}
                placeholder="Fax Number"
              />
            ) : (
              <Typography>
                <FormatPhone phoneNumber={fax} />
              </Typography>
            )}
          </SetupGridItem>

          <SetupGridItem label={<FormattedMessage {...messages.owner} />}>
            <Typography>{owner}</Typography>
          </SetupGridItem>

          <SetupGridItem
            label={
              <>
                <FormattedMessage {...messages.address} />*
              </>
            }
          >
            {editMode ? (
              <Fragment>
                <TextInput
                  name="address1"
                  value={address1}
                  editMode={editMode}
                  onChange={onChange}
                  required={true}
                  placeholder="Address Line 1"
                />
                <TextInput
                  name="address2"
                  value={address2}
                  editMode={editMode}
                  onChange={onChange}
                  required={false}
                  placeholder="Address Line 2"
                />
                <TextInput
                  name="city"
                  value={city}
                  editMode={editMode}
                  onChange={onChange}
                  required={true}
                  placeholder="City"
                />
                <StateSelect
                  name="state"
                  states={states}
                  value={state}
                  onChange={onChange}
                  maxWidth="35%"
                  placeholder="State"
                  style={{ display: 'inline' }}
                />
                <TextInput
                  name="zip"
                  value={zip}
                  editMode={editMode}
                  onChange={({ target: { name, value } }) => {
                    onChange({
                      target: {
                        name,
                        value: removeNonZipCharacters(value),
                      },
                    });
                  }}
                  required={true}
                  maxWidth="50%"
                  display="inline"
                  style={{ display: 'inline' }}
                  // margin="0 0 0 5%"
                  validateFunction={(value) => validateZipCode(value)}
                  message={messages.invalidZipCode}
                  invalidMessageStyles={{
                    float: 'right',
                    marginRight: '10%',
                  }}
                  placeholder="Zip Code / Postal Code"
                />
              </Fragment>
            ) : (
              <Typography>
                <div>{address1} </div>
                <div>{address2 || '---'}</div>
                <div>{city}</div>
                <div>
                  {propOr('', 'state', propertyDetails).split('-')[1]} {zip}
                </div>
              </Typography>
            )}
          </SetupGridItem>
          <SetupGridItem label={<FormattedMessage {...messages.legalEntity} />}>
            <Typography>{legalEntity}</Typography>
          </SetupGridItem>

          {propertyRoleOne ? (
            <SetupGridItem label={propertyRoleOne?.name}>
              {editMode ? (
                <SingleSelect
                  sx={{
                    width: "calc('100% - 32px')",
                  }}
                  options={getRoleOptions(propertyRoleOne)}
                  value={
                    roleAssignments.find((ra) => {
                      return ra.id === propertyRoleOne.id;
                    })?.roleAssignment?.id || ''
                  }
                  onChange={(userId) => {
                    return handleAssignmentChange(propertyRoleOne.id, userId);
                  }}
                />
              ) : (
                <Typography>
                  {roleAssignments.find((ra) => {
                    return ra.id === propertyRoleOne.id;
                  })?.roleAssignment?.name || '---'}
                </Typography>
              )}
            </SetupGridItem>
          ) : null}
          <SetupGridItem
            label={<FormattedMessage {...messages.currentFinancialPeriod} />}
          >
            <Typography>{pfp}</Typography>
          </SetupGridItem>

          {propertyRoleTwo ? (
            <SetupGridItem label={propertyRoleTwo?.name}>
              {editMode ? (
                <SingleSelect
                  sx={{
                    width: "calc('100% - 32px')",
                  }}
                  options={getRoleOptions(propertyRoleTwo)}
                  value={
                    roleAssignments.find((ra) => {
                      return ra.id === propertyRoleTwo.id;
                    })?.roleAssignment?.id || ''
                  }
                  onChange={(userId) => {
                    return handleAssignmentChange(propertyRoleTwo.id, userId);
                  }}
                />
              ) : (
                <Typography>
                  {roleAssignments.find((ra) => {
                    return ra.id === propertyRoleTwo.id;
                  })?.roleAssignment?.name || '---'}
                </Typography>
              )}
            </SetupGridItem>
          ) : null}
          <SetupGridItem label={<FormattedMessage {...messages.gpr} />}>
            <ElementWithPermissions scope={['property-update']}>
              <Button
                variant={'text'}
                onClick={openGPRModal}
                sx={{ px: 0, py: 0, height: 0 }}
              >
                {gpr}
              </Button>
            </ElementWithPermissions>
          </SetupGridItem>
          {propertyRoleThree ? (
            <SetupGridItem label={propertyRoleThree?.name}>
              {editMode ? (
                <SingleSelect
                  sx={{
                    width: "calc('100% - 32px')",
                  }}
                  options={getRoleOptions(propertyRoleThree)}
                  value={
                    roleAssignments.find((ra) => {
                      return ra.id === propertyRoleThree.id;
                    })?.roleAssignment?.id || ''
                  }
                  onChange={(userId) => {
                    return handleAssignmentChange(propertyRoleThree.id, userId);
                  }}
                />
              ) : (
                <Typography>
                  {roleAssignments.find((ra) => {
                    return ra.id === propertyRoleThree.id;
                  })?.roleAssignment?.name || '---'}
                </Typography>
              )}
            </SetupGridItem>
          ) : null}

          <SetupGridItem
            label={<FormattedMessage {...messages.portalActive} />}
          >
            <OnOffTypography on={isResidentPortalActive} display={'block'}>
              {isResidentPortalActive ? 'Yes' : 'No'}
            </OnOffTypography>

            {isResidentPortalActive && isElectronicLeaseSigningActive && (
              <OnOffTypography on={isElectronicLeaseSigningActive}>
                {isElectronicLeaseSigningActive
                  ? 'Electronic Lease Signing - On'
                  : 'Electronic Lease Signing - Off'}
              </OnOffTypography>
            )}
          </SetupGridItem>
          {propertyRoleAssignments ? <SetupGridItem></SetupGridItem> : null}

          <SetupGridItem label={<FormattedMessage {...messages.links} />}>
            {determineLinkColumn(portalStatus, orgPropUrl, contactUsWidget)}
          </SetupGridItem>
        </Grid>
      </Card>
    </>
  );
};

export default BasicDetails;
