import React, { Component } from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { propOr } from 'ramda';
import { FORM_NAME } from './constants';
import messages from './messages';
import { createTransaction } from './actions';
import CreateTransactionForm from './CreateTransactionForm';
import SubmitButton from '../SubmitButton';
import { transactionCodesProvider } from '../../utils/transaction-helpers';

type AppState = {
  form: Object,
  actions: Object,
  frNames: string[],
};

type Props = AppState & {
  customerId: string,
  unit: ?Object,
  transactionTypes: Array<Object>,
  transactionCodes: Array<Object>,
  subjournals: Array<Object>,
  residents: Array<Object>,
  propertyName: string,
  status: string,
  priorBalance: number,
  priorSecurityBalance: number,
  priorSubjournalBalances: Object,
  onClose: Function,
  show: boolean,
  frNames: string[],
  customerStatus: string,
  fasDone: boolean,
  residentId: ?string,
  ledgerReset: ?Function,
  filterReversed: boolean,
  isSubmitting: boolean,
  isAllCommercial: boolean,
  camPools?: Array<Object>,
  propertyFiscalPeriod: Object,
};

type State = {
  showCancelConfirmation: boolean,
};

export class CreateTransaction extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCancelConfirmation: false,
    };
  }

  setCancelConfirmation = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  showConfirmation = () => this.setCancelConfirmation(true);

  hideConfirmation = () => this.setCancelConfirmation(false);

  handleClose = () => {
    const {
      form: { anyTouched },
      onClose,
    } = this.props;
    const { showCancelConfirmation } = this.state;
    if (anyTouched && !showCancelConfirmation) {
      this.showConfirmation();
      return;
    }
    this.hideConfirmation();
    if (onClose) onClose();
  };

  submitValid = () => {
    const { form } = this.props;

    return !form?.syncErrors;
  };

  submit = (evt: Object) => {
    const {
      actions: { submit },
    } = this.props;
    submit(FORM_NAME);
  };

  handleSubmit = () => {
    const {
      customerId,
      unit,
      form: {
        values: {
          code,
          amount,
          date,
          receivedFrom,
          documentNumber,
          note,
          camPool,
          applyToCamPool,
        },
      },
      actions: { createTransaction },
      onClose,
      residentId,
      ledgerReset,
      filterReversed,
    } = this.props;
    const camAllocationsId =
      applyToCamPool === true && camPool !== 'default' ? camPool : undefined;
    const transaction = {
      prospectCustomerId: customerId,
      propertyTransactionCodeId: code,
      date: date.toDate(),
      amount,
      receivedFrom,
      documentNumber,
      unitId: unit ? unit.id : undefined,
      note,
      customerStatus: this.props.customerStatus,
      camAllocationsId,
    };
    createTransaction(transaction, residentId, filterReversed);
    if (ledgerReset) ledgerReset();
    if (onClose) onClose();
  };

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <Typography variant={'h1'} padding={2}>
          <FormattedMessage {...messages.cancelConfirmationHeader} />
        </Typography>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="pull-right"
            onClick={this.handleClose}
          >
            <FormattedMessage {...messages.yes} />
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={this.hideConfirmation}
          >
            <FormattedMessage {...messages.no} />
          </Button>
        </Col>
      </div>
    );
  };

  render() {
    const {
      fasDone,
      unit,
      transactionCodes,
      transactionTypes,
      subjournals,
      residents,
      propertyName,
      status,
      priorBalance,
      priorSecurityBalance,
      priorSubjournalBalances,
      show,
      isSubmitting,
      isAllCommercial,
      propertyFiscalPeriod,
    } = this.props;

    const tCodes = transactionCodesProvider(
      this.props.customerStatus,
      transactionCodes || [],
      fasDone,
    );

    return (
      <Modal
        className="transaction"
        bsSize="small"
        show={show}
        autoFocus
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <i className="icon et-money" />
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
        </Modal.Header>
        <Modal.Body>
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <div
            className="scrollable-modal__content row"
            style={this.state.showCancelConfirmation ? { display: 'none' } : {}}
          >
            <div className="container-fluid">
              <CreateTransactionForm
                formValues={propOr({}, 'values', this.props.form)}
                frNames={this.props.frNames}
                onSubmit={this.handleSubmit}
                priorBalance={priorBalance}
                priorSecurityBalance={priorSecurityBalance}
                priorSubjournalBalances={priorSubjournalBalances}
                propertyName={propertyName}
                residents={residents}
                status={status}
                subjournals={subjournals}
                transactionCodes={tCodes}
                transactionTypes={transactionTypes}
                unit={unit}
                isAllCommercial={isAllCommercial}
                camPools={this.props.camPools}
                propertyFiscalPeriod={propertyFiscalPeriod}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={this.state.showCancelConfirmation ? 'disabled ' : ''}
        >
          <div className="row">
            <div className="col-xs-6">
              <Button
                type="button"
                bsStyle="default"
                className="pull-right"
                onClick={this.handleClose}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </div>
            <div className="col-xs-6">
              <SubmitButton
                bsStyle="primary"
                className="pull-left"
                disabled={!this.submitValid()}
                clickHandler={this.submit}
                isSubmitting={isSubmitting}
              >
                <FormattedMessage {...messages.post} />
              </SubmitButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = ({ form, createTransaction }) => {
  return {
    form: form[FORM_NAME],
    isSubmitting: createTransaction.isSubmitting,
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submit,
      createTransaction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransaction);
