import React, { useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import messages from './messages';
import { useManageFloorplans } from './hooks-main';
import { useSmartPricingEnabled } from './hooks-smart-pricing';
import HudLimitsModal from './HudLimitsModal';
import UnitsByFloorPlanModal from './UnitsByFloorPlanModal';
import { VacantUnitsModal } from './VacantUnitsModal';
import { MissingSmartPricesModal } from './MissingSmartPricesModal';
import TableLayout from './TableLayout';
import SmartPriceBanner from './SmartPriceBanner';
import HudLimitsBanner from './HudLimitsBanner';
import { promptToaster } from '../App/actions';

const NAME = 'manage-floorplans';
const ManageFloorplans = ({
  intl,
  selectedProperty,
  specialsForm,
  actions,
}) => {
  const { section236: section236Flag = false } = useFlags();
  const smartPricingEnabled = useSmartPricingEnabled(selectedProperty);

  const [hudLimitsModalConfig, setHudLimitsModalConfig] = React.useState({
    show: false,
  });

  const [unitsByFloorPlanModalConfig, setUnitsByFloorPlanModalConfig] =
    React.useState({
      show: false,
      floorPlanId: '',
      floorInternalName: '',
      numUnits: 0,
    });

  const [missingSmartPricesModalConfig, setMissingSmartPricesModalConfig] =
    React.useState({
      show: false,
    });
  const [vacantUnitsModalConfig, setVacantUnitsModalConfig] = React.useState({
    show: false,
    floorPlanId: '',
    floorInternalName: '',
    floorPlanMarketingName: '',
    selectedSmartPrice: 0,
    quotingRent: 0,
    numUnits: 0,
    smartPriceStatus: '',
    smartPriceLastLog: {},
  });

  const openUnitsByFloorplanModal = useCallback(
    ({ floorPlanId, floorInternalName, numUnits }) => {
      setUnitsByFloorPlanModalConfig({
        show: true,
        floorPlanId,
        floorInternalName,
        numUnits,
      });
    },
    [],
  );

  const openVacantUnitsModal = useCallback(
    ({
      floorPlanId,
      floorInternalName,
      floorPlanMarketingName,
      selectedSmartPrice,
      quotingRent,
      smartPriceLastLog,
      smartPriceStatus,
      numUnits,
    }) => {
      setVacantUnitsModalConfig({
        show: true,
        floorPlanId,
        floorInternalName,
        floorPlanMarketingName,
        selectedSmartPrice,
        quotingRent,
        smartPriceLastLog,
        smartPriceStatus,
        numUnits,
      });
    },
    [],
  );

  const openMissingSmartPricesModal = useCallback(() => {
    setMissingSmartPricesModalConfig({ show: true });
  }, []);

  const openHudLimitsEdit = useCallback(() => {
    setHudLimitsModalConfig({ show: true });
  }, []);

  const {
    allColumnsHidden,
    count,
    totalCount,
    floorPlans,
    columnOptions,
    isLoading,
    smartPriceLastUpdate,
    isAffordableMixedProperty,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    order,
    orderBy,
    name,
    hasHUD,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleColumnChange,
    selectedColumns,
    refetchSmartPricesHistory,
    refetchFloorPlans,
    searchState,
    onCSVButtonClick,
  } = useManageFloorplans({
    NAME,
    intl,
    selectedProperty,
    smartPricingEnabled,
    openUnitsByFloorplanModal,
    openVacantUnitsModal,
    openMissingSmartPricesModal,
    flags: {
      section236Flag,
    },
  });

  return (
    <DocumentTitle
      data-testid={'manage-floor-plans'}
      title={'Fortress- Manage Floor Plans'}
    >
      <>
        <HudLimitsModal
          intl={intl}
          isAffordableMixedProperty={isAffordableMixedProperty}
          show={hudLimitsModalConfig.show}
          onClose={() => setHudLimitsModalConfig({ show: false })}
          propertyId={selectedProperty?.id}
          organizationId={selectedProperty?.organizationId}
          promptToaster={actions.promptToaster}
        />
        <UnitsByFloorPlanModal
          intl={intl}
          propertyId={selectedProperty?.id}
          organizationId={selectedProperty?.organizationId}
          floorPlanId={unitsByFloorPlanModalConfig?.floorPlanId}
          floorInternalName={unitsByFloorPlanModalConfig?.floorInternalName}
          numUnits={unitsByFloorPlanModalConfig?.numUnits}
          show={unitsByFloorPlanModalConfig?.show}
          onClose={() =>
            setUnitsByFloorPlanModalConfig({
              ...unitsByFloorPlanModalConfig,
              show: false,
            })
          }
        />
        {smartPricingEnabled && selectedProperty?.id && (
          <React.Fragment>
            <VacantUnitsModal
              intl={intl}
              propertyId={selectedProperty?.id}
              organizationId={selectedProperty?.organizationId}
              floorPlanId={vacantUnitsModalConfig.floorPlanId}
              floorInternalName={vacantUnitsModalConfig.floorInternalName}
              floorplanMarketingName={
                vacantUnitsModalConfig.floorPlanMarketingName
              }
              selectedSmartPrice={vacantUnitsModalConfig.selectedSmartPrice}
              numUnits={vacantUnitsModalConfig.numUnits}
              show={vacantUnitsModalConfig.show || false}
              onClose={() =>
                setVacantUnitsModalConfig({
                  ...vacantUnitsModalConfig,
                  show: false,
                })
              }
              quotingRent={vacantUnitsModalConfig.quotingRent}
              getAllFloorPlans={refetchFloorPlans}
              refetchSmartPricesHistory={refetchSmartPricesHistory}
              smartPriceStatus={vacantUnitsModalConfig.smartPriceStatus}
              smartPriceLastLog={vacantUnitsModalConfig.smartPriceLastLog}
            />

            <MissingSmartPricesModal
              intl={intl}
              show={missingSmartPricesModalConfig.show || false}
              onClose={() => setMissingSmartPricesModalConfig({ show: false })}
            />
          </React.Fragment>
        )}

        <TableLayout name={name}>
          {(tableHeight) => (
            <>
              <TableHeader
                title={<FormattedMessage {...messages.header} />}
                count={count}
                totalCount={totalCount}
              />
              <SmartPriceBanner
                isLoading={isLoading}
                smartPricingEnabled={smartPricingEnabled}
                smartPriceLastUpdate={smartPriceLastUpdate}
              />
              <HudLimitsBanner
                isLoading={isLoading}
                hasHud={hasHUD}
                openHudLimitsEdit={openHudLimitsEdit}
                intl={intl}
              ></HudLimitsBanner>

              <VirtualizedTable
                ReactBeautifulDnD={{
                  DragDropContext,
                  Droppable,
                  Draggable,
                }}
                allColumnsHidden={allColumnsHidden}
                ReactRouterLink={ReactRouterLink}
                columnOptions={columnOptions}
                filterState={filterState}
                filterTypeState={filterTypeState}
                footerRow={footerRow}
                handleColumnChange={handleColumnChange}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                headers={filteredHeaders}
                height={tableHeight}
                intl={intl}
                isLoading={isLoading}
                name={name}
                onCSVButtonClick={onCSVButtonClick}
                order={order}
                orderBy={orderBy}
                rows={floorPlans}
                count={floorPlans.length || 0}
                searchState={searchState}
                selectedColumns={selectedColumns}
              />
            </>
          )}
        </TableLayout>
      </>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app }: Object): Object => {
  return {
    organizationId: app.currentUser?.user?.organizationId || '',
    selectedProperty: app.selectedProperty,
  };
};
export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
)(injectIntl(ManageFloorplans));
