import { defineMessages } from 'react-intl';

const messages = defineMessages({
  LeaseSetup: {
    id: 'App.LeaseSetup.LeaseSetup',
    defaultMessage: 'Lease and Property Setup',
  },
  paymentProvider: {
    id: 'App.LeaseSetup.paymentProvider',
    defaultMessage: 'Payment Provider',
  },
  petWeightLimit: {
    id: 'App.LeaseSetup.petWeightLimit',
    defaultMessage: 'Pet Weight Limit',
  },
  allowedLeaseTerms: {
    id: 'App.LeaseSetup.allowedLeaseTerms',
    defaultMessage: 'Allowed Lease Terms (Months)',
  },
  leaseVariance: {
    id: 'App.LeaseSetup.leaseVariance',
    defaultMessage: 'Lease Variance',
  },
  lateFeeMethod: {
    id: 'App.LeaseSetup.lateFeeMethod',
    defaultMessage: 'Late Fee Method',
  },
  turnDays: {
    id: 'App.BasicDetails.turnDays',
    defaultMessage: 'Turn Days (Maintenance)',
  },
  valueMustBeGreaterThan1: {
    id: 'App.BasicDetails.valueMustBeGreaterOrEqualTo1',
    defaultMessage: 'Value must be greater or equal to 1.',
  },
  required: {
    id: 'App.LeaseSetup.Required',
    defaultMessage: 'Field value is required.',
  },
  cashPrepaidGLCode: {
    id: 'App.LeaseSetup.cashPrepaidGLCode',
    defaultMessage: 'Cash Prepaid GL Code.',
  },
  applicationIncomeRequirement: {
    id: 'App.LeaseSetup.AplicationIncomeRequirement',
    defaultMessage: 'Application Income Requirement',
  },
  defaultToNoEndDateMonthlyTransactions: {
    id: 'App.LeaseSetup.defaultToNoEndDateMonthlyTransactions',
    defaultMessage: 'Default to No End Date (Monthly Transactions)',
  },
  isNoEndDateActiveTip: {
    id: 'App.LeaseSetup.isNoEndDateActiveTip',
    defaultMessage:
      'This setting will auto-select the “No End Date” checkbox when ' +
      'creating new reoccurring transactions. You will still be able to' +
      ' manually set an end date if desired.',
  },
  adverseLetterDaysTooltipMessage: {
    id: 'App.LeaseSetup.AdverseLetterDaysTooltipMessage',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This setting will specify how long a conditional application approval will be valid for when generating an Adverse Action Letter for an applicant on the Lease App tab.',
  },
  renewalProrate: {
    id: 'App.LeaseSetup.renewalProrate',
    defaultMessage: 'Renewal Prorate',
  },
  firstOfMonthPostLabel: {
    id: 'App.LeaseSetup.firstOfMonthPostLabel',
    defaultMessage: 'Post Initial 1st of Month Charges',
  },
  confirmFirstOfMonthPostLabel: {
    id: 'App.LeaseSetup.confirmFirstOfMonthPostLabel',
    defaultMessage: 'Are you sure you want to post 1st day of month charges?',
  },
  leaseExpirationLimitsLabel: {
    id: 'App.LeaseSetup.leaseExpirationLimitsLabel',
    defaultMessage: 'Lease Expiration Limits',
  },
  moveOutProrate: {
    id: 'App.LeaseSetup.MoveOutProrate',
    defaultMessage: 'Prorate Rent for Move Outs',
  },
  moveOutProratePlaceholder: {
    id: 'App.LeaseSetup.MoveOutProratePlaceholder',
    defaultMessage: 'Move Out Rent Pro-rate',
  },
  quoteExpirationDate: {
    id: 'App.LeaseSetup.QuoteExpirationDate',
    defaultMessage: 'Quote Expiration Date',
  },
  adverseActionDeadlineDaysLabel: {
    id: 'App.LeaseSetup.AdverseActionDeadlineDaysLabel',
    defaultMessage: 'Adverse Action Deadline (days)',
  },
  valueGreaterThan0: {
    id: 'App.BasicDetails.valueGreaterThan0',
    defaultMessage: 'Value must be greater than 0.',
  },
  valueLessThan999: {
    id: 'App.BasicDetails.valueLessThan999',
    defaultMessage: 'Value must not exceeed 999.',
  },
  valueMustBeWholeNumber: {
    id: 'App.BasicDetails.valueMustBeWholeNumber',
    defaultMessage: 'Value must be a whole number.',
  },
  autoPostMonthlyCharges: {
    id: 'App.LeaseSetup.AutoPostMonthlyCharges',
    defaultMessage: 'Auto-Post Monthly Charges',
  },
  autoPostMonthlyChargesNote: {
    id: 'App.LeaseSetup.AutoPostMonthlyChargesNote',
    defaultMessage: 'Changes will go into affect the next day.',
  },
  autoChargeMtmFeesLabel: {
    id: 'App.LeaseSetup.AutoChargeMtmFeesLabel',
    defaultMessage: 'Auto-Charge Month-to-Month Fees',
  },
  autoChargeMtmFeesTooltip: {
    id: 'App.LeaseSetup.AutoChargeMtmFeesTooltip',
    defaultMessage:
      // eslint-disable-next-line max-len
      'When this setting is On, a month-to-month fee will automatically post to any ledger once the lease expires without a completed renewal in place. A month-to-month fee will continue to post for any household without an active lease on the 1st of every month. Any recurring month-to-month fees will be automatically ended once this feature is turned On to avoid any duplicate charges moving forward.',
  },
  roundProrateRents: {
    id: 'App.LeaseSetup.RoundProrateRents',
    defaultMessage: 'Round Prorated Rents',
  },
  yesRoundProrateRents: {
    id: 'App.LeaseSetup.YesRoundProrateRents',
    defaultMessage: 'Yes - Always Round',
  },
  noRoundProrateRents: {
    id: 'App.LeaseSetup.NoRoundProrateRents',
    defaultMessage: 'No - Never Round',
  },
  successiveLeaseTerm: {
    id: 'App.LeaseSetup.SuccessiveLeaseTerm',
    defaultMessage: 'Successive Lease Term',
  },
  monthToMonth: {
    id: 'App.LeaseSetup.MonthToMonth',
    defaultMessage: 'Month-to-Month',
  },
  annual: {
    id: 'App.LeaseSetup.annual',
    defaultMessage: 'Annual',
  },
  moveOutProrateDesc: {
    id: 'App.LeaseSetup.moveOutProrateDesc',
    defaultMessage: 'Choose how to handle prorated charges for move-outs:',
  },
  yesFullMonth: {
    id: 'App.LeaseSetup.yesFullMonth',
    defaultMessage: 'Yes - Full Month Charge, Reconcile at Move-Out:',
  },
  yesFullMonthDesc: {
    id: 'App.LeaseSetup.yesFullMonthDesc',
    defaultMessage:
      // eslint-disable-next-line max-len
      "This option charges the full month's recurring charges upfront and adjusts the charges at move-out to reflect the actual occupied period.",
  },
  yesStartOfMonth: {
    id: 'App.LeaseSetup.yesStartOfMonth',
    defaultMessage: 'Yes - Prorate at Start of Month:',
  },
  yesStartOfMonthDesc: {
    id: 'App.LeaseSetup.yesStartOfMonthDesc',
    defaultMessage:
      // eslint-disable-next-line max-len
      "This option charges the month's recurring charges based on the anticipated occupied portion of the month according to the scheduled move-out date, and the ledger will be reconciled at move-out for any changes.",
  },
  noNever: {
    id: 'App.LeaseSetup.noNever',
    defaultMessage: 'No - Never Prorate:',
  },
  noNeverDesc: {
    id: 'App.LeaseSetup.noNeverDesc',
    defaultMessage:
      "This option always charges a full month's recurring charges, regardless of the move-out date.",
  },
});

export default messages;
