import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import messages from './messages';

function FeesTable({ name, intl, isLoading, rows }) {
  const headers = [
    {
      id: 'transactionCode',
      label: intl.formatMessage(messages.feeTransactionCode),
    },
    {
      id: 'description',
      label: intl.formatMessage(messages.transactionDescription),
    },
    {
      id: 'chargeAt',
      label: intl.formatMessage(messages.chargeAt),
    },
    {
      id: 'displayNameOnQuote',
      label: intl.formatMessage(messages.displayNameOnQuote),
    },
    {
      id: 'nameQuote',
      label: intl.formatMessage(messages.nameOnQuote),
    },
    {
      id: 'oneTimeCharge',
      label: intl.formatMessage(messages.oneTimeCharge),
    },
    {
      id: 'feeAmount',
      label: intl.formatMessage(messages.feeAmount),
    },
    {
      id: 'descriptionAmount',
      label: intl.formatMessage(messages.feeDescriptionAmount),
    },
    {
      id: 'lastUpdated',
      label: intl.formatMessage(messages.lasUpdatedOnBy),
    },
    {
      id: 'edit',
      label: intl.formatMessage(messages.editHeader),
    },
  ];
  return (
    <Table
      intl={intl}
      ReactBeautifulDnD={{
        DragDropContext,
        Droppable,
        Draggable,
      }}
      ReactRouterLink={ReactRouterLink}
      name={name}
      height={'calc(100vh - 180px)'}
      headers={headers}
      rows={rows}
      emptyStateMessage={intl.formatMessage(messages.emptyStateMessage)}
      isLoading={isLoading}
      sx={{ paddingTop: 0 }}
    />
  );
}

export default FeesTable;
