import {
  LIHTC_HOME_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
  HOME_PROGRAM_NAME,
  HUD_PROGRAM_NAME,
  RD_PROGRAM_NAME,
} from '../../constants/affordableProgramNames';

export const getIsValidProgramAndStateCode = (pap = [], stateCode) => {
  const programNames = pap.map((pap) => pap?.masterAffordableProgram?.name);
  const hasLIHTCHOMEProgram = programNames.includes(LIHTC_HOME_PROGRAM_NAME);
  const hasHUDProgram = programNames.includes(HUD_PROGRAM_NAME);
  const hasRDProgram = programNames.includes(RD_PROGRAM_NAME);
  const hasLIHTCProgram = programNames.includes(LIHTC_PROGRAM_NAME);
  const hasHOMEProgram = programNames.includes(HOME_PROGRAM_NAME);

  /**
   * Note: This is valid when I am logged into an LIHTC or HOME affordable or mixed property
   * in Alabama, DC, Georgia, Kentucky, Louisiana, Mississippi, Michigan, North Carolina, Ohio,
   * South Carolina, Wisconsin, Florida, Oregon or Texas
   */
  const isValidProgramAndStateCode =
    (hasLIHTCProgram || hasHOMEProgram || hasLIHTCHOMEProgram) &&
    enableStatesCode.includes(stateCode);

  /**
   * Note: This is valid when I am logged into a property with HUD, RD affordable or mixed property
   */
  const isValidHUDandRDProgram = hasHUDProgram && hasRDProgram;

  const priority = hasLIHTCProgram && (hasHUDProgram || hasRDProgram);

  return isValidProgramAndStateCode || isValidHUDandRDProgram || priority;
};

export const enableStatesCode = [
  'AL',
  'DC',
  'GA',
  'KY',
  'LA',
  'MS',
  'MI',
  'NC',
  'OH',
  'SC',
  'WI',
  'FL',
  'OR',
  'TX',
];
