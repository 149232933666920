import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NoPrint } from 'react-easy-print';

// Redux Form
import { reduxForm, formValueSelector, Field, FieldArray } from 'redux-form';
import { renderTextField } from '../../../utils/redux-form-helper';

// Components
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  AssetDisposedDateRow,
  InterestRateField,
  SelectionColumn,
  CheckBox,
} from '../SharedFormFields';
import { AssetFooter } from './footer/AssetFooter';
import { AlternateRetirementPlanRows } from './retirementRows/AlternateRetirementPlanRows';
import { RetirementPlanRows } from './retirementRows/RetirementPlanRows';
import { AltAssetFooter } from './footer/AltAssetFooter';
import { AssetTableHeader } from './header/AssetTableHeader';
import { AltAssetTableHeader } from './header/AltAssetTableHeader';
import { ExpandableSuitableOccupancyAssetRows } from './suitableForOccupancyRows/ExpandableSuitableOccupancyAssetRows';
import {
  Banner,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';

// Types
import type { IncomeAssetRow, AssetFormProps } from '../types';

// Utils
import { pick, pathOr, isNil } from 'ramda';
import { getAssetDefinitions } from './utils';
import validate from './validate';

// Constants
import {
  FORM_INFO,
  ASSET_ROW_NAMES,
  ASSET_VALUE_FIELDS,
  ASSET_INCOME_FIELDS,
  NEW_ALTERNATE_ROW,
  ALL_ASSETS_DEFINITIONS,
  HOUSEHOLD_ASSET_THRESHOLD,
} from './constants.js';
import componentMessages from './messages';
import generalMessages from '../../App/messages';

const messages = { ...generalMessages, ...componentMessages };

// These are essentially used to align both the header and row values (single source of truth)
export const ALT_FORM_FIRST_COL_SECTION = 'col-xs-5';
export const ALT_FORM_SECOND_COL_SECTION = 'col-xs-7';

export const ASSET_ACTUAL_IMPUTE_OPTIONS = (intl: Object) => [
  {
    text: intl.formatMessage(messages.choose),
    value: '-',
    disabled: true,
  },
  {
    text: intl.formatMessage(messages.actual),
    value: 'actual',
  },
  {
    text: intl.formatMessage(messages.impute),
    value: 'impute',
  },
];

export const AddAccountLink = ({
  clickHandler,
  fields,
  fieldLimit,
  disabled,
  additionalValues = {},
}) => {
  return (
    <div className="col-xs-2">
      <a
        onClick={() => clickHandler({ fields, fieldLimit, additionalValues })}
        disabled={disabled}
        className="btn-text ignore-react-onclickoutside"
      >
        <i className={'icon et-plus'} />
        <span>Add Account</span>
      </a>
    </div>
  );
};

export const DeleteAccountBtn = ({ clickHandler }) => {
  return (
    <a
      onClick={clickHandler}
      className="btn btn-delete-alt"
      style={{ marginTop: '3px', minWidth: '24px' }}
    >
      <i className="icon et-trash" />
    </a>
  );
};
const createNewRow = ({ fields, fieldLimit, additionalValues }) => {
  if (fields.length >= fieldLimit) {
    return;
  }
  const newRowData = { ...NEW_ALTERNATE_ROW, ...additionalValues };
  fields.push(newRowData);
};

export const NumberAndAssetColumn = ({
  row: { number, name },
  colSize,
  otherFieldName = 'otherAssetName',
  disabled = false,
}: IncomeAssetRow) => {
  const colClass = colSize ? `col-xs-${colSize}` : 'col-xs-3';

  return (
    <div className={colClass}>
      <div className="row">
        <div className="col-xs-3">
          <span>{number}</span>
        </div>
        <div className="col-xs-9">
          {name !== 'other' ? (
            <FormattedMessage {...messages[name]} />
          ) : (
            <Field
              name={otherFieldName}
              component={renderTextField}
              maxLength="35"
              className="input-lg"
              placeholder="Other"
              disabled={disabled}
              required={!disabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ExpandableAssetRows = ({
  asset,
  fields,
  fieldsAnnualIncome,
  isPrior,
  hasAltCols,
  handleDelete,
  handleAdd,
  noClicked,
  calculateCashValue,
  calculateAnnualIncome,
  isHotmaActive = false,
  intl,
}: Object) => {
  // assetName is top level asset name used to check if yes or no
  const disabled = fields[asset.name] !== 'yes';
  const showAddAccountBtn = !disabled && asset?.expandable;
  return (
    <>
      <FieldArray
        name={`${asset.name}List`}
        component={AssetFieldArrayRows}
        asset={asset}
        isPrior={isPrior}
        assetName={asset.name}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        noClicked={noClicked}
        formFields={fields}
        showAddAccountBtn={showAddAccountBtn}
        calculateCashValue={calculateCashValue}
        calculateAnnualIncome={calculateAnnualIncome}
        fieldsAnnualIncome={fieldsAnnualIncome}
        isHotmaActive={isHotmaActive}
        intl={intl}
      />
    </>
  );
};

const AssetFieldArrayRows = ({
  asset,
  fields,
  fieldsAnnualIncome,
  isPrior,
  handleAdd,
  assetName,
  noClicked,
  formFields,
  showAddAccountBtn,
  calculateAnnualIncome,
  calculateCashValue,
  isHotmaActive,
  intl,
}) => {
  const disabled = formFields[asset.name] !== 'yes';
  const isYes = formFields[asset.name] === 'yes';
  if (fields.length === 0) {
    fields.push();
  }
  const addAccountDisabled = fields.length >= asset.fieldLimit;
  // The dot allows us to place value inside object property inside the list
  const marketValueType = '.marketValue';
  const interestRateType = '.interestRate';
  const feesToConvertCash = '.feesToConvertCash';
  const cashValue = '.cashValue';
  const annualIncome = '.annualIncome';
  const actualImpute = '.actualImpute';
  const isOther = asset.name === 'other';
  return (
    <>
      <div className="row container-fluid">
        {fields.map((assetField, index) =>
          index === 0 ? (
            <div className="row" key={assetField}>
              <div className={ALT_FORM_FIRST_COL_SECTION}>
                <div className="row">
                  <NumberAndAssetColumn
                    row={asset}
                    isPrior={isPrior}
                    colSize="5"
                    otherFieldName={`otherList[${index}].otherName`}
                    disabled={disabled}
                  />
                  <YesNoColumns
                    name={assetName}
                    noClicked={() => noClicked(asset.name, true)}
                    isPrior={isPrior}
                    colSize="3"
                  />
                  <IncomeAssetCurrencyField
                    name={assetField}
                    type={marketValueType}
                    disabled={disabled || isPrior}
                    colSize="4"
                    onBlur={() => {
                      if (!isOther) {
                        calculateAnnualIncome(asset.name, true, index);
                        calculateCashValue(asset.name, true, index);
                      }
                    }}
                  />
                </div>
              </div>
              <div className={ALT_FORM_SECOND_COL_SECTION}>
                <div className="row">
                  <div className="col-xs-11">
                    <div className="row">
                      <InterestRateField
                        name={assetField}
                        disabled={disabled || isPrior}
                        type={interestRateType}
                        colSize={isHotmaActive ? '2' : '3'}
                        onBlur={() =>
                          !isOther
                            ? calculateAnnualIncome(asset.name, true, index)
                            : null
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={feesToConvertCash}
                        disabled={disabled || isPrior}
                        colSize={isHotmaActive ? '2' : '3'}
                        onBlur={() =>
                          !isOther
                            ? calculateCashValue(asset.name, true, index)
                            : null
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={cashValue}
                        disabled={!isOther || disabled}
                        colSize={isHotmaActive ? '2' : '3'}
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={annualIncome}
                        disabled={!isOther || disabled}
                        colSize="3"
                      />
                      {isHotmaActive && isYes && (
                        <SelectionColumn
                          name={assetField}
                          type={actualImpute}
                          disabled={
                            disabled ||
                            isPrior ||
                            (isYes &&
                              +fieldsAnnualIncome?.[asset.name]?.values?.[
                                index
                              ] > 0)
                          }
                          colSize="3"
                          options={ASSET_ACTUAL_IMPUTE_OPTIONS(intl)}
                          placeholder={intl.formatMessage(messages.choose)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-xs-1"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row" key={assetField}>
              <div className="col-xs-5">
                <div className="row">
                  {assetName === 'other' ? (
                    <>
                      <div className="col-xs-5">
                        <div className="row">
                          <div className="col-xs-3"></div>
                          <div className="col-xs-9">
                            <Field
                              name={`otherList[${index}].otherName`}
                              component={renderTextField}
                              maxLength="35"
                              className="input-lg"
                              placeholder="Other"
                              disabled={disabled || isPrior}
                              required={!disabled}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-3" />
                    </>
                  ) : (
                    <div className="col-xs-8" />
                  )}
                  <IncomeAssetCurrencyField
                    name={assetField}
                    type={marketValueType}
                    disabled={disabled || isPrior}
                    colSize="4"
                    onBlur={() => {
                      if (!isOther) {
                        calculateAnnualIncome(asset.name, true, index);
                        calculateCashValue(asset.name, true, index);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xs-7">
                <div className="row">
                  <div className="col-xs-11">
                    <div className="row">
                      <InterestRateField
                        name={assetField}
                        disabled={disabled || isPrior}
                        type={interestRateType}
                        colSize={isHotmaActive ? '2' : '3'}
                        onBlur={() =>
                          !isOther
                            ? calculateAnnualIncome(asset.name, true, index)
                            : null
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={feesToConvertCash}
                        disabled={disabled || isPrior}
                        colSize={isHotmaActive ? '2' : '3'}
                        onBlur={() =>
                          !isOther
                            ? calculateCashValue(asset.name, true, index)
                            : null
                        }
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={cashValue}
                        disabled={!isOther || disabled}
                        colSize={isHotmaActive ? '2' : '3'}
                      />
                      <IncomeAssetCurrencyField
                        name={assetField}
                        type={annualIncome}
                        disabled={!isOther || disabled}
                        colSize="3"
                      />
                      {isHotmaActive && isYes && (
                        <SelectionColumn
                          name={assetField}
                          type={actualImpute}
                          disabled={
                            disabled ||
                            isPrior ||
                            (isYes &&
                              +fieldsAnnualIncome?.[asset.name]?.values?.[
                                index
                              ] > 0)
                          }
                          colSize="3"
                          options={ASSET_ACTUAL_IMPUTE_OPTIONS(intl)}
                          placeholder={intl.formatMessage(messages.choose)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-xs-1">
                    <DeleteAccountBtn
                      clickHandler={() => fields.remove(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
        )}
        {showAddAccountBtn ? (
          <AddAccountLink
            clickHandler={handleAdd}
            fields={fields}
            fieldLimit={asset.fieldLimit}
            disabled={addAccountDisabled}
          />
        ) : null}
      </div>
    </>
  );
};

const AlternateAssetRow = ({
  asset,
  fields,
  fieldsAnnualIncome,
  noClicked,
  isPrior,
  intl,
  calculateCashValue,
  calculateAnnualIncome,
  isHotmaActive = false,
}: Object) => {
  const disabled = fields?.[asset.name] !== 'yes';
  const isYes = fields?.[asset.name] === 'yes';
  const fieldAnnualIncome = +fieldsAnnualIncome?.[asset.name];
  return (
    <React.Fragment key={asset.name}>
      <div className="row faux-table__row" key={asset.name}>
        <div className={ALT_FORM_FIRST_COL_SECTION}>
          <div className="row">
            <NumberAndAssetColumn
              row={asset}
              isPrior={isPrior}
              colSize="5"
              disabled={disabled}
            />
            <YesNoColumns
              name={asset.name}
              noClicked={() => noClicked(asset.name)}
              isPrior={isPrior}
              colSize="3"
            />
            <IncomeAssetCurrencyField
              name={asset.name}
              type={'AssetMarketValue'}
              disabled={disabled || isPrior}
              colSize="4"
              onBlur={() => {
                calculateAnnualIncome(asset.name);
                calculateCashValue(asset.name);
              }}
            />
          </div>
        </div>
        <div className={ALT_FORM_SECOND_COL_SECTION}>
          <div className="row">
            <div className="col-xs-11">
              <div className="row">
                <InterestRateField
                  name={asset.name}
                  type={'AssetInterestRate'}
                  disabled={disabled || isPrior}
                  colSize={isHotmaActive ? '2' : '3'}
                  onBlur={() => calculateAnnualIncome(asset.name)}
                />
                <IncomeAssetCurrencyField
                  name={asset.name}
                  type={'AssetFeesToConvertCash'}
                  disabled={disabled || isPrior}
                  colSize={isHotmaActive ? '2' : '3'}
                  onBlur={() => calculateCashValue(asset.name)}
                />
                <IncomeAssetCurrencyField
                  name={asset.name}
                  type={'AssetCashValue'}
                  disabled={true}
                  colSize={isHotmaActive ? '2' : '3'}
                />
                <IncomeAssetCurrencyField
                  name={asset.name}
                  type={'AssetAnnualIncome'}
                  disabled={true}
                  colSize="3"
                />
                {isHotmaActive && isYes && (
                  <SelectionColumn
                    name={asset.name}
                    type={'AssetActualImpute'}
                    disabled={
                      disabled || isPrior || (isYes && fieldAnnualIncome > 0)
                    }
                    colSize="3"
                    options={ASSET_ACTUAL_IMPUTE_OPTIONS(intl)}
                    placeholder={intl.formatMessage(messages.choose)}
                  />
                )}
              </div>
            </div>
            {/* Space for delete button on expanded rows */}
            <div className="col-xs-1"></div>
          </div>
        </div>
      </div>
      {asset.name === 'disposed' ? (
        <div className="row">
          <div className={ALT_FORM_FIRST_COL_SECTION}>
            <div className="row">
              <AssetDisposedDateRow
                disabled={disabled}
                intl={intl}
                colOffset="8"
                colSize="4"
              />
            </div>
          </div>
          <div
            className={ALT_FORM_SECOND_COL_SECTION}
            style={{ height: '75px' }}
          ></div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const AssetRow = ({ asset, fields, noClicked, isPrior, intl }: Object) => {
  const disabled = fields[asset.name] !== 'yes';
  const reqFormsColSize = 'col-xs-3';
  const assetDisposedDateRowOffset = '5';
  return (
    <>
      <div className="row faux-table__row" key={asset.name}>
        <NumberAndAssetColumn
          row={asset}
          isPrior={isPrior}
          disabled={disabled}
        />
        <YesNoColumns
          name={asset.name}
          noClicked={() => noClicked(asset.name)}
          isPrior={isPrior}
        />
        <IncomeAssetCurrencyField
          name={asset.name}
          type={'AssetValue'}
          disabled={disabled || isPrior}
        />
        <IncomeAssetCurrencyField
          name={asset.name}
          type={'AssetIncome'}
          disabled={disabled || isPrior}
        />
        <div className={reqFormsColSize}>
          <FormattedMessage {...messages[`${asset.forms}`]} />
        </div>
        {asset.name === 'disposed' ? (
          <AssetDisposedDateRow
            disabled={disabled}
            intl={intl}
            colOffset={assetDisposedDateRowOffset}
          />
        ) : null}
      </div>
    </>
  );
};

const AssetRows = ({
  assets,
  fields,
  fieldsAnnualIncome,
  noClicked,
  isPrior,
  intl,
  hasAltCols,
  handleAddAccount,
  assetType,
  altNoClicked,
  calculateCashValue,
  calculateAnnualIncome,
  isHotmaActive = false,
}: Object) => {
  return assets.map((asset) => {
    const expandable = (asset?.expandable ?? false) && hasAltCols;
    const AssetRowToShow = hasAltCols ? (
      <AlternateAssetRow
        asset={asset}
        fields={fields}
        fieldsAnnualIncome={fieldsAnnualIncome}
        noClicked={altNoClicked}
        isPrior={isPrior}
        intl={intl}
        assetType={assetType}
        calculateCashValue={calculateCashValue}
        calculateAnnualIncome={calculateAnnualIncome}
        isHotmaActive={isHotmaActive}
      />
    ) : (
      <AssetRow
        asset={asset}
        fields={fields}
        noClicked={noClicked}
        isPrior={isPrior}
        intl={intl}
        assetType={assetType}
      />
    );
    return (
      <React.Fragment key={asset.name}>
        {expandable ? (
          <ExpandableAssetRows
            asset={asset}
            fields={fields}
            fieldsAnnualIncome={fieldsAnnualIncome}
            isPrior={isPrior}
            hasAltCols={hasAltCols}
            assetType={assetType}
            assetName={asset.name}
            handleAdd={handleAddAccount}
            noClicked={altNoClicked}
            calculateCashValue={calculateCashValue}
            calculateAnnualIncome={calculateAnnualIncome}
            isHotmaActive={isHotmaActive}
            intl={intl}
          />
        ) : (
          AssetRowToShow
        )}
      </React.Fragment>
    );
  });
};

const HotmaAssetRows = ({
  assets,
  fields,
  fieldsAnnualIncome,
  noClicked,
  isPrior,
  intl,
  handleAddAccount,
  assetType,
  calculateCashValue,
  calculateAnnualIncome,
}: Object) => {
  return assets.map((asset) => {
    let renderComponent = 'default';
    const expandable = asset?.expandable ?? false;
    if (expandable) renderComponent = 'expandable';
    const suitableForOcc =
      expandable &&
      (asset.name === 'rentalProperty' || asset.name === 'realEstate');
    if (suitableForOcc) renderComponent = 'expandableSuitableOccupancy';

    switch (renderComponent) {
      case 'expandable':
        return (
          <ExpandableAssetRows
            asset={asset}
            fields={fields}
            fieldsAnnualIncome={fieldsAnnualIncome}
            isPrior={isPrior}
            assetType={assetType}
            assetName={asset.name}
            handleAdd={handleAddAccount}
            noClicked={noClicked}
            calculateCashValue={calculateCashValue}
            calculateAnnualIncome={calculateAnnualIncome}
            isHotmaActive={true}
            intl={intl}
          />
        );
      case 'expandableSuitableOccupancy':
        return (
          <ExpandableSuitableOccupancyAssetRows
            asset={asset}
            fields={fields}
            isPrior={isPrior}
            assetType={assetType}
            assetName={asset.name}
            handleAdd={handleAddAccount}
            noClicked={noClicked}
            calculateCashValue={calculateCashValue}
            calculateAnnualIncome={calculateAnnualIncome}
            intl={intl}
            fieldsAnnualIncome={fieldsAnnualIncome}
          />
        );

      default:
        return (
          <AlternateAssetRow
            asset={asset}
            fields={fields}
            noClicked={noClicked}
            isPrior={isPrior}
            intl={intl}
            assetType={assetType}
            calculateCashValue={calculateCashValue}
            calculateAnnualIncome={calculateAnnualIncome}
            isHotmaActive={true}
            fieldsAnnualIncome={fieldsAnnualIncome}
          />
        );
    }
  });
};

const generateNoClicked = (changeCallback: Function) => {
  return (rowName: string) => {
    changeCallback(`${rowName}AssetValue`, '0.00');
    changeCallback(`${rowName}AssetIncome`, '0.00');
    changeCallback(`${rowName}InterestRate`, '0.00');
    if (rowName === 'otherRetirementAccount') {
      changeCallback('otherRetirementAccountName', null);
    }
    if (rowName === 'other') {
      changeCallback('otherAssetName', null);
    }
    if (rowName === 'disposed') {
      changeCallback('disposedDate', null);
    }
  };
};

const generateCalcCashValueFunc = (changeCallback: Function, props) => {
  return (rowName: string, isList = false, index = 0) => {
    if (!isList) {
      const marketValue = parseFloat(props?.[`${rowName}AssetMarketValue`]);
      const feesConvertCash = parseFloat(
        props?.[`${rowName}AssetFeesToConvertCash`],
      );
      if (isNaN(marketValue) || isNaN(feesConvertCash)) {
        changeCallback(`${rowName}AssetCashValue`, '0.00');
      }
      const cashValue = (marketValue - feesConvertCash).toFixed(2);
      changeCallback(`${rowName}AssetCashValue`, cashValue);
    } else {
      // Handle expandable list logic
      const listKey = `${rowName}List`;
      const mv = props?.[listKey]?.[index]?.marketValue ?? null;
      const fees = props?.[listKey]?.[index]?.feesToConvertCash ?? null;
      const marketValue = parseFloat(mv);
      const feesConvertCash = parseFloat(fees);
      if (isNaN(marketValue) || isNaN(feesConvertCash)) {
        changeCallback(`${rowName}List[${index}].cashValue`, '0.00');
      }
      const cashValue = (marketValue - feesConvertCash).toFixed(2);
      changeCallback(`${rowName}List[${index}].cashValue`, cashValue);
    }
  };
};

const generateCalcAnnualIncomeFunc = (changeCallback: Function, props) => {
  const { isHotmaActive = false } = props;
  return (rowName: string, isList = false, index = 0) => {
    if (!isList) {
      const marketValue = parseFloat(props?.[`${rowName}AssetMarketValue`]);
      const interestRate = parseFloat(props?.[`${rowName}AssetInterestRate`]);
      if (isNaN(marketValue) || isNaN(interestRate)) {
        changeCallback(`${rowName}AssetAnnualIncome`, '0.00');
      }
      const annualIncome = ((marketValue * interestRate) / 100).toFixed(2);
      if (isHotmaActive) {
        const currentValue = props?.[`${rowName}AssetActualImpute`];
        const isAnnualIncomePositive = +annualIncome > 0;
        if (isAnnualIncomePositive && currentValue !== 'actual') {
          changeCallback(`${rowName}AssetActualImpute`, 'actual');
        }
      }

      changeCallback(`${rowName}AssetAnnualIncome`, annualIncome);
    } else {
      // Handle expandable list logic
      const listKey = `${rowName}List`;
      const mv = props?.[listKey]?.[index]?.marketValue ?? null;
      const ir = props?.[listKey]?.[index]?.interestRate ?? null;
      const marketValue = parseFloat(mv);
      const interestRate = parseFloat(ir);
      if (isNaN(marketValue) || isNaN(interestRate)) {
        changeCallback(`${rowName}List[${index}].annualIncome`, '0.00');
      }
      const annualIncome = ((marketValue * interestRate) / 100).toFixed(2);
      if (isHotmaActive) {
        const currentValue = props?.[listKey]?.[index]?.actualImpute;
        const isAnnualIncomePositive = +annualIncome > 0;
        if (isAnnualIncomePositive && currentValue !== 'actual') {
          changeCallback(`${rowName}List[${index}].actualImpute`, 'actual');
        }
      }
      changeCallback(`${rowName}List[${index}].annualIncome`, annualIncome);
    }
  };
};

// handle logic for clicking no for alternate asset form
const generateAltNoClicked = (changeCallback: Function) => {
  return (rowName: string, isList = false) => {
    if (isList) {
      const clearedList = [{ ...NEW_ALTERNATE_ROW }];
      changeCallback(`${rowName}List`, clearedList);
    } else {
      changeCallback(`${rowName}AssetMarketValue`, '0.00');
      changeCallback(`${rowName}AssetInterestRate`, '0.00');
      changeCallback(`${rowName}AssetFeesToConvertCash`, '0.00');
      changeCallback(`${rowName}AssetCashValue`, '0.00');
      changeCallback(`${rowName}AssetAnnualIncome`, '0.00');
      changeCallback(`${rowName}AssetActualImpute`, '-');
      if (rowName === 'otherRetirementAccount') {
        changeCallback('otherRetirementAccountName', null);
      }
      if (rowName === 'other') {
        changeCallback('otherAssetName', null);
      }
      if (rowName === 'disposed') {
        changeCallback('disposedDate', null);
      }
    }
  };
};

const calculateTotalAssets = (
  data,
  isAltForm = false,
  isHotmaActive = false,
) => {
  if (isAltForm) {
    const assetDefinitions = getAssetDefinitions(isHotmaActive);
    return assetDefinitions.reduce((prevTotal, assetDef) => {
      if (assetDef.expandable) {
        const key = `${assetDef.name}List`;
        if (!data[key] || data[key].length === 0) {
          return prevTotal;
        } else {
          const totalFromThisAssetList = data[key].reduce((prevTotal, row) => {
            const tot =
              +row?.marketValue > 0 ? +row?.marketValue + prevTotal : prevTotal;
            return tot;
          }, 0);
          return prevTotal + totalFromThisAssetList;
        }
      } else {
        const key = `${assetDef.name}AssetMarketValue`;
        const tot = +data[key] > 0 ? +data[key] + prevTotal : prevTotal;
        return tot;
      }
    }, 0);
  } else {
    return ASSET_VALUE_FIELDS.reduce((prevTotal, key) => {
      const amount = +data[key] > 0 ? +data[key] + prevTotal : prevTotal;
      return amount;
    }, 0);
  }
};

const calculateCashValue = (data, isHotmaActive) => {
  const assetDefinitions = getAssetDefinitions(isHotmaActive);
  return assetDefinitions.reduce((prevTotal, assetDef) => {
    if (assetDef.expandable) {
      const key = `${assetDef.name}List`;
      if (!data[key] || data[key].length === 0) {
        return prevTotal;
      } else {
        const totalFromThisAssetList = data[key].reduce(
          (innerPrevTotal, row) => {
            const tot =
              +row?.cashValue > 0
                ? +row?.cashValue + innerPrevTotal
                : innerPrevTotal;
            return tot;
          },
          0,
        );
        return prevTotal + totalFromThisAssetList;
      }
    } else {
      const key = `${assetDef.name}AssetCashValue`;
      const tot = +data[key] > 0 ? +data[key] + prevTotal : prevTotal;
      return tot;
    }
  }, 0);
};

// Monthly for regular form, annual for alternate form
const calculateTotalAssetIncome = (data, isAltForm, isHotmaActive) => {
  if (isAltForm) {
    const assetDefinitions = getAssetDefinitions(isHotmaActive);
    return assetDefinitions.reduce((prevTotal, assetDef) => {
      if (assetDef.expandable) {
        const key = `${assetDef.name}List`;
        if (!data[key] || data[key].length === 0) {
          return prevTotal;
        } else {
          const totalFromThisAssetList = data[key].reduce(
            (localPrevTotal, row) => {
              const tot =
                +row?.annualIncome > 0
                  ? +row?.annualIncome + localPrevTotal
                  : localPrevTotal;
              return tot;
            },
            0,
          );
          return prevTotal + totalFromThisAssetList;
        }
      } else {
        const key = `${assetDef.name}AssetAnnualIncome`;
        const tot = +data[key] > 0 ? +data[key] + prevTotal : prevTotal;
        return tot;
      }
    }, 0);
  } else {
    return ASSET_INCOME_FIELDS.reduce((prevTotal, key) => {
      const amount = +data[key] > 0 ? +data[key] + prevTotal : prevTotal;
      return amount;
    }, 0);
  }
};

const calculateImputedIncome = (data, isAltForm, isHotmaActive) => {
  if (isAltForm) {
    const totalCashValue = calculateCashValue(data, isHotmaActive);
    return +totalCashValue < 5000 ? 0 : totalCashValue * 0.0006;
  } else {
    const totalAssets = calculateTotalAssets(data, isAltForm, isHotmaActive);
    return +totalAssets < 5000 ? 0 : totalAssets * 0.0006;
  }
};

const AssetInformation = (props: AssetFormProps) => {
  const {
    change,
    handleSubmit,
    applicantName,
    onSubmit,
    valid,
    isPrior,
    hasCompliancePermission,
    intl,
    isSubmitting,
    handlePrintIncomeAssetForm,
    isHotmaActive = false,
    isAltForm,
    isFormDisabled,
    initialValues: { assetConfirmation },
    wasFormEdited,
    flags,
  } = props;
  const { rolloverIncomeAndAssets, hotmaChanges = false } = flags ?? {};
  const hasAlternateColumns = isAltForm;
  const [initialAssets, setInitialAssets] = useState([]);
  const [retirementAccountsAssets, setRetirementAccountsAssets] = useState([]);
  const [lifeInsuranceAssets, setLifeInsuranceAssets] = useState([]);
  const [finalAssets, setFinalAssets] = useState([]);
  const [isAssetFormReviewed, setIsAssetFormReviewed] = useState(false);

  useEffect(() => {
    setIsAssetFormReviewed(
      rolloverIncomeAndAssets
        ? isNil(assetConfirmation) || wasFormEdited
          ? false
          : assetConfirmation
        : true,
    );
  }, [rolloverIncomeAndAssets, assetConfirmation, wasFormEdited]);

  useEffect(() => {
    // Make number dynamically calculated based on number of assets
    // Since it was hardcoded before and we want to make sure its correct when removing/adding rows
    // If you want to filter out rows, do it here
    let runningCounter = 1;
    const initialAssetsWithNumbers = FORM_INFO.initialAssets.map((asset) => {
      return { ...asset, number: runningCounter++ };
    });
    if (!isHotmaActive || !isAltForm) {
      // Add 1 to counter for retirement accounts row
      runningCounter += 1;
    }
    const lifeInsuranceAssetsWithNumbers = FORM_INFO.lifeInsurance.map(
      (asset) => {
        return { ...asset, number: runningCounter++ };
      },
    );
    const finalAssetsWithNumbers = FORM_INFO.finalAssets.map((asset) => {
      return { ...asset, number: runningCounter++ };
    });

    setInitialAssets(initialAssetsWithNumbers);
    setRetirementAccountsAssets(FORM_INFO.retirementAccounts);
    setLifeInsuranceAssets(lifeInsuranceAssetsWithNumbers);
    setFinalAssets(finalAssetsWithNumbers);
  }, [isHotmaActive, isAltForm]);
  // $FlowFixMe
  const fields = pick(ASSET_ROW_NAMES, props);
  const fieldsAnnualIncome = ALL_ASSETS_DEFINITIONS.reduce((acc, asset) => {
    const { name, expandable } = asset;
    if (expandable) {
      const key = `${name}List`;
      const list = props[key];
      if (list) {
        const values = list.map((row) => row?.annualIncome);
        return {
          ...acc,
          [name]: {
            expandable,
            values,
          },
        };
      }
      return acc;
    } else {
      const key = `${name}AssetAnnualIncome`;
      return {
        ...acc,
        [name]: props[key],
      };
    }
  }, {});
  const totalAssets = calculateTotalAssets(
    props,
    hasAlternateColumns,
    isHotmaActive,
  );
  const totalAssetIncome = calculateTotalAssetIncome(
    props,
    hasAlternateColumns,
    isHotmaActive,
  );
  const imputedIncome = calculateImputedIncome(
    props,
    hasAlternateColumns,
    isHotmaActive,
  );
  // $FlowFixMe
  const stateDisabled = pathOr(
    false,
    ['location', 'state', 'isDisabled'],
    props,
  );
  const isDisabled = stateDisabled && !hasCompliancePermission;
  const priorAndNotCompliance = hasAlternateColumns
    ? isPrior
    : isPrior && !hasCompliancePermission;
  const totalMonthlyAssetIncome = hasAlternateColumns
    ? totalAssetIncome / 12
    : totalAssetIncome;
  const submit = (values: Object) => {
    onSubmit(
      { ...values, isAltForm: hasAlternateColumns },
      totalMonthlyAssetIncome.toFixed(2),
      imputedIncome.toFixed(2),
    );
  };

  const printAssetForm = () => {
    handlePrintIncomeAssetForm('asset');
  };

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-11">
        <div className="panel block block-white-shadow">
          <div className="panel-head">
            {hotmaChanges && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ paddingBottom: '15px' }}
              >
                <Grid item>
                  <Banner
                    color="purple"
                    text={intl.formatMessage(
                      messages?.[
                        isHotmaActive
                          ? 'postHotmaFormNotification'
                          : 'preHotmaFormNotification'
                      ],
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <div className="row">
              <div className="col-xs-10 col-md-11 col-lg-11">
                <h2>
                  <FormattedMessage {...messages.assetInformation} />{' '}
                  {applicantName}
                </h2>
                <NoPrint>
                  <div className="row">
                    <div className="col-xs-12">
                      <p className="no-margin-bottom">
                        <FormattedMessage {...messages.assetNote} />
                      </p>
                    </div>
                  </div>
                </NoPrint>
              </div>
              {!isPrior && (
                <div className="col-xs-2 col-md-1 col-lg-1 text-right">
                  <a
                    className="btn btn-tertiary btn-print no-print"
                    title="Print this form"
                    style={{ fontSize: '1.8em' }}
                    onClick={printAssetForm}
                  >
                    <i className="icon et-print" />
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="panel-body">
            <div className="container-fluid">
              <div className="row container-fluid faux-table">
                <form onSubmit={handleSubmit(submit)}>
                  {hasAlternateColumns ? (
                    <AltAssetTableHeader
                      intl={intl}
                      isHotmaActive={isHotmaActive}
                      householdAssetThreshold={HOUSEHOLD_ASSET_THRESHOLD}
                    />
                  ) : (
                    <AssetTableHeader />
                  )}
                  <AssetRows
                    assets={initialAssets}
                    fields={fields}
                    fieldsAnnualIncome={fieldsAnnualIncome}
                    noClicked={generateNoClicked(change)}
                    altNoClicked={generateAltNoClicked(change)}
                    isPrior={
                      !!priorAndNotCompliance || isDisabled || isFormDisabled
                    }
                    intl={intl}
                    hasAltCols={hasAlternateColumns}
                    handleAddAccount={createNewRow}
                    assetType="initialAssets"
                    calculateCashValue={generateCalcCashValueFunc(
                      change,
                      props,
                    )}
                    calculateAnnualIncome={generateCalcAnnualIncomeFunc(
                      change,
                      props,
                    )}
                    isHotmaActive={isHotmaActive}
                  />
                  {hasAlternateColumns && !isHotmaActive && (
                    <AlternateRetirementPlanRows
                      assets={retirementAccountsAssets}
                      fields={fields}
                      noClicked={generateAltNoClicked(change)}
                      isPrior={
                        !!priorAndNotCompliance || isDisabled || isFormDisabled
                      }
                      calculateCashValue={generateCalcCashValueFunc(
                        change,
                        props,
                      )}
                      calculateAnnualIncome={generateCalcAnnualIncomeFunc(
                        change,
                        props,
                      )}
                      handleAdd={createNewRow}
                    />
                  )}
                  {!hasAlternateColumns && (
                    <RetirementPlanRows
                      assets={retirementAccountsAssets}
                      fields={fields}
                      noClicked={generateNoClicked(change)}
                      isPrior={
                        !!priorAndNotCompliance || isDisabled || isFormDisabled
                      }
                    />
                  )}
                  <AssetRows
                    assets={lifeInsuranceAssets}
                    fields={fields}
                    fieldsAnnualIncome={fieldsAnnualIncome}
                    noClicked={generateNoClicked(change)}
                    altNoClicked={generateAltNoClicked(change)}
                    isPrior={
                      !!priorAndNotCompliance || isDisabled || isFormDisabled
                    }
                    intl={intl}
                    hasAltCols={hasAlternateColumns}
                    assetType="lifeInsurance"
                    handleAddAccount={createNewRow}
                    calculateCashValue={generateCalcCashValueFunc(
                      change,
                      props,
                    )}
                    calculateAnnualIncome={generateCalcAnnualIncomeFunc(
                      change,
                      props,
                    )}
                    isHotmaActive={isHotmaActive}
                  />
                  {
                    // Hotma has different asset rows
                    isHotmaActive ? (
                      <HotmaAssetRows
                        assets={finalAssets}
                        fields={fields}
                        fieldsAnnualIncome={fieldsAnnualIncome}
                        noClicked={generateAltNoClicked(change)}
                        isPrior={
                          !!priorAndNotCompliance ||
                          isDisabled ||
                          isFormDisabled
                        }
                        intl={intl}
                        assetType="finalAssets"
                        handleAddAccount={createNewRow}
                        calculateCashValue={generateCalcCashValueFunc(
                          change,
                          props,
                        )}
                        calculateAnnualIncome={generateCalcAnnualIncomeFunc(
                          change,
                          props,
                        )}
                      />
                    ) : (
                      <AssetRows
                        assets={finalAssets}
                        fields={fields}
                        noClicked={generateNoClicked(change)}
                        altNoClicked={generateAltNoClicked(change)}
                        isPrior={
                          !!priorAndNotCompliance ||
                          isDisabled ||
                          isFormDisabled
                        }
                        intl={intl}
                        hasAltCols={hasAlternateColumns}
                        assetType="finalAssets"
                        handleAddAccount={createNewRow}
                        calculateCashValue={generateCalcCashValueFunc(
                          change,
                          props,
                        )}
                        calculateAnnualIncome={generateCalcAnnualIncomeFunc(
                          change,
                          props,
                        )}
                      />
                    )
                  }

                  {hasAlternateColumns ? (
                    <AltAssetFooter
                      totalAssets={totalAssets}
                      totalAssetIncome={totalAssetIncome}
                      imputedIncome={imputedIncome}
                      isHotmaActive={isHotmaActive}
                    />
                  ) : (
                    <AssetFooter
                      totalAssets={totalAssets}
                      totalAssetIncome={totalAssetIncome}
                      imputedIncome={imputedIncome}
                    />
                  )}
                  {!isPrior || hasCompliancePermission ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={4}
                    >
                      {(isHotmaActive || rolloverIncomeAndAssets) && (
                        <Grid item>
                          <CheckBox
                            label={intl.formatMessage(
                              messages.assetConfirmation,
                            )}
                            name={'assetConfirmation'}
                            onChange={() =>
                              setIsAssetFormReviewed(!isAssetFormReviewed)
                            }
                            checked={isAssetFormReviewed}
                            disabled={
                              !valid ||
                              isDisabled ||
                              isSubmitting ||
                              priorAndNotCompliance ||
                              isFormDisabled
                            }
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <button
                          className="btn btn-primary center-block padbottom20"
                          disabled={
                            !(valid && isAssetFormReviewed) ||
                            isDisabled ||
                            isSubmitting ||
                            priorAndNotCompliance ||
                            isFormDisabled
                          }
                        >
                          <FormattedMessage
                            {...messages.saveAssetInformation}
                          />
                        </button>
                      </Grid>
                    </Grid>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const generateSelectorsforAlternateForm = (selector, form) => {
  const assetDefinitions = getAssetDefinitions();
  return assetDefinitions.reduce((mp, assetDef) => {
    const isExpandableAsset = assetDef.expandable;
    const assetName = assetDef.name;
    if (!isExpandableAsset) {
      return {
        ...mp,
        [`${assetName}AssetMarketValue`]: selector(
          { form },
          `${assetName}AssetMarketValue`,
        ),
        [`${assetName}AssetInterestRate`]: selector(
          { form },
          `${assetName}AssetInterestRate`,
        ),
        [`${assetName}AssetFeesToConvertCash`]: selector(
          { form },
          `${assetName}AssetFeesToConvertCash`,
        ),
        [`${assetName}AssetCashValue`]: selector(
          { form },
          `${assetName}AssetCashValue`,
        ),
        [`${assetName}AssetAnnualIncome`]: selector(
          { form },
          `${assetName}AssetAnnualIncome`,
        ),
        [`${assetName}AssetActualImpute`]: selector(
          { form },
          `${assetName}AssetActualImpute`,
        ),
      };
    } else {
      return {
        ...mp,
        [`${assetName}List`]: selector({ form }, `${assetName}List`),
      };
    }
  }, {});
};
export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('assetInformation');
  return {
    initialValues,
    cashHeldAssetIncome: selector({ form }, 'cashHeldAssetIncome'),
    cashHeldAssetValue: selector({ form }, 'cashHeldAssetValue'),
    certificateOfDepositAssetIncome: selector(
      { form },
      'certificateOfDepositAssetIncome',
    ),
    certificateOfDepositAssetValue: selector(
      { form },
      'certificateOfDepositAssetValue',
    ),
    checkingAccountAssetIncome: selector(
      { form },
      'checkingAccountAssetIncome',
    ),
    checkingAccountAssetValue: selector({ form }, 'checkingAccountAssetValue'),
    disposedAssetIncome: selector({ form }, 'disposedAssetIncome'),
    disposedAssetValue: selector({ form }, 'disposedAssetValue'),
    investmentPropertiesAssetIncome: selector(
      { form },
      'investmentPropertiesAssetIncome',
    ),
    investmentPropertiesAssetValue: selector(
      { form },
      'investmentPropertiesAssetValue',
    ),
    iraAssetIncome: selector({ form }, 'iraAssetIncome'),
    iraAssetValue: selector({ form }, 'iraAssetValue'),
    k401AssetIncome: selector({ form }, 'k401AssetIncome'),
    k401AssetValue: selector({ form }, 'k401AssetValue'),
    keoghAssetIncome: selector({ form }, 'keoghAssetIncome'),
    keoghAssetValue: selector({ form }, 'keoghAssetValue'),
    otherRetirementAccountAssetIncome: selector(
      { form },
      'otherRetirementAccountAssetIncome',
    ),
    otherRetirementAccountAssetValue: selector(
      { form },
      'otherRetirementAccountAssetValue',
    ),
    lifeInsuranceAssetIncome: selector({ form }, 'lifeInsuranceAssetIncome'),
    lifeInsuranceAssetValue: selector({ form }, 'lifeInsuranceAssetValue'),
    otherAssetIncome: selector({ form }, 'otherAssetIncome'),
    otherAssetValue: selector({ form }, 'otherAssetValue'),
    payCardAssetIncome: selector({ form }, 'payCardAssetIncome'),
    payCardAssetValue: selector({ form }, 'payCardAssetValue'),
    pensionAssetIncome: selector({ form }, 'pensionAssetIncome'),
    pensionAssetValue: selector({ form }, 'pensionAssetValue'),
    realEstateAssetIncome: selector({ form }, 'realEstateAssetIncome'),
    realEstateAssetValue: selector({ form }, 'realEstateAssetValue'),
    rentalPropertyAssetIncome: selector({ form }, 'rentalPropertyAssetIncome'),
    rentalPropertyAssetValue: selector({ form }, 'rentalPropertyAssetValue'),
    savingsAccountAssetIncome: selector({ form }, 'savingsAccountAssetIncome'),
    savingsAccountAssetValue: selector({ form }, 'savingsAccountAssetValue'),
    stocksOrBondsAssetIncome: selector({ form }, 'stocksOrBondsAssetIncome'),
    stocksOrBondsAssetValue: selector({ form }, 'stocksOrBondsAssetValue'),
    treasuryMoneyMarketFundAssetIncome: selector(
      { form },
      'treasuryMoneyMarketFundAssetIncome',
    ),
    treasuryMoneyMarketFundAssetValue: selector(
      { form },
      'treasuryMoneyMarketFundAssetValue',
    ),
    trustAccountAssetIncome: selector({ form }, 'trustAccountAssetIncome'),
    trustAccountAssetValue: selector({ form }, 'trustAccountAssetValue'),
    cashHeld: selector({ form }, 'cashHeld'),
    certificateOfDeposit: selector({ form }, 'certificateOfDeposit'),
    checkingAccount: selector({ form }, 'checkingAccount'),
    disposed: selector({ form }, 'disposed'),
    investmentProperties: selector({ form }, 'investmentProperties'),
    ira: selector({ form }, 'ira'),
    k401: selector({ form }, 'k401'),
    keogh: selector({ form }, 'keogh'),
    otherRetirementAccountName: selector(
      { form },
      'otherRetirementAccountName',
    ),
    otherRetirementAccount: selector({ form }, 'otherRetirementAccount'),
    lifeInsurance: selector({ form }, 'lifeInsurance'),
    otherAssetName: selector({ form }, 'otherAssetName'),
    other: selector({ form }, 'other'),
    payCard: selector({ form }, 'payCard'),
    pension: selector({ form }, 'pension'),
    realEstate: selector({ form }, 'realEstate'),
    rentalProperty: selector({ form }, 'rentalProperty'),
    savingsAccount: selector({ form }, 'savingsAccount'),
    stocksOrBonds: selector({ form }, 'stocksOrBonds'),
    treasuryMoneyMarketFund: selector({ form }, 'treasuryMoneyMarketFund'),
    trustAccount: selector({ form }, 'trustAccount'),
    assetConfirmation: selector({ form }, 'assetConfirmation'),
    // For alterate form calculations
    ...generateSelectorsforAlternateForm(selector, form),
  };
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'assetInformation',
      validate,
      enableReinitialize: true,
    })(AssetInformation),
  ),
);
