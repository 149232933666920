import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { useQueryClient } from 'react-query';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  Button,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useViewCentralizedReportsHistory } from './hooks';
import useFormatMessage from '../../../../hooks/useFormatMessage';
import componentMessages from './messages';
import containerMessages from '../../messages';
import generalMessages from '../../../App/messages';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const messages = {
  ...containerMessages,
  ...componentMessages,
  ...generalMessages,
};

const NAME = 'centralized-reports-history';

const ViewCentralizedReportsHistoryModal = ({
  isOpen,
  closeModal,
  intl,
  organizationId,
  report,
}) => {
  const formatMessage = useFormatMessage();
  const [dateHasChanged, setDateHasChanged] = useState(false);
  const [initialFromDate, initialToDate] = useMemo(
    () => [moment().subtract(90, 'days'), moment()],
    [],
  );
  const {
    name,
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleSortChange,
    isLoading,
    order,
    orderBy,
    rows,
    filterState,
    dateState,
    handleDateSubmit,
    handleDateReset,
  } = useViewCentralizedReportsHistory({
    NAME,
    organizationId,
    report,
    fromDate: initialFromDate,
    toDate: initialToDate,
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (dateHasChanged) return;
    const { fromDate, toDate } = dateState?.createdAt || {};
    const initialDateHasChanged =
      !moment(fromDate).isSame(initialFromDate) ||
      !moment(toDate).isSame(initialToDate);
    if (initialDateHasChanged) {
      setDateHasChanged(true);
    }
  }, [dateHasChanged, dateState.createdAt, initialFromDate, initialToDate]);

  useEffect(() => {
    isOpen &&
      queryClient.invalidateQueries([
        'CentralizedReports',
        'history',
        report?.id,
      ]);
  }, [isOpen, report?.id, queryClient]);

  const handleClose = () => {
    clearDates();
    closeModal();
  };
  const clearDates = () => {
    Object.keys(dateState).forEach((key) => {
      handleDateReset(key);
    });
    setDateHasChanged(false);
  };
  const actions = (
    <>
      <Button variant="primarySubtle" onClick={handleClose}>
        {formatMessage(messages.close)}
      </Button>
    </>
  );

  return (
    <>
      <Modal
        actions={actions}
        open={isOpen}
        onClose={handleClose}
        title={formatMessage(messages.viewHistory)}
      >
        <Box width={400}>
          <Table
            ReactBeautifulDnD={{
              DragDropContext,
              Droppable,
              Draggable,
            }}
            name={name}
            allColumnsHidden={allColumnsHidden}
            columnOptions={columnOptions}
            selectedColumns={selectedColumns}
            handleColumnChange={handleColumnChange}
            filterState={filterState}
            handleSortChange={handleSortChange}
            headers={filteredHeaders}
            intl={intl}
            isLoading={isLoading}
            order={order}
            orderBy={orderBy}
            rows={rows}
            count={rows.length}
            sx={{
              padding: 0,
              overflow: 'hidden',
            }}
            emptyStateMessage={`${
              dateHasChanged
                ? formatMessage(messages.noReportForSelectedDate)
                : formatMessage(messages.noReportForLast90Days)
            }`}
            height={rows.length > 0 ? `${(rows.length + 1) * 75}px` : '200px'}
            dateState={dateState}
            handleDateSubmit={handleDateSubmit}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ViewCentralizedReportsHistoryModal;
