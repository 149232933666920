import { useRef, useState } from 'react';
import { useResizeObserver } from '@fortress-technology-solutions/fortress-component-library/hooks';

const ManageProspectsLayout = ({ children, name }) => {
  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState('calc(100vh - 134px)');

  useResizeObserver({
    ref,
    callback: (entry) => {
      let newTableHeight = 0;
      const otherComponentsHeight = 68;
      // loop through children and set height
      entry.target.childNodes.forEach((child) => {
        if (child.id !== `table-wrapper_${name}`) {
          newTableHeight += child.clientHeight;
        }
      });

      setTableHeight(
        `calc(100vh - ${newTableHeight + 44 + otherComponentsHeight}px)`,
      );
    },
  });

  return (
    <div ref={ref} style={{ height: 'calc(100vh - 44px)', overflow: 'hidden' }}>
      {children(tableHeight)}
    </div>
  );
};

export default ManageProspectsLayout;
