import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import CreateReportModal from './components/CreateCentralizedReportModal';
import ViewHistoryModal from './components/ViewCentralizedReportsHistoryModal';
import { SECTION_HEADERS } from './constants';
import { useManageCentralizedReports } from './hooks';
import messages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';

const NAME = 'centralized-reports';

function AllManageReports({ intl, organizationId, userId, username }) {
  const [selectedReport, setSelectedReport] = useState(null);
  const formatMessage = useFormatMessage();
  const TITLE = formatMessage(messages.manageReports);
  const {
    closeModal: closeCreateReportModal,
    openModal: openCreateReportModal,
    open: createReportModalIsOpen,
  } = useModal(false);
  const {
    closeModal: closeViewHistoryModal,
    openModal,
    open: viewHistoryModalIsOpen,
  } = useModal(false);

  function openViewHistoryModal(report) {
    setSelectedReport(report);
    openModal();
  }
  const {
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSortChange,
    handleTruncateChange,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    selectedColumns,
    truncatedColumns,
    handleCreateReport,
    report,
  } = useManageCentralizedReports({
    NAME,
    organizationId,
    userId,
    username,
    openCreateReportModal,
    openViewHistoryModal,
  });

  return (
    <DocumentTitle title={TITLE}>
      <>
        <TableHeader title={TITLE} />
        <Table
          ReactBeautifulDnD={{
            DragDropContext,
            Droppable,
            Draggable,
          }}
          ReactRouterLink={ReactRouterLink}
          sectionHeaders={SECTION_HEADERS}
          height={'calc(100vh - 210px)'}
          allColumnsHidden={allColumnsHidden}
          columnOptions={columnOptions}
          dateState={dateState}
          filterState={filterState}
          filterTypeState={filterTypeState}
          handleColumnChange={handleColumnChange}
          handleDateSubmit={handleDateSubmit}
          handleFilterChange={handleFilterChange}
          handleFilterTypeChange={handleFilterTypeChange}
          handleSortChange={handleSortChange}
          handleTruncateChange={handleTruncateChange}
          headers={filteredHeaders}
          intl={intl}
          isLoading={isLoading}
          name={name}
          order={order}
          orderBy={orderBy}
          rows={rows}
          selectedColumns={selectedColumns}
          truncatedColumns={truncatedColumns}
        />
        <CreateReportModal
          isOpen={createReportModalIsOpen}
          closeModal={closeCreateReportModal}
          createReport={handleCreateReport}
          reportName={report?.name}
        />
        <ViewHistoryModal
          isOpen={viewHistoryModalIsOpen}
          closeModal={closeViewHistoryModal}
          intl={intl}
          organizationId={organizationId}
          report={selectedReport}
        />
      </>
    </DocumentTitle>
  );
}

const mapStateToProps = (state) => {
  const {
    app: { currentUser },
  } = state;
  return {
    organizationId: currentUser?.user?.organization?.id ?? '',
    userId: currentUser?.user?.id ?? '',
    username: currentUser?.user?.username ?? '',
  };
};

export default connect(mapStateToProps)(injectIntl(AllManageReports));
