import { put, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';

import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../containers/App/selectors';

import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import type { Action } from '../../containers/App/types';
import LeaseService from '../../services/leaseService';

import { getOneResident } from '../ResidentProfile/actions';
import { getOneApplication } from '../ApplicationProfile/actions';
import { getAllDocuments } from '../ManageDocuments/actions';
import {
  getProspectCompletedActivities,
  getProspectAllActivities,
} from '../PeopleProfile/actions';
import {
  DOCUMENTS_DEFAULT_PAGE,
  DOCUMENTS_DEFAULT_LIMIT,
  DOCUMENTS_DEFAULT_SORTING,
} from '../ManageDocuments/constants';

import {
  getSignatureStatuses,
  getSignatureStatusesSuccess,
  getSignatureStatusesError,
} from './actions';
import {
  EDIT_LEASE,
  EXECUTE_LEASE,
  GET_SIGNATURE_STATUSES,
  UPLOAD_LEASE,
  SEND_TO_PORTAL,
} from './constants';
import messages from './messages';

export function* editLease(action: Action<any>): Saga<void> {
  action?.payload?.setIsEditLeaseMode?.(true);
  try {
    const {
      applicationId,
      isCancel,
      isResident,
      leaseId,
      profileId,
      prospectId,
    } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const leaseService = new LeaseService();

    yield leaseService.cancelLease(
      organizationId,
      selectedProperty.id,
      leaseId,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.unlockedLeaseSuccess),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    const refreshDataAction = isResident ? getOneResident : getOneApplication;
    yield put(refreshDataAction(profileId));
    if (prospectId) {
      yield put(getProspectCompletedActivities(prospectId));
      yield put(getProspectAllActivities(prospectId));
    }

    if (applicationId && isCancel) {
      yield put(
        getAllDocuments(
          applicationId,
          DOCUMENTS_DEFAULT_PAGE,
          DOCUMENTS_DEFAULT_LIMIT,
          DOCUMENTS_DEFAULT_SORTING,
        ),
      );
    }
    yield put(getSignatureStatuses(leaseId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.unlockedLeaseError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* editLeaseSaga(): Saga<void> {
  yield throttle(500, EDIT_LEASE, editLease);
}

export function* uploadLease(action: Action<any>): Saga<void> {
  try {
    const { applicationId, file, isResident, leaseId, notes, profileId } =
      action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const leaseService = new LeaseService();

    const document = new FormData();
    document.append('file', file);
    document.append('notes', notes);

    yield leaseService.uploadLease(
      organizationId,
      selectedProperty.id,
      leaseId,
      document,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.uploadedLeaseSuccess),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    const refreshDataAction = isResident ? getOneResident : getOneApplication;
    yield put(
      getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      ),
    );
    yield put(refreshDataAction(profileId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.uploadedLeaseError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* uploadLeaseSaga(): Saga<void> {
  yield throttle(500, UPLOAD_LEASE, uploadLease);
}

export function* sendToPortal(action: Action<any>): Saga<void> {
  try {
    const { isResident, leaseId, profileId, prospectId } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const leaseService = new LeaseService();

    yield leaseService.sendToPortal(
      organizationId,
      selectedProperty.id,
      leaseId,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.leaseSentToPortal),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    const refreshDataAction = isResident ? getOneResident : getOneApplication;
    yield put(refreshDataAction(profileId));
    yield put(getSignatureStatuses(leaseId));
    yield put(getProspectCompletedActivities(prospectId));
    yield put(getProspectAllActivities(prospectId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.sendToPortalError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* sendToPortalSaga(): Saga<void> {
  yield throttle(500, SEND_TO_PORTAL, sendToPortal);
}

export function* getLeaseSignatureStatuses(action: Action<any>): Saga<void> {
  try {
    const { leaseId } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const leaseService = new LeaseService();

    const signaturesInfo = yield leaseService.getLeaseHoldersSignatureStatus(
      organizationId,
      selectedProperty.id,
      leaseId,
    );

    yield put(getSignatureStatusesSuccess(signaturesInfo));
  } catch (err) {
    yield put(getSignatureStatusesError(err));
  }
}

export function* getLeaseSignatureStatusesSaga(): Saga<void> {
  yield throttle(500, GET_SIGNATURE_STATUSES, getLeaseSignatureStatuses);
}

export function* executeLease(action: Action<any>): Saga<void> {
  const {
    applicationId,
    isResident,
    leaseId,
    profileId,
    prospectId,
    signature,
    hideModalCb,
  } = action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const leaseService = new LeaseService();
    yield leaseService.executeLease(
      organizationId,
      selectedProperty.id,
      leaseId,
      signature,
    );

    hideModalCb();
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.leaseSuccessfullyExecuted),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    yield put(
      getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      ),
    );

    const refreshDataAction = isResident ? getOneResident : getOneApplication;
    yield put(refreshDataAction(profileId));
    yield put(getSignatureStatuses(leaseId));
    yield put(getProspectCompletedActivities(prospectId));
    yield put(getProspectAllActivities(prospectId));
  } catch (err) {
    hideModalCb();
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.errorExecutingLease),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* executeLeaseSaga(): Saga<void> {
  yield throttle(500, EXECUTE_LEASE, executeLease);
}

export default [
  executeLeaseSaga,
  editLeaseSaga,
  getLeaseSignatureStatusesSaga,
  sendToPortalSaga,
  uploadLeaseSaga,
];
