import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { ALT_FORM_FIRST_COL_SECTION, ALT_FORM_SECOND_COL_SECTION } from '..';

import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

export const AltAssetTableHeader = ({
  isHotmaActive = false,
  intl,
  householdAssetThreshold = 50000,
}) => {
  const colSize = isHotmaActive ? 2 : 3;
  return (
    <div className="row faux-table__head">
      <div className={ALT_FORM_FIRST_COL_SECTION}>
        <div className="row">
          <div className="col-xs-5">
            <div className="row">
              <div className="col-xs-3">
                <label>#</label>
              </div>
              <div className="col-xs-9">
                <label>
                  <FormattedMessage {...messages.typeOfAsset} />
                </label>
              </div>
            </div>
          </div>
          <div className="col-xs-3 text-center">
            <div className="row">
              <div className="col-xs-12">
                <label>
                  <FormattedMessage {...messages.currentlyOwn} />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6">
                <label>
                  <FormattedMessage {...messages.no} />
                </label>
              </div>
              <div className="col-xs-6">
                <label>
                  <FormattedMessage {...messages.yes} />
                </label>
              </div>
            </div>
          </div>
          <div className="col-xs-4">
            <label>
              <FormattedMessage {...messages.marketValue} />
            </label>
          </div>
        </div>
      </div>
      <div className={ALT_FORM_SECOND_COL_SECTION}>
        <div className="row">
          <div className="col-xs-11">
            <div className="row">
              <div className={`col-xs-${colSize}`}>
                <label>
                  <FormattedMessage {...messages.interestRate} />
                </label>
              </div>
              <div className={`col-xs-${colSize}`}>
                <label>
                  <FormattedMessage {...messages.feesToCash} />
                </label>
              </div>
              <div className={`col-xs-${colSize}`}>
                <label>
                  <FormattedMessage {...messages.cashValue} />
                </label>
              </div>
              <div className="col-xs-3">
                <label>
                  <FormattedMessage {...messages.annualIncome} />
                </label>
              </div>
              {isHotmaActive && (
                <div className="col-xs-3">
                  <label>
                    <FormattedMessage {...messages.actualImpute} />
                  </label>
                  <Tooltip
                    title={intl.formatMessage(messages.actualImputeTooltip, {
                      householdAssetThreshold: intl.formatNumber(
                        householdAssetThreshold,
                        {
                          style: 'currency',
                          currency: 'USD',
                        },
                      ),
                    })}
                    arrow
                    placement="top"
                    style={{
                      display: 'inline-flex',
                      marginLeft: '2px',
                      verticalAlign: 'middle',
                    }}
                    variant="light"
                  >
                    <AlertInfoIcon />
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
          <div className="col-xs-1"></div>
        </div>
      </div>
    </div>
  );
};
