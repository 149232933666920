import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.Header',
    defaultMessage: 'Documents',
  },
  uploadFile: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.UploadFile',
    defaultMessage: 'Upload File',
  },
  date: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.Date',
    defaultMessage: 'Date',
  },
  documentType: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.DocumentType',
    defaultMessage: 'Document Type',
  },
  householdMember: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable..HouseholdMember',
    defaultMessage: 'Household Member',
  },
  documentName: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.DocumentName',
    defaultMessage: 'Document Name',
  },
  notes: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.Notes',
    defaultMessage: 'Notes',
  },
  actions: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.Actions',
    defaultMessage: 'Actions',
  },
  createResidentLetter: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.CreateResidentLetter',
    defaultMessage: 'Create Resident Letter',
  },
  createTenantLetter: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.CreateTenantLetter',
    defaultMessage: 'Create Tenant Letter',
  },
  wasUploadedFromPortal: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.WasUploadedFromPortal',
    defaultMessage: 'P*',
  },
  wasUploadedFromPortalLegend: {
    id: 'App.ResidentProfile.ManageDocuments.DocumentsTable.WasUploadedFromPortalLegend',
    defaultMessage: 'P*: Check mark indicates document was uploaded via Portal',
  },
});

export default messages;
