import React from 'react';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import { injectIntl, FormattedDate } from 'react-intl';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import confirm from '../../../../../../components/ConfirmDialogModal';
import ElementWithPermissions from '../../../../../../components/ElementWithPermissions';

import { editLease } from '../../../../actions';
import { ELECTRONIC_SIGNING_METHOD } from '../../../../../../utils/lease-helpers';

import messages from './messages';

const BlockIcon = styled.i`
  display: block;
  font-size: 40px;
  margin: 10px;
`;

type ExecutedCancelButtonProps = {
  applicationId: string,
  isResident: string,
  lease: Object,
  leaseId: string,
  profileId: string,
  prospectId: string,
};

type ExecutedCancelButtonInjectedProps = {
  actions: Object,
  intl: Object,
};

export const ExecutedCancelButton = ({
  actions,
  applicationId,
  intl,
  isResident,
  lease,
  leaseId,
  profileId,
  prospectId,
}: ExecutedCancelButtonProps & ExecutedCancelButtonInjectedProps) => {
  const desiredSignatureMethod = pathOr(
    null,
    ['desiredSignatureMethod'],
    lease,
  );
  const isElectronic = desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD;

  const confirmHeader = () => (
    <span>
      <BlockIcon className="icon et-alert-critical" />
      {intl.formatMessage(messages.warning)}
    </span>
  );

  const confirmBody = () => {
    return (
      <React.Fragment>
        <p>
          {isElectronic && (
            <React.Fragment>
              {intl.formatMessage(messages.cancelElectronic)}
            </React.Fragment>
          )}
          {!isElectronic && (
            <React.Fragment>
              {intl.formatMessage(messages.cancelManual)}
            </React.Fragment>
          )}
        </p>
        <p>{intl.formatMessage(messages.areYouSure)}</p>
      </React.Fragment>
    );
  };

  const confirmEditDialog = () => {
    confirm(confirmHeader(), {
      altBody: confirmBody(),
    }).then(() => {
      actions.editLease(
        leaseId,
        profileId,
        isResident,
        prospectId,
        applicationId,
        true,
      );
    });
  };

  return (
    <React.Fragment>
      <ElementWithPermissions scope={['lease-cancel']}>
        <Button className="btn btn-tertiary" onClick={confirmEditDialog}>
          {intl.formatMessage(messages.cancel)}
        </Button>
      </ElementWithPermissions>
      {isElectronic && (
        <div className="text-center" style={{ marginRight: '3%' }}>
          {`${intl.formatMessage(messages.electronicallyExecuted)} - `}
          <FormattedDate value={lease.updatedAt} />
        </div>
      )}
      <div className="text-center" style={{ marginRight: '3%' }}>
        {intl.formatMessage(messages.signedLeaseUploaded)}
      </div>
    </React.Fragment>
  );
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators({ editLease }, dispatch);
  return { actions };
}

const InjectedExecutedCancelButton = injectIntl(ExecutedCancelButton);

export default connect(null, mapDispatchToProps)(InjectedExecutedCancelButton);
