import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { curryN, find, propEq, pathOr } from 'ramda';

import * as uploaderActions from './actions';
import { uploader } from './Uploader';
import messages from './messages';
import { getDocumentTypesOptions, getApplicantsOptions } from './selectors';
import type {
  GlobalState,
  SelectOption,
  PaginationMeta,
  OrderValue,
  Property,
} from '../App/types';
import type { Document } from './types';
import DocumentsTable from './DocumentsTable';
import confirm from '../../components/ConfirmDialogModal';
import {
  RESIDENT_LETTER_FORM_NAME,
  COMPLIANCE_DOCUMENT_TYPE,
} from './constants';
import ResidentLettersModal from './ResidentLettersModal';

type Props = {
  allDocumentTypes: Array<Object>,
  documentTypes: Array<SelectOption>,
  applicants: Array<SelectOption>,
  documents: Array<Document>,
  meta: PaginationMeta,
  currentSorting: OrderValue,
  columnOrder: {
    createdAt: string,
    documentTypeId: string,
    applicantId: string,
    name: string,
  },
  selectedProperty?: Property,
};

type InjectedProps = {
  applicationId: string,
  actions: Object,
  intl: any,
  history: Object,
  locale: string,
  customerType: string,
  customerName?: string,
  deletedApplicants: Array<any>,
};

type State = {
  currentPage: number,
  limit: number,
  modal: ?string,
};

export class ManageDocumentsPage extends Component<
  Props & InjectedProps,
  State,
> {
  state = {
    currentPage: 1,
    limit: 15,
    modal: null,
  };

  componentDidMount() {
    this.onPageChange(this.state.currentPage);
    this.props.actions.getDocumentTypes(this.props.applicationId);
    this.props.actions.getResidentLetterDocumentTypes(this.props.applicationId);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.applicationId !== this.props.applicationId) {
      this.onPageChange(this.state.currentPage);
    }
    if (
      prevProps.documentsListStatus !== this.props.documentsListStatus &&
      this.props.documentsListStatus === 'changed'
    ) {
      this.onPageChange(this.state.currentPage);
      this.handleSetDocumentsListStatus('updated');
    }
  }

  showUploader = () => {
    uploader(
      this.props.intl,
      this.context.store,
      this.props.applicationId,
      this.props.documentTypes,
      this.props.applicants,
      this.uploadDocument,
    ).then(() => {
      this.onPageChange(this.state.currentPage);
    });
  };

  uploadDocument = (data: any) => {
    this.props.actions.saveDocument(
      data,
      this.state.currentPage,
      this.state.limit,
      this.props.currentSorting,
    );
  };

  deleteDocument = (documentId: string) => {
    this.props.actions.deleteDocument(
      documentId,
      this.props.applicationId,
      this.state.currentPage,
      this.state.limit,
      this.props.currentSorting,
    );
  };

  handleOrder = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.props.actions.getAllDocuments(
      this.props.applicationId,
      this.state.currentPage,
      this.state.limit,
      {
        fieldName: field,
        order,
      },
    );
  };

  handleDelete = (documentId: string) => {
    confirm(this.props.intl.formatMessage(messages.deleteConfirmation), {
      intl: this.props.intl,
    }).then(
      () => {
        this.deleteDocument(documentId);
      },
      () => {},
    );
  };

  handleSetDocumentsListStatus = (status: 'changed' | 'updated') => {
    this.props.actions.setDocumentsListStatus(status);
  };

  onPageChange = (nextPage: number) => {
    const limit = window.matchMedia('(min-width: 767px)').matches ? 15 : 10;

    this.props.actions.getAllDocuments(
      this.props.applicationId,
      nextPage,
      limit,
      this.props.currentSorting,
    );
    this.setState({
      currentPage: nextPage,
      limit: limit,
    });
  };

  handleDownloadDocument = (documentId: string, filename: string) => {
    this.props.actions.downloadDocument(documentId, filename);
  };

  onAddLetterClick = () => {
    this.setState({ modal: RESIDENT_LETTER_FORM_NAME });
  };

  resetModal = () => {
    this.setState({ modal: null });
  };

  handleFormsSubmit = ({ residentLetterId }: Object) => {
    const {
      applicationId,
      customerName,
      currentSorting,
      actions: { generateResidentLetter },
    } = this.props;
    const documentName = this.getDocumentNameById(residentLetterId);
    const name = customerName ? customerName.split(' ').join('_') : '';
    const filename = `${documentName}-${name}`;
    const { currentPage, limit } = this.state;
    generateResidentLetter(
      applicationId,
      residentLetterId,
      filename,
      currentPage,
      limit,
      currentSorting,
    );
    this.resetModal();
  };

  parseResidentLetters = (
    documentTypes: Array<Object>,
    locale: string,
  ): Array<Object> => {
    if (!documentTypes) {
      return [];
    }
    const residentLetters = documentTypes
      .filter((letter) => letter.type !== COMPLIANCE_DOCUMENT_TYPE)
      .map((letter) => ({
        value: letter.id,
        text: letter.translations[locale] || letter.name,
      }));
    return residentLetters;
  };

  getDocumentNameById = (id: string) => {
    const doc = find(propEq('id', id))(this.props.allDocumentTypes);
    const docName = pathOr('Resident-Letter', ['name'], doc);
    return docName.split(' ').join('_');
  };

  render() {
    const onOrderClickCurried = curryN(2, this.handleOrder);
    const onDeleteClickCurried = curryN(2, this.handleDelete);
    const {
      intl,
      allResidentLetterDocumentTypes,
      customerType,
      deletedApplicants,
    } = this.props;

    const residentLetters = this.parseResidentLetters(
      allResidentLetterDocumentTypes,
      this.props.locale || this.props.intl.locale,
    );
    const isAllCommercial =
      this.props.selectedProperty.hasCommercialFloorPlans === 'ALL';
    return (
      <Fragment>
        <ResidentLettersModal
          intl={intl}
          show={this.state.modal === RESIDENT_LETTER_FORM_NAME}
          letters={residentLetters}
          onClose={this.resetModal}
          onSubmit={this.handleFormsSubmit}
          residentLetters={residentLetters}
          isAllCommercial={isAllCommercial}
        />
        <DocumentsTable
          intl={this.props.intl}
          showUploader={this.showUploader}
          documents={this.props.documents}
          handleDownloadDocument={this.handleDownloadDocument}
          columnOrder={this.props.columnOrder}
          pageCount={this.props.meta.pageCount}
          totalCount={this.props.meta.totalCount}
          onPageChange={this.onPageChange}
          onOrderClick={onOrderClickCurried}
          onDeleteClick={onDeleteClickCurried}
          currentPage={this.state.currentPage}
          limit={this.state.limit}
          count={this.props.meta.count}
          onAddLetterClick={this.onAddLetterClick}
          customerType={customerType}
          deletedApplicants={deletedApplicants}
          isAllCommercial={isAllCommercial}
        />
      </Fragment>
    );
  }
}

ManageDocumentsPage.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Props => {
  return {
    documentTypes: getDocumentTypesOptions(state),
    applicants: getApplicantsOptions(state, ownProps.customerType),
    documents: state.manageDocuments.documents,
    documentsListStatus: state.manageDocuments.documentsListStatus,
    allDocumentTypes: state.manageDocuments.documentTypes,
    allResidentLetterDocumentTypes:
      state.manageDocuments.residentLetterDocumentTypes,
    meta: state.manageDocuments.meta,
    currentSorting: state.manageDocuments.currentSorting,
    columnOrder: state.manageDocuments.columnOrder,
    selectedProperty: state.app.selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...uploaderActions,
    },
    dispatch,
  );
  return { actions };
}

const InjectedManageDocumentsPage = injectIntl(ManageDocumentsPage);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageDocumentsPage);
