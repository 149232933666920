import React, { useState, useEffect } from 'react';
import { find, findIndex, head, pathOr, propEq, sortBy } from 'ramda';
import isEmpty from 'lodash/isEmpty';
import { Col, Panel, Row } from 'react-bootstrap';
import {
  Button,
  Box,
  Link,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  ModalConfirm,
  useConfirmModal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {
  ELECTRONIC_SIGNING_METHOD,
  getSentAndExecutedStatus,
} from '../../../utils/lease-helpers';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import messages from './messages';
import type { Application } from '../../CreateApplication/types';
import type { ActivityType } from '../../App/types';
import type { Intl } from '../../../types';
import styled from 'styled-components';
import { filterFRApplicants } from '../ProfileDetails/index';
import {
  DetailList,
  Divider,
  Header,
} from '../../../components/ProfileVisualComponents';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
import AssignUnitLink from '../../../containers/ApplicationProfile/Snapshot/AssignUnitLink';
import { getDesiredMoveInDate } from '../../ManageProspects/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';

type Props = {
  currentRecord: Application,
  currentUser: Object,
  users: Array<Object>,
  intl: Intl,
  activityTypes: Array<ActivityType>,
  handleAssignedToChange: Function,
  handleConvertToResident: Function,
  handleConvertToCommercialTenant: Function,
  hasCompletedQualifications: boolean,
  history: Object,
  assignedUnits: Array<Object>,
  unassignUnitCallback: Function,
  scheduledMoveInDate: string,
  applicationId: string,
  getUnitQuote: Function,
  prospectId: string,
  paymentRestrictions: Object,
  rentStartDate: string,
  propertyId: string,
  organizationId?: string,
  disableUnassignUnitLink?: boolean,
  isAffordable: boolean,
};
const LabelProfileDetails = styled.label.attrs({
  className: 'profile-details',
})``;
const SnapshotPanel = styled(Panel).attrs({
  className: 'block block-snapshot block-white-shadow',
})``;

export const ButtonsRow = ({
  handleConvertToResident,
  handleConvertToCommercialTenant,
  convertButtonDisabled,
}: any) => {
  let ConvertButton = null;
  if (handleConvertToResident) {
    ConvertButton = (
      <Button
        onClick={handleConvertToResident}
        type="button"
        variant="action"
        disabled={convertButtonDisabled}
      >
        <FormattedMessage {...messages.convertToResident} />
      </Button>
    );
  }

  if (handleConvertToCommercialTenant) {
    ConvertButton = (
      <Button
        variant={'action'}
        onClick={handleConvertToCommercialTenant}
        type="button"
        disabled={convertButtonDisabled}
      >
        <FormattedMessage {...messages.convertToCommercialTenant} />
      </Button>
    );
  }

  return (
    <Row>
      <Col xs={12} sm={6}>
        <ElementWithPermissions scope={['resident-create']}>
          {ConvertButton}
        </ElementWithPermissions>
      </Col>
    </Row>
  );
};

export const Snapshot = ({
  currentRecord,
  currentUser,
  users,
  intl,
  activityTypes,
  handleAssignedToChange,
  history,
  assignedUnits,
  handleConvertToResident,
  handleConvertToCommercialTenant,
  hasHouseholdRentableItems,
  unassignUnitCallback,
  applicationId,
  scheduledMoveInDate,
  getUnitQuote,
  prospectId,
  onPaymentRestrictionChange,
  paymentRestrictions,
  rentStartDate,
  hasCompletedQualifications,
  refreshActivityTable,
  areApplicationFeesPaid,
  propertyId,
  disableUnassignUnitLink,
  isAffordable,
}: Props) => {
  const { rentableItems } = useFlags();

  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();
  const [confirmModalContentState, setConfirmModalContentState] = useState({
    title: '',
    body: '',
  });

  // Clear confirm modal content when it closes
  useEffect(() => {
    if (!openConfirm) {
      setConfirmModalContentState({ title: '', body: [] });
    }
  }, [openConfirm]);

  const prospectInfo = currentRecord.prospectInfo || {
    lastActivity: null,
    prospectPreferences: { moveInDateFrom: null },
  };
  const prospectPreferences = prospectInfo.prospectPreferences || {
    moveInDateFrom: null,
  };

  const lastActivityType = prospectInfo.lastActivity
    ? find(propEq('id', prospectInfo.lastActivity.activityTypeId))(
        activityTypes,
      )
    : null;
  const lastActivityDateFormatted = prospectInfo.lastActivity
    ? moment(
        prospectInfo.lastActivity.endTime ||
          prospectInfo.lastActivity.createdAt,
      ).format('MMM DD, YYYY')
    : '---';
  const assigneeIndex = findIndex(
    (x) => x.value === currentRecord.assignedToId,
    users,
  );
  const assignee = currentRecord.assignedTo
    ? currentRecord.assignedTo
    : { firstName: '', lastName: '' };
  const assignees =
    assigneeIndex < 0
      ? sortBy(
          (x) => {
            return x.text.toLowerCase();
          },
          [
            {
              value: currentRecord.assignedToId,
              text: `${assignee.firstName} ${assignee.lastName}`,
            },
          ].concat(users),
        )
      : users;

  const assignedUnit =
    assignedUnits && !!head(assignedUnits) ? head(assignedUnits) : null;

  const unitNumber = pathOr(
    intl.formatMessage(messages.notAssigned),
    ['unit', 'number'],
    // $FlowFixMe
    assignedUnit,
  );

  const unitRentRestriction = (
    currentRecord?.au?.unit?.floorPlan?.unitRentRestriction ?? ''
  ).trim();

  const visibleApplicants = filterFRApplicants(currentRecord.applicants);
  const applicantNames = visibleApplicants
    .filter((applicant) =>
      pathOr(false, ['applicantCustomer', 'isProspect'], applicant),
    )
    .map((applicant) => {
      // $FlowFixMe
      const first = pathOr(
        null,
        ['applicantCustomer', 'customer', 'firstName'],
        applicant,
      );
      // $FlowFixMe
      const last = pathOr(
        null,
        ['applicantCustomer', 'customer', 'lastName'],
        applicant,
      );
      const name = [];
      if (first) {
        name.push(first);
      }
      if (last) {
        name.push(last);
      }
      return name.join(' ');
    })
    .join(', ');

  const unassignUnit = () => {
    const { lease } = currentRecord;
    const { leaseExecuted, leaseSentToPortal } =
      getSentAndExecutedStatus(lease);
    const isElectronic =
      lease.desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD;

    let title = `${intl.formatMessage(
      messages.confirmUnassignUnit,
    )} ${applicantNames}?`;

    let body = '';
    if (leaseExecuted && isElectronic) {
      body = intl.formatMessage(messages.cancelElectronic);
    }
    if (leaseExecuted && !isElectronic) {
      body = intl.formatMessage(messages.cancelManual);
    }
    if (leaseSentToPortal) {
      body = intl.formatMessage(messages.confirmEditElectronicLease);
    }
    if (rentableItems && hasHouseholdRentableItems) {
      body = intl.formatMessage(messages.confirmHouseholdRentableItemsRemoval);
    }
    if (hasCompletedQualifications) {
      title = intl.formatMessage(messages.warning);
      body = intl.formatMessage(
        messages.confirmUnassignUnitWithCompletedCertifications,
      );
    }

    setConfirmModalContentState({ title, body });
    openConfirmModal();
  };

  const applicant = visibleApplicants.filter((applicant) =>
    pathOr(false, ['applicantCustomer', 'isProspect'], applicant),
  )[0];

  const convertButtonDisabled =
    !assignedUnit || currentRecord.applicationStatus.name !== 'Approved';

  const routeToAvailableUnits = () => {
    if (assignedUnit) {
      getUnitQuote({
        userId: currentUser.id,
        unitId: assignedUnit.unitId,
        applicationId: assignedUnit.applicationId,
        applicantId: applicant.id,
        prospectId,
        refreshActivityTable,
      });
    } else {
      try {
        localStorage.setItem(
          'previous-applicant-id',
          JSON.stringify(currentRecord) + '',
        );
        history.push(
          // eslint-disable-next-line max-len
          `/property/${propertyId}/manage-unit-availability?applicantId=${applicant.id}&applicationId=${applicationId}`,
        );
      } catch (e) {
        /* empty */
      }
    }
  };
  const isCommercial = currentRecord.isCommercial === true;
  const hasAssignedUnit = assignedUnit !== null;
  const propertyConfigs = currentRecord?.property?.config ?? {};
  const { assigningUnitsMoveInDates: assigningUnitsMoveInDatesFlag } =
    useFlags();
  const { moveInDateFrom } = getDesiredMoveInDate({
    prospectPreferences,
    isUnitAssigned: Boolean(currentRecord?.au?.unitId),
  });

  const ConfirmModalProps = {
    title: confirmModalContentState.title,
    children: confirmModalContentState.body?.length ? (
      <Box component={'ul'}>
        <Typography component={'li'} paragraph={true}>
          {confirmModalContentState.body}
        </Typography>
      </Box>
    ) : null,
    open: openConfirm,
    actionsProps: [
      { children: 'Cancel', onClick: closeConfirmModal },
      {
        children: 'Confirm',
        onClick: () => {
          unassignUnitCallback();
          closeConfirmModal();
        },
      },
    ],
  };
  return (
    <SnapshotPanel>
      <Header>
        <Stack spacing={2}>
          <IconTitle
            icon={<i key="1" className="et-prospect" />}
            message={messages.snapshot}
          />
          <Stack direction={'row'} spacing={2}>
            <span>
              <div className="custom-checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={paymentRestrictions.certifiedOnly}
                    disabled={true}
                  />

                  <span className="custom-check-square" />
                  <FormattedMessage {...messages.certifiedOnly} />
                </label>
              </div>
            </span>
            <div className="custom-checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={paymentRestrictions.doNotAccept}
                  disabled={true}
                />
                <span className="custom-check-square" />
                <FormattedMessage {...messages.doNotAccept} />
              </label>
            </div>
          </Stack>
        </Stack>
      </Header>
      <Panel.Body>
        <Row className="row">
          <Col xs={12} md={3}>
            <LabelProfileDetails>
              <FormattedMessage {...messages.assignedTo} />
            </LabelProfileDetails>
          </Col>
          <Col xs={12} md={8}>
            <ElementWithPermissions
              scope={['prospect-assign', 'prospect-update']}
            >
              <Field
                name="assignedToId"
                component="select"
                className="form-control input-sm"
                onChange={handleAssignedToChange}
              >
                {assignees.map((user) => (
                  <option key={user.value} value={user.value}>
                    {user.text}
                  </option>
                ))}
              </Field>
            </ElementWithPermissions>
          </Col>
        </Row>
        <Divider />
        <DetailList>
          <li style={{ display: '-webkit-box' }}>
            {' '}
            <FormattedMessage {...messages.unitNumber} />
            {` ${unitNumber}`}
            {isAffordable && !isEmpty(unitRentRestriction) && (
              <Typography
                variant="h6"
                color={palette.dark.blue}
                sx={{
                  lineHeight: '20px',
                  marginLeft: '5px',
                  background: palette.lighter.blue,
                  borderRadius: '4px',
                  padding: '0px 4px',
                }}
              >
                {unitRentRestriction}
              </Typography>
            )}
            <Box marginLeft={'auto'} maxWidth={'fit-content'}>
              <ElementWithPermissions scope={['manage-units-update']}>
                <AssignUnitLink
                  applicantName={applicant?.name}
                  hasAssignedUnit={hasAssignedUnit}
                  routeToAvailableUnits={routeToAvailableUnits}
                  unassignUnit={unassignUnit}
                  areApplicationFeesPaid={areApplicationFeesPaid}
                  propertyConfigs={propertyConfigs}
                  isDisabled={disableUnassignUnitLink}
                />
              </ElementWithPermissions>
              {applicant && currentUser && !isCommercial
                ? [
                    <span key={1}>{' / '}</span>,
                    <Link key={2} onClick={routeToAvailableUnits}>
                      <FormattedMessage {...messages.provideQuote} />
                    </Link>,
                  ]
                : null}
            </Box>
          </li>
          <li data-testid="scheduledMoveInDate">
            {' '}
            <FormattedMessage {...messages.scheduledMoveInDate} />{' '}
            <FormattedDateOrDashes
              value={scheduledMoveInDate}
              format="MMM DD, YYYY"
            />
          </li>
          {isCommercial && (
            <li>
              {' '}
              <FormattedMessage {...messages.rentStartDate} />{' '}
              <FormattedDateOrDashes
                value={rentStartDate}
                format="MMM DD, YYYY"
              />
            </li>
          )}
          <li data-testid="desiredMoveInDate">
            {' '}
            <FormattedMessage {...messages.desiredMoveInDate} />{' '}
            <FormattedDateOrDashes
              value={
                assigningUnitsMoveInDatesFlag
                  ? moveInDateFrom
                  : prospectPreferences.moveInDateFrom
              }
              format="MMM DD, YYYY"
            />
          </li>
          <li>
            {' '}
            <FormattedMessage {...messages.lastActivity} />{' '}
            {lastActivityType
              ? ` ${lastActivityType.name}, ${lastActivityDateFormatted}`
              : ' ---'}
          </li>
          <li>
            {' '}
            <FormattedMessage {...messages.conversionDate} />{' '}
            <FormattedDateOrDashes
              value={currentRecord.createdAt}
              format="MMM DD, YYYY"
            />
          </li>
        </DetailList>
        {!isCommercial && (
          <ButtonsRow
            handleConvertToResident={handleConvertToResident}
            convertButtonDisabled={convertButtonDisabled}
          />
        )}
        {isCommercial && (
          <ButtonsRow
            handleConvertToCommercialTenant={handleConvertToCommercialTenant}
            convertButtonDisabled={convertButtonDisabled}
          />
        )}
      </Panel.Body>
      <ModalConfirm {...ConfirmModalProps} />
    </SnapshotPanel>
  );
};

export default reduxForm({
  form: 'snapshot',
  enableReinitialize: true,
})(Snapshot);
