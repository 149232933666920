import { CentralizedDocumentForm } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useContext, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';

import useUniqueTableName from '../../hooks/useUniqueTableName';
import UserService from '../../services/userService';
import { get, post, apiURL } from '../../utils/api';
import { AppContext } from '../App/context';
import { handlePreview } from './utils';

// bootstrap css is interfering with the ckeditor tables so this fixes it
export const CentralizedDocumentFormContainer = styled.span`
  .ck .table {
    width: initial;
  }
`;

function AddDocument({ intl, history }) {
  const { documentManagementMvp } = useFlags();
  const { userOrganizationId, isLdUserContextReady } = useContext(AppContext);
  const isPageEnabled = documentManagementMvp && isLdUserContextReady;
  const mergeFieldTableName = useUniqueTableName(
    'add-document-merge-field-inventory',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: properties, isLoading: propertiesLoading } = useQuery(
    ['properties', userOrganizationId],
    () => {
      const service = new UserService();
      return service.getAllActiveProperties(userOrganizationId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: documentCategories } = useQuery(
    ['documentCategories', userOrganizationId],
    () => {
      return get(`/${userOrganizationId}/document-categories`);
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: isPageEnabled,
    },
  );
  const { data: mergeFields, isLoading: mergeFieldsLoading } = useQuery(
    ['mergeFields'],
    () => {
      return get('/universal-merge-field-configurations?hideFromUsers=false');
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );

  if (!isLdUserContextReady) return null;
  if (isPageEnabled === false) return <Redirect to="/404" />;

  return (
    <DocumentTitle title="Add Document">
      <CentralizedDocumentFormContainer>
        <CentralizedDocumentForm
          intl={intl}
          properties={properties ?? []}
          propertiesLoading={propertiesLoading}
          documentCategories={documentCategories.map((c) => ({
            value: c.id,
            text: c.name,
          }))}
          onCancel={() => history.push('/manage-documents')}
          onSubmit={async (values) => {
            try {
              setIsSubmitting(true);

              await post(
                `/${userOrganizationId}/centralized-documents`,
                JSON.stringify({
                  name: values.documentName,
                  documentCategoryId: values.documentCategoryId,
                  documentContent: DOMPurify.sanitize(values.documentContent),
                  propertyIds: values.assignedProperties ?? [],
                  categoryDetails: {
                    isAddendumRequired: values.isAddendumRequired,
                    unitTypes: values.unitTypes ?? [],
                  },
                }),
              );
              toastr.success(
                'Success',
                'Document has been added and made available to all assigned properties.',
              );
              history.push('/manage-documents');
            } catch (error) {
              let message =
                typeof error === 'string' ? error : error.toString();

              if (message.includes('[object Object]')) {
                message = 'Failed to add document.';
                console.error(error); // eslint-disable-line
              }

              toastr.error('Error', message);

              setIsSubmitting(false);
            }
          }}
          isSubmitting={isSubmitting}
          MergeFieldInventoryProps={{
            intl,
            mergeFields,
            isLoading: mergeFieldsLoading,
            mergeFieldTableName,
          }}
          onPreview={(content) =>
            handlePreview({
              content,
              organizationId: userOrganizationId,
            })
          }
          ifwUrl={`${apiURL}/ckeditor/import-from-word`}
        />
      </CentralizedDocumentFormContainer>
    </DocumentTitle>
  );
}

export default injectIntl(AddDocument);
