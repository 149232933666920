import React from 'react';
// eslint-disable-next-line max-len
import { UnitSpecials as FortressUnitSpecials } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

const UnitSpecials = ({
  editMode,
  intl,
  special,
  handleSubmitSpecials,
  handleToggleEditMode,
  isLoading,
}) => {
  return (
    <FortressUnitSpecials
      editMode={editMode}
      intl={intl}
      special={special}
      handleSubmitSpecials={handleSubmitSpecials}
      handleToggleEditMode={handleToggleEditMode}
      isLoading={isLoading}
      sx={{ marginLeft: 2, marginRight: 2 }}
    />
  );
};

export default UnitSpecials;
