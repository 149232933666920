import { useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { CERTIFICATION_TYPE_DISPLAY_NAMES_LOWERCASE_KEY_MAP } from '../../constants/affordableCertificationTypes';
import { COMPLIANCE_DOCUMENT_TYPES_SHORT_TO_KEY_MAP } from '../../constants/complianceDocumentTypes';
import { PROGRAM_NAMES_KEY_MAP } from '../../constants/affordableProgramNames';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import useDownloadComplianceOverviewReportMutation from './hooks.useDownloadComplianceOverviewReportMutation';

const messages = { ...generalMessages, ...componentMessages };

const YES_NO = {
  yes: 'Yes',
  no: 'No',
};

const VOUCHER_EFFECTIVE_DATE_STATUS = {
  pastDue: 'pastDue',
  attentionRequired: 'attentionRequired',
};

const getItemKey = (item: string) => {
  return (
    CERTIFICATION_TYPE_DISPLAY_NAMES_LOWERCASE_KEY_MAP[item] ??
    COMPLIANCE_DOCUMENT_TYPES_SHORT_TO_KEY_MAP[item] ??
    PROGRAM_NAMES_KEY_MAP[item] ??
    YES_NO[item] ??
    VOUCHER_EFFECTIVE_DATE_STATUS[item] ??
    item
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};
const adaptFiltersForBackend = ({
  // dateState,
  filterState,
  order,
  orderBy,
  searchState,
}: {
  dateState: { [id: string]: string },
  filterState: { [id: string]: string[] | string },
  searchState: { [id: string]: string },
}) => {
  // Dynamically Map filterState
  const mappedFilterState = Object.entries(filterState).map(([key, value]) => {
    if (key === 'isPastDue') {
      key = 'status';
      value = 'pastDue';
    }

    let filter = null;

    if (Array.isArray(value)) {
      filter = value.map((item) => [getItemKey(item), true]);
    } else {
      filter = [[getItemKey(value), true]];
    }

    const filterObject = Object.fromEntries(filter);

    return [key, filterObject];
  });
  const transformedFilterState = Object.fromEntries(mappedFilterState);

  return {
    currentFilter: transformedFilterState,
    currentSorting: { fieldName: orderBy, order },
    // turn searchState into a string
    searchText: Object.keys(searchState)
      .map((key) => `${key}:${searchState[key]}`)
      .join(', '),
  };
};

const useDownloadCSVPDF = ({
  complianceType,
  dateState,
  filterState,
  intl,
  order,
  orderBy,
  organizationId,
  searchState,
}) => {
  const { currentFilter, currentSorting, searchText } = useMemo(
    () =>
      adaptFiltersForBackend({
        dateState,
        filterState,
        order: order.toUpperCase(),
        orderBy,
        searchState,
      }),
    [dateState, filterState, order, orderBy, searchState],
  );

  const { isLoading: isCSVPDFExportLoading, mutate: handleDownloadCSVPDF } =
    useDownloadComplianceOverviewReportMutation({
      organizationId,
      complianceType,
      fileType: 'csv',
      searchText,
      currentFilter,
      currentSorting,
      intl,
      options: {
        onError: () => {
          toastr.error(
            intl.formatMessage(messages.errorDownloading),
            intl.formatMessage(messages.failedDocumentDownload),
          );
        },
      },
    });

  return { handleDownloadCSVPDF, isCSVPDFExportLoading };
};

export default useDownloadCSVPDF;
