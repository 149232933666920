import { useQuery } from 'react-query';
import { injectIntl } from 'react-intl';
import { useContext, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../App/context';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link as ReactRouterLink } from 'react-router-dom';
import { CentralizedManageDocuments as CentralizedManageDocumentsPage } from '@fortress-technology-solutions/fortress-component-library/Pages_Fortress'; // eslint-disable-line
import { get } from '../../utils/api';
import UserService from '../../services/userService';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import ElementWithPermissions from '../../components/ElementWithPermissions';

function CentralizedManageDocuments({ intl, history }) {
  const { documentManagementMvp } = useFlags();
  const { userOrganizationId, isLdUserContextReady } = useContext(AppContext);
  const isPageEnabled = documentManagementMvp && isLdUserContextReady;
  const name = useUniqueTableName('centralized-manage-documents');
  const mergeFieldTableName = useUniqueTableName(
    'centralized-manage-documents-merge-field-inventory',
  );
  const { data: documents, isLoading: docsLoading } = useQuery(
    ['documents', userOrganizationId],
    () => {
      return get(`/${userOrganizationId}/centralized-documents`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: properties, isLoading: propertiesLoading } = useQuery(
    ['properties', userOrganizationId],
    () => {
      const service = new UserService();
      return service.getAllActiveProperties(userOrganizationId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: mergeFields, isLoading: mergeFieldsLoading } = useQuery(
    ['mergeFields'],
    () => {
      return get('/universal-merge-field-configurations?hideFromUsers=false');
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const mappedDocuments = useMemo(() => {
    return documents
      ? documents.map((document) => {
          const addendum = document.leaseAddendum;
          const format = document.lease?.format ?? addendum?.format;
          const isRequiredAddendumDetails =
            addendum?.isRequired === true ? 'Required' : 'Optional';
          const categoryDetails = addendum ? isRequiredAddendumDetails : '---';
          return {
            id: document.id,
            documentName: document.name,
            documentCategory: document.documentCategory.name,
            categoryDetails,
            numberOfAssignedProperties: document.numberOfAssignedProperties,
            format,
          };
        })
      : [];
  }, [documents]);

  if (!isLdUserContextReady) return null;
  if (isPageEnabled === false) return <Redirect to="/404" />;

  const isLoading = docsLoading || propertiesLoading;

  return (
    <CentralizedManageDocumentsPage
      ReactBeautifulDnD={{
        DragDropContext,
        Droppable,
        Draggable,
      }}
      ReactRouterLink={ReactRouterLink}
      intl={intl}
      name={name}
      totalNumberOfProperties={properties?.length ?? 0}
      documents={mappedDocuments}
      isLoading={isLoading}
      ElementWithPermissions={ElementWithPermissions}
      onAddNewDocumentClick={() => history?.push('/add-document')}
      MergeFieldInventoryProps={{
        intl,
        mergeFields,
        isLoading: mergeFieldsLoading,
        mergeFieldTableName,
      }}
    />
  );
}

export default injectIntl(CentralizedManageDocuments);
