import React, { useEffect } from 'react';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import type { BaseSetAsideSummaryProps } from './types';
import {
  useFetchSetAsideSummaryKPI,
  useFetchIncomeAveragingPercent,
  useUpdateIncomeAgeragingPercent,
} from './hooks';
import SetAsideSummaryTable from './SetAsideSummaryTable';
import SetAsideSummaryGraph from './SetAsideSummaryGraph';
import { useFlags } from 'launchdarkly-react-client-sdk';

const SetAsideSummary = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
  userPermissions,
  refreshHandler,
}: BaseSetAsideSummaryProps) => {
  const { shouldRefresh, setShouldRefresh } = refreshHandler;
  const hooksArgs = {
    intl,
    propertyId,
    organizationId,
    promptToaster,
  };
  const { setAsideKpiLeasedUnits } = useFlags();
  const [isLoadingKPI, kpi, refreshSetAsideSummaryKPI] =
    useFetchSetAsideSummaryKPI(hooksArgs);
  const [
    isLoadingIncomeAvgPercent,
    incomeAveragingPercent,
    refreshIncomeAveragingPercent,
  ] = useFetchIncomeAveragingPercent(hooksArgs);

  const updateIncomeAveragingPercent = useUpdateIncomeAgeragingPercent({
    ...hooksArgs,
    refresh: refreshIncomeAveragingPercent,
  });

  const isLoading = isLoadingKPI || isLoadingIncomeAvgPercent;

  const showSpinner = isLoading;

  useEffect(() => {
    if (shouldRefresh) {
      refreshSetAsideSummaryKPI();
      refreshIncomeAveragingPercent();
      setShouldRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  return (
    <AsyncBox loading={showSpinner}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <SetAsideSummaryTable
          intl={intl}
          kpi={kpi}
          setAsideKpiLeasedUnits={setAsideKpiLeasedUnits}
        />
        <SetAsideSummaryGraph
          intl={intl}
          kpi={kpi}
          incomeAveragingPercent={incomeAveragingPercent}
          updateIncomeAveragingPercent={updateIncomeAveragingPercent}
          userPermissions={userPermissions}
        />
      </Stack>
    </AsyncBox>
  );
};

export default SetAsideSummary;
