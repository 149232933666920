import {
  always,
  any,
  comparator,
  cond,
  descend,
  defaultTo,
  either,
  equals,
  find,
  isEmpty,
  isNil,
  gt,
  none,
  not,
  omit,
  path,
  pathOr,
  prop,
  propOr,
  propEq,
  range,
  reject,
  sort,
  uniq,
  all,
} from 'ramda';
import moment from 'moment';

import {
  getAffordableFloorPlanProgramTypes,
  getCurrentNonOptionalCharge,
} from './affordable';

export const ELECTRONIC_SIGNING_METHOD = 'ELECTRONIC';
export const MANUAL_SIGNING_METHOD = 'MANUAL';

export const PORTAL_ACTION_EXECUTED = 'EXECUTED';
export const PORTAL_ACTION_REMOVED = 'REMOVED';
export const PORTAL_ACTION_SENT = 'SENT';

export const PORTAL_SIGNATURE_STATUS_NOT_SIGNED = 'notSigned';
export const PORTAL_SIGNATURE_STATUS_SIGNED = 'signed';

export const PORTAL_INVITE_STATUS_NOT_SENT = 'notSent';
export const PORTAL_INVITE_STATUS_SENT = 'sent';

export const DEFAULT_EMPTY_LEASE = {
  id: null,
  unitId: null,
  lateMethodId: 'default',
  leaseTermId: 'default',
  leasedRent: '0.00',
  numberOfKeys: 'default',
  moveInDate: '',
  startDate: '',
  endDate: '',
  securityDeposit: '0.00',
  proratedRent: '0.00',
  proratedOther: '0.00',
  petDeposit: '0.00',
  monthlyPetRent: '0.00',
  securityDepositAlternativeId: 'default',
  satelliteDeposit: '0.00',
  petFee: '0.00',
  oneTimeRentConcession: '0.00',
  monthlyRentConcession: '0.00',
  shortTermFee: '0.00',
  nonRefundableAdminFee: 0,
  otherDiscountConcession: '0.00',
  oneTimeRentMonth: '',
  monthlyRentMonth: '',
  discountDescription: '',
  emergencyContactName: '',
  emergencyContactPhone: '',
  emergencyContactRelationship: '',
  isRenewal: false,
  desiredSignatureMethod: 'default',
  documentId: null,
  rentStartDate: '',
};

export const DEFAULT_APPLICANT_UNIT = {
  unit: {
    id: null,
    number: '',
    floorPlan: {
      marketingName: '',
    },
  },
  lease: DEFAULT_EMPTY_LEASE,
};

export const DEFAULT_RESIDENT_UNIT = {
  units: [
    {
      id: null,
      number: '',
      floorPlan: {
        marketingName: '',
      },
    },
  ],
  lease: {
    ...DEFAULT_EMPTY_LEASE,
    units: [
      {
        id: null,
        number: '',
        floorPlan: {
          marketingName: '',
        },
      },
    ],
  },
};

export function generateNumberOfKeys(amount: number) {
  const amountValues = range(1, amount).map((n) => ({
    value: n,
    text: n.toString(),
    disabled: false,
  }));

  amountValues.unshift({
    value: 'default',
    text: 'Choose',
    disabled: true,
  });
  return amountValues;
}

export function mapFormValuesLeaseData(values: Object) {
  const isDefault = (val) => val === 'default' || val === '';
  const isNilOrDefault = either(isNil, isDefault);
  const leaseDataValues = {
    comments: values.comments || null,
    commercialLeaseTypeId: values.commercialLeaseTypeId || null,
    commercialRetailTypeId: values.commercialRetailTypeId || null,
    desiredSignatureMethod: values.desiredSignatureMethod || null,
    discountDescription: values.discountDescription || null,
    effectiveDate: moment(values.effectiveDate) || null,
    emergencyContactName: values.emergencyContactName || null,
    emergencyContactPhone: values.emergencyContactPhone || null,
    emergencyContactRelationship: values.emergencyContactRelationship || null,
    endDate: moment(values.endDate).format('YYYY-MM-DD'),
    id: values.id,
    isRentDateEstimated: values.isRentDateEstimated || null,
    lateMethodId: values.lateMethodId || null,
    leasedRent: values.leasedRent || null,
    leaseTermId: values.leaseTermId || null,
    monthlyPetRent: values.monthlyPetRent || 0,
    monthlyRentConcession: values.monthlyRentConcession || 0,
    monthlyRentMonth: values.monthlyRentMonth || null,
    moveInDate: moment(values.moveInDate) || null,
    nonRefundableAdminFee: values.nonRefundableAdminFee || 0,
    numberOfKeys: values.numberOfKeys || null,
    numSatellites: values.numSatellites || 0,
    oneTimeRentConcession: values.oneTimeRentConcession || 0,
    oneTimeRentMonth: values.oneTimeRentMonth || null,
    otherDiscountConcession: values.otherDiscountConcession || 0,
    petDeposit: values.petDeposit || 0,
    petFee: values.petFee || 0,
    proratedOther: values.proratedOther || 0,
    proratedRent: values.proratedRent || 0,
    rentStartDate: moment(values.rentStartDate) || null,
    receivesHousingAssistance:
      typeof values.isReceivingAssistance === 'boolean'
        ? values.isReceivingAssistance
        : undefined,
    satelliteDeposit: values.satelliteDeposit || 0,
    securityDeposit: values.securityDeposit || 0,
    securityDepositAlternativeId: values.securityDepositAlternativeId || null,
    shortTermFee: values.shortTermFee || 0,
    startDate: moment(values.startDate).format('YYYY-MM-DD') || null,
    unitId: values.unitId || null,
    monthlyRecurringCharges: values.monthlyRecurringCharges || [],
    marketRentValue: values.marketRentValue || null,
    quotingRentValue: values.quotingRentValue || null,
    noteRentValue: values.noteRentValue || null,
    basicRentValue: values.basicRentValue || null,
    overrideLeaseExpirationLimit: values.overrideLeaseExpirationLimit ?? false,
  };

  return {
    ...reject(isNilOrDefault, leaseDataValues),
  };
}

export function mapCommercialLeaseBasicsToLeaseData(values: Object) {
  const valuesToSubmit = omit(
    [
      'leaseTypeId',
      'overallLeaseComments',
      'retailTypeId',
      'scheduledMoveInDate',
      'leaseEffectiveDate',
      'commencementDate',
      'leaseEndDate',
    ],
    values,
  );

  return {
    ...valuesToSubmit,
    commercialLeaseTypeId:
      !values.leaseTypeId || values.leaseTypeId === 'default'
        ? undefined
        : values.leaseTypeId,
    lateMethodId:
      !values.lateMethodId || values.lateMethodId === 'default'
        ? undefined
        : values.lateMethodId,
    comments: values.overallLeaseComments || undefined,
    commercialRetailTypeId:
      values.retailTypeId === 'default' ? null : values.retailTypeId,
    moveInDate: values.scheduledMoveInDate || undefined,
    effectiveDate: values.leaseEffectiveDate || null,
    startDate: values.commencementDate || undefined,
    endDate: values.leaseEndDate || undefined,
    rentStartDate: values.rentStartDate || undefined,
    isRentDateEstimated: values.isRentDateEstimated || undefined,
    oneTimeRentMonth: values.oneTimeRentMonth || undefined,
    monthlyRentMonth: values.monthlyRentMonth || undefined,
    discountDescription: values.discountDescription || undefined,
    desiredSignatureMethod: MANUAL_SIGNING_METHOD,
  };
}

type MonthlyTransaction = {
  startDate: string,
  endDate: string,
  amount: number,
  propertyTransactionCode: {
    transactionCode: {
      transactionType: {
        name: string,
      },
    },
    isOptional: boolean,
  },
};

export function calculateTotalMonthlyCharges(
  monthlyTransactions: MonthlyTransaction[],
  leaseStartDate: string,
  leaseEndDate: string,
): string | null {
  const newLeaseStartDate = moment.utc(leaseStartDate).format('YYYY-MM-DD');
  const newLeaseEndDate = moment(leaseEndDate).format('YYYY-MM-DD');
  let total = 0;
  monthlyTransactions.forEach((transaction) => {
    let invalidDate = false;
    const transactionStartDate = transaction.startDate
      ? moment.utc(transaction.startDate).format('YYYY-MM-DD')
      : null;
    const transactionEndDate = transaction.endDate
      ? moment.utc(transaction.endDate).format('YYYY-MM-DD')
      : null;
    const transactionType = pathOr(
      null,
      ['propertyTransactionCode', 'transactionCode', 'transactionType', 'name'],
      transaction,
    );
    const isOptional = pathOr(
      false,
      ['propertyTransactionCode', 'isOptional'],
      transaction,
    );
    if (newLeaseStartDate && transactionStartDate) {
      if (
        moment(transactionEndDate).isSameOrBefore(moment(), 'day') ||
        moment(transactionStartDate).isAfter(moment(newLeaseEndDate), 'day') ||
        (transactionEndDate &&
          moment(transactionEndDate).isSameOrBefore(
            moment(transactionStartDate),
            'day',
          )) ||
        (transactionEndDate &&
          moment(transactionEndDate).isSameOrBefore(
            moment(newLeaseStartDate),
            'day',
          ))
      ) {
        invalidDate = true;
      }
    }
    if (
      transactionType === 'Charge' &&
      isOptional === false &&
      invalidDate === false
    ) {
      total += parseFloat(transaction.amount);
    }
  });
  return total
    ? `$${Number(total).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`
    : null;
}

/**
 * Retrieves the fee amount from a standard Fee object.
 *
 * @param {Object} fee A PropertyFee, FloorplanFee, or UnitFee object
 * @returns {number} Fee amount
 */
export const getFeeAmount = (fee: Object): number => {
  const ownFee = pathOr(
    pathOr(null, ['feeAmount'], fee),
    ['dataValues', 'feeAmount'],
    fee,
  );
  if (ownFee !== null && ownFee !== 0) {
    return ownFee;
  }
  return pathOr(0, ['propertyFees', 'feeAmount'], fee);
};

/**
 * Retrieves the descriptivefee amount from a standard Fee object.
 *
 * @param {Object} fee A PropertyFee, FloorplanFee, or UnitFee object
 * @returns {number} Fee amount
 */
export const getFeeDescriptiveAmount = (fee: Object): string | null => {
  const ownFee = pathOr(
    pathOr(null, ['descriptiveFeeAmount'], fee),
    ['dataValues', 'descriptiveFeeAmount'],
    fee,
  );
  if (ownFee !== null && ownFee !== 0) {
    return ownFee;
  }
  return pathOr(null, ['propertyFees', 'descriptiveFeeAmount'], fee);
};

export const validField = (value: string) =>
  !!(not(isEmpty(value)) && not(isNil(value)) && !equals('default', value));

/**
 * Retrieves all fees (FloorPlan, Unit, and Property) for a Unit.
 *
 * @param {Object} unit A Unit object
 * @returns {Array} FloorPlanFees, UnitFees, and PropertyFees combined
 */
export function getAllUnitFees(unit: Object) {
  const fpFees = pathOr(
    pathOr([], ['floorPlanFees'], unit),
    ['floorPlan', 'floorPlanFees'],
    unit,
  );
  const unitFees = pathOr([], ['unitFees'], unit);
  const propertyFees = pathOr([], ['property', 'propertyFees'], unit)
    .filter(({ isForAllUnits }) => isForAllUnits)
    .map((prop) => ({ propertyFees: prop }));

  return [...fpFees, ...unitFees, ...propertyFees];
}

/**
 * Retrieves the Quoting Rent for a Unit.
 *
 * @param {Object} unit A Unit object
 * @returns {number} Quoting rent amount
 */
export function getQuotingRent(unit: Object) {
  return pathOr(0, ['floorPlan', 'quotingRentAmount'], unit);
}

/**
 * Calculates final rent with all amenities that are marked as `includeInMarketRentCalculation`
 * as `true` included.
 *
 * @param {Object} unit A Unit object
 * @param {number} startingRent (optional) Rent calculations will be based on
 * @returns {number} Final rent
 */
export const calculateRentWithAmenities = (
  unit: Object,
  startingRent: number = null,
) => {
  const allFees = getAllUnitFees(unit);

  let finalRent = startingRent;
  if (finalRent === null) {
    finalRent = getQuotingRent(unit);
  }

  if (finalRent) {
    allFees.reduce((rent: number, fee: Object) => {
      const isForAllUnits = pathOr(
        false,
        ['propertyFees', 'isForAllUnits'],
        fee,
      );

      /*
        Specifically filter out PropertyFee records that are _not_ for all units.
        Using `id` to determine if PropertyFee vs. UnitFee or FloorPlanFee.
        PropertyFees only have `propertyFees` as their only key.

        ex:
        PropertyFee = { propertyFees: { ... } };
        UnitFee = { id, propertyFeeId, propertyFees: { ... } };
      */
      if (!fee.id && isForAllUnits === false) {
        return rent;
      }

      const optional = pathOr(
        false,
        ['propertyFees', 'displayAsOptionalFeeOnQuote'],
        fee,
      );
      const value = getFeeAmount(fee);
      const feeType = pathOr(null, ['propertyFees', 'feeType'], fee);
      const includeInRent = pathOr(
        false,
        ['propertyFees', 'includeInMarketRentCalculation'],
        fee,
      );

      if (
        value !== 0 &&
        includeInRent === true &&
        feeType === 'Amenity' &&
        optional === false
      ) {
        finalRent += value;
      }
      return finalRent;
    }, finalRent);
  }

  return finalRent;
};

/**
 * Calculates monthly total charges including all monthly fees.
 *
 * @param {Object} unit A Unit object
 * @returns {number} Total monthly charge
 */
export function calculateMonthlyRecurringChargesTotal(unit: Object) {
  const quotingRent = calculateRentWithAmenities(unit);
  const monthlyFees = getMonthlyRecurringCharges(unit);

  return monthlyFees.reduce(
    (total, fee) => (total = total + fee.value),
    quotingRent,
  );
}

/**
 * Retrieves all Monthly Recurring Charges for a Unit.
 *
 * @param {Object} unit
 * @returns {Array} All non-optional fees
 */
export function getMonthlyRecurringCharges(unit: Object) {
  const allFees = getAllUnitFees(unit);

  const recurringFees = [];
  allFees.reduce((prev, fee) => {
    const label = pathOr('', ['propertyFees', 'displayNameOnQuote'], fee);
    const value = getFeeAmount(fee);
    const descriptiveValue = getFeeDescriptiveAmount(fee);
    const ptc = pathOr(
      propOr(null, 'propertyTransactionCodeId', fee),
      ['propertyFees', 'propertyTransactionCodeId'],
      fee,
    );
    const optional = pathOr(
      false,
      ['propertyFees', 'displayAsOptionalFeeOnQuote'],
      fee,
    );
    const autoGen = pathOr('', ['propertyFees', 'whenAutoGenerated'], fee);

    const isAssetProtectFee = label === 'Building Protection Fee'; // Asset Protect
    const isCommercialUnit = unit.floorPlan.isCommercial;

    if (
      (value !== 0 || !isNil(descriptiveValue)) &&
      autoGen === 'Monthly Recurring Lease Charges' &&
      optional === false &&
      !(isCommercialUnit && isAssetProtectFee)
    ) {
      prev.push({ label, value, descriptiveValue, ptc });
    }

    return prev;
  }, recurringFees);
  return recurringFees;
}

export const getNonRentRecurringCharges = (
  monthlyRecurringCharges: Array<Object>,
) =>
  monthlyRecurringCharges.reduce((prev, fee) => {
    return prev + fee.value * 100;
  }, 0) / 100;

export const getLeaseDifference = (
  leasedRent: number | string,
  nonRentCharges: number | string,
  totalMonthlyCharges: ?string,
) => {
  const parsedNumberTotalMonthlyCharges = totalMonthlyCharges
    ? parseFloat(totalMonthlyCharges.replace(/[$,]/g, ''))
    : 0;

  return +leasedRent + +nonRentCharges - parsedNumberTotalMonthlyCharges;
};

export const calculateLeaseEndDate = (
  startDate: any,
  leaseTermId: string,
  leaseTerms: any[] = [],
  endOfMonth?: boolean = false,
): Moment => {
  const startDateMoment = moment(startDate);
  const calculatedEndDate = startDateMoment.isValid()
    ? moment(startDate)
    : moment();
  const defaultNumberOfMonths = 12;

  const leaseTerm = leaseTerms.find(propEq('id', leaseTermId)) ?? {};

  const months =
    path(['masterLeaseTerm', 'nMonths'], leaseTerm) ?? defaultNumberOfMonths;

  calculatedEndDate.add(months, 'months');

  if (endOfMonth) {
    calculatedEndDate.subtract(1, 'month').endOf('month');
  }

  return calculatedEndDate;
};

export const calculateLeaseEndDateV2 = (
  startDate: any,
  leaseTermId: string,
  leaseTerms: any[] = [],
  property?: Object,
): Moment => {
  const startDateMoment = moment(startDate);
  const endDateSettingMoreThan12months =
    property?.leaseEndDateGreaterThan12m || 'CALCULATED';
  const endDateSettingLessThan12months =
    property?.leaseEndDateLesserThan12m || 'CALCULATED';

  const newEndDateCalculator = {
    CALCULATED: (endDate) => endDate,
    LAST_OF_MONTH: (endDate) => endDate.endOf('month'),
    LAST_OF_PRIOR_MONTH: (endDate) =>
      endDate.subtract(1, 'month').endOf('month'),
  };
  const calculatedEndDate = startDateMoment.isValid()
    ? moment(startDate)
    : moment();
  const defaultNumberOfMonths = 12;

  const leaseTerm = leaseTerms.find(propEq('id', leaseTermId)) ?? {};

  const months =
    path(['masterLeaseTerm', 'nMonths'], leaseTerm) ?? defaultNumberOfMonths;

  calculatedEndDate.add(months, 'months');

  if (months >= defaultNumberOfMonths) {
    newEndDateCalculator[endDateSettingMoreThan12months](calculatedEndDate);
  }
  if (months < defaultNumberOfMonths) {
    newEndDateCalculator[endDateSettingLessThan12months](calculatedEndDate);
  }
  return calculatedEndDate;
};

export const getLeaseTermOptions = (leaseTerms: Array<Object>) => {
  if (!leaseTerms) {
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
    ];
  }
  leaseTerms.sort((a, b) =>
    a.masterLeaseTerm.nMonths > b.masterLeaseTerm.nMonths
      ? 1
      : b.masterLeaseTerm.nMonths > a.masterLeaseTerm.nMonths
      ? -1
      : 0,
  );
  return [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...leaseTerms.map((lt) => ({
      value: lt.id,
      text:
        lt.masterLeaseTerm.nMonths === 1
          ? `${lt.masterLeaseTerm.nMonths} month`
          : `${lt.masterLeaseTerm.nMonths} months`,
      disabled: lt.disabled || false,
    })),
  ];
};

export const getCurrentGrossRentLimit = (
  currentMarketRent: number,
  marketRents: Array<any>,
  floorPlanId: string,
): number => {
  return marketRents
    .filter((rent) => floorPlanId === rent.floorPlanId)
    .reduce((prev, rent) => {
      const today = moment();
      const startDate = moment(rent.startDate);
      const endDate = !rent.endDate ? null : moment(rent.endDate);
      if (
        startDate.isBefore(today) &&
        (endDate === null || endDate.isAfter(today))
      ) {
        return +rent.feeAmount;
      }
      return prev;
    }, +currentMarketRent);
};

export const getCurrentTDHA = (allowances: Array<any>): any => {
  return allowances.filter((allowance) => {
    const today = moment();
    const startDate = moment(allowance.startDate);
    const endDate = !allowance.endDate ? null : moment(allowance.endDate);
    return (
      today.isSameOrAfter(startDate) &&
      (endDate === null || today.isSameOrBefore(endDate))
    );
  });
};

export const getSelectedLease = (
  leases: Array<Object>,
  selectedLeaseId: string,
): Object => {
  if (leases && !selectedLeaseId) {
    const emptyApplicantLeaseWithAssignedUnitValues =
      leases.length > 0 ? leases[0] : {};
    return {
      ...DEFAULT_EMPTY_LEASE,
      ...emptyApplicantLeaseWithAssignedUnitValues,
    };
  }
  if (!leases && !selectedLeaseId) {
    return {};
  }
  const defaultToDefault = defaultTo('default');

  /* Parser for Renewal values */
  const parseRenewalValues = (leasesArray: Array<Object>) => {
    const renewalLeaseInCaseOfEditingCurrentLeaseEndDate = find(
      propEq('isRenewal', true),
    )(leasesArray);
    const renewalLeaseStartDate = pathOr(
      null,
      ['startDate'],
      renewalLeaseInCaseOfEditingCurrentLeaseEndDate,
    );
    const renewalIsRenewalComplete = pathOr(
      null,
      ['isRenewalComplete'],
      renewalLeaseInCaseOfEditingCurrentLeaseEndDate,
    );
    return { renewalLeaseStartDate, renewalIsRenewalComplete };
  };

  /* Getter to find the actual lease in question */
  const findSelectedLease = (leasesArray: Array<Object>, leaseId: string) => {
    if (!isNil(leaseId)) {
      return (
        leasesArray.find((lease) => lease.id === leaseId) ||
        DEFAULT_RESIDENT_UNIT.lease
      );
    }
    if (leasesArray.length > 0) {
      return leasesArray[0];
    }
    if (leasesArray.length < 1) {
      return DEFAULT_RESIDENT_UNIT.lease;
    }
    return {};
  };

  /* Start of actually parsing out the values for the return object */
  const { renewalLeaseStartDate, renewalIsRenewalComplete } =
    parseRenewalValues(leases);
  const selectedLease = findSelectedLease(leases, selectedLeaseId);
  const units: Array<Object> =
    selectedLease.units || DEFAULT_RESIDENT_UNIT.units;
  let leasedRent = pathOr(null, ['leasedRent'], selectedLease);
  if (!leasedRent) {
    leasedRent = pathOr(null, ['floorPlan', 'quotingRentAmount'], units[0]);
    leasedRent = calculateRentWithAmenities(units[0], +leasedRent);
  }
  const startDate = selectedLease.startDate
    ? moment(selectedLease.startDate)
    : '';
  const moveInDate = selectedLease.moveInDate
    ? moment(selectedLease.moveInDate)
    : '';
  const endDate = selectedLease.endDate
    ? moment(selectedLease.endDate).format('MM/DD/YYYY')
    : '';
  const currentLeaseId = selectedLease.id;
  const lateMethodId = defaultToDefault(selectedLease.lateMethodId);
  const leaseTermId = defaultToDefault(selectedLease.leaseTermId);
  const desiredSignatureMethod = defaultToDefault(
    selectedLease.desiredSignatureMethod,
  );
  const securityDepositAlternativeId = defaultToDefault(
    selectedLease.securityDepositAlternativeId,
  );
  const numberOfKeys = defaultToDefault(selectedLease.numberOfKeys);
  const unitId = units[0].id;
  const unitNumber = units ? units[0].number : '';
  const floorPlanId = units ? units[0].floorPlan.id : '';
  const floorPlan = units[0].floorPlan ? units[0].floorPlan.marketingName : '';
  const documentId = pathOr(null, ['documentId'], selectedLease);

  return {
    ...selectedLease,
    leasedRent,
    renewalLeaseStartDate,
    renewalIsRenewalComplete,
    startDate,
    moveInDate,
    endDate,
    currentLeaseId,
    lateMethodId,
    leaseTermId,
    securityDepositAlternativeId,
    numberOfKeys,
    unitId,
    unitNumber,
    floorPlanId,
    floorPlan,
    desiredSignatureMethod,
    documentId,
  };
};

/**
 * TODO: Deprecate this when we move the `Complete Renewal` button to the
 * LeaseDataTab
 */
export const checkLeaseValidityForSubmittal = (lease: Object) => {
  const checkExistence = (value: any) =>
    value !== null && value !== '' && value !== 'default';

  const hasLateMethodId = pathOr(null, ['lateMethodId'], lease);
  const hasLeaseTermId = pathOr(null, ['leaseTermId'], lease);
  const hasMoveInDate = pathOr(null, ['moveInDate'], lease);
  const hasNumberOfKeys = pathOr(null, ['numberOfKeys'], lease);
  const hasStartDate = pathOr(null, ['startDate'], lease);

  return (
    checkExistence(hasLateMethodId) &&
    checkExistence(hasNumberOfKeys) &&
    checkExistence(hasMoveInDate) &&
    checkExistence(hasLeaseTermId) &&
    checkExistence(hasStartDate)
  );
};
export const checkLeaseValidityCommercial = (lease: Object) => {
  const checkExistence = (value: any) =>
    value !== null && value !== '' && value !== 'default';

  const hasLateMethodId = pathOr(null, ['lateMethodId'], lease);
  const hasRentStartDate = pathOr(null, ['rentStartDate'], lease);
  const hasMoveInDate = pathOr(null, ['moveInDate'], lease);
  const hasStartDate = pathOr(null, ['startDate'], lease);

  return (
    checkExistence(hasLateMethodId) &&
    checkExistence(hasMoveInDate) &&
    checkExistence(hasRentStartDate) &&
    checkExistence(hasStartDate)
  );
};

export const canGenerateLease = (formSyncWarnings: any, valid: boolean) => {
  const lateMethodId = pathOr(undefined, ['lateMethodId'], formSyncWarnings);
  const numberOfKeys = pathOr(undefined, ['numberOfKeys'], formSyncWarnings);
  const moveInDate = pathOr(undefined, ['moveInDate'], formSyncWarnings);
  const leaseTermId = pathOr(undefined, ['leaseTermId'], formSyncWarnings);
  const startDate = pathOr(undefined, ['startDate'], formSyncWarnings);

  const notMissingInfo = all(isNil)([
    lateMethodId,
    numberOfKeys,
    moveInDate,
    leaseTermId,
    startDate,
  ])
    ? true
    : false;

  return notMissingInfo && valid;
};

const fillInEndDates = (priceArray) => {
  return priceArray.map((price) => {
    if (price.endDate) return price;
    return { ...price, endDate: moment().add(2, 'year') };
  });
};

const getAffordableFeeAmount = (priceObj) =>
  pathOr(null, ['feeAmount'], priceObj);

const findPriceByDate = (priceArray, date) => {
  if (!date || !moment(date).isValid()) {
    return null;
  }
  const getPriceByDate = (obj) =>
    moment(date).isBetween(
      prop('startDate')(obj),
      prop('endDate')(obj),
      null,
      // $FlowFixMe
      [],
    );
  return find(getPriceByDate)(priceArray);
};

const getDateToUse = (isRenewal: boolean, leaseDates: Object) => {
  const { moveInDate, startDate } = leaseDates;
  const affordableMoveInDate = moveInDate ? moment(moveInDate) : null;
  const renewalStartDate = moveInDate ? moment(startDate) : null;
  return isRenewal ? renewalStartDate : affordableMoveInDate;
};
const parseAffordableMarketRent = (
  affordableFloorplanPricing: Object,
  date: any,
  unit: Object,
) => {
  const marketRents = pathOr([], ['marketRents'], affordableFloorplanPricing);
  const initialMarketRent = pathOr(
    0,
    ['floorPlan', 'baseMarketRentAmount'],
    unit,
  );
  const affMarketRents = marketRents ? fillInEndDates(marketRents) : [];
  const potentialMarketRent = getAffordableFeeAmount(
    findPriceByDate(affMarketRents, date),
  );
  const fullPriceAffordableMarketRent = potentialMarketRent
    ? potentialMarketRent
    : initialMarketRent;

  return (
    (fullPriceAffordableMarketRent *
      pathOr(100, ['floorPlan', 'maxRentPercent'], unit)) /
    100
  );
};

const parseAffordableQuotingRent = (
  affordableFloorplanPricing: Object,
  date: any,
  unit: Object,
) => {
  const quotingRents = pathOr([], ['quotingRents'], affordableFloorplanPricing);
  const affQuotingRents = quotingRents ? fillInEndDates(quotingRents) : [];
  const potentialQuotingRent = getAffordableFeeAmount(
    findPriceByDate(affQuotingRents, date),
  );
  const initialQuotingRent = pathOr(
    null,
    ['floorPlan', 'quotingRentAmount'],
    unit,
  );

  const affordableQuotingRent = potentialQuotingRent
    ? potentialQuotingRent
    : initialQuotingRent;

  return affordableQuotingRent;
};

export const parseAffordableRent = ({
  affordableFloorplanPricing,
  date,
  unit,
  rentListName,
  floorPlanRentName,
}) => {
  const rents = affordableFloorplanPricing[rentListName] || [];
  const affRents = rents ? fillInEndDates(rents) : [];
  const potentialRent = getAffordableFeeAmount(findPriceByDate(affRents, date));

  const initialRent = pathOr(
    null,
    [floorPlanRentName],
    affordableFloorplanPricing,
  );
  const affordableRent = potentialRent ? potentialRent : initialRent;
  return affordableRent;
};

const parseUtilityAllowance = (
  affordableFloorplanPricing: Object,
  utilityAllowanceId: ?string,
  date: any,
  unit: Object,
) => {
  const allowances = pathOr([], ['allowances'], affordableFloorplanPricing);

  const initialAllowances = pathOr([], ['floorPlan', 'allowances'], unit);

  const affAllowancesById = allowances
    ? allowances.filter((allowance) => {
        return (
          pathOr('', ['allowances', 'publicHousingAuthorityId'], allowance) ===
          utilityAllowanceId
        );
      })
    : [];

  const initialAllowanceById = find(
    propEq('publicHousingAuthorityId', utilityAllowanceId),
  )(initialAllowances);

  const affAllowancesWithFinalDates = affAllowancesById
    ? fillInEndDates(affAllowancesById)
    : [];

  const utilityAllowanceByIdAndDate = findPriceByDate(
    affAllowancesWithFinalDates,
    date,
  );

  const useInitialAllowances =
    !utilityAllowanceByIdAndDate || utilityAllowanceByIdAndDate.length === 0;
  const allowanceToUse = useInitialAllowances
    ? initialAllowanceById
    : utilityAllowanceByIdAndDate;

  if (useInitialAllowances) {
    const utilityAllowanceName = pathOr(null, ['pha', 'name'], allowanceToUse);
    return { utilityAllowanceName };
  } else {
    const utilityAllowanceName = pathOr(
      null,
      ['allowances', 'pha', 'name'],
      allowanceToUse,
    );
    const utilityAllowanceAmount = getAffordableFeeAmount(allowanceToUse);
    return {
      utilityAllowanceAmount,
      utilityAllowanceName,
    };
  }
};

export const parseAffordableValues = (
  affordableFloorplanPricing: Object,
  leaseDates: Object,
  isRenewal: boolean,
  unit: Object,
  utilityAllowanceId: ?string,
) => {
  const dateToUse = getDateToUse(isRenewal, leaseDates);
  const affordableMarketRent = parseAffordableMarketRent(
    affordableFloorplanPricing,
    dateToUse,
    unit,
  );
  const affordableQuotingRent = parseAffordableQuotingRent(
    affordableFloorplanPricing,
    dateToUse,
    unit,
  );

  const noteRent = parseAffordableRent({
    affordableFloorplanPricing,
    date: dateToUse,
    unit,
    rentListName: 'noteRents',
    floorPlanRentName: 'noteRent',
  });

  const basicRent = parseAffordableRent({
    affordableFloorplanPricing,
    date: dateToUse,
    unit,
    rentListName: 'basicRents',
    floorPlanRentName: 'basicRent',
  });

  const hudGrossRent = parseAffordableRent({
    affordableFloorplanPricing,
    date: dateToUse,
    unit,
    rentListName: 'HUDGrossRents',
    floorPlanRentName: 'HUDGrossRent',
  });

  const hud236BasicRent = parseAffordableRent({
    affordableFloorplanPricing,
    date: dateToUse,
    unit,
    rentListName: 'hud236BasicRents',
    floorPlanRentName: 'hud236BasicRent',
  });

  const hud236MarketRent = parseAffordableRent({
    affordableFloorplanPricing,
    date: dateToUse,
    unit,
    rentListName: 'hud236MarketRents',
    floorPlanRentName: 'hud236MarketRent',
  });

  const utilityAllowance = parseUtilityAllowance(
    affordableFloorplanPricing,
    utilityAllowanceId,
    dateToUse,
    unit,
  );

  const applicableMarketRent = overrideIfRDOnlyFloorplanMktRent(
    unit,
    noteRent,
    utilityAllowance,
    affordableMarketRent,
  );

  return {
    affordableMarketRent: applicableMarketRent,
    affordableQuotingRent,
    noteRent,
    basicRent,
    hudGrossRent,
    hud236BasicRent,
    hud236MarketRent,
    ...utilityAllowance,
  };
};

export const parseRentValues = (
  affordablePricing: Object,
  unit: Object,
  lease?: ?Object,
  isSection236: boolean,
): Object => {
  const {
    isAffordable,
    affordableMarketRent,
    affordableQuotingRent,
    basicRent,
    noteRent,
    hudGrossRent,
    hud236BasicRent,
    hud236MarketRent,
  } = affordablePricing;

  const section236Rents = isSection236
    ? {
        hud236BasicRent,
        hud236MarketRent,
      }
    : {};

  if (lease && lease.marketRentValue && lease.quotingRentValue) {
    // If the values are already saved for the lease, return those
    const { marketRentValue, quotingRentValue } = lease;
    return {
      marketRent: marketRentValue,
      quotingRent: quotingRentValue,
      basicRent,
      noteRent,
      ...section236Rents,
    };
  }

  const conventionalMarketRent = pathOr(
    null,
    ['floorPlan', 'baseMarketRentAmount'],
    unit,
  );

  const conventionalQuotingRent = pathOr(
    null,
    ['floorPlan', 'quotingRentAmount'],
    unit,
  );

  let preCalcMarketRent = conventionalMarketRent;
  let preCalcQuotingRent = conventionalQuotingRent;

  if (isAffordable) {
    const { isHUDFloorPlan, isLIHTCFloorPlan, isRDFloorPlan } =
      getAffordableFloorPlanProgramTypes(unit.floorPlan);
    preCalcMarketRent = affordableMarketRent;

    if (
      (isHUDFloorPlan && isLIHTCFloorPlan) ||
      (isRDFloorPlan && isLIHTCFloorPlan)
    ) {
      preCalcQuotingRent = Math.max(+hudGrossRent, preCalcMarketRent);
      preCalcMarketRent = Math.max(+hudGrossRent, preCalcMarketRent);
    } else {
      preCalcQuotingRent = Math.max(+hudGrossRent, affordableQuotingRent);
      preCalcMarketRent = Math.max(+hudGrossRent, preCalcMarketRent);
    }
  }

  const marketRent = calculateRentWithAmenities(unit, +preCalcMarketRent);
  const quotingRent = calculateRentWithAmenities(unit, +preCalcQuotingRent);

  return {
    marketRent,
    quotingRent,
    noteRent,
    basicRent,
    ...section236Rents,
  };
};

export const leaseText = (
  leases: Array<Object>,
  index: any,
  isResident: boolean,
) => {
  const startDate = moment(leases[index].startDate).format('YYYY.MM');
  const endDate = moment(leases[index].endDate).format('YYYY.MM');
  const unitNumber = pathOr('', ['units', '0', 'number'], leases[index]);
  const isRenewal = leases[index].isRenewal;
  const isTransfer = leases[index].isTransfer;
  const isCurrent = leases[index].isMovedIn;
  const allDifferentUnitNumbers = uniq(
    leases
      .map((l) => pathOr(null, ['units', '0', 'number'], l))
      .filter((num) => !isNil(num)),
  );

  if (isResident) {
    if (isCurrent) {
      return 'Current';
    }
    if (isTransfer) {
      return `Transfer - Unit ${unitNumber}`;
    }
    if (isRenewal) {
      return 'Renewal';
    }
  }
  if (allDifferentUnitNumbers.length > 1) {
    // Only show unit numbers if the resident has transferred units
    return `${startDate}-${endDate} - Unit ${unitNumber}`;
  }
  return `${startDate}-${endDate}`;
};

export const getLeasesOptions = (
  leases: Array<Object>,
  isResident: boolean,
) => {
  if (!leases) {
    return [];
  }

  return [
    ...leases.map((lease, index, leases) => {
      return {
        value: lease.id,
        text: leaseText(leases, index, isResident),
        disabled: false,
      };
    }),
  ];
};

export const getIsDisabledMonthly = (
  selectedLease: Object,
  options: Array<Object>,
  residentId: ?string,
) => {
  if (!selectedLease || !options || !residentId) {
    return false;
  }
  const currentOption = options.find(
    (option) => option.value === selectedLease.id,
  );
  if (currentOption) {
    if (
      currentOption.text !== 'Current' &&
      currentOption.text !== 'Renewal' &&
      !currentOption.text.includes('Transfer')
    ) {
      return true;
    }
  }
  return false;
};

export const getMonthlyTransactions = (
  monthlyTransactions: Array<Object>,
  selectedMonthlyOption: string,
) => {
  // $FlowFixMe
  if (monthlyTransactions.length < 1) {
    return [];
  }
  const today = moment();
  // $FlowFixMe
  const filteredMonthlyTransactions = monthlyTransactions.filter(
    (transaction) => {
      const { startDate, endDate } = transaction;
      const transactionStartDate = startDate ? moment(startDate) : null;
      const transactionEndDate = endDate ? moment(endDate) : null;
      if (selectedMonthlyOption === 'past') {
        return transactionEndDate
          ? transactionEndDate.isBefore(today, 'day')
          : false;
      }
      if (selectedMonthlyOption === 'future') {
        return transactionStartDate
          ? transactionStartDate.isAfter(today, 'day')
          : false;
      }

      /* TODO: rip out the ramda and make this more readable */
      const filter = cond([
        [
          (startDate, endDate) => always(none(isNil, [startDate, endDate]))(),
          (startDate, endDate) =>
            any(equals(true))([
              // $FlowFixMe
              startDate.isSame(today, 'day'),
              // $FlowFixMe
              startDate.isBefore(today, 'day') &&
                // $FlowFixMe
                endDate.isAfter(today, 'day'),
              // $FlowFixMe
              endDate.isSame(today, 'day'),
            ]),
        ],
        [
          (startDate) => always(!isNil(startDate))(),
          (startDate) =>
            any(equals(true), [
              // $FlowFixMe
              startDate.isSame(today, 'day'),
              // $FlowFixMe
              startDate.isBefore(today, 'day'),
            ]),
        ],
        [(startDate) => always(isNil(startDate))(), always(false)],
      ])(transactionStartDate, transactionEndDate);

      return filter;
    },
  );

  return filteredMonthlyTransactions;
};

export const getTotalMonthlyCharges = (
  monthlyTransactions: Array<Object>,
  currentLease: Object,
) => {
  if (monthlyTransactions.length < 1) {
    return null;
  }
  return calculateTotalMonthlyCharges(
    monthlyTransactions,
    currentLease.startDate,
    currentLease.endDate,
  );
};

export const getValidLeaseStartDate = (leases: Array<Object>) => {
  const [newestLease, previousLease] = leases;
  if (!newestLease || !previousLease) {
    return { valid: false, statusText: 'Not Completed' };
  }
  const currentStartDate = moment(newestLease.startDate);
  const beforeStartDate = moment(previousLease.startDate);

  return currentStartDate && currentStartDate.isAfter(beforeStartDate, 'day')
    ? { valid: true, statusText: 'Completed' }
    : { valid: false, statusText: 'Not Completed' };
};

export const getRenewalAndTransferInfo = (lease: Object) => {
  if (!lease) {
    return {};
  }
  const isOngoingTransfer = lease.isTransfer && !lease.isTransferDone;
  const isOngoingRenewal = lease.isRenewal && !lease.isRenewalComplete;
  return {
    isOngoingTransfer,
    isOngoingRenewal,
  };
};

export const checkLeaseEditablility = (lease: Object, residentId: ?string) => {
  const { isOngoingTransfer, isOngoingRenewal } =
    getRenewalAndTransferInfo(lease);
  return (
    !lease.isLocked && (!residentId || isOngoingTransfer || isOngoingRenewal)
  );
};

export const getSentAndExecutedStatus = (lease: Object) => {
  const isMovedIn = pathOr(null, ['isMovedIn'], lease);
  if (isMovedIn) {
    return { leaseExecuted: false, leaseSentToPortal: false };
  }
  const documentId = pathOr(null, ['documentId'], lease);
  const isLocked = pathOr(false, ['isLocked'], lease);
  const isElectronic =
    pathOr(false, ['desiredSignatureMethod'], lease) ===
    ELECTRONIC_SIGNING_METHOD;

  const leasePortalLogs = pathOr([{}], ['leasePortalLogs'], lease);
  const byCreationDate = comparator((a, b) => gt(a.createdAt, b.createdAt));
  const mostRecentLog = sort(byCreationDate, leasePortalLogs)[0];
  const leasePortalLogAction = pathOr(null, ['action'], mostRecentLog);

  const leaseExecuted =
    !!documentId && leasePortalLogAction === PORTAL_ACTION_EXECUTED;
  const leaseSentToPortal = !!(
    leasePortalLogAction === PORTAL_ACTION_SENT &&
    isLocked &&
    isElectronic
  );
  return { leaseExecuted, leaseSentToPortal };
};

type GenerateLeaseSigningOptionsPayload = {
  isResidentPortalActive: boolean,
  isElectronicLeaseSigningActive: boolean,
  hasNonFR: boolean,
  isEditable: boolean,
  sigMethod: string,
  isIli: boolean,
  isIliElectronicSigningFlagOn: boolean,
};

export const generateLeaseSigningOptions = ({
  isResidentPortalActive,
  isElectronicLeaseSigningActive,
  hasNonFR,
  isEditable,
  sigMethod,
  isIli,
  isIliElectronicSigningFlagOn,
}: GenerateLeaseSigningOptionsPayload) => {
  const defaultOption = {
    value: 'default',
    text: 'Choose',
    disabled: true,
  };
  const electronicOption = {
    value: ELECTRONIC_SIGNING_METHOD,
    text: 'Electronic (Portal)',
    disabled: hasNonFR,
  };
  const manualOption = {
    value: MANUAL_SIGNING_METHOD,
    text: 'Manual Signing',
    disabled: false,
  };

  if (isIli)
    return isIliElectronicSigningFlagOn &&
      ((isResidentPortalActive && isElectronicLeaseSigningActive) ||
        (!isEditable && sigMethod === 'ELECTRONIC'))
      ? [defaultOption, electronicOption, manualOption]
      : [defaultOption, manualOption];

  return (isResidentPortalActive && isElectronicLeaseSigningActive) ||
    (!isEditable && sigMethod === 'ELECTRONIC')
    ? [defaultOption, electronicOption, manualOption]
    : [defaultOption, manualOption];
};

/**
 * Calculates Base Market Rent based on the next contrainsts:
 * 1. (property.propertyClass.name === 'Affordable' || property.propertyClass.name === 'Mixed')
 *      && floorPlan not HUD ==> grossRent = lowest fpua that is not HUD
 * 2. floorPlan is HUD ==> HUD gross limit - lowest fpua that is HUD
 * @param {object} unit
 * @param {number} startingRent Rent calculations will be based on
 * @returns {number} Final rent
 */
export const calculateBaseMarketRentForAfforbableMixed = (
  unit: Object,
  startingRent: number = 0,
) => {
  let finalRent = startingRent;

  if (unit.hudFloorplan) {
    finalRent = pathOr(0, ['HUDGrossRent'], unit);
    const allowances = pathOr([], ['allowances'], unit);
    const allowanceId = pathOr(
      '',
      ['id'],
      allowances.find((allowance) => {
        return pathOr(false, ['pha', 'isHUD'], allowance);
      }),
    );
    const fpua = pathOr([], ['fpua'], unit).find((ua) => {
      const today = moment().unix();
      const start = moment(ua.startDate).startOf('day').unix();
      const end =
        ua.endDate !== null ? moment(ua.endDate).endOf('day').unix() : null;
      return (
        today > start &&
        (end === null || today < end) &&
        ua.utilityAlowancesId === allowanceId
      );
    });
    const hudUaAmount = pathOr(0, ['feeAmount'], fpua);
    finalRent -= hudUaAmount;
  } else {
    const fpua = pathOr([], ['fpua'], unit).filter((ua) => {
      const today = moment().unix();
      const start = moment(ua.startDate).startOf('day').unix();
      const end =
        ua.endDate !== null ? moment(ua.endDate).endOf('day').unix() : null;
      return today > start && (end === null || today < end);
    });
    const uaAmount = pathOr(
      0,
      ['feeAmount'],
      sort(descend(prop('feeAmount')), fpua)[0],
    );
    finalRent -= uaAmount;
  }
  const nonOptionalCharge = getCurrentNonOptionalCharge(unit?.fpnoc ?? []);
  finalRent -= nonOptionalCharge;
  return finalRent;
};

/**
 * Helper function to parse LeaseMonthlyRequiredFees to match
 * the legacy structure used before (property+unit+fp fees)
 * @param {[]} fees
 */
export const parseLeaseMonthlyReqFees = (fees: Array<Object>) => {
  return fees.map((f) => ({
    id: f.id,
    label: f.feeNameDisplayed,
    value: f.leaseFeeAmount,
    ptc: f.propertyTransactionCodeId,
    descriptiveValue: f.descriptiveFeeAmount,
    isRemoved: f.isRemoved,
  }));
};

// For RD only floorplans, the "gross rent limit" is the note rent - UA
function overrideIfRDOnlyFloorplanMktRent(
  unit,
  noteRent,
  utilityAllowance,
  affordableMarketRent,
) {
  const floorPlanAffordablePrograms =
    unit?.floorPlan?.floorPlanAffordablePrograms ?? [];
  const isRD = floorPlanAffordablePrograms.some(
    (program) =>
      program.propertyAffordableProgram?.masterAffordableProgram?.name === 'RD',
  );
  const isRDOnly = isRD && floorPlanAffordablePrograms.length === 1;
  const utilityAllowanceAmount = utilityAllowance?.utilityAllowanceAmount ?? 0;
  const RDOnlyMarketRent = noteRent - utilityAllowanceAmount;
  const applicableMarketRent = isRDOnly
    ? RDOnlyMarketRent
    : affordableMarketRent;
  return applicableMarketRent;
}

export function getIsAffordableMixedProperty({ property }) {
  const propertyClass = pathOr('', ['propertyClass', 'name'], property);
  const ret = propertyClass === 'Affordable' || propertyClass === 'Mixed';
  return ret;
}

export function getIsProperty236({ property, HUD_SUBSIDY_CODES }) {
  const propertyHUDSubsidy = (property?.propertyHUDSubsidy ?? []).map(
    (hud) => hud?.masterHUDSubsidy?.code,
  );
  propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236);
}

export function calculateBaseMarketRent({ floorplan, property }) {
  const isAffordableMixedProperty = getIsAffordableMixedProperty({ property });
  let baseMarketRentAmount = calculateRentWithAmenities(
    floorplan,
    floorplan.baseMarketRentAmount,
  );
  const grossRentLimit = baseMarketRentAmount;
  if (isAffordableMixedProperty && !property.isTestProperty) {
    baseMarketRentAmount = calculateBaseMarketRentForAfforbableMixed(
      floorplan,
      baseMarketRentAmount,
    );
  }
  return { baseMarketRentAmount, grossRentLimit };
}
