// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedDate } from 'react-intl';
import { bindActionCreators } from 'redux';
import { pathOr } from 'ramda';
import {
  Box,
  Stack,
  Button,
  Spinner,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

import confirm from '../../../../../../components/ConfirmDialogModal';
import ElementWithPermissions from '../../../../../../components/ElementWithPermissions';

import { editLease, sendToPortal } from '../../../../actions';
import {
  PORTAL_ACTION_SENT,
  PORTAL_ACTION_EXECUTED,
  PORTAL_SIGNATURE_STATUS_SIGNED,
} from '../../../../../../utils/lease-helpers';

import ExecuteSignatureModal from '../ButtonActionModals/ExecuteSignatureModal';
import messages from './messages';

type ElectronicMethodButtonsProps = {
  allAffordableTabsApproved: boolean,
  applicationId: string,
  canComplete: boolean,
  difference: number,
  hasNonFR: boolean,
  isResident: boolean,
  leaseId: string,
  leaseSignatureStatuses: Object,
  onGenerate: Function,
  profileId: string,
  prospectId: string,
};

type ElectronicMethodButtonsInjectedProps = {
  actions: Object,
  intl: Object,
};

export const ElectronicMethodButtons = ({
  actions,
  allAffordableTabsApproved,
  applicationId,
  canComplete,
  difference,
  hasNonFR,
  intl,
  isResident,
  leaseId,
  leaseSignatureStatuses,
  onGenerate,
  profileId,
  prospectId,
}: ElectronicMethodButtonsProps & ElectronicMethodButtonsInjectedProps) => {
  const [isEditLeaseMode, setIsEditLeaseMode] = useState(false);

  const [showExecuteModal, setShowExecuteModal] = useState(false);

  const handleShowExecuteClick = () => setShowExecuteModal(true);
  const handleHideExecuteClick = () => setShowExecuteModal(false);

  const { latestLog, signatures } = leaseSignatureStatuses;

  const confirmEditDialog = () => {
    confirm(intl.formatMessage(messages.confirmEditElectronicLease)).then(
      () => {
        actions.editLease(
          leaseId,
          profileId,
          isResident,
          prospectId,
          undefined,
          undefined,
          setIsEditLeaseMode,
        );
      },
    );
  };

  const handleSendToPortalClick = () =>
    actions.sendToPortal(leaseId, profileId, isResident, prospectId);

  const portalAction = pathOr(null, ['action'], latestLog);
  const portalActionDate = pathOr(null, ['createdAt'], latestLog);
  const notSignedLeases = signatures.filter(
    (sig) => sig.signatureStatus !== PORTAL_SIGNATURE_STATUS_SIGNED,
  );

  const leaseSent = portalAction === PORTAL_ACTION_SENT;
  const leaseExecuted = portalAction === PORTAL_ACTION_EXECUTED;
  return (
    <>
      <ExecuteSignatureModal
        applicationId={applicationId}
        isResident={isResident}
        leaseId={leaseId}
        onHide={handleHideExecuteClick}
        profileId={profileId}
        prospectId={prospectId}
        show={showExecuteModal}
      />
      <ElementWithPermissions scope={['lease-create']}>
        <Stack spacing={1} direction={'row'} justifyContent={'center'}>
          <Button
            sx={{ flex: 1, maxWidth: '150px' }}
            data-testid={'editLeaseButton'}
            variant={'primarySubtle'}
            onClick={confirmEditDialog}
            disabled={isEditLeaseMode}
          >
            {isEditLeaseMode ? (
              <Spinner small />
            ) : (
              intl.formatMessage(messages.edit)
            )}
          </Button>
          <>
            {!isEditLeaseMode && (
              <>
                {!leaseSent && !leaseExecuted && (
                  <Button
                    sx={{ flex: 1, maxWidth: '150px' }}
                    data-testid={'sendToPortalButton'}
                    variant={'shout'}
                    onClick={handleSendToPortalClick}
                    disabled={
                      !canComplete ||
                      difference !== 0 ||
                      hasNonFR ||
                      !allAffordableTabsApproved
                    }
                  >
                    {intl.formatMessage(messages.sendToPortal)}
                  </Button>
                )}
                {leaseSent && !leaseExecuted && (
                  <ElementWithPermissions scope={['lease-execute']}>
                    <Button
                      sx={{ flex: 1, maxWidth: '150px' }}
                      variant={'shout'}
                      data-testid={'executeLeaseButton'}
                      onClick={handleShowExecuteClick}
                      disabled={
                        notSignedLeases.length > 0 ||
                        hasNonFR ||
                        !allAffordableTabsApproved
                      }
                    >
                      {intl.formatMessage(messages.executeLease)}
                    </Button>
                  </ElementWithPermissions>
                )}
                <Button
                  sx={{ flex: 1, maxWidth: '150px' }}
                  data-testid={'downloadLeaseButton'}
                  variant={'primary'}
                  disabled={
                    !canComplete ||
                    difference !== 0 ||
                    hasNonFR ||
                    !allAffordableTabsApproved
                  }
                  onClick={onGenerate}
                >
                  {intl.formatMessage(messages.downloadLease)}
                </Button>
              </>
            )}
          </>
        </Stack>
        <Box marginTop={1}>
          {leaseSent &&
            !leaseExecuted &&
            portalActionDate &&
            !isEditLeaseMode && (
              <div className="text-center" style={{ marginRight: '3%' }}>
                {`${intl.formatMessage(messages.sentToPortal)} - `}
                <FormattedDate value={portalActionDate} />
              </div>
            )}
          {!allAffordableTabsApproved && (
            <div className="text-center" style={{ marginRight: '3%' }}>
              {intl.formatMessage(messages.notApproved)}
            </div>
          )}
          {difference !== 0 && (
            <div className="text-center" style={{ marginRight: '3%' }}>
              {intl.formatMessage(messages.notReconciled)}
            </div>
          )}
        </Box>
      </ElementWithPermissions>
    </>
  );
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators({ editLease, sendToPortal }, dispatch);
  return { actions };
}

const InjectedElectronicMethodButtons = injectIntl(ElectronicMethodButtons);

export default connect(
  null,
  mapDispatchToProps,
)(InjectedElectronicMethodButtons);
