import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SingleSelect } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  red,
  warning,
} from '@fortress-technology-solutions/fortress-component-library/design';
import { formatDateDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { createActivity } from '../../../components/CreateActivityModal';
import { editCompletedActivity } from '../../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../../components/EditPendingActivityModal';
import useActivityTypes from '../../../hooks/data-fetching/useActivityTypes';
import { useFetchConversations } from '../../../hooks/data-fetching/useFetchConversations';
import useProspectAssignees from '../../../hooks/data-fetching/useProspectAssignees';
import useProspectStatuses from '../../../hooks/data-fetching/useProspectStatuses';
import useHasPermission from '../../../hooks/useHasPermission';
import useSelectedProperty from '../../../hooks/useSelectedProperty';
import ActivityService from '../../../services/activityService';
import ProspectProfileService from '../../../services/prospectProfileService';
import { exportAndDownloadCSV } from '../../../utils/csv-helpers';
import {
  getUrlWithSelectedPropertyId,
  navigateToUrlWithSelectedPropertyId,
} from '../../../utils/navigation-helpers';
import { formatNBedsArr } from '../../../utils/prospectPreferences-helpers';
import { phoneFormatter } from '../../../utils/redux-form-helper';
import useUniqueTableName from '../../../hooks/useUniqueTableName';
import actionMenuMessages from '../ActionsMenu/messages';
import ChangeStatusModal from '../ChangeStatusModal';
import Status from '../Status';
import messages from '../messages';
import { HEADERS, PROPERTY_PATH_MAP } from './constants';

export function useManageProspects({ intl, store }) {
  const selectedProperty = useSelectedProperty();
  const name = useUniqueTableName('manage-prospects');
  const hasProspectAssignPermission = useHasPermission('prospect-assign');
  const hasProspectUpdatePermission = useHasPermission('prospect-update');
  const hasActivityCreatePermission = useHasPermission('activity-create');
  const hasActivityReadPermission = useHasPermission('activity-read');
  const hasActivityUpdatePermission = useHasPermission('activity-update');
  const hasActivityDeletePermission = useHasPermission('activity-delete');
  const isEditActivityDisabled =
    (hasActivityReadPermission &&
      hasActivityUpdatePermission &&
      hasActivityDeletePermission) === false;
  const [showAllStatuses, setShowAllStatuses] = useState(false);
  const {
    data,
    isLoading,
    refetch: refreshTable,
  } = useManageProspectsTableData({ getAllStatuses: showAllStatuses });
  const { prospectAssignees: users } = useProspectAssignees();
  const { prospectStatuses } = useProspectStatuses();
  const { activityTypes } = useActivityTypes();

  const { householdConversationsStatusDictionary, showTextingColumn } =
    useTextingColumn({ prospects: data ?? [] });

  const statusList = useMemo(
    () =>
      prospectStatuses.map((prospectStatus) => ({
        value: prospectStatus.id,
        text: prospectStatus.name,
        color: prospectStatus.color,
        requiresNote: prospectStatus.requiresNote,
        isDefault: prospectStatus.isDefault,
      })),
    [prospectStatuses],
  );
  const activityTypesList = useMemo(() => {
    const list = activityTypes
      .filter((at) => at.automatedOnly === false)
      .map((activityType) => ({
        value: activityType.id,
        text: activityType.name,
        canBeOverlapped: activityType.canBeOverlapped,
        completionStatusOptions: activityType.completionStatusOptions,
        disabled: false,
      }));

    list.unshift({
      value: 'default',
      text: intl.formatMessage(messages.chooseOption),
      completionStatusOptions: [{ id: '' }],
      disabled: true,
      showForRecord: true,
    });

    return list;
  }, [activityTypes, intl]);

  const assigneeOptions = useMemo(
    () =>
      users.map((u) => ({
        value: u.id,
        text: `${u.firstName} ${u.lastName}`,
      })),
    [users],
  );

  const mappedData = useMemo(
    () =>
      data?.map((d) => {
        const assignedToName = d.assignedTo
          ? `${d.assignedTo.firstName} ${d.assignedTo.lastName}`
          : null;
        const nbf = formatNBedsArr(
          d.prospectPreferences?.nBedsArr,
          d.prospectPreferences?.nBeds,
        );
        return {
          ...d,
          prospectName: `${d.lastName}, ${d.firstName}`,
          nBedsFormatted: Array.isArray(nbf) ? nbf.join(', ') : nbf,
          assignedToName,
          texting: householdConversationsStatusDictionary[d.id] ?? null,
        };
      }),
    [data, householdConversationsStatusDictionary],
  );

  const filterOptions = useFilterOptions({ prospects: mappedData });

  const headers = useHeaders({
    showTextingColumn,
    filterOptions,
    showAllStatuses,
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: mappedData,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const handleAssigneeChange = useCallback(
    async (assigneeId, prospect) => {
      const service = new ProspectProfileService();
      try {
        await service.assign(
          {
            id: prospect.id,
            assignedToId: assigneeId,
            propertyId: selectedProperty?.id,
          },
          selectedProperty.organizationId,
        );

        toastr.success(
          intl.formatMessage(messages.successDescription),
          intl.formatMessage(messages.successHeader),
          {
            showCloseButton: true,
            removeOnHover: true,
          },
        );
        refreshTable();
      } catch (err) {
        toastr.error(err.toString(), intl.formatMessage(messages.errorHeader), {
          showCloseButton: true,
          removeOnHover: true,
        });
      }
    },
    [selectedProperty, intl, refreshTable],
  );

  const handleChangeStatus = useCallback(
    async ({ prospect, newStatus, notes }) => {
      try {
        const service = new ProspectProfileService();

        await service.changeStatus(
          prospect,
          newStatus,
          notes,
          selectedProperty.organizationId,
        );

        toastr.success(
          intl.formatMessage(messages.successDescription),
          intl.formatMessage(messages.successHeader),
          {
            showCloseButton: true,
            removeOnHover: true,
          },
        );
        refreshTable();
      } catch (err) {
        toastr.error(err.toString(), intl.formatMessage(messages.errorHeader), {
          showCloseButton: true,
          removeOnHover: true,
        });
      }
    },
    [selectedProperty, intl, refreshTable],
  );

  const handleCreateActivity = useCallback(
    async ({ activity }) => {
      try {
        const service = new ActivityService();

        await service.save(activity, selectedProperty.organizationId);

        toastr.success(
          intl.formatMessage(messages.successActivityDescription),
          intl.formatMessage(messages.successHeader),
          {
            showCloseButton: true,
            removeOnHover: true,
          },
        );
        refreshTable();
      } catch (err) {
        toastr.error(err.toString(), intl.formatMessage(messages.errorHeader), {
          showCloseButton: true,
          removeOnHover: true,
        });
      }
    },
    [selectedProperty, intl, refreshTable],
  );

  const handleCreateActivityClick = useCallback(
    (prospect) => {
      createActivity({
        store,
        intl,
        prospect,
        activityTypesList,
        assigneeList: assigneeOptions,
        stage: 'prospect',
        urlId: prospect.id,
        showViewProfileBtn: true,
      }).then((data) => handleCreateActivity({ activity: data }));
    },
    [intl, store, activityTypesList, assigneeOptions, handleCreateActivity],
  );

  const updateProspectActivity = useCallback(
    async (activity) => {
      try {
        const service = new ActivityService();

        const payload = omit(activity, [
          'id',
          'saveAndClose',
          'saveAndAddNew',
          'createdAt',
          'updatedAt',
          'createdById',
          'updatedById',
          'updatedBy',
          'deletedAt',
          'lastUpdatedById',
          'ownedBy',
          'prospect',
          'completionStatus',
          'activityType',
        ]);

        await service.edit(
          payload,
          activity.id,
          selectedProperty.organizationId,
        );

        refreshTable();

        toastr.success(
          intl.formatMessage(messages.successEditActivityDescription),
          intl.formatMessage(messages.successHeader),
          {
            showCloseButton: true,
            removeOnHover: true,
          },
        );
      } catch (err) {
        toastr.error(err.toString(), intl.formatMessage(messages.errorHeader), {
          showCloseButton: true,
          removeOnHover: true,
        });
      }
    },
    [intl, refreshTable, selectedProperty],
  );

  const deleteProspectActivity = useCallback(
    async (activity) => {
      try {
        const service = new ActivityService();

        await service.delete(
          omit(activity, ['prospect']),
          selectedProperty.organizationId,
        );

        refreshTable();

        toastr.success(
          intl.formatMessage(messages.successDeleteActivityDescription),
          intl.formatMessage(messages.successHeader),
          {
            showCloseButton: true,
            removeOnHover: true,
          },
        );
      } catch (err) {
        toastr.error(err.toString(), intl.formatMessage(messages.errorHeader), {
          showCloseButton: true,
          removeOnHover: true,
        });
      }
    },
    [intl, refreshTable, selectedProperty],
  );

  const handleEditActivity = useCallback(
    ({ activity, prospect }) => {
      const stageInfo = {
        currentStage: 'prospect',
        urlId: activity.prospectId,
      };

      activity.activityType = activityTypes.filter(
        (at) => at.id === activity.activityTypeId,
      )[0];

      if (activity.activityType) {
        activity.completionStatus =
          activity.activityType.completionStatusOptions.filter(
            (s) => s.id === activity.activityCompletionStatusId,
          )[0];
      }

      activity.prospect = prospect;

      const editPromise = activity.activityCompletionStatusId
        ? editCompletedActivity(store, intl, activity, stageInfo)
        : editPendingActivity(
            store,
            intl,
            activity,
            activityTypesList,
            assigneeOptions,
            activity.prospect,
            stageInfo,
          );

      const editFunction = () => handleCreateActivityClick(prospect);

      editPromise.then((data: Activity) => {
        if (data.saveAndClose) {
          updateProspectActivity(data);
        } else if (data.saveAndAddNew) {
          updateProspectActivity(data);
          editFunction();
        } else {
          deleteProspectActivity(data);
        }
      });
    },
    [
      intl,
      store,
      activityTypesList,
      handleCreateActivityClick,
      updateProspectActivity,
      deleteProspectActivity,
      activityTypes,
      assigneeOptions,
    ],
  );

  const getNextActivityColor = useCallback((activity) => {
    if (!activity || isEmpty(activity.startTime)) return;

    const currentTime = new Date();
    const startTime = new Date(activity.startTime);

    if (startTime.getTime() < currentTime.getTime()) return red.dark;
  }, []);

  const rows = useMemo(
    () =>
      sortedAndFilteredResults.map((result) => {
        const lastActivity = result.lastActivity?.startTime;
        const lastActivityFormatted = lastActivity
          ? formatDateDisplayLocal(lastActivity)
          : '';
        const nextActivity = result.nextActivity?.startTime;
        const nextActivityFormatted = nextActivity
          ? formatDateDisplayLocal(nextActivity)
          : '';
        const moveInDate = result.prospectPreferences?.moveInDateFrom;
        const color = result.currentProspectStatus?.color ?? 'gray';
        const statusName = result.currentProspectStatus?.name ?? '';
        const assignee = users.find((u) => u.id === result.assignedToId);
        const backgroundColor = assignee ? null : warning.lighter;
        const texting = result.texting ?? {
          TOTAL: 0,
        };
        const urlToProspectProfile = getUrlWithSelectedPropertyId(
          `/prospect/${result.id}`,
        );
        const _assigneeOptions =
          assignee || !result.assignedToId
            ? assigneeOptions
            : [
                ...assigneeOptions,
                {
                  value: result.assignedToId,
                  text: `${result.assignedTo?.firstName} ${result.assignedTo?.lastName}`,
                },
              ];

        return {
          sx: {
            backgroundColor,
          },
          status: {
            value: (
              <Stack direction="row" spacing={1} alignItems="center">
                <Status color={color} noMargin />
                <Typography fontSize={12}>{statusName}</Typography>
              </Stack>
            ),
          },
          prospectName: {
            variant: 'link',
            to: urlToProspectProfile,
            value: `${result.lastName}, ${result.firstName}`,
          },
          email: {
            variant: 'link',
            href: `mailto:${result.emailAddress}`,
            value: result.emailAddress,
          },
          phoneNumber: {
            variant: 'phoneNumber',
            value: result.phoneNumber,
          },
          texting: {
            variant: texting.TOTAL > 0 ? 'link' : undefined,
            value: <TextingIndicator {...result.texting} />,
            to:
              texting.TOTAL > 0
                ? `${urlToProspectProfile}?tab=texting`
                : undefined,
          },
          prospectCreated: {
            variant: 'date',
            value: result.createdAt,
          },
          lastActivity: {
            variant: 'link',
            onClick: () => {
              handleEditActivity({
                activity: result.lastActivity,
                prospect: result,
              });
            },
            disabled: isEditActivityDisabled,
            value: lastActivityFormatted,
          },
          nextActivity: {
            variant: 'link',
            onClick: () => {
              handleEditActivity({
                activity: result.nextActivity,
                prospect: result,
              });
            },
            disabled: isEditActivityDisabled,
            value: nextActivityFormatted,
            style: { color: getNextActivityColor(result.nextActivity) },
          },
          moveInDate: {
            variant: 'date',
            value: moveInDate,
          },
          nBeds: {
            variant: 'description',
            value: result.nBedsFormatted,
          },
          assignedTo: {
            value: (
              <SingleSelect
                options={_assigneeOptions}
                value={result.assignedToId}
                fullWidth
                sx={{ height: 40 }}
                disabled={!hasProspectAssignPermission}
                onChange={(value) => {
                  handleAssigneeChange(value, result);
                }}
              />
            ),
          },
          actions: {
            variant: 'menu',
            name: 'actions',
            iconName: 'BoltIcon',
            color: 'primary',
            closeOnClick: true,
            options: [
              {
                disabled: !hasProspectUpdatePermission,
                value: 'changeStatus',
                text: <FormattedMessage {...actionMenuMessages.changeStatus} />,
                onClick: () => {
                  ChangeStatusModal(
                    store,
                    intl,
                    statusList,
                    result.prospectStatusId,
                  ).then(({ newStatus, notes }) => {
                    handleChangeStatus({
                      prospect: result,
                      newStatus,
                      notes,
                    });
                  });
                },
              },
              {
                disabled: !hasActivityCreatePermission,
                value: 'createActivity',
                text: (
                  <FormattedMessage {...actionMenuMessages.createActivity} />
                ),
                onClick: () => handleCreateActivityClick(result),
              },
            ],
          },
        };
      }),
    [
      sortedAndFilteredResults,
      users,
      assigneeOptions,
      hasProspectAssignPermission,
      hasProspectUpdatePermission,
      hasActivityCreatePermission,
      handleAssigneeChange,
      intl,
      store,
      statusList,
      handleChangeStatus,
      handleCreateActivityClick,
      isEditActivityDisabled,
      handleEditActivity,
      getNextActivityColor,
    ],
  );

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const csvRows = useMemo(() => {
    return sortedAndFilteredResults?.map((result) => {
      const statusName = result.currentProspectStatus?.name ?? '';
      return {
        status: {
          value: statusName,
        },
        prospectName: {
          value: `${result.lastName}, ${result.firstName}`,
        },
        phoneNumber: {
          value: phoneFormatter(result.phoneNumber),
        },
        texting: {
          value: `New = ${result.texting?.NEW ?? 0}, Unresolved = ${
            result.texting.UNRESOLVED ?? 0
          }`,
        },
        email: {
          value: result.emailAddress,
        },
        prospectCreated: {
          variant: 'date',
          value: result.createdAt,
        },
        lastActivity: {
          variant: 'date',
          value: result.lastActivity?.startTime,
        },
        nextActivity: {
          variant: 'date',
          value: result.nextActivity?.startTime,
        },
        moveInDate: {
          variant: 'date',
          value: result.prospectPreferences?.moveInDateFrom,
        },
        nBeds: {
          value: result.nBedsFormatted,
        },
        assignedTo: {
          value: `${result.assignedTo?.firstName} ${result.assignedTo?.lastName}`,
        },
      };
    });
  }, [sortedAndFilteredResults]);

  const handleCSVButtonClick = useCallback(() => {
    const hasAnyFilters =
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length;

    exportAndDownloadCSV({
      excludedHeaders: ['actions'].concat(showTextingColumn ? [] : ['texting']),
      filteredHeaders,
      fileName: 'ManageProspects.csv',
      hasAnyFilters,
      rows: csvRows,
    });

    return true;
  }, [
    filterState,
    dateState,
    searchState,
    csvRows,
    filteredHeaders,
    showTextingColumn,
  ]);

  function onNewProspectClick() {
    navigateToUrlWithSelectedPropertyId('/prospect');
  }

  return {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    name,
    searchState,
    sortedAndFilteredResults,
    isLoading,
    rows,
    count: rows.length ?? 0,
    totalCount: data?.length ?? 0,
    onCSVButtonClick: handleCSVButtonClick,
    onNewProspectClick,
    showAllStatuses,
    setShowAllStatuses,
  };
}

function useManageProspectsTableData({ getAllStatuses }) {
  const selectedProperty = useSelectedProperty();
  const queryKey = [
    'getManageProspectsTableData',
    selectedProperty?.id,
    selectedProperty?.organizationId,
    getAllStatuses,
  ];
  const { data, isLoading, refetch } = useQuery(
    queryKey,
    () => {
      const service = new ProspectProfileService();
      return service
        .getAllProspects({
          organizationId: selectedProperty?.organizationId,
          propertyId: selectedProperty?.id,
          getAllStatuses,
        })
        .then((response) => response?.results);
    },
    {
      staleTime: 60000,
    },
  );

  return {
    data,
    isLoading,
    refetch,
  };
}

function useTextingColumn({ prospects }) {
  const selectedProperty = useSelectedProperty();
  const prospectIds = useMemo(
    () => prospects.map((p) => p.id).flat(),
    [prospects],
  );

  const hasCommunicationCreatePermission = useHasPermission(
    'communication-create',
  );
  const showTextingColumn =
    selectedProperty.isTwoWayCommunicationActive &&
    hasCommunicationCreatePermission;

  const [conversations] = useFetchConversations(prospectIds, showTextingColumn);

  const householdConversationsStatusDictionary = useMemo(() => {
    const conversationsDictionary = keyBy(
      conversations,
      (c) => c.recipient.prospectId,
    );

    return prospects.reduce((acc, prospect) => {
      const conversation = conversationsDictionary[prospect.id] ?? {};
      const householdConversationsStatus = {
        NEW: conversation.conversationStatus === 'NEW' ? 1 : 0,
        UNRESOLVED: conversation.conversationStatus === 'UNRESOLVED' ? 1 : 0,
        RESOLVED: conversation.conversationStatus === 'RESOLVED' ? 1 : 0,
      };

      householdConversationsStatus.TOTAL =
        householdConversationsStatus.NEW +
        householdConversationsStatus.UNRESOLVED;

      acc[prospect.id] = householdConversationsStatus;

      return acc;
    }, {});
  }, [conversations, prospects]);

  return {
    householdConversationsStatusDictionary,
    showTextingColumn,
  };
}

function useHeaders({ showTextingColumn, filterOptions, showAllStatuses }) {
  return useMemo(() => {
    const headers = [];

    HEADERS({ showTextingColumn, showAllStatuses }).forEach((header) => {
      if (filterOptions[`${header.id}Options`])
        header.filterOptions = filterOptions[`${header.id}Options`];

      headers.push(header);
    });

    return headers;
  }, [showTextingColumn, filterOptions, showAllStatuses]);
}

function useFilterOptions({ prospects }) {
  return useMemo(() => {
    const options = {
      nBedsOptions: [],
      statusOptions: [],
    };

    if (!prospects) return options;

    prospects.forEach(
      ({ currentProspectStatus: prospectStatus, nBedsFormatted }) => {
        if (prospectStatus) options.statusOptions.push(prospectStatus);
        if (nBedsFormatted?.length) options.nBedsOptions.push(nBedsFormatted);
      },
    );

    options.statusOptions = Array.from(uniqBy(options.statusOptions, 'id'))
      .sort()
      .map((status) => ({
        text: status?.name,
        value: status?.id,
      }));

    options.nBedsOptions = Array.from(new Set(options.nBedsOptions))
      .sort()
      .map((bedsBaths) => ({
        text: bedsBaths,
        value: bedsBaths,
      }));

    return options;
  }, [prospects]);
}
