import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import messages from './messages';
import { formatCurrency } from '../../../../../utils';
import DashesIfNullOrUndefined from '../../../../../components/DashesIfNullOrUndefined';

export const Reconciliation = ({
  formValues,
  difference,
  intl,
  nonRentCharges,
  totalMonthlyCharges,
}: Object) => {
  return (
    <Col xs={12} sm={6}>
      <div className="form-groupbox padbottom20">
        <h3>
          <FormattedMessage {...messages.reconciliation} />
        </h3>
        <Row>
          <Col xs={8}>
            <label>{intl.formatMessage(messages.leaseRent)}</label>
          </Col>
          <Col xs={4}>
            <DashesIfNullOrUndefined
              data={formatCurrency(
                intl,
                pathOr(null, ['leasedRent'], formValues),
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <label>{intl.formatMessage(messages.nonRentCharges)}</label>
          </Col>
          <Col xs={4}>
            <div className="form-group">
              <span>{formatCurrency(intl, nonRentCharges)}</span>
            </div>
          </Col>
        </Row>
        <div className="highlighted-footer padbottom10">
          <Row>
            <Col xs={8}>
              <span>
                <FormattedMessage {...messages.totalLeaseCharges} />
              </span>
            </Col>
            <Col xs={4}>
              <span>
                {formatCurrency(
                  intl,
                  +pathOr(0, ['leasedRent'], formValues) + +nonRentCharges,
                )}
              </span>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={8}>
            <label>
              {intl.formatMessage(messages.totalMonthlyChargesLabel)}
            </label>
          </Col>
          <Col xs={4}>
            <div className="form-group">
              <DashesIfNullOrUndefined data={totalMonthlyCharges} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <small className="font-12">
              <em className="text-gray">
                {intl.formatMessage(messages.requiredMonthlyLeaseChargesLabel)}
              </em>
            </small>
          </Col>
        </Row>
        <div
          className={
            difference === 0
              ? 'highlighted-footer--green padbottom10 padtop10'
              : 'highlighted-footer--red padbottom10 padtop10'
          }
        >
          <Row>
            <Col xs={8}>
              <span>{intl.formatMessage(messages.differenceLabel)}</span>
            </Col>
            <Col xs={4}>
              <span>{formatCurrency(intl, difference)}</span>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={8}>
            <small className="font-12">
              <em className="text-gray">
                {intl.formatMessage(messages.differenceMessage)}
              </em>
            </small>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default Reconciliation;
