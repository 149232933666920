import { useEffect, useState } from 'react';
import CamReconciliationService from '../../../../services/camReconciliationService';

export const useFetchCamReconciliations = ({
  intl,
  propertyId,
  organizationId,
  camRecordId,
  promptToaster,
}: Object): any => {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reconciliations, setReconciliations] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchRecnciliations = async () => {
      if (!propertyId || !organizationId) {
        throw new Error('Could not fetch CAM Reconciliattions');
      }
      setIsLoading(true);
      const camReconciliationService = new CamReconciliationService();
      const response = await camReconciliationService.getAllReconciliations(
        organizationId,
        propertyId,
        camRecordId,
        abortController.signal,
      );
      setReconciliations(response);
      setIsLoading(false);
    };
    if (camRecordId) {
      fetchRecnciliations();
    }
    setRefresh(false);
    return () => abortController.abort();
  }, [intl, organizationId, propertyId, camRecordId, promptToaster, refresh]);

  return [isLoading, reconciliations, () => setRefresh(true)];
};
