import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  getDateDiffInDays,
  getDateUTC,
  getIsPastDueDate,
} from '../../utils/date-helpers';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import { exportAndDownloadCSV } from '../../utils/csv-helpers';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useHasPermission from '../../hooks/useHasPermission';
import UserService from '../../services/userService';
import PriorityLevelService from '../../services/priorityLevelService';
import { toastr } from 'react-redux-toastr';
import WorkOrderService from '../../services/workOrderService';
import useUniqueTableName from '../../hooks/useUniqueTableName';

const defaultOption = {
  value: '',
  text: 'Choose',
  disabled: true,
};

export function useManageWorkOrdersV2({ intl }) {
  const { manageWorkOrdersBuildingNumber } = useFlags();
  const selectedProperty = useSelectedProperty();
  const name = useUniqueTableName('manage-work-orders');
  const [
    includeCanceledAndCompletedWorkOrders,
    setIncludeCanceledAndCompletedWorkOrders,
  ] = useState(false);
  const { assignedToOptions, isLoading: isAssignedToOptionsLoading } =
    useAssignedToOptions();
  const { priorityOptions, isLoading: isPriorityOptionsLoading } =
    usePriorityOptions();
  const {
    workOrders,
    isLoading: isWorkOrdersLoading,
    refetch: refreshWorkOrders,
  } = useWorkOrderData({
    includeCanceledAndCompletedWorkOrders,
    assignedToOptions,
  });
  const assigneeChangeDisabled =
    useHasPermission('change-work-order-assignee') === false;
  const priorityChangeDisabled = useHasPermission('workorder-edit') === false;
  const viewWorkOrderDisabled = useHasPermission('workorder-read') === false;

  const handleCreateWorkOrderClick = () => {
    navigateToUrlWithSelectedPropertyId('/add-work-order');
  };

  const handleToggleCanceledAndCompletedWorkOrders = useCallback(() => {
    setIncludeCanceledAndCompletedWorkOrders(
      !includeCanceledAndCompletedWorkOrders,
    );
  }, [includeCanceledAndCompletedWorkOrders]);

  const isLoading =
    isAssignedToOptionsLoading ||
    isPriorityOptionsLoading ||
    isWorkOrdersLoading;

  return {
    name,
    workOrders,
    intl,
    isLoading,
    assignedToOptions,
    priorityOptions,
    ElementWithPermissions,
    onAssignedToChange: (workOrder, value) => {
      updateWorkOrder({
        detailId: workOrder.dbId,
        field: 'assignedToId',
        value,
        propertyId: selectedProperty.id,
        organizationId: selectedProperty.organizationId,
      }).then(() => refreshWorkOrders());
    },
    onPriorityChange: (workOrder, value) => {
      updateWorkOrder({
        detailId: workOrder.dbId,
        field: 'priorityLevelId',
        value,
        propertyId: selectedProperty.id,
        organizationId: selectedProperty.organizationId,
      }).then(() => refreshWorkOrders());
    },
    onCreateWorkOrderClick: handleCreateWorkOrderClick,
    exportAndDownloadCSV,
    includeCanceledAndCompletedWorkOrders,
    onToggleCanceledAndCompletedWorkOrders:
      handleToggleCanceledAndCompletedWorkOrders,
    assigneeChangeDisabled,
    priorityChangeDisabled,
    viewWorkOrderDisabled,
    manageWorkOrdersBuildingNumberFlag: manageWorkOrdersBuildingNumber,
  };
}

function useWorkOrderData({
  includeCanceledAndCompletedWorkOrders,
  assignedToOptions,
}) {
  const selectedProperty = useSelectedProperty();
  const workOrderService = new WorkOrderService();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'propertyWorkOrders',
      selectedProperty.id,
      includeCanceledAndCompletedWorkOrders ? 'withCanceledAndCompleted' : '',
    ],
    queryFn: () =>
      workOrderService
        .getPropertyWorkOrders({
          propertyId: selectedProperty.id,
          organizationId: selectedProperty.organizationId,
          defaultFilterActive: includeCanceledAndCompletedWorkOrders === false,
        })
        .then((response) => response.results),
    onError: () => {
      toastr.error('Error', 'Failed to load work orders.');
    },
    enabled:
      assignedToOptions &&
      Array.isArray(assignedToOptions) &&
      assignedToOptions.length > 0,
  });

  return {
    workOrders: useMemo(() => {
      if (isLoading) return [];

      return data.map((workOrder) => {
        const location =
          workOrder.commonAreaLocation?.commonAreaLocationDescription ??
          workOrder.unitNumber;
        const issueDescription = workOrder.issue?.issueDescription ?? '';
        const description = workOrder.otherDescription
          ? `Other - ${workOrder.otherDescription}`
          : issueDescription;
        const finishedDate = workOrder.finishedDate;
        const lastStatusChange = workOrder.lastStatusChangeDate;
        const status = workOrder.status?.statusDescription;
        const dateToCompare =
          status === 'Open' || status === 'On Hold' || status === 'In Progress'
            ? getDateUTC()
            : status === 'Canceled'
            ? lastStatusChange
            : isNil(finishedDate)
            ? lastStatusChange
            : finishedDate;

        const daysOpen = getDateDiffInDays(dateToCompare, workOrder.createdAt);

        const assigneeStatus =
          workOrder.assignedTo?.userStatus?.name ?? 'Inactive';
        const isWithoutAccess =
          isNil(workOrder.assignedTo) ||
          isNil(
            assignedToOptions.find(
              (option) => option.value === workOrder.assignedTo.id,
            ),
          );
        const assigneeInactiveOrWithoutAccess =
          assigneeStatus === 'Inactive' || isWithoutAccess;

        const isActiveWorkOrder =
          status !== 'Completed' && status !== 'Canceled';
        const pastDue =
          getIsPastDueDate(workOrder.dueDate) && isActiveWorkOrder;

        return {
          id: workOrder.detailIdReadable.toString(),
          location,
          buildingNumber: workOrder.buildingNumber,
          issueDescription: description,
          creationDate: workOrder.createdAt,
          assignedToId: workOrder.assignedToId,
          assignedTo: workOrder.assignedTo
            ? `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}`
            : undefined,
          priorityId: workOrder.priorityLevelId,
          priority: workOrder.priorityLevel?.priorityLevelDescription,
          status,
          daysOpen,
          dueDate: workOrder.dueDate,
          completionDate: finishedDate,
          requestorName: workOrder.requestorName,
          requestorPhone: workOrder.requestorPhone,
          href: `/property/${selectedProperty.id}/edit-work-order/${workOrder.detailId}`,
          assigneeInactiveOrWithoutAccess,
          pastDue,
          dbId: workOrder.detailId,
        };
      });
    }, [assignedToOptions, data, isLoading, selectedProperty.id]),
    isLoading,
    refetch,
  };
}

function useAssignedToOptions() {
  const selectedProperty = useSelectedProperty();
  const userService = new UserService();

  const { data, isLoading } = useQuery({
    queryKey: ['workOrderAssignees', selectedProperty.id],
    queryFn: () =>
      userService.getWorkOrderAssignees(
        selectedProperty.organizationId,
        selectedProperty.id,
      ),
    onError: () => {
      toastr.error('Error', 'Failed to load assignees.');
    },
  });

  return {
    assignedToOptions: useMemo(() => {
      const options = orderBy(
        isLoading
          ? []
          : data.map((assignee) => ({
              value: assignee.id,
              text: `${assignee.firstName} ${assignee.lastName}`,
            })),
        'text',
        'asc',
      );

      options.unshift({
        ...defaultOption,
      });

      return options;
    }, [data, isLoading]),
    isLoading,
  };
}

function usePriorityOptions() {
  const selectedProperty = useSelectedProperty();
  const priorityLevelService = new PriorityLevelService();

  const { data, isLoading } = useQuery({
    queryKey: ['workOrderPriorityLevels', selectedProperty.organizationId],
    queryFn: () => priorityLevelService.getAll(selectedProperty.organizationId),
    onError: () => {
      toastr.error('Error', 'Failed to load priority levels.');
    },
  });

  return {
    priorityOptions: useMemo(() => {
      const options = orderBy(
        isLoading
          ? []
          : data.map((priorityLevel) => ({
              value: priorityLevel.priorityLevelId,
              text: priorityLevel.priorityLevelDescription,
            })),
        'text',
        'asc',
      );

      options.unshift({
        ...defaultOption,
      });

      return options;
    }, [data, isLoading]),
    isLoading,
  };
}

function updateWorkOrder({
  detailId,
  field,
  value,
  propertyId,
  organizationId,
}) {
  const service = new WorkOrderService();
  return service
    .update(
      {
        detailId,
        [field]: value,
        propertyId,
      },
      organizationId,
    )
    .then(() => {
      toastr.success('Success', 'Work order updated.');
    })
    .catch(() => {
      toastr.error('Error', 'Failed to update work order.');
    });
}
