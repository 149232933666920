import {
  QUOTE_EXPIRATION_OPTIONS,
  SUCCESSIVE_LEASE_TERM_OPTIONS,
} from './constants';

export const formatQuoteExpirationTime = (quoteExpTime) => {
  const quoteExpirationOpt = QUOTE_EXPIRATION_OPTIONS.find(
    (qeo) => qeo.value === quoteExpTime,
  );
  return quoteExpirationOpt?.label ?? '---';
};

export const getQuoteExpirationOptions = () =>
  QUOTE_EXPIRATION_OPTIONS.map((opt) => (
    <option
      key={`quote-exp-options-${opt.value}`}
      value={opt.value}
      name="quoteExpTime"
    >
      {opt.label}
    </option>
  ));

export const getSuccessiveLeaseTermOptions = (intl: Object, messages: Object) =>
  SUCCESSIVE_LEASE_TERM_OPTIONS.map((opt) => (
    <option
      key={`successive-lease-term-options-${opt.value}`}
      value={opt.value}
    >
      {intl.formatMessage(messages[opt.label])}
    </option>
  ));

export function getUsesFortressPayments(paymentProviders = []) {
  return (
    paymentProviders.find((pp) => pp.paymentProvider === 'FORTRESS') !==
    undefined
  );
}
