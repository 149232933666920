import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Stack,
  Switch,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AddIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Legend } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  TableHeader,
  VirtualizedTable,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import messages from '../messages';
import ManageProspectsLayout from './Layout';
import { useManageProspects } from './hooks';
import withReduxStore from './withReduxStore';

const ManageProspects = ({ intl, store }) => {
  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    truncatedColumns,
    isLoading,
    totalCount,
    onCSVButtonClick,
    onNewProspectClick,
    showAllStatuses,
    setShowAllStatuses,
  } = useManageProspects({ intl, store });

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <ManageProspectsLayout name={name}>
        {(tableHeight) => (
          <>
            <TableHeader
              title={intl.formatMessage(messages.header)}
              count={count}
              totalCount={totalCount}
              actions={
                <ElementWithPermissions scope={['prospect-create']}>
                  <Button
                    variant="shout"
                    onClick={onNewProspectClick}
                    startIcon={<AddIcon />}
                  >
                    {intl.formatMessage(messages.newProspect)}
                  </Button>
                </ElementWithPermissions>
              }
            />
            <Stack paddingX={2} spacing={2} sx={{ maxWidth: 'fit-content' }}>
              <Legend
                data={[
                  {
                    label: intl.formatMessage(messages.yellowLegend),
                    sx: { backgroundColor: 'warning.lighter' },
                  },
                ]}
              />
              <Switch
                checked={showAllStatuses}
                onChange={() => setShowAllStatuses(!showAllStatuses)}
                label={intl.formatMessage(messages.showAllStatusesLabel)}
              />
            </Stack>

            <VirtualizedTable
              ReactBeautifulDnD={{
                DragDropContext,
                Droppable,
                Draggable,
              }}
              ReactRouterLink={ReactRouterLink}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              handleTruncateChange={handleTruncateChange}
              headers={filteredHeaders}
              height={tableHeight}
              isLoading={isLoading}
              name={name}
              order={order}
              orderBy={orderBy}
              rows={rows}
              count={count}
              searchState={searchState}
              selectedColumns={selectedColumns}
              truncatedColumns={truncatedColumns}
              onCSVButtonClick={onCSVButtonClick}
            />
          </>
        )}
      </ManageProspectsLayout>
    </DocumentTitle>
  );
};

export default injectIntl(withReduxStore(ManageProspects));
