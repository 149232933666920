import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Skeleton,
  Card,
  Stack,
  Switch,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Collapse } from '@fortress-technology-solutions/fortress-component-library/Transitions';
import {
  VisibilityIcon,
  VisibilityOffIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { getSortFn } from '@fortress-technology-solutions/fortress-component-library/utils';
import { useLocalStorage } from '@fortress-technology-solutions/fortress-component-library/hooks';
import { combineUnitsGreaterThanFive } from '../ManageUnitAvailability/utils';
import appMessages from '../App/messages';

const Summary = ({ name, isLoading, sortedAndFilteredResults }) => {
  const {
    localStorageState: showHideSummary,
    setLocalStorage: setShowHideSummary,
  } = useLocalStorage(`show-hide-summary_${name}`, 'boolean', true);

  const summary = useMemo(() => {
    const unitsSummaryCount = new Map();

    sortedAndFilteredResults.forEach(({ bedsBaths, leased, status, nBeds }) => {
      const unitData = { bedsBaths, leased, status };
      const existingUnits = unitsSummaryCount.get(nBeds) || [];
      unitsSummaryCount.set(nBeds, [unitData, ...existingUnits]);
    });

    unitsSummaryCount.forEach((units, bedroomSize) => {
      const leased = units.reduce((acc, { leased }) => {
        acc[leased] = (acc[leased] || 0) + 1;
        return acc;
      }, {});
      const status = units.reduce((acc, { status }) => {
        acc[status] = (acc[status] || 0) + 1;
        return acc;
      }, {});
      unitsSummaryCount.set(bedroomSize, { bedroomSize, leased, status });
    });

    const unitsGreaterThanFive = new Map(
      [...unitsSummaryCount].filter(([bedroomSize]) => bedroomSize > 4),
    );

    if (
      unitsGreaterThanFive.size > 1 ||
      (unitsGreaterThanFive.size === 1 && !unitsGreaterThanFive.get('5'))
    ) {
      return Array.from(
        combineUnitsGreaterThanFive(unitsGreaterThanFive, unitsSummaryCount),
      ).sort(getSortFn('0', 'ASC').number);
    }
    return Array.from(unitsSummaryCount).sort(getSortFn('0', 'ASC').number);
  }, [sortedAndFilteredResults]);

  return (
    <>
      <Switch
        sx={{ paddingX: 2, paddingTop: 1 }}
        checked={showHideSummary}
        onChange={() => setShowHideSummary((prevState) => !prevState)}
        label={<FormattedMessage {...appMessages.kpiData} />}
        checkedIcon={<VisibilityIcon />}
        icon={<VisibilityOffIcon />}
      />
      <Collapse id={'collapse'} in={showHideSummary}>
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            paddingX: 2,
            paddingBottom: 1,
            overflowX: 'auto',
            width: '100%',
            minHeight: 198,
          }}
        >
          {isLoading &&
            !Boolean(summary?.length) &&
            Array.from(Array(6)).map((_, i) => (
              <Skeleton
                key={i}
                variant="rect"
                sx={{
                  borderRadius: '2px', // matches Card borderRadius
                }}
                height={198}
                width={200}
              />
            ))}
          {summary?.map(([key, { bedroomSize, leased, status }]) => {
            const blockTitle =
              bedroomSize === '0' ? 'STUDIO' : `${bedroomSize} BEDROOM`;
            const leasedCount = leased['true'] || 0;
            const notLeasedCount = leased['false'] || 0;
            const readyCount = status['Vacant - Ready'] || 0;
            const notReadyCount =
              (status['Vacant / Not Ready'] || 0) +
              (status['Occupied / On Notice to Vacate'] || 0);
            return (
              <Card
                key={key}
                sx={{
                  minWidth: 200,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="inputLabel" padding={2}>
                  {blockTitle}
                </Typography>
                <Stack spacing={1} sx={{ padding: 2 }}>
                  <Typography
                    color={leasedCount === 0 ? 'text.disabled' : 'text.primary'}
                  >
                    Leased: {leasedCount}
                  </Typography>
                  <Typography
                    color={
                      notLeasedCount === 0 ? 'text.disabled' : 'text.primary'
                    }
                  >
                    Not Leased: {notLeasedCount}
                  </Typography>
                  <Typography
                    color={readyCount === 0 ? 'text.disabled' : 'text.primary'}
                  >
                    Ready: {readyCount}
                  </Typography>
                  <Typography
                    color={
                      notReadyCount === 0 ? 'text.disabled' : 'text.primary'
                    }
                  >
                    Not Ready: {notReadyCount}
                  </Typography>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Collapse>
    </>
  );
};

export default Summary;
