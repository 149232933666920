import moment from 'moment-business-days';
import { isEmpty, isNil, omit } from 'ramda';
import FileSaver from 'file-saver';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { COMPLIANCE_TYPES } from './constants';
import { sortAndFilterRows } from '../../utils';
import { HEADER_ROWS_NEEDS_REVIEW, HEADER_ROWS } from './constants';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../utils/affordable';
import ReportService from '../../services/reportService';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const parseComplianceOverviewEntries = (
  complianceOverviewData: Array<Object>,
  currentSorting: Object,
  hasFilters: boolean,
  filter: Object,
  searchText: string,
): Array<Object> => {
  const { fieldName, order } = currentSorting;
  let entries = complianceOverviewData;
  if (hasFilters) {
    const filters = Object.entries(filter).reduce((acc, f) => {
      const [key, value] = f;
      Object.entries(value).forEach((filterOptions) => {
        const [k, v] = filterOptions;
        if (v) {
          if (acc[key]) {
            acc[key].push(k);
          } else {
            acc[key] = [k];
          }
        }
      });
      return acc;
    }, {});

    entries = entries.filter((entry) => {
      return Object.entries(filters).every((f) => {
        const [key, value] = f;
        // $FlowFixMe
        if (
          key === 'waitlistApplicant' &&
          (value === 'Do Not Include' ||
            (value ?? []).includes('Do Not Include'))
        ) {
          return !entry?.waitlistApplicant;
        }
        return value
          .map((val) => (val === 'None' ? null : val))
          .includes(entry[key]);
      });
    });
  }

  return sortAndFilterRows(entries, fieldName, order, searchText);
};

export const adaptFiltersObject = (
  filtersObject,
  locale?: { [key: string]: string },
) => {
  const localizedFiltersObject = Object.entries(filtersObject ?? {}).reduce(
    (finalObject, filterKeyValuePair) => {
      const [filterKey, filterValue] = filterKeyValuePair;
      if (Array.isArray(filterValue)) {
        const localizedValues = filterValue.map((individualValue) => {
          return locale?.[individualValue] ?? individualValue;
        });

        return {
          ...finalObject,
          [filterKey]: localizedValues,
        };
      }

      return {
        ...finalObject,
        [filterKey]: locale?.[filterValue] ?? filterValue,
      };
    },
    {},
  );

  return localizedFiltersObject;
};

export const equality = (
  filter: [key, value],
  complianceOverviewEntry: Object,
): boolean => {
  const [filterKey, filterValue] = filter ?? ['', ''];
  const currentValue = complianceOverviewEntry?.[filterKey];
  return currentValue === filterValue;
};

export const pastDue = (
  filter: [key, value],
  complianceOverviewEntry: { voucherEffectiveDate?: string },
): boolean => {
  return moment().isSameOrAfter(
    moment(complianceOverviewEntry?.voucherEffectiveDate ?? ''),
  );
};

export const isStatusUpdateRequired = (
  complianceOverviewReviewedAndNumberOfDays,
  complianceType,
  voucherEffectiveDate,
  complianceApprovalStatus,
  documentUploadDate,
  lateDate,
) => {
  return complianceOverviewReviewedAndNumberOfDays &&
    complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS
    ? moment(voucherEffectiveDate).hours(14).isBefore(moment().hours(14))
    : [
        COMPLIANCE_APPROVAL_STATUSES.PENDING,
        COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL,
        null,
      ].includes(complianceApprovalStatus) &&
        documentUploadDate &&
        documentUploadDate.isBefore(lateDate);
};

export const attentionRequired = (
  filter: [key, value],
  complianceOverviewEntry,
): boolean => {
  const {
    voucherEffectiveDate,
    complianceApprovalStatus,
    certificationType,
    uploadDate,
    isReviewed,
  } = complianceOverviewEntry ?? {};
  const documentUploadDate = moment(uploadDate ?? '');
  const isRecert = certificationType === 'Recert';
  const recertLate = moment().hour(14).businessSubtract(7, 'days');
  const moveInLate = moment().hour(14).businessSubtract(2, 'days');
  const lateDate = isRecert ? recertLate : moveInLate;
  return (
    !isStatusUpdateRequired(
      true,
      COMPLIANCE_TYPES.ALL_OPEN_CERTS,
      voucherEffectiveDate,
      complianceApprovalStatus,
      documentUploadDate,
      lateDate,
    ) && !isReviewed
  );
};

export const regexComparison = (
  filterKeyValuePair: [key, value],
  complianceOverviewEntry: Object,
  regex?: RegExp,
): boolean => {
  const [filterKey] = filterKeyValuePair ?? [];
  const currentValue = complianceOverviewEntry?.[filterKey] ?? '';
  return (regex ?? /.*/).test(currentValue);
};

export const getComparisonStrategiesMap = (complianceType?: string) => {
  const baseStrategies = {
    finalSignedPacket: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /^Compliance Final Signed Packet$/gi,
      ),
    hudFinalSignedPacket: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /HUD Compliance Final Signed Packet/gi,
      ),
    hudReviewedBy: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /HUD Reviewed By .+/gi,
      ),
    hudSubmission: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /^HUD Compliance Submission Packet #\d{1,3}$/gi,
      ),
    reviewedBy: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /^Reviewed By .+/gi,
      ),
    submission: (filterKeyValuePair, complianceOverviewEntry) =>
      regexComparison(
        filterKeyValuePair,
        complianceOverviewEntry,
        /^Compliance Submission Packet #\d{1,3}$/gi,
      ),
    noDocumentUploaded: (filterKeyValuePair, complianceOverviewEntry) => {
      const [filterKey] = filterKeyValuePair;
      const currentValue = complianceOverviewEntry?.[filterKey];
      return currentValue === null;
    },
    none: (filterKeyValuePair, complianceOverviewEntry) => {
      const [filterKey] = filterKeyValuePair;
      const currentValue = complianceOverviewEntry?.[filterKey];
      return currentValue === null;
    },
    pastDue,
    equality,
  };
  const strategiesBasedOnComplianceType = Object.freeze({
    [COMPLIANCE_TYPES.ALL_OPEN_CERTS]: {
      ...baseStrategies,
      attentionRequired,
    },
    default: baseStrategies,
  });

  return (
    strategiesBasedOnComplianceType?.[complianceType] ??
    strategiesBasedOnComplianceType.default
  );
};

export const getComparisonStrategy = (
  filterValue?: any,
  complianceType?: string,
): Function => {
  const strategiesMap = getComparisonStrategiesMap(complianceType);
  const fallbackComparison = strategiesMap.equality;

  return strategiesMap?.[filterValue] ?? fallbackComparison;
};

// This function is to be run as a parameter to Array.reduce
export const evaluateFiltersAgainstEntries = (
  finalEntries: Array<Object>,
  filterKeyValuePair: [key, value],
  complianceType?: string,
): boolean => {
  return (finalEntries ?? []).filter((complianceOverviewEntry) => {
    const [filterKey, filterValue] = filterKeyValuePair ?? [];

    if (Array.isArray(filterValue)) {
      return filterValue.some((individualValue) => {
        const comparison = getComparisonStrategy(
          individualValue,
          complianceType,
        );
        return comparison(
          [filterKey, individualValue],
          complianceOverviewEntry,
        );
      });
    }

    const comparison = getComparisonStrategy(filterValue, complianceType);

    return comparison(filterKeyValuePair, complianceOverviewEntry);
  });
};

export const newFilterStyleParseAndFilterEntries = (
  entries: Array<Object>,
  currentSorting: Object,
  filtersObject: Object,
  searchText: string,
  complianceType?: string,
  locale?: {
    [key: string]: string,
  },
): Array<Object> => {
  const { fieldName, order } = currentSorting ?? {};

  const adaptedFiltersObject = adaptFiltersObject(filtersObject, locale);
  const filters = Object.entries(adaptedFiltersObject);

  const filteredEntries = filters.reduce(
    (finalEntries, filterKeyValuePair) =>
      evaluateFiltersAgainstEntries(
        finalEntries,
        filterKeyValuePair,
        complianceType,
      ),
    entries,
  );

  return sortAndFilterRows(filteredEntries, fieldName, order, searchText);
};

export const determineLateDate = (isRecert, complianceDocumentType) => {
  const isFinalDocument =
    complianceDocumentType && complianceDocumentType.includes('Final');
  if (isFinalDocument) {
    return moment().hour(14).businessSubtract(4, 'days');
  }
  const recertLate = moment().hour(14).businessSubtract(7, 'days');
  const moveInLate = moment().hour(14).businessSubtract(2, 'days');
  return isRecert ? recertLate : moveInLate;
};

export const buildRows = (
  complianceOverviewData: Array<Object>,
  complianceType: string,
  currentSorting: Object,
  hasFilters: boolean,
  filter: Object,
  searchText: string,
  onViewCertHistory: Function,
  flags?: Object,
  locale?: {
    [key: string]: string,
  },
): Array<Object> => {
  const complianceOverviewReviewedAndNumberOfDays =
    flags?.complianceOverviewReviewedAndNumberOfDays ?? false;
  const complianceCertHistorySummary =
    flags?.complianceCertHistorySummary ?? false;
  const newFilterStyleCompliance = flags?.newFilterStyleCompliance ?? false;

  const rowEntries = newFilterStyleCompliance
    ? newFilterStyleParseAndFilterEntries(
        complianceOverviewData,
        currentSorting,
        filter,
        searchText,
        complianceType,
        locale,
      )
    : parseComplianceOverviewEntries(
        complianceOverviewData,
        currentSorting,
        hasFilters,
        filter,
        searchText,
      );
  if (!rowEntries) return [];

  const rows = rowEntries.map((row, i) => {
    const {
      id,
      applicationId,
      applicantName,
      uploadDate,
      complianceDocumentType,
      programType,
      isResident,
      propertyName,
      residentId,
      complianceApprovalStatus,
      isActive,
      certificationType,
      unitNumber,
      voucherEffectiveDate,
      isReviewed,
      oldestSubmissionDocumentDate,
      certOpenedDate,
      propertyId,
    } = row;
    const isRecert = certificationType === 'Recert';
    const documentUploadDate = uploadDate ? moment(uploadDate) : '';
    const lateDate = determineLateDate(isRecert, complianceDocumentType);
    let customerType = 'application';
    if (certificationType === 'Move-out') {
      customerType = 'prior-resident';
    } else if (isResident === 'Yes') {
      customerType = 'resident';
    }
    const customerId = isResident === 'Yes' ? residentId : applicationId;
    const rowId = customerId || id;
    const customerUrl = `/property/${propertyId}/${customerType}/${customerId}`;
    const error = isStatusUpdateRequired(
      complianceOverviewReviewedAndNumberOfDays,
      complianceType,
      voucherEffectiveDate,
      complianceApprovalStatus,
      documentUploadDate,
      lateDate,
    );

    const needsReview =
      !complianceOverviewReviewedAndNumberOfDays ||
      complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
        ? undefined
        : !isReviewed;

    const certHistory = {
      applicantName,
      oldestSubmissionDocumentDate,
      certOpenedDate,
    };

    switch (complianceType) {
      case COMPLIANCE_TYPES.NEEDS_REVIEW:
        return {
          id: rowId,
          error,
          needsReview,
          certHistory,
          columns: [
            i + 1,
            certificationType,
            propertyName,
            unitNumber,
            <a href={customerUrl} target="_blank" rel="noreferrer">
              {applicantName}
            </a>,
            complianceDocumentType,
            documentUploadDate
              ? documentUploadDate.format('MM/DD/YYYY hh:mm A')
              : '',
            programType,
            voucherEffectiveDate
              ? moment(voucherEffectiveDate).format('MM/DD/YYYY')
              : '',
            complianceApprovalStatus,
            isActive,
            isResident,
          ],
        };
      default:
        return {
          id: rowId,
          error,
          needsReview,
          certHistory,
          columns: [
            i + 1,
            certificationType,
            propertyName,
            unitNumber,
            <a href={customerUrl} target="_blank" rel="noreferrer">
              {applicantName}
            </a>,
            complianceDocumentType,
            documentUploadDate
              ? documentUploadDate.format('MM/DD/YYYY hh:mm A')
              : '',
            ...(complianceOverviewReviewedAndNumberOfDays
              ? [isReviewed ? 'Yes' : 'No']
              : []),
            programType,
            voucherEffectiveDate
              ? moment(voucherEffectiveDate).format('MM/DD/YYYY')
              : '',
            ...(complianceCertHistorySummary
              ? [
                  <button
                    id={`onViewCertHistory-${id}`}
                    className={cn('btn btn-text btn-text--small text-right')}
                    onClick={() => onViewCertHistory(certHistory)}
                  >
                    <FormattedMessage {...messages.view} />
                  </button>,
                ]
              : []),
            complianceApprovalStatus,
            isActive,
            isResident,
          ],
        };
    }
  });
  return rows;
};

export function getHeaders(complianceType: string) {
  switch (complianceType) {
    case COMPLIANCE_TYPES.NEEDS_REVIEW:
      return HEADER_ROWS_NEEDS_REVIEW;
    case COMPLIANCE_TYPES.ALL_OPEN_CERTS:
      return HEADER_ROWS;
    default:
      return HEADER_ROWS;
  }
}

export function queryBasedOnComplianceType(
  complianceType,
  orgId,
  aqdService,
  kpiService,
) {
  switch (complianceType) {
    case COMPLIANCE_TYPES.NEEDS_REVIEW:
      return aqdService.getComplianceDocumentsNeedsReview(orgId);
    case COMPLIANCE_TYPES.ALL_OPEN_CERTS:
      return kpiService.getComplianceOverviewOpenCerts(orgId);
    default:
      return new Promise((res) => res([]));
  }
}

export const handleDownload = async ({
  complianceType,
  fileType,
  organizationId,
  searchText,
  currentFilter,
  currentSorting,
  promptToaster,
  intl,
}: Object) => {
  try {
    const reportService = new ReportService();
    const response = await reportService.downloadComplianceOverviewReport(
      organizationId,
      complianceType,
      fileType,
      searchText,
      currentFilter,
      currentSorting,
    );
    const fileName = `${intl.formatMessage(messages.compliance)}_${(!isNil(
      messages[complianceType],
    )
      ? intl.formatMessage(messages[complianceType])
      : ''
    ).replace(/\s/g, '_')}_${moment().format('YYYYMMDD')}.${fileType}`;
    FileSaver.saveAs(response, fileName);
  } catch {
    promptToaster({
      type: 'error',
      message: intl.formatMessage(messages.failedDocumentDownload),
      title: intl.formatMessage(messages.errorDownloading),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};

export const cleanupFilters = (filters: Object): Object => {
  const filterKeys = Object.keys(filters);
  const filter = filterKeys.reduce((acc, key) => {
    const filterValue = filters[key];
    const defaultKeys = Object.keys(filterValue).filter(
      (filterValueKey) => !filterValue[filterValueKey],
    );
    if (key !== 'waitlistApplicant') {
      const newFilter = omit(defaultKeys, filterValue);
      return isEmpty(newFilter) ? acc : { ...acc, [key]: { ...newFilter } };
    } else {
      return !isEmpty(defaultKeys)
        ? { ...acc, [key]: { ...filterValue } }
        : acc;
    }
  }, {});

  return filter;
};

export const adaptFiltersForBackendCall = (filtersObject) => {
  const entries = Object.entries(filtersObject ?? {});
  return entries.reduce((finalObject, entry) => {
    const [key, rawValue] = entry;
    const value = Array.isArray(rawValue) ? rawValue : [rawValue];
    const descriptorObject = value.reduce((finalDescriptor, value) => {
      return {
        ...finalDescriptor,
        [value]: true,
      };
    }, {});
    return { ...finalObject, [key]: descriptorObject };
  }, {});
};
