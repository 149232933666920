import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  snapshot: {
    id: 'App.ApplicationProfile.Snapshot.Snapshot',
    defaultMessage: 'Snapshot',
  },
  assignedTo: {
    id: 'App.ApplicationProfile.Snapshot.AssignedTo',
    defaultMessage: 'Assigned to: ',
  },
  unitNumber: {
    id: 'App.ApplicationProfile.Snapshot.unitNumber',
    defaultMessage: 'Unit #: ',
  },
  notAssigned: {
    id: 'App.ApplicationProfile.Snapshot.notAssigned',
    defaultMessage: 'Not Assigned',
  },
  doNotAccept: {
    id: 'App.ApplicationProfile.Snapshot.doNotAccept',
    defaultMessage: 'Do Not Accept',
  },
  certifiedOnly: {
    id: 'App.ApplicationProfile.Snapshot.certifiedOnly',
    defaultMessage: 'Certified Only',
  },
  scheduledMoveInDate: {
    id: 'App.ApplicationProfile.Snapshot.scheduledMoveInDate',
    defaultMessage: 'Scheduled Move-in date: ',
  },
  desiredMoveInDate: {
    id: 'App.ApplicationProfile.Snapshot.desiredMoveInDate',
    defaultMessage: 'Desired Move-in date: ',
  },
  lastActivity: {
    id: 'App.ApplicationProfile.Snapshot.lastActivity',
    defaultMessage: 'Last Activity: ',
  },
  conversionDate: {
    id: 'App.ApplicationProfile.Snapshot.conversionDate',
    defaultMessage: 'Applicant conversion date: ',
  },
  convertToProspect: {
    id: 'App.ApplicationProfile.Snapshot.convertToProspect',
    defaultMessage: 'Convert to Prospect',
  },
  convertToResident: {
    id: 'App.ApplicationProfile.Snapshot.convertToResident',
    defaultMessage: 'Convert to Resident',
  },
  convertToCommercialTenant: {
    id: 'App.ApplicationProfile.Snapshot.convertToCommercialTenant',
    defaultMessage: 'Convert to Tenant',
  },
  assignUnit: {
    id: 'App.ApplicationProfile.Snapshot.assignUnit',
    defaultMessage: 'Assign Unit',
  },
  assignUnitDisabled: {
    id: 'App.ApplicationProfile.Snapshot.assignUnitDisabled',
    defaultMessage:
      'You cannot assign a unit until the application fees have been paid.',
  },
  unAssignUnit: {
    id: 'App.ApplicationProfile.Snapshot.UnAssignUnit',
    defaultMessage: 'Unassign Unit',
  },
  provideQuote: {
    id: 'App.ApplicationProfile.Snapshot.provideQuote',
    defaultMessage: 'Provide Quote',
  },
  confirmUnassignUnit: {
    id: 'App.ApplicationProfile.Snapshot.confirmUnassignUnit',
    defaultMessage:
      'Are you sure you want to unassign this Unit from Applicant:',
  },
  confirmUnassignUnitWithCompletedCertifications: {
    id: 'App.ApplicationProfile.Snapshot.confirmUnassignUnitWithCompletedCertifications',
    defaultMessage:
      'This action will affect a completed certification for this household. Confirming this action will:{br}1. Reopen the certification{br}2. Change the Compliance Approval Status to “Correction Needed”{br}3. Require a new document to be generated and uploaded to reflect the changed information.{br}{br}Do you want to continue?',
  },
  cancelElectronic: {
    id: 'App.ApplicationProfile.Snapshot.CancelElectronic',
    defaultMessage:
      'Unassigning this Unit will remove the lease from Portal and the Documents Tab and render the previously executed lease and signatures invalid.',
  },
  cancelManual: {
    id: 'App.ApplicationProfile.Snapshot.CancelManual',
    defaultMessage:
      'Unassigning this Unit will render the previously executed lease and signatures invalid, and remove the lease from the Documents Tab.',
  },
  confirmEditElectronicLease: {
    id: 'App.ApplicationProfile.Snapshot.ConfirmEditElectronicLease',
    defaultMessage:
      'Unassigning this Unit will remove the lease from Portal and remove any signatures. Are you sure you want to continue?',
  },
  confirmHouseholdRentableItemsRemoval: {
    id: 'App.ApplicationProfile.Snapshot.ConfirmHouseholdRentableItemsRemoval',
    defaultMessage:
      'Unassigning this unit will automatically remove any rentable items that have been added to this household.',
  },
  rentStartDate: {
    id: 'App.ApplicationProfile.Snapshot.RentStartDate',
    defaultMessage: 'Rent Start Date:',
  },
  warning: {
    id: 'App.ApplicationProfile.Snapshot.Warning',
    defaultMessage: 'Warning',
  },
  unassignDisabled: {
    id: 'App.ApplicationProfile.Snapshot.UnassignDisabled',
    defaultMessage:
      'Compliance Approval Status prevents un-assigning this unit',
  },
});

export default messages;
