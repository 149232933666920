import React, { useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageRentRoll } from './hooksV2';
import UnitSpecials from '../../components/UnitSpecials/indexV2';
import TableLayout from '../../components/TableLayout';

const NAME = 'manage-rent-roll';
const ManageRentRoll = ({
  intl: injectedIntl,
  selectedProperty,
  specialsForm,
}) => {
  const intl = useMemo(() => injectedIntl, [injectedIntl]);

  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    editMode,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleSubmitSpecial,
    handleToggleEditMode,
    isLoading,
    isPropertySpecialsLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    propertySpecial,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = useManageRentRoll({ selectedProperty, specialsForm, NAME, intl });

  return (
    <DocumentTitle data-testid={'manage-rent-roll'} title={'Manage Rent Roll'}>
      <TableLayout name={name}>
        {(tableHeight) => (
          <>
            <TableHeader
              title={'Manage Rent Roll'}
              count={count}
              totalCount={totalCount}
            />
            <UnitSpecials
              editMode={editMode}
              intl={intl}
              special={propertySpecial}
              handleSubmitSpecials={handleSubmitSpecial}
              handleToggleEditMode={handleToggleEditMode}
              isLoading={isPropertySpecialsLoading}
            />
            <VirtualizedTable
              ReactBeautifulDnD={{
                DragDropContext,
                Droppable,
                Draggable,
              }}
              ReactRouterLink={ReactRouterLink}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              count={count}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              footerRow={footerRow}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              headers={filteredHeaders}
              height={tableHeight}
              intl={intl}
              isLoading={isLoading}
              name={name}
              onCSVButtonClick={onCSVButtonClick}
              order={order}
              orderBy={orderBy}
              rows={rows}
              searchState={searchState}
              selectedColumns={selectedColumns}
              sx={{ paddingTop: 1 }}
            />
          </>
        )}
      </TableLayout>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app }: Object): Object => {
  return {
    organizationId: app.currentUser?.user?.organizationId || '',
    selectedProperty: app.selectedProperty,
  };
};
export default connect(mapStateToProps, null)(injectIntl(ManageRentRoll));
