import { defineMessages } from 'react-intl';

const messages = defineMessages({
  basicDetails: {
    id: 'App.BasicDetails.basicDetails',
    defaultMessage: 'Basic Details',
  },
  propertyName: {
    id: 'App.BasicDetails.propertyName',
    defaultMessage: 'Property Name',
  },
  shortName: {
    id: 'App.BasicDetails.shortName',
    defaultMessage: 'Short Name',
  },
  email: {
    id: 'App.BasicDetails.email',
    defaultMessage: 'Email',
  },
  propertyId: {
    id: 'App.BasicDetails.propertyId',
    defaultMessage: 'Property ID',
  },
  phone: {
    id: 'App.BasicDetails.phone',
    defaultMessage: 'Phone',
  },
  textingPhoneNumber: {
    id: 'App.BasicDetails.TextingPhoneNumber',
    defaultMessage: 'Texting Phone Number',
  },
  missingConfiguration: {
    id: 'App.BasicDetails.MissingConfiguration',
    defaultMessage: 'Missing configuration',
  },
  propertyType: {
    id: 'App.BasicDetails.propertyType',
    defaultMessage: 'Property Type',
  },
  fax: {
    id: 'App.BasicDetails.fax',
    defaultMessage: 'Fax',
  },
  status: {
    id: 'App.BasicDetails.status',
    defaultMessage: 'Status',
  },
  address: {
    id: 'App.BasicDetails.address',
    defaultMessage: 'Address',
  },
  owner: {
    id: 'App.BasicDetails.owner',
    defaultMessage: 'Owner',
  },
  legalEntity: {
    id: 'App.BasicDetails.legalEntity',
    defaultMessage: 'Legal Entity',
  },
  portalActive: {
    id: 'App.BasicDetails.portalActive',
    defaultMessage: 'Portal Active',
  },
  currentFinancialPeriod: {
    id: 'App.BasicDetails.currentFinancialPeriod',
    defaultMessage: 'Current Financial Period',
  },
  links: {
    id: 'App.BasicDetails.links',
    defaultMessage: 'Links',
  },
  required: {
    id: 'App.BasicDetails.Required',
    defaultMessage: 'Field value is required.',
  },
  invalidEmailAddress: {
    id: 'App.BasicDetails.InvalidEmailAddress',
    defaultMessage: 'Invalid email address',
  },
  invalidPhoneNumber: {
    id: 'App.BasicDetails.InvalidPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
  invalidZipCode: {
    id: 'App.BasicDetails.InvalidZipCode',
    defaultMessage: 'Invalid Zip Code / Postal Code',
  },
  gpr: {
    id: 'App.BasicDetails.GPR',
    defaultMessage: 'GPR',
  },
  success: {
    id: 'App.ViewProperty.GPRModal.Update.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ViewProperty.GPRModal.Update.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id: 'App.ViewProperty.GPRModal.Update.successBody',
    defaultMessage: 'MST Type updated',
  },
  errorBody: {
    id: 'App.ViewProperty.GPRModal.Update.errorBody',
    defaultMessage: 'Error updating MST Type',
  },
});

export default messages;
