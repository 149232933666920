import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { useFlags } from 'launchdarkly-react-client-sdk';
import messages from './messages';
import ColumnHeader from '../../../components/ColumnHeader';
import DocumentsDetails from '../DocumentsDetails';
import PaginationFooter from '../../../components/PaginationFooter';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

import type { Document } from '../types';
import type { FeatureFlags } from '../../../types';

type Props = {
  intl: any,
  documents: Array<Document>,
  columnOrder: Object,
  pageCount: number,
  currentPage: number,
  limit: number,
  count: number,
  totalCount: number,
  onPageChange: Function,
  onOrderClick: Function,
  onDeleteClick: Function,
  showUploader: Function,
  handleDownloadDocument: Function,
  onAddLetterClick: Function,
  customerType: string,
  deletedApplicants: Array<any>,
  isAllCommercial: boolean,
};

const DocumentsTable = ({
  intl,
  showUploader,
  columnOrder,
  documents,
  pageCount,
  currentPage,
  limit,
  count,
  totalCount,
  onPageChange,
  onOrderClick,
  onDeleteClick,
  handleDownloadDocument,
  onAddLetterClick,
  customerType,
  deletedApplicants,
  isAllCommercial,
}: Props) => {
  const { uploadDocumentsInPortal }: FeatureFlags = useFlags();

  return (
    <Grid className="bodywrap" fluid>
      <Row className="container-fluid ledger-head">
        <div className="col-xs-12 col-sm-4 col-lg-5">
          <h2>{intl.formatMessage(messages.header)}</h2>
        </div>
        <div className="col-xs-12 col-sm-8 col-lg-7">
          <div className="button-group pull-right">
            {customerType === 'resident' ? (
              <ElementWithPermissions scope={['generate-resident-letter']}>
                <button
                  className="btn btn-callto padright10"
                  onClick={onAddLetterClick}
                >
                  {isAllCommercial
                    ? intl.formatMessage(messages.createTenantLetter)
                    : intl.formatMessage(messages.createResidentLetter)}
                </button>
              </ElementWithPermissions>
            ) : null}
            <ElementWithPermissions scope={['document-create']}>
              <button className="btn btn-shout" onClick={showUploader}>
                <i className="icon et-upload" />
                {intl.formatMessage(messages.uploadFile)}
              </button>
            </ElementWithPermissions>
          </div>
        </div>
      </Row>
      <Row>
        <Col xs={12} id="table-row">
          <table
            className="table table-prospects"
            style={{
              tableLayout: 'fixed',
            }}
          >
            <thead className="table-header">
              <tr>
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.date)}
                  sortable={true}
                  icon={columnOrder.createdAt}
                  onOrderClick={onOrderClick('createdAt')}
                />
                {uploadDocumentsInPortal && (
                  <ColumnHeader
                    columnLabel={intl.formatMessage(
                      messages.wasUploadedFromPortal,
                    )}
                    sortable={true}
                    icon={columnOrder.wasUploadedFromPortal}
                    onOrderClick={onOrderClick('wasUploadedFromPortal')}
                    style={{
                      width: '22px',
                    }}
                  />
                )}
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.documentType)}
                  sortable={true}
                  icon={columnOrder.documentTypeId}
                  onOrderClick={onOrderClick('documentTypeId')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.householdMember)}
                  sortable={true}
                  icon={columnOrder.applicantId}
                  onOrderClick={onOrderClick('applicantId')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.documentName)}
                  sortable={true}
                  icon={columnOrder.name}
                  onOrderClick={onOrderClick('name')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.notes)}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.actions)}
                  hasTwoButtons={true}
                />
              </tr>
            </thead>
            <DocumentsDetails
              intl={intl}
              documents={documents}
              onDeleteClick={onDeleteClick}
              handleDownloadDocument={handleDownloadDocument}
              deletedApplicants={deletedApplicants}
            />
          </table>
        </Col>
      </Row>
      {pageCount > 1 && (
        <PaginationFooter
          currentPage={currentPage}
          limit={limit}
          count={count}
          totalCount={totalCount}
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      )}
      {uploadDocumentsInPortal && (
        <div className="row legend-simple" style={{ paddingLeft: 10 }}>
          <p>{intl.formatMessage(messages.wasUploadedFromPortalLegend)}</p>
        </div>
      )}
    </Grid>
  );
};

export default DocumentsTable;
