import { useState, useMemo, useEffect } from 'react';
import { useQuery, useInfiniteQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import isNil from 'lodash/isNil';
import { Props } from './types';
import {
  fetchHouseholdMembers,
  fetchConversations,
  fetchMessages,
  sendMessage,
  updateConversation,
} from './data';
import intlMessages from './messages';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import { getTextingPhoneNumber } from '../../utils/property-helpers';
import { NEW_CONV_SUFFIX } from './constants';
import {
  CONVERSATIONS_CHANGED_EVENT,
  CONVERSATION_STATUS_CHANGED_EVENT,
} from './customEvents';
import useCustomEvent from '../../hooks/useCustomEvent';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function useTextingTab({ prospectId, householdId, intl }: Props) {
  const selectedProperty = useSelectedProperty();
  const [selectedConversationId, setSelectedConversationId] = useState();
  const {
    conversations,
    messages,
    isLoading,
    refreshMessages,
    refreshConversations,
    loadMoreMessages,
    hasMoreMessages,
  } = useTabData({
    prospectId,
    householdId,
    intl,
    selectedConversationId,
    setSelectedConversationId,
  });
  const textingPhoneNumber = useMemo(
    () => getTextingPhoneNumber(selectedProperty),
    [selectedProperty],
  );
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const { emit: emitConversationsChanged } = useCustomEvent({
    eventKey: CONVERSATIONS_CHANGED_EVENT,
  });
  const { emit: emitConversationStatusChanged } = useCustomEvent({
    eventKey: CONVERSATION_STATUS_CHANGED_EVENT,
  });

  const recipientCategory = useMemo(() => {
    const url = window.location.href;
    if (url.includes('prospect')) return 'prospects';
    if (url.includes('application')) return 'applicants';
    if (url.includes('prior-resident')) return 'priorResidents';
    if (url.includes('resident')) return 'residents';
  }, []);

  useEffect(() => {
    emitConversationsChanged(conversations);
  }, [conversations, emitConversationsChanged]);

  function handleLoadMoreMessages() {
    loadMoreMessages();
  }

  function handleSelectConversation(conversationId) {
    setSelectedConversationId(conversationId);

    const conv = conversations.find((c) => c.id === conversationId);

    if (conv.status !== 'new-message' || conv.id.includes(NEW_CONV_SUFFIX))
      return;

    updateConversation(conversationId, {
      conversationStatus: 'UNRESOLVED',
      propertyId: selectedProperty.id,
    }).then(() => {
      refreshConversations();
      emitConversationStatusChanged({
        conversationStatus: 'UNRESOLVED',
        recipientCategory,
      });
    });
  }

  function handleMessageChange(value) {
    setMessage(value);
  }

  async function handleSendMessage(message: string) {
    setIsSending(true);

    const createdMessage = await sendMessage({
      message,
      conversation: conversations.find((c) => c.id === selectedConversationId),
    });

    await refreshConversations();
    await refreshMessages();

    setMessage('');
    setIsSending(false);
    setSelectedConversationId(createdMessage.conversationId);
  }

  function handleToggleResolved() {
    const conv = conversations.find((c) => c.id === selectedConversationId);
    const isResolved = conv.status === 'resolved';
    const conversationStatus = isResolved ? 'UNRESOLVED' : 'RESOLVED';

    updateConversation(selectedConversationId, {
      conversationStatus,
      propertyId: selectedProperty.id,
    }).then(() => {
      refreshConversations();
      emitConversationStatusChanged({ conversationStatus, recipientCategory });
    });
  }

  return {
    conversations,
    messages,
    hasMoreMessages,
    selectedConversationId,
    message,
    isSending,
    textingPhoneNumber,
    isLoading,
    handleLoadMoreMessages,
    handleSelectConversation,
    handleMessageChange,
    handleSendMessage,
    handleToggleResolved,
  };
}

function useTabData({
  prospectId,
  householdId,
  intl,
  selectedConversationId,
  setSelectedConversationId,
}) {
  const {
    conversations,
    isLoading: isConversationsLoading,
    refreshConversations,
  } = useConversations({
    householdId,
    intl,
    prospectId,
    selectedConversationId,
    setSelectedConversationId,
  });
  const selectedConversation = useMemo(
    () =>
      conversations &&
      conversations.find((c) => c.id === selectedConversationId),
    [conversations, selectedConversationId],
  );
  const { messages, refreshMessages, loadMoreMessages, hasMoreMessages } =
    useMessages({
      selectedConversation,
      intl,
    });
  const isLoading = isConversationsLoading;

  return {
    conversations,
    messages,
    isLoading,
    refreshMessages,
    refreshConversations,
    loadMoreMessages,
    hasMoreMessages,
  };
}

function useHouseholdMembers({ prospectId, householdId, intl }) {
  const selectedProperty = useSelectedProperty();
  const { isLoading, data: householdMembers } = useQuery(
    ['householdMembers', prospectId, householdId],
    () => fetchHouseholdMembers({ prospectId, householdId, selectedProperty }),
    {
      onError: (error) => {
        toastr.error(
          intl.formatMessage(intlMessages.errorHeader),
          intl.formatMessage(intlMessages.failedToLoadHouseholdMembersErrorMsg),
        );
      },
    },
  );

  return {
    householdMembers,
    isLoading,
  };
}

function useConversations({
  prospectId,
  householdId,
  intl,
  selectedConversationId,
  setSelectedConversationId,
}) {
  const { householdMembers, isLoading: isHouseholdMembersLoading } =
    useHouseholdMembers({ prospectId, householdId, intl });
  const {
    data: conversations,
    isLoading: isConversationsLoading,
    refetch: refreshConversations,
  } = useQuery(
    ['conversations', householdMembers],
    () => fetchConversations({ householdMembers }),
    {
      enabled: !isHouseholdMembersLoading,
      onSuccess: (conversations) => {
        if (!selectedConversationId)
          setSelectedConversationId(conversations[0].id);
      },
      onError: () => {
        toastr.error(
          intl.formatMessage(intlMessages.errorHeader),
          intl.formatMessage(intlMessages.failedToLoadConversationsErrorMsg),
        );
      },
    },
  );
  const isLoading = isHouseholdMembersLoading || isConversationsLoading;

  return {
    conversations,
    isLoading,
    refreshConversations,
  };
}

function useMessages({ selectedConversation, intl }) {
  const { twoWayTextingDisplayFortressUsername, twoWayTextingAcceptImages } =
    useFlags();
  const selectedProperty = useSelectedProperty();
  const {
    data,
    isFetching,
    isFetchingPreviousPage,
    fetchPreviousPage: loadMoreMessages,
    refetch: refreshMessages,
    hasPreviousPage,
  } = useInfiniteQuery({
    enabled: !isNil(selectedConversation),
    initialData: { pages: [] },
    queryKey: ['messages', selectedConversation, selectedProperty],
    queryFn: ({ pageParam }) =>
      fetchMessages({
        conversation: selectedConversation,
        selectedProperty,
        cursor: pageParam,
        includeUser: twoWayTextingDisplayFortressUsername,
        includeAttachments: twoWayTextingAcceptImages,
      }),
    getPreviousPageParam: (oldestPage, pages) => {
      const oldestMessage = oldestPage?.[0];
      return oldestMessage ? `before__${oldestMessage.id}` : null;
    },
    refetchInterval: 60000,
  });

  return {
    messages: data.pages.flat(),
    isLoading: isFetching,
    isLoadingMore: isFetchingPreviousPage,
    refreshMessages,
    loadMoreMessages,
    hasMoreMessages: hasPreviousPage,
  };
}
