import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { CONVERSATIONS_CHANGED_EVENT } from '../TextingTab/customEvents';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useCustomEvent from '../../hooks/useCustomEvent';
import { fetchConversations } from './data';

export function useHasIndicator({ householdId, prospectId }) {
  const selectedProperty = useSelectedProperty();
  const queryKey = ['conversations', householdId, prospectId, selectedProperty];
  const queryClient = useQueryClient();
  const { data } = useQuery(
    queryKey,
    () => fetchConversations({ householdId, prospectId, selectedProperty }),
    {
      staleTime: Infinity,
    },
  );
  const hasIndicator = useMemo(() => getHasIndicator(data), [data]);

  useCustomEvent({
    eventKey: CONVERSATIONS_CHANGED_EVENT,
    listener: ({ detail: conversations }) =>
      queryClient.setQueryData(queryKey, conversations),
  });

  return hasIndicator;
}

function getHasIndicator(conversations) {
  return (
    conversations &&
    conversations.some((c) => ['new-message', 'unresolved'].includes(c.status))
  );
}
