import React from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { pathOr } from 'ramda';
import {
  BACKGROUND_COLORS,
  HOVER_BACKGROUND_COLORS,
  BORDER_WIDTH,
  BAR_PERCENTAGE,
  STACKED_BAR_PADDING,
} from '../constants';

const BarGraph = ({
  data,
  horizontal,
  options,
  stacked,
  currency,
  barPercentage = BAR_PERCENTAGE,
  onElementsClick,
}: Object) => {
  const datasetsToStyle = pathOr([], ['datasets'], data);
  const defaultYLabel = (value) => (value % 1 === 0 ? value : null);
  const currencyLabel = (value) =>
    value % 1000 === 0 ? `$${value / 1000}K` : null;
  const datasets = datasetsToStyle.map((set, i) => {
    return {
      ...set,
      backgroundColor: BACKGROUND_COLORS[i],
      borderWidth: BORDER_WIDTH,
      hoverBackgroundColor: HOVER_BACKGROUND_COLORS[i],
    };
  });

  const xAxes = [
    {
      stacked,
      gridLines: {
        drawBorder: false,
        display: false,
      },
      barPercentage,
    },
  ];
  const yAxes = [
    {
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
        padding: stacked ? STACKED_BAR_PADDING : 0,
        callback: currency ? currencyLabel : defaultYLabel,
      },
      stacked,
      gridLines: {
        drawBorder: false,
      },
    },
  ];

  const axes = !horizontal ? { xAxes, yAxes } : { xAxes: yAxes, yAxes: xAxes };

  const props = {
    data: { ...data, datasets },
    options: {
      legend: {
        display: false,
      },
      scales: {
        display: false,
        ...axes,
      },
      ...options,
    },
    onElementsClick,
  };

  if (onElementsClick) {
    props.options.onHover = (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    };
  }

  const graph = !horizontal ? <Bar {...props} /> : <HorizontalBar {...props} />;
  return graph;
};

export default BarGraph;
