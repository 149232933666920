import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import {
  reduxForm,
  Field,
  getFormSyncErrors,
  getFormAsyncErrors,
  getFormValues,
} from 'redux-form';
import * as R from 'ramda';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';

import { getLeaseTermOptions } from '../../../../utils/lease-helpers.js';
import {
  renderDateField,
  renderSelectField,
  renderCurrencyField,
  renderSimpleCheckboxField,
  renderCheckboxField,
} from '../../../../utils/redux-form-helper';
import validate from './validate';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import messages from '../../LeaseDataTabForm/LeaseDataTabFormSections/LeaseBasics/messages';
import { asyncValidate } from '../asyncValidate';
import { withExpirationLimitsPropertyToggle } from '../../../../hooks/data-fetching/useExpirationLimitsPropertyToggle';
import { promptToaster } from '../../../App/actions';

type Props = {
  leaseTerms: Array<Object>,
  leaseRentPercentage: any,
  quotingRent: any,
  show: boolean,
  dismiss: Function,
  handleSubmit: Function,
  onSubmit: Function,
  pristine: boolean,
  valid: boolean,
  reset: Function,
  dirty: boolean,
  initialValues: Object,
  affordableValues: Object,
};

export const EditLeaseDataModal = (props: Props) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const cancelConfirmationToggle = (visible: boolean) => {
    setShowCancelConfirmation(visible);
  };

  const onClose = () => {
    const { dirty, reset, dismiss } = props;

    if (dirty && !showCancelConfirmation) {
      cancelConfirmationToggle(true);
      return;
    } else if (dirty && showCancelConfirmation) {
      reset();
    }
    cancelConfirmationToggle(false);
    dismiss();
  };

  const renderModalHeader = () => (
    <Modal.Header closeButton>
      <i className="icon et-pencil" />
      <Modal.Title componentClass="h1">Edit Fields</Modal.Title>
    </Modal.Header>
  );

  const renderModalFooter = () => {
    const { pristine, valid, handleSubmit, formSyncErrors } = props;
    const submitEnabled =
      pristine || (!pristine && valid && !formSyncErrors.endDate);

    return (
      <Modal.Footer className={showCancelConfirmation ? 'disabled' : ''}>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              onClick={handleSubmit}
              disabled={!submitEnabled}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const [endDateChanged, setEndDateChanged] = useState(false);
  const [isEndDateOverLimit, setIsEndDateOverLimit] = useState(
    Boolean(props.lease.overrideLeaseExpirationLimitUpdatedAt),
  );

  const [overLimitMap, setOverLimitMap] = useState({});

  useEffect(() => {
    if (props.formAsyncErrors?.editLeaseEndDate) {
      setOverLimitMap((p) => ({
        ...p,
        [moment(props.formValues.editLeaseEndDate).format('YYYY-MM')]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formAsyncErrors?.editLeaseEndDate]);

  useEffect(() => {
    if (
      props.formValues?.overrideLeaseExpirationLimit === false &&
      endDateChanged
    ) {
      setIsEndDateOverLimit(
        overLimitMap[
          moment(props.formValues.editLeaseEndDate).format('YYYY-MM')
        ] ?? false,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overLimitMap,
    props.formValues?.editLeaseEndDate,
    props.formValues?.overrideLeaseExpirationLimit,
  ]);

  const onChangeEndDate = (date) => {
    props.change('editLeaseEndDate', date);
    props.change('overrideLeaseExpirationLimit', false);
    setEndDateChanged(true);
  };

  useEffect(() => {
    setEndDateChanged(false);
  }, [props.lease.overrideLeaseExpirationLimitUpdatedAt]);

  const renderModalFormBody = () => {
    const { formSyncErrors, leaseTerms, affordableValues, flags, intl, lease } =
      props;
    const error = formSyncErrors.endDate;

    return (
      <Row className="scrollable-modal__content">
        <div className="container-fluid">
          <Row>
            <Col md={5} xs={12}>
              <label>Lease Term</label>
            </Col>
            <Col md={7} xs={12}>
              <div className="form-group">
                <Field
                  name="editLeaseTerm"
                  component={renderSelectField}
                  options={getLeaseTermOptions(leaseTerms)}
                  className={error && 'has-error'}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={5} xs={12}>
              <label>Lease Start Date</label>
            </Col>
            <Col md={7} xs={12}>
              <Field
                name="editLeaseStartDate"
                component={renderDateField}
                bsSize="md"
                className={error && 'has-error'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={5} xs={12}>
              <label>Lease End Date</label>
            </Col>
            <Col md={7} xs={12}>
              <Field
                name="editLeaseEndDate"
                component={renderDateField}
                bsSize="md"
                className={error && 'has-error'}
                onChange={onChangeEndDate}
              />
            </Col>
          </Row>
          {flags.leaseExpirationMgmtV1 && props.isLeaseExpirationLimitsActive && (
            <Row>
              <Col xs={5}></Col>
              <Col xs={7}>
                {!R.isNil(lease.overrideLeaseExpirationLimitUpdatedAt) &&
                  isEndDateOverLimit &&
                  !endDateChanged && (
                    <span className="text-gray--darker">
                      {intl.formatMessage(
                        messages.overrideLeaseExpirationLimitMessage,
                        {
                          ...lease.overrideLeaseExpirationLimitUpdatedBy,
                          date: moment(
                            lease.overrideLeaseExpirationLimitUpdatedAt,
                          ).format('MM/DD/YYYY'),
                        },
                      )}
                    </span>
                  )}
                <ElementWithPermissions
                  scope={['lease-expirations-limit-override']}
                >
                  <Field
                    name="overrideLeaseExpirationLimit"
                    component={renderCheckboxField}
                    label={intl.formatMessage(
                      messages.overrideLeaseExpirationLimitLabel,
                    )}
                    disabled={!isEndDateOverLimit}
                    aria-label="override-lease-expiration-limit"
                  />
                </ElementWithPermissions>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={5} xs={12}>
              <label>Lease Rent Amount</label>
            </Col>
            <Col md={7} xs={12}>
              <Field
                name="editLeaseRentAmount"
                component={renderCurrencyField}
                step="0.01"
              />
            </Col>
          </Row>
          {affordableValues.isAffordable && (
            <Row>
              <Col md={5} xs={12}>
                <label>Receives Housing Assistance</label>
              </Col>
              <Col md={7} xs={12}>
                <Field
                  name="editIsReceivingAssistance"
                  component={renderSimpleCheckboxField}
                  label="Yes"
                />
              </Col>
            </Row>
          )}
          {error && (
            <Row>
              <Col xs={12} className="has-error">
                {error}
              </Col>
            </Row>
          )}
        </div>
      </Row>
    );
  };

  const renderModalCancelBody = () => {
    return (
      <div className="modal-confirm">
        <Row>
          <Col xs={12}>
            <h1>Are you sure you want to leave without saving?</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Button bsStyle="primary" className="pull-right" onClick={onClose}>
              Yes
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              bsStyle="default"
              className="pull-left"
              onClick={() => cancelConfirmationToggle(false)}
            >
              No
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const { show } = props;

  return (
    <form>
      <Modal
        className="edit-lease-data"
        bsSize="sm"
        backdrop
        show={show}
        onHide={() => onClose()}
      >
        {renderModalHeader()}
        <Modal.Body>
          {renderModalFormBody()}
          {showCancelConfirmation && renderModalCancelBody()}
        </Modal.Body>
        {renderModalFooter()}
      </Modal>
    </form>
  );
};

export const mapStateToProps = (
  state: Object,
  { initialValues }: Object,
): Object => {
  return {
    selectedProperty: state.app.selectedProperty,
    initialValues,
    formSyncErrors: getFormSyncErrors('editLeaseDataForm')(state),
    formAsyncErrors: getFormAsyncErrors('editLeaseDataForm')(state),
    formValues: getFormValues('editLeaseDataForm')(state),
  };
};

const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
};

export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    injectIntl(
      withExpirationLimitsPropertyToggle(
        reduxForm({
          form: 'editLeaseDataForm',
          enableReinitialize: true,
          validate,
          asyncValidate: asyncValidate({
            endDate: 'editLeaseEndDate',
            overrideLeaseExpirationLimit: 'overrideLeaseExpirationLimit',
            startDate: 'editLeaseStartDate',
            leaseTermId: 'editLeaseTerm',
          }),
          asyncChangeFields: [
            'overrideLeaseExpirationLimit',
            'editLeaseEndDate',
          ],
          asyncBlurFields: ['overrideLeaseExpirationLimit', 'editLeaseEndDate'],
        })(EditLeaseDataModal),
      ),
    ),
  ),
);
