import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useCallback, useMemo } from 'react';
import FileSaver from 'file-saver';
import { parseRowValues } from './utils';
import { HEADERS } from './constants';
import { useFetchAllUnitAvailablity } from './useFetchAllUnitAvailability';
import UnitService from '../../services/unitService';
import moment from 'moment';
import {
  appendFilterTextToCSV,
  processDataToCSV,
} from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import useUniqueTableName from '../../hooks/useUniqueTableName';

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = { propertyOptions: [], statusOptions: [] };

    if (results) {
      results.forEach(({ property, unitStatus }) => {
        if (property?.name?.length) options.propertyOptions.push(property.name);
        if (unitStatus?.description?.length)
          options.statusOptions.push(unitStatus.description);
      });

      options.propertyOptions = Array.from(new Set(options.propertyOptions))
        .sort()
        .map((propertyName) => ({
          text: propertyName,
          value: propertyName?.toLowerCase(),
        }));
      options.statusOptions = Array.from(new Set(options.statusOptions))
        .sort()
        .map((statusName) => ({
          text: statusName,
          value: statusName?.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};

const useHeaders = ({ propertyOptions, statusOptions }) => {
  return useMemo(() => {
    return HEADERS.map((header) => {
      if (header.id === 'property') header.filterOptions = propertyOptions;
      if (header.id === 'status') header.filterOptions = statusOptions;
      return header;
    });
  }, [propertyOptions, statusOptions]);
};

const useAllUnitAvailability = ({ intl, currentUserId }) => {
  const name = useUniqueTableName('centralized-unit-availability');
  const { data, isLoading } = useFetchAllUnitAvailablity();

  const { propertyOptions, statusOptions } = useGetFilterOptions({
    results: data,
  });

  const headers = useHeaders({ propertyOptions, statusOptions });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'moveInReady',
    initialOrder: 'ASC',
  });

  const PROPERTY_PATH_MAP = {
    property: {
      path: 'property',
      dataType: 'string',
    },
    unit: {
      path: 'unit.number',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorPlan',
      dataType: 'string',
    },
    bedsBaths: {
      path: 'bedsBaths',
      dataType: 'string',
    },
    sqFeet: {
      path: 'sqFeet',
      dataType: 'number',
    },
    status: { path: 'status', dataType: 'string' },
    moveOut: {
      path: 'moveOut',
      dataType: 'date',
    },
    moveInReady: {
      path: 'moveInReady',
      dataType: 'date',
    },
    daysVacant: {
      path: 'daysVacant',
      dataType: 'number',
    },
    quotingRent: {
      path: 'quotingRent',
      dataType: 'number',
    },
    requiredUnitFees: {
      path: 'requiredUnitFees',
      dataType: 'number',
    },
  };

  const parsedData = useParseData({ data, intl });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedData,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const rows = useRows({ sortedAndFilteredResults, intl, currentUserId });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
  } = useTableManageColumns({
    name,
    headers: HEADERS,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    propertyName: name,
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    results: sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    columnOptions,
    count: rows?.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    headers: HEADERS,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount: data?.length ?? undefined,
    truncatedColumns,
    onCSVButtonClick,
  };
};

const generateQuote = async (unit, currentUserId) => {
  const unitId = unit.id;
  const applicantId = undefined; //payload.applicantId;
  const prospectId = undefined;
  const userId = currentUserId;
  const organizationId = unit?.property?.organizationId;
  const property = unit?.property;
  const unitService = new UnitService();
  const file = await unitService.getQuote(
    property.id,
    organizationId,
    unitId,
    userId,
    applicantId,
    prospectId,
  );
  await FileSaver.saveAs(
    file,
    `Quote-${property.name.replace(/[^a-zA-z0-9]+/gi, '-')}-${moment().format(
      'MM-DD-YYYY',
    )}.pdf`,
  );
};

export const useRows = ({ sortedAndFilteredResults, currentUserId }) => {
  return useMemo(
    () =>
      sortedAndFilteredResults?.map((row) => {
        return {
          property: {
            value: row.property,
          },
          unit: {
            variant: 'link',
            href: `/property/${row.unit.propertyId}/unit/${row.unit.id}?prev=available`,
            target: '_blank',
            value: row.unit.number,
          },
          floorPlan: {
            variant: 'link',
            href: `/property/${row.unit.propertyId}/floorplan/${row.floorPlanId}?prev=available`,
            target: '_blank',
            value: row.floorPlan,
          },
          bedsBaths: {
            value: row.bedsBaths,
          },
          sqFeet: {
            value: row.sqFeet,
            variant: 'number',
          },
          status: { value: row.status },
          moveOut: {
            variant: 'date',
            value: row.moveOut,
          },
          moveInReady: {
            variant: 'date',
            value: row.moveInReady,
          },
          notes: {
            variant: 'description',
            value: row.notes,
          },
          daysVacant: {
            variant: 'number',
            value: row.daysVacant,
          },
          quotingRent: {
            variant: 'currency',
            value: row.quotingRent,
          },
          requiredUnitFees: {
            variant: 'currency',
            value: row.requiredUnitFees,
          },
          actions: {
            variant: 'menu',
            name: 'actionsMenu',
            iconName: 'BoltIcon',
            options: [
              {
                text: 'Quote',
                value: row,
                onClick: () => {
                  generateQuote(row.unit, currentUserId);
                },
              },
            ],
          },
        };
      }),
    [sortedAndFilteredResults, currentUserId],
  );
};

export const useParseData = ({ data }) => {
  return useMemo(
    () =>
      data?.map((unit) => {
        const {
          property,
          floorPlan,
          floorPlanId,
          bedsBaths,
          notes,
          status,
          sqFeet,
          moveOut,
          moveInReady,
          daysVacant,
          quotingRent,
          requiredUnitFees,
        } = parseRowValues(unit);

        return {
          unit,
          property,
          floorPlan,
          floorPlanId,
          bedsBaths,
          notes,
          status,
          sqFeet,
          moveOut,
          moveInReady,
          daysVacant,
          quotingRent,
          requiredUnitFees,
          actions: '',
        };
      }),
    [data],
  );
};

const useCSVPDFExport = ({
  filteredHeaders,
  rows,
  propertyName,
  hasAnyFilters,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = [];
    for (const { label } of filteredHeaders) {
      if (label === 'Actions') continue;
      csvHeaders.push(
        typeof label === 'string' ? label : label.props?.defaultMessage,
      );
    }

    const csvRows = processDataToCSV({ rows, filteredHeaders });

    const csv = appendFilterTextToCSV({
      headers: csvHeaders.join(','),
      rows: csvRows,
      hasAnyFilters,
    });

    download(
      csv,
      `AllUnitAvailability_${propertyName?.split(' ').join('-')}_${formatDateDB(
        new Date(),
      )}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [filteredHeaders, rows, hasAnyFilters, propertyName]);

  return { onCSVButtonClick };
};

export default useAllUnitAvailability;
