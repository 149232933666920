import Moment from 'moment';
import moment from 'moment';
import {
  validateDate,
  validateName,
  validatePhoneNumber,
  validatePositiveMoney,
} from '../../../utils/validations';
import formMessages from '../../ShortLeaseApplication/messages';
import messages from './messages';
import { isEmpty, isNil, pathOr } from 'ramda';

import {
  calculateLeaseEndDate,
  calculateLeaseEndDateV2,
} from '../../../utils/lease-helpers.js';
import { calculateRentWithAmenities } from '../../../utils/lease-helpers';
import {
  getIsSection236,
  getAffordableFloorPlanProgramTypes,
} from '../../../utils/affordable';

export const validateOneYearPast = (moveInDate: Object) => {
  const substractOneYear = moment();
  substractOneYear.subtract(1, 'years');
  return moment(moveInDate).isBefore(substractOneYear, 'day');
};

export const validDateAfterMoveIn = (startDate: Object, moveInDate: Object) =>
  startDate.isAfter(moveInDate, 'day');

export const validDateAfterMoveOut = (
  unit: Object,
  lease: Object,
  moveInDate: Moment,
  residentId: string | null,
  transferInformation: Object,
): boolean => {
  if (!isNil(transferInformation) && !isEmpty(transferInformation)) {
    return moment(transferInformation.previousResidentMoveOutDate).isBefore(
      moveInDate,
    );
  } else {
    const unitLeases = pathOr([], ['leases'], unit);
    const unitStatus = pathOr('', ['unitStatus', 'description'], unit);
    if (!unitStatus.includes('Occupied')) {
      return true;
    }
    const lastLease = unitLeases.filter((l) => l.id !== lease.id)[0];
    const moveOutDate = pathOr(
      '',
      ['noticeToVacate', 'moveOutDate'],
      lastLease,
    );
    return !moveOutDate ? true : moment(moveOutDate).isBefore(moveInDate);
  }
};

const validate = (
  values: any,
  {
    affordableValues,
    applicableRents,
    intl,
    lease,
    leaseRentPercentage,
    leaseTerms,
    openFiscalPeriod,
    priorLease,
    residentId,
    selectedProperty,
    transferInformation,
    unit,
    noticeToVacate,
    flags,
    formValues,
    isEditable,
  }: Object,
) => {
  let vacateNotice;
  if (noticeToVacate) {
    vacateNotice = noticeToVacate ? moment(noticeToVacate.moveOutDate) : '';
  }
  const {
    leaseEndEnhance,
    backdateRenewalLeasesMoreThan16Months,
    backdateScheduledMoveInDateMoreThan12Months,
  } = flags;
  const errors = {};
  const moveInDate = moment(values.moveInDate);
  const startDate = moment(values.startDate);
  const endDate = moment(values.endDate);
  const leasedRent = pathOr(0, ['leasedRent'], values);
  const affordableMarketRent = +pathOr(
    0,
    ['affordableMarketRent'],
    affordableValues,
  );
  const affordableQuotingRent = +pathOr(
    0,
    ['affordableQuotingRent'],
    affordableValues,
  );
  const utilityAllowanceAmount = +pathOr(
    0,
    ['utilityAllowanceAmount'],
    affordableValues,
  );
  const noteRent = +pathOr(0, ['noteRent'], affordableValues);
  const nonOptionalCharge = +(affordableValues?.nonOptionalCharge ?? 0);
  const isAffordable = pathOr(false, ['floorPlan', 'isAffordable'], unit);
  const phaFloorplan = pathOr(false, ['floorPlan', 'phaFloorplan'], unit);
  const floorPlan = unit?.floorPlan ?? {};
  const { isHUDFloorPlan, isRDFloorPlan } =
    getAffordableFloorPlanProgramTypes(floorPlan);
  const hudFloorplan = isHUDFloorPlan;
  const receivingAssistance = pathOr(false, ['isReceivingAssistance'], values);

  const section236Flag = flags?.section236 ?? false;
  const hud236BasicRent = values?.hud236BasicRent ?? 0;
  const hud236MarketRent = values?.hud236MarketRent ?? 0;
  const isSection236 =
    section236Flag && getIsSection236(selectedProperty, floorPlan);

  const initialQuotingRentAmount =
    isAffordable && !(phaFloorplan || hudFloorplan)
      ? affordableQuotingRent
      : pathOr(
          pathOr(null, ['floorPlan', 'quotingRentAmount'], unit),
          ['quotingRentAmount'],
          unit,
        );
  const quotingRentAmount =
    lease?.quotingRentValue ??
    calculateRentWithAmenities(unit, +initialQuotingRentAmount);
  const isLeaseDisabled =
    !formValues?.startDate ||
    !formValues?.leaseTermId ||
    formValues?.leaseTermId === 'default' ||
    !isEditable;
  if (lease.isRenewal || lease.isTransfer || isNil(residentId)) {
    if (
      !errors['emergencyContactName'] &&
      values['emergencyContactName'] &&
      !validateName(values['emergencyContactName'])
    ) {
      errors['emergencyContactName'] = intl.formatMessage(
        formMessages.invalidValue,
      );
    }

    if (
      values['emergencyContactPhone'] &&
      !validatePhoneNumber(values.emergencyContactPhone)
    ) {
      errors['emergencyContactPhone'] = intl.formatMessage(
        formMessages.invalidValue,
      );
    }

    if (values.moveInDate) {
      if (!validateDate(values.moveInDate)) {
        errors['moveInDate'] = intl.formatMessage(formMessages.invalidValue);
      }
    }

    if (values.startDate) {
      if (!validateDate(values.startDate)) {
        errors['startDate'] = intl.formatMessage(formMessages.invalidValue);
      }
    }

    if (
      !backdateRenewalLeasesMoreThan16Months &&
      startDate.isBefore(moment().subtract(16, 'months'))
    ) {
      errors['startDate'] = intl.formatMessage(messages.validDate16MonthsPast);
    }

    if (vacateNotice && startDate.isAfter(vacateNotice)) {
      errors['startDate'] = intl.formatMessage(
        messages.dateMustBeOnOrAfterRenewalDate,
      );
    }

    if (residentId && !lease.isTransfer) {
      if (startDate.isBefore(moveInDate, 'day')) {
        errors['startDate'] = intl.formatMessage(
          messages.validDateBeforeMoveIn,
        );
      }
      if (priorLease) {
        const leaseBeforeEndDate = moment(priorLease.endDate);
        if (startDate.isBefore(leaseBeforeEndDate, 'day')) {
          errors['startDate'] = intl.formatMessage(
            messages.validDateBeforeEndDate,
          );
        }
      }
    }
    if (!residentId || lease.isTransfer) {
      if (validDateAfterMoveIn(startDate, moveInDate)) {
        errors['startDate'] = intl.formatMessage(messages.validDateAfterMoveIn);
      }
      if (moveInDate.isBefore(startDate, 'day')) {
        errors['moveInDate'] = intl.formatMessage(
          messages.validDateBeforeStartDate,
        );
      }
      if (
        !validDateAfterMoveOut(
          unit,
          lease,
          moveInDate,
          residentId,
          transferInformation,
        )
      ) {
        errors['moveInDate'] = intl.formatMessage(
          messages.validDateBeforeMoveOutDate,
        );
      }
      if (
        validateOneYearPast(moveInDate) &&
        !backdateScheduledMoveInDateMoreThan12Months
      ) {
        errors['moveInDate'] = intl.formatMessage(messages.validDatePastYear);
      }
    }

    const hudOrPha = phaFloorplan || hudFloorplan;
    const leasedRentOverride = hudOrPha || receivingAssistance;
    const outsideLowestQuote = leasedRentOverride
      ? +leasedRent < 0
      : (+leasedRent / +quotingRentAmount) * 100 + +leaseRentPercentage < 100;
    const outsideHighestQuote = leasedRentOverride
      ? false
      : (+leasedRent / +quotingRentAmount) * 100 - +leaseRentPercentage > 100;
    if (outsideLowestQuote) {
      errors.leasedRent = leasedRentOverride
        ? intl.formatMessage(messages.rentPositive)
        : intl.formatMessage(messages.rentWithinLeasedRentPercentage, {
            leaseRentPercentage,
          });
    }
    if (outsideHighestQuote && !hudOrPha && isAffordable) {
      errors.leasedRent = intl.formatMessage(
        messages.rentWithinLeasedRentPercentage,
        {
          leaseRentPercentage,
        },
      );
    }
    if (outsideHighestQuote && !isAffordable) {
      errors.leasedRent = intl.formatMessage(
        messages.rentWithinLeasedRentPercentage,
        {
          leaseRentPercentage,
        },
      );
    }

    if (
      !receivingAssistance &&
      isAffordable &&
      !(hudFloorplan || phaFloorplan) &&
      +leasedRent >
        affordableMarketRent - utilityAllowanceAmount - nonOptionalCharge
    ) {
      errors.leasedRent = intl.formatMessage(
        messages[
          nonOptionalCharge !== 0
            ? 'rentIsTooHighWithNonOptionalCharge'
            : 'rentIsTooHigh'
        ],
      );
    }
    /**RD rents */

    if (applicableRents.noteRent && isRDFloorPlan) {
      if (+leasedRent > noteRent - utilityAllowanceAmount) {
        errors.leasedRent = intl.formatMessage(
          messages[
            utilityAllowanceAmount !== 0
              ? 'noteRentErrorWithUA'
              : 'noteRentError'
          ],
        );
      }
    }
    if (
      applicableRents.noteRent &&
      applicableRents.hudGrossRent &&
      isRDFloorPlan
    ) {
      if (
        +leasedRent > noteRent - utilityAllowanceAmount ||
        +leasedRent > affordableMarketRent - utilityAllowanceAmount
      ) {
        errors.leasedRent = intl.formatMessage(
          messages[
            utilityAllowanceAmount !== 0
              ? 'hudGrossRentAndNoteErrorWithUA'
              : 'hudGrossRentandNoteError'
          ],
        );
      }
    }

    if (isSection236) {
      const leasedRentValue = +leasedRent;
      if (leasedRentValue < hud236BasicRent) {
        //TODO: add message for this
      }

      if (leasedRentValue > hud236MarketRent - utilityAllowanceAmount) {
        errors.leasedRent = intl.formatMessage(
          messages[
            utilityAllowanceAmount !== 0
              ? 'leaseRent236ErrorWithUA'
              : 'leaseRent236Error'
          ],
        );
      }
    }

    if ((isNil(values.endDate) || isEmpty(values.endDate)) && !isLeaseDisabled)
      errors['endDate'] = 'End date is required.';

    if (values.endDate) {
      if (!validateDate(values.endDate)) {
        errors['endDate'] = intl.formatMessage(formMessages.invalidValue);
      }
    }

    if (
      !errors['startDate'] &&
      !errors['endDate'] &&
      endDate.isBefore(startDate)
    ) {
      errors['endDate'] = 'End date cannot be before start date.';
    }
    if (
      !errors['startDate'] &&
      !errors['endDate'] &&
      values.leaseTermId &&
      leaseTerms
    ) {
      const calculatedEndDate = leaseEndEnhance
        ? calculateLeaseEndDateV2(
            startDate,
            values.leaseTermId,
            leaseTerms,
            selectedProperty,
          )
        : calculateLeaseEndDate(
            startDate,
            values.leaseTermId,
            leaseTerms,
            selectedProperty?.isSetLeaseEndDateToEndOfMonth,
          );

      if (calculatedEndDate) {
        const sixtyOneDaysBefore = calculatedEndDate
          .clone()
          .subtract(61, 'days');
        const sixtyOneDaysAfter = calculatedEndDate.clone().add(61, 'days');

        if (
          endDate.isBefore(sixtyOneDaysBefore) ||
          endDate.isAfter(sixtyOneDaysAfter)
        ) {
          errors[
            'endDate'
          ] = `End date cannot be more than 61 days before or after ${calculatedEndDate.format(
            'MM/DD/YYYY',
          )}.`;
        }
      }
    }

    [
      'securityDeposit',
      'proratedRent',
      'proratedOther',
      'petDeposit',
      'satelliteDeposit',
      'petFee',
    ].forEach((fieldName) => {
      if (
        values[fieldName] &&
        (!validatePositiveMoney(values[fieldName]) || values[fieldName] > 10000)
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.validNumber);
      }
    });

    [
      'oneTimeRentConcession',
      'monthlyRentConcession',
      'otherDiscountConcession',
    ].forEach((fieldName) => {
      if (
        values[fieldName] &&
        (!validatePositiveMoney(values[fieldName]) || values[fieldName] > 11000)
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.validNumber);
      }
    });
  }

  return errors;
};

export default validate;
