import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from './constants';
import * as actions from './actions';
import { actions as toastrActions } from 'react-redux-toastr';
// Services
import ActivityTypeService from '../../services/activityTypeService';
import AffordableService from '../../services/affordableService';
import AffordableRelationshipService from '../../services/affordableRelationshipService';
import ApplicantCancellationReasonService from '../../services/applicantCancellationReasonsService';
import ApplicantTypeService from '../../services/applicantTypeService';
import CommonAreaLocationService from '../../services/commonAreaLocationService';
import ContactTypeService from '../../services/contactTypeService';
import CountryService from '../../services/countryService';
import CustomerService from '../../services/customersService';
import EmploymentStatusService from '../../services/employmentStatusService';
import EntryPermissionService from '../../services/entryPermissionService';
import FiscalPeriodsService from '../../services/fiscalPeriodsService';
import HousingSituationService from '../../services/housingSituationService';
import IncomeTypeService from '../../services/incomeTypeService';
import MoveOutReasonService from '../../services/moveOutReasonService';
import NameSuffixService from '../../services/nameSuffixService';
import NavigationOptionsService from '../../services/navigationOptionsService';
import PetBreedService from '../../services/petBreedService';
import PetTypeService from '../../services/petTypeService';
import PriorityLevelService from '../../services/priorityLevelService';
import ProrateMethodsService from '../../services/prorateMethodsService';
import ProspectStatusService from '../../services/prospectStatusService';
import PropertyService from '../../services/propertyService';
import PropertyBankAccountsService from '../../services/propertyBankAccountsService';
import PropertyClassService from '../../services/propertyClassService';
import PropertySubjournalsService from '../../services/propertySubjournalTypesService';
import PropertyTransactionCodesService from '../../services/propertyTransactionCodesService';
import ReferralTypeService from '../../services/referralTypeService';
import RelationshipService from '../../services/relationshipService';
import SpecialNeedsDesignationService from '../../services/specialNeedsDesignationService';
import StateService from '../../services/stateService';
import UnitLocationService from '../../services/unitLocationService';
import UnitService from '../../services/unitService';
import UserService from '../../services/userService';
import UtilityAllowanceTypeService from '../../services/utilityAllowanceTypeService';
import WorkOrderIssueService from '../../services/workOrderIssueService';
import WorkOrderStatusService from '../../services/workOrderStatusService';
import PropertyPaymentProviderService from '../../services/propertyPaymentProviderService';

// Selectors
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from './selectors';
import type { Saga } from 'redux-saga';
import type { Action, FilterValueAndPagination } from './types';
import FloorPlanService from '../../services/floorPlanService';
import { renderNoDataToastr } from '../../utils/redux-form-helper';

export function* fetchGetAllContactTypes(): Saga<void> {
  try {
    const contactTypeService = new ContactTypeService();
    const contactTypes = yield contactTypeService.getAll();
    yield put(actions.getAllContactTypesSuccess(contactTypes));
  } catch (err) {
    yield put(actions.getAllContactTypesError(err));
  }
}

export function* fetchGetAllApplicantCancellationReasons(): Saga<void> {
  try {
    const applicantCancellationReasonsService =
      new ApplicantCancellationReasonService();
    const applicantCancellationReasons =
      yield applicantCancellationReasonsService.getAll();
    yield put(
      actions.getAllApplicantCancellationReasonsSuccess(
        applicantCancellationReasons,
      ),
    );
  } catch (err) {
    yield put(actions.getAllApplicantCancellationReasonsError(err));
  }
}
export function* getAllApplicantCancellationReasons(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_APPLICANT_CANCELLATION_REASONS,
    fetchGetAllApplicantCancellationReasons,
  );
}

export function* getAllContactTypes(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_CONTACT_TYPES, fetchGetAllContactTypes);
}

export function* fetchGetAllReferralTypes(): Saga<void> {
  try {
    const referralTypeService = new ReferralTypeService();
    const referralTypes = yield referralTypeService.getAll();
    yield put(actions.getAllReferralTypesSuccess(referralTypes));
  } catch (err) {
    yield put(actions.getAllReferralTypesError(err));
  }
}

export function* getAllReferralTypes(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_REFERRAL_TYPES,
    fetchGetAllReferralTypes,
  );
}

export function* fetchGetProspectAssignees(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const userService = new UserService();
    const assignees = yield userService.getProspectAssignees(
      organizationId,
      property.id,
    );
    yield put(actions.getProspectAssigneesSuccess(assignees));
  } catch (err) {
    yield put(actions.getProspectAssigneesError(err));
  }
}

export function* getProspectAssignees(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_PROSPECT_ASSIGNEES,
    fetchGetProspectAssignees,
  );
}

export function* fetchGetWorkOrderAssignees(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const userService = new UserService();
    const assignees = yield userService.getWorkOrderAssignees(
      organizationId,
      property.id,
    );
    yield put(actions.getWorkOrderAssigneesSuccess(assignees));
  } catch (err) {
    yield put(actions.getWorkOrderAssigneesError(err));
  }
}

export function* getWorkOrderAssignees(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_WORK_ORDER_ASSIGNEES,
    fetchGetWorkOrderAssignees,
  );
}

export function* fetchGetAllAssignees(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const userService = new UserService();
    const assignees = yield userService.getAllAssignees(
      organizationId,
      property.id,
    );
    yield put(actions.getAllAssigneesSuccess(assignees));
  } catch (err) {
    yield put(actions.getAllAssigneesError(err));
  }
}

export function* getAllAssignees(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_ASSIGNEES, fetchGetAllAssignees);
}

export function* fetchGetAllPetTypes(): Saga<void> {
  try {
    const petTypeService = new PetTypeService();
    const petTypes = yield petTypeService.getAll();
    yield put(actions.getAllPetTypesSuccess(petTypes));
  } catch (err) {
    yield put(actions.getAllPetTypesError(err));
  }
}

export function* getAllPetTypes(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_PET_TYPES, fetchGetAllPetTypes);
}

export function* fetchGetAllPetBreeds(): Saga<void> {
  try {
    const petBreedService = new PetBreedService();
    const petBreeds = yield petBreedService.getAll();
    yield put(actions.getAllPetBreedsSuccess(petBreeds));
  } catch (err) {
    yield put(actions.getAllPetBreedsError(err));
  }
}

export function* getAllPetBreeds(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_PET_BREEDS, fetchGetAllPetBreeds);
}

export function* fetchGetAllProspectStatus(): Saga<void> {
  try {
    const prospectStatusService = new ProspectStatusService();
    const prospectStatus = yield prospectStatusService.getAll();
    yield put(actions.getAllProspectStatusSuccess(prospectStatus));
  } catch (err) {
    yield put(actions.getAllProspectStatusError(err));
  }
}

export function* getProspectStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_PROSPECT_STATUS,
    fetchGetAllProspectStatus,
  );
}

export function* fetchGetAllWorkOrderStatus(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const workOrderStatusService = new WorkOrderStatusService();
    const workOrderStatus = yield workOrderStatusService.getAll(
      organizationId,
      property.id,
    );
    yield put(actions.getAllWorkOrderStatusSuccess(workOrderStatus));
  } catch (err) {
    yield put(actions.getAllWorkOrderStatusError(err));
  }
}

export function* getWorkOrderStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_WORK_ORDER_STATUS,
    fetchGetAllWorkOrderStatus,
  );
}

export function* fetchGetAllActivityTypes(): Saga<void> {
  try {
    const activityTypeService = new ActivityTypeService();
    const activityTypes = yield activityTypeService.getAll();
    yield put(actions.getAllActivityTypesSuccess(activityTypes));
  } catch (err) {
    yield put(actions.getAllActivityTypesError(err));
  }
}

export function* getActivityTypes(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_ACTIVITY_TYPES,
    fetchGetAllActivityTypes,
  );
}

export function* fetchGetAllNavigationOptions(): Saga<void> {
  try {
    const navigationOptionsService = new NavigationOptionsService();
    const options = yield navigationOptionsService.getAll();
    yield put(actions.getAllNavigationOptionsSuccess(options));
  } catch (err) {
    yield put(actions.getAllNavigationOptionsError(err));
  }
}

export function* getAllNavigationOptions(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_NAVIGATION_OPTIONS,
    fetchGetAllNavigationOptions,
  );
}

export function* fetchGetAllNameSuffixes(): Saga<void> {
  try {
    const nameSuffixService = new NameSuffixService();
    const nameSuffixes = yield nameSuffixService.getAll();
    yield put(actions.getAllNameSuffixesSuccess(nameSuffixes));
  } catch (err) {
    yield put(actions.getAllNameSuffixesError(err));
  }
}

export function* getAllNameSuffixes(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_NAME_SUFFIXES, fetchGetAllNameSuffixes);
}

export function* fetchGetAllPropertyClasses(): Saga<void> {
  try {
    const propertyClassService = new PropertyClassService();
    const propertyClasses = yield propertyClassService.getAll();
    yield put(actions.getAllPropertyClassesSuccess(propertyClasses));
  } catch (err) {
    yield put(actions.getAllPropertyClassesError(err));
  }
}

export function* getAllPropertyClasses(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_PROPERTY_CLASSES,
    fetchGetAllPropertyClasses,
  );
}

export function* fetchGetAllRelationships(): Saga<void> {
  try {
    const relationshipService = new RelationshipService();
    const relationships = yield relationshipService.getAll();
    yield put(actions.getAllRelationshipsSuccess(relationships));
  } catch (err) {
    yield put(actions.getAllRelationshipsError(err));
  }
}

export function* fetchGetAllAffordableRelationships(): Saga<void> {
  try {
    const affordableRelationshipService = new AffordableRelationshipService();
    const affordableRelationships =
      yield affordableRelationshipService.getAll();
    yield put(
      actions.getAllAffordableRelationshipsSuccess(affordableRelationships),
    );
  } catch (err) {
    yield put(actions.getAllAffordableRelationshipsError(err));
  }
}

export function* getAllRelationships(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_RELATIONSHIPS, fetchGetAllRelationships);
}

export function* getAllAffordableRelationships(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS,
    fetchGetAllAffordableRelationships,
  );
}

export function* fetchGetAllApplicantTypes(): Saga<void> {
  try {
    const applicantTypeService = new ApplicantTypeService();
    const applicantTypes = yield applicantTypeService.getAll();
    yield put(actions.getAllApplicantTypesSuccess(applicantTypes));
  } catch (err) {
    yield put(actions.getAllApplicantTypesError(err));
  }
}

export function* getAllApplicantTypes(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_APPLICANT_TYPES,
    fetchGetAllApplicantTypes,
  );
}

export function* fetchGetAllFloorPlansSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const floorPlanService = new FloorPlanService();
    const response = yield floorPlanService.getAll(organizationId, property.id);
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(actions.getAllFloorPlansSuccess(response));
  } catch (err) {
    yield put(actions.getAllFloorPlansFail(err));
  }
}

export function* getAllFloorPlansSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_FLOOR_PLANS, fetchGetAllFloorPlansSaga);
}

export function* fetchGetAllCountries(): Saga<void> {
  try {
    const countryService = new CountryService();
    const countries = yield countryService.getAll();
    yield put(actions.getAllCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.getAllCountriesError(err));
  }
}

export function* getAllCountries(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_COUNTRIES, fetchGetAllCountries);
}

export function* fetchGetAllStates(): Saga<void> {
  try {
    const stateService = new StateService();
    const states = yield stateService.getAll();
    yield put(actions.getAllStatesSuccess(states));
  } catch (err) {
    yield put(actions.getAllStatesError(err));
  }
}

export function* getAllStates(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_STATES, fetchGetAllStates);
}

export function* fetchGetAllIncomeTypes(): Saga<void> {
  try {
    const incomeTypeService = new IncomeTypeService();
    const incomeTypes = yield incomeTypeService.getAll();
    yield put(actions.getAllIncomeTypesSuccess(incomeTypes));
  } catch (err) {
    yield put(actions.getAllIncomeTypesError(err));
  }
}

export function* getAllIncomeTypes(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ALL_INCOME_TYPES, fetchGetAllIncomeTypes);
}

export function* fetchGetAllSpecialNeedsDesignations(): Saga<void> {
  try {
    const specialNeedsDesignationService = new SpecialNeedsDesignationService();
    const specialNeedsDesignations =
      yield specialNeedsDesignationService.getAll();
    yield put(
      actions.getAllSpecialNeedsDesignationsSuccess(specialNeedsDesignations),
    );
  } catch (err) {
    yield put(actions.getAllSpecialNeedsDesignationsError(err));
  }
}

export function* getAllSpecialNeedsDesignations(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_SPECIAL_NEEDS_DESIGNATION,
    fetchGetAllSpecialNeedsDesignations,
  );
}

export function* fetchGetAllEmploymentStatus(): Saga<void> {
  try {
    const employmentStatusService = new EmploymentStatusService();
    const statuses = yield employmentStatusService.getAll();
    yield put(actions.getAllEmploymentStatusesSuccess(statuses));
  } catch (err) {
    yield put(actions.getAllEmploymentStatusesError(err));
  }
}

export function* getAllEmploymentStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_EMPLOYMENT_STATUS,
    fetchGetAllEmploymentStatus,
  );
}

export function* fetchGetAllWorkOrderIssues(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const workOrderIssuesService = new WorkOrderIssueService();
    const issues = yield workOrderIssuesService.getAll(
      organizationId,
      property.id,
    );
    yield put(actions.getAllWorkOrderIssuesSuccess(issues));
  } catch (err) {
    yield put(actions.getAllWorkOrderIssuesError(err));
  }
}

export function* getAllWorkOrderIssues(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_WORK_ORDER_ISSUES,
    fetchGetAllWorkOrderIssues,
  );
}

export function* fetchGetAllUnitLocations(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const unitLocationService = new UnitLocationService();
    const unitLocations = yield unitLocationService.getAll(
      organizationId,
      property.id,
    );
    yield put(actions.getAllUnitLocationsSuccess(unitLocations));
  } catch (err) {
    yield put(actions.getAllUnitLocationsError(err));
  }
}

export function* getAllUnitLocations(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_UNIT_LOCATIONS,
    fetchGetAllUnitLocations,
  );
}

export function* fetchGetAllCommonAreaLocations(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const commonAreaLocationService = new CommonAreaLocationService();
    const commonAreaLocations = yield commonAreaLocationService.getAll(
      organizationId,
      property.id,
    );
    yield put(actions.getAllCommonAreaLocationsSuccess(commonAreaLocations));
  } catch (err) {
    yield put(actions.getAllCommonAreaLocationsError(err));
  }
}

export function* getAllCommonAreaLocations(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_COMMON_AREA_LOCATIONS,
    fetchGetAllCommonAreaLocations,
  );
}

export function* fetchGetAllEntryPermissions(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const entryPermissionService = new EntryPermissionService();
    const entryPermissions = yield entryPermissionService.getAll(
      organizationId,
    );
    yield put(actions.getAllEntryPermissionsSuccess(entryPermissions));
  } catch (err) {
    yield put(actions.getAllEntryPermissionsError(err));
  }
}

export function* getAllEntryPermissions(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_ENTRY_PERMISSIONS,
    fetchGetAllEntryPermissions,
  );
}

export function* fetchGetAllPriorityLevels(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const priorityLevelsService = new PriorityLevelService();
    const priorityLevels = yield priorityLevelsService.getAll(organizationId);
    yield put(actions.getAllPriorityLevelsSuccess(priorityLevels));
  } catch (err) {
    yield put(actions.getAllPriorityLevelsError(err));
  }
}

export function* getAllPriorityLevels(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_PRIORITY_LEVELS,
    fetchGetAllPriorityLevels,
  );
}

export function* fetchGetAllMoveOutReasons(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const moveOutReasonService = new MoveOutReasonService();
    const moveOutReasons = yield call(
      moveOutReasonService.getAll,
      organizationId,
      selectedProperty.id,
    );
    yield put(actions.getAllMoveOutReasonsSuccess(moveOutReasons));
  } catch (err) {
    yield put(actions.getAllMoveOutReasonsError(err));
  }
}

export function* getAllMoveOutReasons(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_MOVE_OUT_REASONS,
    fetchGetAllMoveOutReasons,
  );
}

export function* fetchGetAllHousingSituations(): Saga<void> {
  try {
    const housingSituationService = new HousingSituationService();
    const housingSituations = yield call(housingSituationService.getAll);
    yield put(actions.getAllHousingSituationsSuccess(housingSituations));
  } catch (err) {
    yield put(actions.getAllHousingSituationsError(err));
  }
}

export function* getAllHousingSituations(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_HOUSING_SITUATIONS,
    fetchGetAllHousingSituations,
  );
}

export function* fetchGetOccupiedUnitsForSelectedProperty(): Saga<void> {
  try {
    const unitsService = new UnitService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const occupiedUnits = yield call(
      unitsService.getOccupiedUnitsForProperty,
      selectedProperty.id,
      organizationId,
    );
    yield put(
      actions.getOccupiedUnitsForSelectedPropertySuccess(occupiedUnits),
    );
  } catch (err) {
    yield put(actions.getOccupiedUnitsForSelectedPropertyFailure(err));
  }
}

export function* getOccupiedUnitsForSelectedProperty(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY,
    fetchGetOccupiedUnitsForSelectedProperty,
  );
}

export function* fetchGetSelectedPropertyTransactionCodes(): Saga<void> {
  try {
    const ptcService = new PropertyTransactionCodesService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const codes = yield call(
      ptcService.getPropertyTransactionCodes,
      organizationId,
      selectedProperty.id,
    );
    yield put(actions.getSelectedPropertyTransactionCodesSuccess(codes));
  } catch (err) {
    yield put(actions.getSelectedPropertyTransactionCodesFailure(err));
  }
}

export function* getSelectedPropertyTransactionCodes(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES,
    fetchGetSelectedPropertyTransactionCodes,
  );
}

export function* fetchGetSelectedPropertySubjournals(): Saga<void> {
  try {
    const subjournalsService = new PropertySubjournalsService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const subjournals = yield call(
      subjournalsService.getPropertySubjournalTypes,
      organizationId,
      selectedProperty.id,
    );
    yield put(actions.getSelectedPropertySubjournalsSuccess(subjournals));
  } catch (err) {
    yield put(actions.getSelectedPropertySubjournalsFailure(err));
  }
}

export function* getSelectedPropertySubjournals(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS,
    fetchGetSelectedPropertySubjournals,
  );
}

export function* fetchGetSelectedPropertyFiscalPeriod(): Saga<void> {
  try {
    const fiscalPeriodsService = new FiscalPeriodsService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const fiscalPeriod = yield call(
      fiscalPeriodsService.getPropertyOpenFiscalPeriod,
      selectedProperty.id,
      organizationId,
    );
    yield put(actions.getSelectedPropertyFiscalPeriodsSuccess(fiscalPeriod));
  } catch (err) {
    yield put(actions.getSelectedPropertyFiscalPeriodsFailure(err));
  }
}

export function* getSelectedPropertyFiscalPeriod(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD,
    fetchGetSelectedPropertyFiscalPeriod,
  );
}

export function* fetchGetSelectedPropertyBankAccounts(): Saga<void> {
  try {
    const service = new PropertyBankAccountsService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    if (!organizationId || !selectedProperty) {
      yield put(
        actions.getSelectedPropertyBankAccountsFailure(
          'A property must be selected.',
        ),
      );
      return;
    }

    const bankAccounts = yield call(
      service.getAllForProperty,
      selectedProperty.id,
      organizationId,
    );

    yield put(actions.getSelectedPropertyBankAccountsSuccess(bankAccounts));
  } catch (error) {
    yield put(actions.getSelectedPropertyBankAccountsFailure(error));
  }
}

export function* getSelectedPropertyBankAccounts(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS,
    fetchGetSelectedPropertyBankAccounts,
  );
}

export function* fetchGetOneProperty(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    const propertyService = new PropertyService();
    const response = yield propertyService.getOneProperty(
      selectedProperty.organizationId,
      selectedProperty.id,
    );
    yield put(actions.selectProperty(response));
  } catch (err) {
    // do nothing
  }
}

export function* updateSelectedProperty(): Saga<void> {
  yield takeLatest(ActionTypes.UPDATE_SELECTED_PROPERTY, fetchGetOneProperty);
}

export function* getWriteOffsForCustomer(action: {
  payload: string,
}): Saga<void> {
  try {
    const service = new CustomerService();

    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const writeOffs = yield call(
      service.getWriteOffsForCustomer,
      action.payload,
      property.id,
      orgId,
    );

    yield put(
      actions.getWriteOffsForCustomerSuccess(action.payload, writeOffs),
    );
  } catch (error) {
    yield put(
      actions.getWriteOffsForCustomerError(error.message || error.toString()),
    );
  }
}

export function* waitForGetWriteOffsForCustomer(): Saga<void> {
  yield takeEvery(
    ActionTypes.GET_WRITEOFFS_FOR_CUSTOMER,
    getWriteOffsForCustomer,
  );
}

export function* promptPrintDialogSaga(): Saga<void> {
  try {
    yield put(window.print());
  } catch (error) {
    console.warn(error); // eslint-disable-line
  }
}
export function* promptPrintDialog(): Saga<void> {
  yield takeLatest(ActionTypes.PROMPT_PRINT_DIALOG, promptPrintDialogSaga);
}

export function* promptToasterSaga({
  payload: { toasterPayload },
}: Object): Saga<void> {
  yield put(
    toastrActions.add({
      type: toasterPayload.type || 'success',
      message: toasterPayload.message,
      title: toasterPayload.title,
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );
}

export function* promptToaster(payload: Object): Saga<void> {
  yield takeLatest(ActionTypes.PROMPT_TOASTER, promptToasterSaga);
}

export function* disconnectSocketSaga(): Saga<void> {
  yield put(actions.socketDisconnect());
}

export function* disconnectSocketOnLogout(): Saga<void> {
  yield takeLatest(ActionTypes.LOG_OUT, disconnectSocketSaga);
}

export function* getComplianceStatuses(): Saga<void> {
  const affordableService = new AffordableService();
  const response = yield affordableService.getAllComplianceStatuses();
  yield put(actions.getComplianceStatusesSuccess(response));
}

export function* getComplianceStatusesSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_COMPLIANCE_STATUSES, getComplianceStatuses);
}

export function* getRentalAssistanceSources(): Saga<void> {
  const affordableService = new AffordableService();
  const response = yield affordableService.getAllRentalAssistanceSources();
  yield put(actions.getRentalAssistanceSourcesSuccess(response));
}

export function* getRentalAssistanceSourcesSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_RENTAL_ASSISTANCE_SOURCES,
    getRentalAssistanceSources,
  );
}

export function* getUtilityAllowances(): Saga<void> {
  const selectedProperty = yield select(selectSelectedProperty);
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const affordableService = new AffordableService();
  const response = yield affordableService.getUtilityAllowances(
    organizationId,
    selectedProperty.id,
  );
  yield put(actions.getUtilityAllowancesSuccess(response));
}

export function* getUtilityAllowancesSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_UTILITY_ALLOWANCES, getUtilityAllowances);
}

export function* fetchGetAllProrateMethods(): Saga<void> {
  try {
    const prorateMethodsService = new ProrateMethodsService();
    const prorateMethods = yield prorateMethodsService.getAll();
    yield put(actions.getAllProrateMethodsSuccess(prorateMethods));
  } catch (err) {
    yield put(actions.getAllProrateMethodsError(err));
  }
}

export function* getAllProrateMethods(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_PRORATE_METHODS,
    fetchGetAllProrateMethods,
  );
}

export function* fetchGetAllMoveOutProrateMethods(): Saga<void> {
  try {
    const prorateMethodsService = new ProrateMethodsService();
    const moveOutProrateMethods =
      yield prorateMethodsService.getMoveOutProrateMethods();
    yield put(
      actions.getAllMoveOutProrateMethodsSuccess(moveOutProrateMethods),
    );
  } catch (err) {
    yield put(actions.getAllProrateMethodsError(err));
  }
}
export function* getAllMoveOutProrateMethods(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_MOVE_OUT_PRORATE_METHODS,
    fetchGetAllMoveOutProrateMethods,
  );
}

export function* fetchGetAllUtilityAllowanceTypes(): Saga<void> {
  try {
    const utilityAllowanceTypeService = new UtilityAllowanceTypeService();
    const utilityAllowanceTypes = yield utilityAllowanceTypeService.getAll();
    yield put(
      actions.getAllUtilityAllowanceTypesSuccess(utilityAllowanceTypes),
    );
  } catch (err) {
    yield put(actions.getAllUtilityAllowanceTypesError(err));
  }
}

export function* getAllUtilityAllowanceTypes(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_UTILITY_ALLOWANCE_TYPES,
    fetchGetAllUtilityAllowanceTypes,
  );
}

export function* fetchGetPropertyPaymentProvider(): Saga<void> {
  try {
    const propertyPaymentProviderService = new PropertyPaymentProviderService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    if (!organizationId || !selectedProperty) return;
    const propertyPaymentMethod = yield propertyPaymentProviderService.getAll(
      organizationId,
      selectedProperty.id,
    );
    yield put(actions.getPropertyPaymentProviderSuccess(propertyPaymentMethod));
  } catch (err) {
    yield put(actions.getPropertyPaymentProviderError(err));
  }
}

export function* getPropertyPaymentProvider(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER,
    fetchGetPropertyPaymentProvider,
  );
}

export default [
  disconnectSocketOnLogout,
  getAllContactTypes,
  getAllReferralTypes,
  getAllPetBreeds,
  getAllPetTypes,
  getAllAssignees,
  getProspectAssignees,
  getWorkOrderAssignees,
  getProspectStatus,
  getWorkOrderStatus,
  getActivityTypes,
  getAllApplicantCancellationReasons,
  getAllNavigationOptions,
  getAllNameSuffixes,
  getAllPropertyClasses,
  getAllRelationships,
  getAllAffordableRelationships,
  getAllApplicantTypes,
  getAllCountries,
  getAllStates,
  getAllIncomeTypes,
  getAllEmploymentStatus,
  getAllWorkOrderIssues,
  getAllUnitLocations,
  getAllCommonAreaLocations,
  getAllEntryPermissions,
  getAllPriorityLevels,
  getAllMoveOutReasons,
  getAllHousingSituations,
  getAllProrateMethods,
  getAllMoveOutProrateMethods,
  getComplianceStatusesSaga,
  getOccupiedUnitsForSelectedProperty,
  getRentalAssistanceSourcesSaga,
  getSelectedPropertyTransactionCodes,
  getSelectedPropertySubjournals,
  getSelectedPropertyFiscalPeriod,
  getSelectedPropertyBankAccounts,
  getUtilityAllowancesSaga,
  getAllSpecialNeedsDesignations,
  getAllUtilityAllowanceTypes,
  getPropertyPaymentProvider,
  waitForGetWriteOffsForCustomer,
  promptPrintDialog,
  promptToaster,
  updateSelectedProperty,
  getAllFloorPlansSaga,
];
