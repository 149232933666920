// $FlowFixMe
import {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  useCallback,
  useMemo,
} from 'react';
import { isEmpty, sort, comparator, gt, assocPath, pick } from 'ramda';
import { toastr } from 'react-redux-toastr';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import _ from 'lodash';

import PropertyService from '../../services/propertyService';
import MarketingSectionService from '../../services/marketingSectionService';
import IntegrationService from '../../services/integrationService';
import messages from './messages';
import { MasterLeaseTermsService } from '../../services/masterLeaseTermsService';
import ProrateMethodsService from '../../services/prorateMethodsService';
import {
  MARKETING_INFO_UPDATE_ERROR,
  PROPERTY_UPDATE_ERROR,
  INTEGRATION_UPDATE_ERROR,
  COLLECTIONS_DEFAULT_DAYS_AFTER_FAS,
  COLLECTIONS_DEFAULT_MINIMUM_AMOUNT,
} from './constants';
import RoleService from '../../services/roleService';

export const useAsyncPropertyDetails = (
  organizationId: string,
  propertyId: string,
  intl: any,
  selectProperty: Function,
) => {
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const [propertyInfo, setPropertyInfo] = useState({
    leaseRentPercentage: '',
    propertyLateMethods: [],
    propertyLeaseTerms: [],
    organizationIntegrations: [],
    propertyIntegrations: [],
    hubPremiumFeatures: [],
    isSetLeaseEndDateToEndOfMonth: false,
    leaseEndDateGreaterThan12m: 'CALCULATED',
    leaseEndDateLesserThan12m: 'CALCULATED',
    propertyCollectionSetting: {
      enabled: false,
      minimumAmount: COLLECTIONS_DEFAULT_MINIMUM_AMOUNT,
      daysAfterFAS: COLLECTIONS_DEFAULT_DAYS_AFTER_FAS,
    },
    config: {
      successiveLeaseTerm: 'monthly',
      autoSendToTRACS: true,
    },
    mobileAppSetup: { residentCommunications: false },
    paymentProviders: [],
  });

  const handleNullValues = (propertyInfo: Object) => {
    const [propertyChangeHistoryLog] = sort(
      comparator((a, b) => gt(a.changeDate, b.changeDate)),
      propertyInfo.propertyChangeHistoryLog,
    );
    const affordableProps = pick(
      ['setup', 'pap', 'propertyHUDSubsidy', 'propertyHUDSecondarySubsidy'],
      propertyInfo,
    );

    return {
      name: propertyInfo.name,
      shortName: propertyInfo.shortName,
      id: propertyInfo.id,
      onboardingQueue: propertyInfo.onboardingQueue,
      portalLatestOnboardingGroupId: propertyInfo.portalLatestOnboardingGroupId,
      portalLatestOnboardingById: propertyInfo.portalLatestOnboardingById,
      portalUpdatedAt: propertyInfo.portalUpdatedAt,
      phoneNumber: propertyInfo?.phoneNumber ?? '',
      fax: propertyInfo?.faxNumber ?? '',
      email: propertyInfo?.propertyEmail ?? '',
      address1: propertyInfo?.physicalStreetAddress1 ?? '',
      address2: propertyInfo?.physicalStreetAddress2 ?? '',
      city: propertyInfo?.physicalCity ?? '',
      state: propertyInfo?.physicalState ?? '',
      zip: propertyInfo?.physicalZip ?? '',
      owner: propertyInfo?.propertyOwner?.name ?? '---',
      propertyClass: propertyInfo?.propertyClass?.name ?? '---',
      status: propertyInfo?.propertyStatus?.description ?? '---',
      isResidentPortalActive: propertyInfo?.isResidentPortalActive ?? false,
      isElectronicLeaseSigningActive:
        propertyInfo?.isElectronicLeaseSigningActive ?? false,
      legalEntity: propertyInfo?.ownershipEntity?.name ?? '---',
      pfp: propertyInfo?.pfp?.periodName ?? '---',
      isDomusoActive: propertyInfo.isDomusoActive,
      isPayLeaseActive: propertyInfo.isPayLeaseActive,
      isTransUnionActive: propertyInfo.isTransUnionActive,
      domusoPropertyCode: propertyInfo?.domusoPropertyCode ?? '',
      transunionPropertyId: propertyInfo.transunionPropertyId,
      leaseRentPercentage: propertyInfo?.leaseRentPercentage ?? '20',
      sitePetWeightMax: propertyInfo?.sitePetWeightMax ?? '---',
      turnMoveOutDays: propertyInfo?.turnMoveOutDays ?? '---',
      propertyLateMethods: propertyInfo?.propertyLateMethods ?? [],
      propertyLeaseTerms: propertyInfo?.propertyLeaseTerms ?? [],
      hasCommercialFloorPlans: propertyInfo?.hasCommercialFloorPlans ?? 'NONE',
      propertyBankAccounts: propertyInfo?.propertyBankAccounts ?? [],
      gpr:
        propertyInfo?.propertyReportConfiguration?.['0']?.config1Value ?? '---',
      propertyChangeHistoryLog,
      cashPrepaidGLCode: propertyInfo?.cashPrepaidGLCode ?? [],
      applicationIncomeMultiplier:
        propertyInfo?.applicationIncomeMultiplier ?? '',
      renewalProrateMethodId: propertyInfo?.renewalProrateMethodId ?? null,
      organizationIntegrations: propertyInfo?.organization?.integrations ?? [],
      propertyIntegrations: propertyInfo?.integrations ?? [],
      isLeaseExpirationLimitsActive:
        propertyInfo?.isLeaseExpirationLimitsActive,
      ...affordableProps,
      moveOutProrateMethodId: propertyInfo?.moveOutProrateMethodId ?? null,
      deprecated_moveOutProrateMethodId:
        propertyInfo?.deprecated_moveOutProrateMethodId ?? null,
      isSetLeaseEndDateToEndOfMonth:
        propertyInfo?.isSetLeaseEndDateToEndOfMonth ?? false,
      leaseEndDateGreaterThan12m:
        propertyInfo?.leaseEndDateGreaterThan12m ?? 'CALCULATED',
      leaseEndDateLesserThan12m:
        propertyInfo?.leaseEndDateLesserThan12m ?? 'CALCULATED',
      quoteExpTime: propertyInfo?.quoteExpTime ?? null,
      isNoEndDateActive: propertyInfo?.isNoEndDateActive,
      insuranceType: propertyInfo.insuranceType ?? 'None',
      insuranceFeeAmount: propertyInfo.insuranceFeeAmount,
      propertyRiskManagement: propertyInfo.propertyRiskManagement ?? [],
      propertyCollectionSetting: propertyInfo.propertyCollectionSetting
        ? pick(
            ['enabled', 'minimumAmount', 'daysAfterFAS', 'collectionAgency'],
            propertyInfo.propertyCollectionSetting,
          )
        : {
            enabled: false,
            minimumAmount: COLLECTIONS_DEFAULT_MINIMUM_AMOUNT,
            daysAfterFAS: COLLECTIONS_DEFAULT_DAYS_AFTER_FAS,
          },
      config: propertyInfo.config
        ? pick(
            [
              'adverseActionDeadlineDays',
              'autoPostMonthlyCharges',
              'residentCommunicationEnabled',
              'autoChargeMtmFees',
              'roundProratedRents',
              'smartPricingEnabled',
              'smartPricingTargetOccupancy',
              'smartPricingTargetTimeframe',
              'successiveLeaseTerm',
              'autoSendToTRACS',
            ],
            propertyInfo.config,
          )
        : {},
      hubPremiumFeatures: propertyInfo.hubPremiumFeatures ?? [],
      propertyType: propertyInfo.propertyClass?.propertyType ?? 'Conventional',
      isTwoWayCommunicationActive:
        propertyInfo.isTwoWayCommunicationActive ?? false,
      textingPhoneNumber:
        propertyInfo.phoneNumbers?.find((p) => p.campaign === 'Customer Care')
          ?.phoneNumber ?? '',
      paymentProviders: propertyInfo.paymentProviders ?? [],
    };
  };

  const updateProperty = async (
    data: Object,
    intl: Object,
    throwError?: boolean,
  ) => {
    const propertyService = new PropertyService();

    try {
      const response = await propertyService.update(
        organizationId,
        propertyId,
        data,
      );

      if (response && isMounted.current) {
        setPropertyInfo(handleNullValues(response));
        selectProperty(response);
      }
    } catch (e) {
      if (isMounted.current) {
        setPropertyInfo({ ...propertyInfo });
      }
      if (throwError) {
        throw PROPERTY_UPDATE_ERROR;
      }
    }
  };

  const updatePropertyIntegrations = async (
    integrationsChanges: Object,
    intl: Object,
    throwError?: boolean,
  ) => {
    const integrationService = new IntegrationService();
    if (!integrationsChanges) {
      return;
    }
    // filter out null/undefined values
    const previousIntegrations = propertyInfo.propertyIntegrations;
    const integrations = Object.values(integrationsChanges).filter((x) => x);
    const integrationUpdateRequests = integrations.map((inte) => {
      return integrationService.updateIntegrationProperty(
        organizationId,
        propertyId,
        inte.integrationId,
        { isActive: inte.value },
      );
    });
    try {
      const updatedIntegrations = await Promise.all(integrationUpdateRequests);
      return updatedIntegrations;
    } catch (error) {
      if (isMounted.current) {
        setPropertyInfo({
          ...propertyInfo,
          propertyIntegrations: previousIntegrations,
        });
      }
      if (throwError) {
        throw INTEGRATION_UPDATE_ERROR;
      }
    }
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchPropertyDetails = async () => {
      try {
        const propertyService = new PropertyService();
        setIsLoading(true);
        const response = await propertyService.getPropertyById(
          organizationId,
          propertyId,
          options,
        );
        if (response && subscribed) {
          setPropertyInfo(handleNullValues(response));
          setIsLoading(false);
        }
      } catch (error) {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.fetchPropertyErrorBody),
        );
      }
    };
    if (subscribed && !isEmpty(organizationId)) {
      fetchPropertyDetails();
    }
    return () => {
      subscribed = false;
    };
  }, [organizationId, propertyId, setPropertyInfo, intl]);

  return [propertyInfo, updateProperty, updatePropertyIntegrations, isLoading];
};

export const useForm = (initialValues: Object) => {
  const [values, setValues] = useState(initialValues);

  const onChange = useCallback(({ target: { name, value } }: ChangeEvent) => {
    const path = typeof name === 'string' ? name.split('.') : [];
    setValues((prevState) => assocPath(path, value, prevState));
  }, []);

  return [values, setValues, onChange];
};

export const useAsyncMarketingSection = (
  organizationId: string,
  propertyId: string,
) => {
  const isMounted = useRef(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [marketingInfo, setMarketingInfo] = useState({
    name: '',
    propertyLogoLink: '',
    pitch1: '',
    officeHoursDescription: '',
    propertyDescription: '',
  });

  const handleNullValues = (marketingInfo: Object) => ({
    name: marketingInfo.name,
    propertyLogoLink: marketingInfo?.propertyLogoLink ?? '',
    pitch1: marketingInfo?.pitch1 ?? '',
    officeHoursDescription: marketingInfo?.officeHoursDescription ?? '',
    propertyDescription: marketingInfo?.propertyDescription ?? '',
  });

  const updateMarketingSection = async (
    data: Object,
    intl: Object,
    throwError?: boolean,
  ) => {
    const marketingSectionService = new MarketingSectionService();

    try {
      const response = await marketingSectionService.update(
        organizationId,
        propertyId,
        data,
      );

      if (response && isMounted.current) {
        setMarketingInfo(handleNullValues(response));
      }
    } catch (err) {
      if (isMounted.current) {
        setMarketingInfo({ ...marketingInfo });
      }
      if (throwError) {
        throw MARKETING_INFO_UPDATE_ERROR;
      }
    }
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchMarketingDetails = async () => {
      const marketingSectionService = new MarketingSectionService();
      setIsLoading(true);
      const response = await marketingSectionService.getProperty(
        organizationId,
        propertyId,
        options,
      );
      if (response && subscribed) {
        setMarketingInfo(handleNullValues(response));
        setIsLoading(false);
      }
    };

    if (subscribed && !isEmpty(organizationId)) {
      fetchMarketingDetails();
    }
    return () => {
      subscribed = false;
    };
  }, [organizationId, propertyId, setMarketingInfo]);

  return [marketingInfo, updateMarketingSection, isLoading];
};

export const useAsyncMasterLeaseTerms = (organizationId: string) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [masterLeaseTerms, setMasterLeaseTerms] = useState([]);

  useEffect(() => {
    (async () => {
      const masterLeaseTermsService = new MasterLeaseTermsService();
      const response = await masterLeaseTermsService.getAllMasterLeaseTerms(
        organizationId,
      );

      if (isMounted.current && response) {
        setMasterLeaseTerms(response);
      }
    })();
  }, [organizationId]);

  return [masterLeaseTerms];
};

export const useAsyncProrateMethods = (): any => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [prorateMethods, setProrateMethods] = useState([]);

  useEffect(() => {
    (async () => {
      const prorateMethodsService = new ProrateMethodsService();
      const response = await prorateMethodsService.getAll();
      if (isMounted.current && response) {
        setProrateMethods(response);
      }
    })();
  }, []);

  return [prorateMethods];
};

export const useAsyncValidateFirstOfMonthPostEnabled = ({
  propertyId,
  organizationId,
}: Object): any => {
  const [enabledStatusMLT, setEnabledStatusMLT] = useState(null);
  const [errorStatusMLT, setErrorStatusMLT] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const propertyService = new PropertyService();
        const response = await propertyService.checkEnableFirstOfMonthPosting(
          organizationId,
          propertyId,
          abortController.signal,
        );
        setEnabledStatusMLT(response.enable);
        setErrorStatusMLT(response.error);
        setIsLoading(false);
      }
    };

    fetch();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, shouldRefresh]);

  return [isLoading, enabledStatusMLT, errorStatusMLT];
};

export function usePostFirstOfMonthMLT(
  intl: any,
  propertyId: string,
  organizationId: string,
  toasterFn: Function,
) {
  const [isPosting, setIsPosting] = useState(false);

  const postMLT = async () => {
    if (isPosting) return;

    const service = new PropertyService();

    setIsPosting(true);

    try {
      const response = await service.postFirstOfMonthMLT(
        organizationId,
        propertyId,
      );
      if (response.error) throw new Error(response.error);
      toasterFn({
        type: 'info',
        title: intl.formatMessage(messages.inProgress),
        message: intl.formatMessage(messages.postMLTInProgress),
      });
    } catch (e) {
      toasterFn({
        type: 'error',
        message: e.toString(),
        title: intl.formatMessage(messages.error),
      });
    }

    setIsPosting(false);
  };

  return [postMLT, isPosting];
}
export function usePropertyRoles({ organizationId, propertyId }) {
  const { propertyRoles, isLoading, roleAssignments, setRoleAssignments } =
    useFetchPropertyRoleAssignments(organizationId, propertyId);

  const handleAssignmentChange = (propertyRoleId, userId) => {
    const updatedRoleAssignments = roleAssignments.map((roleAssignment) => {
      if (roleAssignment.id === propertyRoleId) {
        return {
          ...roleAssignment,
          roleAssignment: {
            id: userId,
            name: propertyRoles
              .find((role) => role.id === propertyRoleId)
              ?.assignmentOptions?.find((option) => {
                return option.id === userId;
              })?.name,
          },
        };
      }
      return roleAssignment;
    });
    setRoleAssignments(updatedRoleAssignments);
  };
  const assignPropertyRoles = useAssignPropertyRoles({
    propertyId,
    roleAssignments: roleAssignments.filter((ra) => {
      return !!ra.roleAssignment.id && ra.roleAssignment.id !== '';
    }),
  });

  return {
    propertyRoles,
    isLoading,
    roleAssignments,
    setRoleAssignments,
    handleAssignmentChange,
    assignPropertyRoles,
  };
}

const useFetchPropertyRoleAssignments = (organizationId, propertyId) => {
  const getName = (user) => {
    if (!user?.firstName || !user?.lastName) return null;
    return `${user?.firstName} ${user?.lastName}`;
  };
  const roleService = new RoleService();
  const queryKey = ['propertyRoleAssignments', organizationId, propertyId];
  const options = {
    initialData: [],
  };
  const { data, isLoading } = useQuery(
    queryKey,
    () => roleService.getPropertyRoleAssignments(organizationId, propertyId),
    options,
  );
  const propertyRoles = useMemo(() => {
    return data.map((datum) => {
      const assignmentOptions = [];
      datum.userRoles.forEach((userRole) => {
        assignmentOptions.push(
          userRole.assignedUsers.map((user) => {
            return {
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
            };
          }),
        );
      });

      return {
        ..._.omit(datum, ['userRoles', 'propertyRoleAssignments']),
        assignmentOptions: assignmentOptions
          .flat()
          .sort((a, b) => a.name.localeCompare(b.name)),
        currentAssignedUser: {
          id: datum.propertyRoleAssignments?.[0]?.assignedUser.id,
          name: getName(datum.propertyRoleAssignments?.[0]?.assignedUser),
        },
      };
    });
  }, [data]);

  const initialRoleAssignments = useMemo(() => {
    return propertyRoles.map((propertyRole) => {
      return {
        id: propertyRole?.id,
        name: propertyRole?.name,
        roleAssignment: {
          id: propertyRole?.currentAssignedUser?.id,
          name: propertyRole?.currentAssignedUser?.name,
        },
      };
    });
  }, [propertyRoles]);

  const [roleAssignments, setRoleAssignments] = useState([]);

  useEffect(() => {
    setRoleAssignments(initialRoleAssignments);
  }, [initialRoleAssignments]);

  return {
    propertyRoles,
    isLoading,
    roleAssignments,
    setRoleAssignments,
  };
};

export const useAssignPropertyRoles = ({ propertyId, roleAssignments }) => {
  const queryClient = useQueryClient();
  const roleService = new RoleService();
  const assignPropertyRoles = async () => {
    return roleService.assignPropertyRoles(propertyId, roleAssignments);
  };

  const assignPropertyRolesMutation = useMutation(assignPropertyRoles, {
    onSuccess: () => {
      queryClient.invalidateQueries('propertyRoleAssignments');
    },
  });
  return assignPropertyRolesMutation.mutate;
};

export const useMoveOutProrateMethods = () => {
  const queryKey = 'moveOutProrateMethods';
  const options = {
    initialData: [],
  };
  const { data } = useQuery(
    queryKey,
    () => {
      const prorateMethodsService = new ProrateMethodsService();
      return prorateMethodsService.getMoveOutProrateMethods();
    },
    options,
  );

  return data;
};
