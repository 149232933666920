import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Typography,
  Switch,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  VirtualizedTable,
  OverviewKPIs,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  VisibilityIcon,
  VisibilityOffIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import TableLayout from '../../components/TableLayout';
import { Collapse } from '@fortress-technology-solutions/fortress-component-library/Transitions';
import { connect } from 'react-redux';
import { usePortfolio } from './hooksV2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appMessages from '../App/messages';

const Portfolio = ({ organizationId, userId }) => {
  const { portfolioKpiTiles } = useFlags();

  const {
    KPIData,
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleSortChange,
    isCSVExportLoading,
    isLoading,
    isPDFExportLoading,
    name,
    onCSVButtonClick,
    onPDFButtonClick,
    order,
    orderBy,
    refreshedAt,
    rows,
    selectedColumns,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    dateState,
    searchState,
  } = usePortfolio({ organizationId, userId });

  const [isOverviewVisible, setOverviewVisible] = useState(true);

  return (
    <TableLayout name={name} initialTableHeight={'calc(100dvh - 537px)'}>
      {(tableHeight) => (
        <>
          <>
            {portfolioKpiTiles && (
              <Switch
                sx={{ paddingX: 1 }}
                checked={isOverviewVisible}
                onChange={() => setOverviewVisible(!isOverviewVisible)}
                label={<FormattedMessage {...appMessages.kpiData} />}
                icon={<VisibilityOffIcon />}
                checkedIcon={<VisibilityIcon />}
              />
            )}

            <Collapse in={isOverviewVisible}>
              <OverviewKPIs
                isLoading={isLoading}
                data={KPIData}
                sx={{ minHeight: 284, marginX: -2, paddingX: 2 }}
              />
            </Collapse>
          </>

          <VirtualizedTable
            ReactBeautifulDnD={{
              DragDropContext,
              Droppable,
              Draggable,
            }}
            ReactRouterLink={ReactRouterLink}
            allColumnsHidden={allColumnsHidden}
            columnOptions={columnOptions}
            dateState={dateState}
            filterState={filterState}
            filterTypeState={filterTypeState}
            footerRow={footerRow}
            handleColumnChange={handleColumnChange}
            handleDateSubmit={handleDateSubmit}
            handleFilterChange={handleFilterChange}
            handleFilterTypeChange={handleFilterTypeChange}
            handleSearchSubmit={handleSearchSubmit}
            handleSortChange={handleSortChange}
            headers={filteredHeaders}
            height={tableHeight}
            isLoading={isLoading}
            name={name}
            onCSVButtonClick={onCSVButtonClick}
            onPDFButtonClick={onPDFButtonClick}
            isCSVExportLoading={isCSVExportLoading}
            isPDFExportLoading={isPDFExportLoading}
            order={order}
            orderBy={orderBy}
            rows={rows}
            searchState={searchState}
            selectedColumns={selectedColumns}
            sx={{
              paddingTop: 0,
              paddingRight: 0,
              paddingLeft: 0,
              paddingBottom: 0,
            }}
          />
          {refreshedAt && (
            <Typography
              variant={'caption'}
              component={'div'}
              textAlign={'right'}
              margin={1}
              marginBottom={0}
            >{`Last updated: ${refreshedAt}`}</Typography>
          )}
        </>
      )}
    </TableLayout>
  );
};

export const mapStateToProps = (state: Object): Object => {
  const {
    app: { currentUser },
  } = state;
  return {
    userId: currentUser?.user?.id ?? '',
    organizationId: currentUser?.user?.organizationId ?? '',
  };
};
export default connect(mapStateToProps)(Portfolio);
