import { useRef, useState } from 'react';
import { useResizeObserver } from '@fortress-technology-solutions/fortress-component-library/hooks';

const ManageCertificationsLayout = ({ children }) => {
  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState('calc(100vh - 40px)');

  useResizeObserver({
    ref,
    callback: (entry) => {
      let newTableHeight = 0;
      // loop through children and set height
      entry.target.childNodes.forEach((child) => {
        if (child.id !== 'tableContainer') {
          newTableHeight += child.clientHeight;
        }
      });

      setTableHeight(`calc(100vh - ${newTableHeight + 44 + 80}px)`);
    },
  });

  return (
    <div ref={ref} style={{ height: 'calc(100vh - 40px)', overflow: 'hidden' }}>
      {children(tableHeight)}
    </div>
  );
};

export default ManageCertificationsLayout;
