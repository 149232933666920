import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import type {
  FetchCalculationsHookProps,
  GenerateCalculationsHookProps,
  ConfirmEstimatesHookProps,
  FetchRetroactiveTransactionsHookProps,
} from './types';
import CamCalculationService from '../../../../services/camCalculationService';
import CamReconciliationTypesService from '../../../../services/camReconciliationTypesService';
import CamReconciliationService from '../../../../services/camReconciliationService';
import CamRecordService from '../../../../services/camRecordService';
import messages from './messages';

export const useFetchCalculations = ({
  intl,
  propertyId,
  organizationId,
  selectedCamRecord,
  promptToaster,
}: FetchCalculationsHookProps): any => {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [calculations, setCalculations] = useState([]);
  const [onSuccessCb, setOnSuccessCb] = useState(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchCalculations = async () => {
      setIsLoading(true);
      const camCalculationService = new CamCalculationService();
      const response = await camCalculationService.getAllByRecordId(
        organizationId,
        propertyId,
        selectedCamRecord.id,
        abortController.signal,
      );
      setCalculations(response);
      setIsLoading(false);
      if (onSuccessCb) {
        onSuccessCb();
      }
    };
    if (selectedCamRecord && selectedCamRecord.id) {
      fetchCalculations();
    }
    setRefresh(false);
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    intl,
    organizationId,
    propertyId,
    selectedCamRecord,
    promptToaster,
    refresh,
  ]);

  return [
    calculations,
    isLoading,
    (newOnSuccessCb) => {
      setRefresh(true);
      setOnSuccessCb(newOnSuccessCb);
    },
  ];
};

export const useGenerateCamCalculations = ({
  intl,
  organizationId,
  propertyId,
  selectedCamRecordId,
  promptToaster,
  refreshCalculations,
  refreshReconciliations,
}: GenerateCalculationsHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);

  const generateCamCalculations = async (onCalculationsRefreshed) => {
    try {
      setIsLoading(true);
      if (!propertyId || !selectedCamRecordId) {
        throw new Error('Could not generate CAM calculations');
      }
      const camCalculationService = new CamCalculationService();
      await camCalculationService.generateCamCalculations(
        organizationId,
        propertyId,
        selectedCamRecordId,
      );
      refreshCalculations(onCalculationsRefreshed);
      refreshReconciliations();
      promptToaster({
        type: 'success',
        title: intl.formatMessage(messages.generateCalculationsSuccessTitle),
        message: intl.formatMessage(
          messages.generateCalculationsSuccessMessage,
        ),
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.generateCalculationsError),
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return [generateCamCalculations, isLoading];
};

export const useConfirmCamEstimates = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  selectedCamRecord,
  promptToaster,
  onEstimatesConfirmed,
}: ConfirmEstimatesHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const confirmCAMEstimates = async (
    transactionsToReassign?: Array<Object>,
  ) => {
    setIsLoading(true);
    const camRecordsId = pathOr(null, ['id'], selectedCamRecord);
    try {
      if (!propertyId || !camRecordsId) {
        throw new Error('Could not confirm CAM estimates');
      }
      const camCalculationService = new CamCalculationService();
      await camCalculationService.confirmCamEstimates(
        organizationId,
        propertyId,
        camRecordsId,
        transactionsToReassign,
      );

      promptToaster({
        type: 'success',
        title: intl.formatMessage(messages.confirmCAMestimatesSuccessTitle),
        message: intl.formatMessage(messages.confirmCAMestimatesSuccessMessage),
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.confirmCAMestimatesError),
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      onEstimatesConfirmed();
    }
  };

  return [confirmCAMEstimates, isLoading];
};

export const useFetchReconciliationTypes = (): any => {
  const [reconciliationTypes, setReconciliationTypes] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchReconciliationTypes = async () => {
      const camReconciliationTypesService = new CamReconciliationTypesService();
      const response = await camReconciliationTypesService.getAll(
        abortController.signal,
      );
      setReconciliationTypes(response);
    };
    if (!reconciliationTypes || reconciliationTypes.length === 0) {
      fetchReconciliationTypes();
    }
    return () => abortController.abort();
  }, [reconciliationTypes]);

  return [reconciliationTypes];
};

export const usePostCAMReconciliation = ({
  intl,
  organizationId,
  propertyId,
  selectedCamRecord,
  promptToaster,
  onHide,
  refreshReconciliations,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const postCAMReconciliation = async (payload: Object) => {
    setIsLoading(true);
    const camRecordsId = pathOr(null, ['id'], selectedCamRecord);
    try {
      if (!propertyId || !camRecordsId) {
        throw new Error('Could not reconcile CAM');
      }
      const camReconciliationService = new CamReconciliationService();
      await camReconciliationService
        .postReconciliation(organizationId, propertyId, camRecordsId, payload)
        .then(() => {
          refreshReconciliations();
          onHide();
          setIsLoading(false);
        });
      promptToaster({
        type: 'info',
        title: intl.formatMessage(messages.reconcileCAMSuccessTitle),
        message: intl.formatMessage(messages.reconciliationPendingMessage),
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.reconcileCAMError),
        message: err.toString(),
      });
    }
  };

  return [postCAMReconciliation, isLoading];
};

export const useFetchRetroactiveTransactions = ({
  intl,
  organizationId,
  propertyId,
  camRecordsId,
  promptToaster,
  onComplete,
}: FetchRetroactiveTransactionsHookProps): any => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchData = async () => {
      if (propertyId && organizationId && camRecordsId) {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getRetroactiveTransactions(
          organizationId,
          propertyId,
          camRecordsId,
        );

        setIsLoading(false);
        setTransactions(response);
        onComplete(response);
      }
    };

    if (shouldRefresh) {
      fetchData();
      setShouldRefresh(false);
    }

    return () => abortController.abort();
  }, [organizationId, propertyId, camRecordsId, shouldRefresh, onComplete]);

  return [isLoading, transactions, () => setShouldRefresh(true)];
};

export const useFetchCamPools = ({
  propertyId,
  organizationId,
  householdId,
}: FetchCamPoolsProps): any => {
  const [camPools, setCamPools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchCamRecords = async () => {
      if (organizationId && propertyId && householdId) {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getCamPools(
          organizationId,
          propertyId,
          householdId,
          true,
          abortController.signal,
        );
        setCamPools(response);
        setIsLoading(false);
      }
    };

    fetchCamRecords();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, householdId, shouldRefresh]);

  return [isLoading, camPools, () => setShouldRefresh(true)];
};
