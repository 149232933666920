import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Components
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import ManageCertificationsTabs from './ManageCertificationsTabs';
import CertificationsSummary from './CertificationsSummary';
import ManageCertificationModals from './ManageCertificationModals';
import CertificationNotes from './CertificationNotes';
import ManageCertificationsLayout from './ManageCertificationsLayout';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import GenerateAll59AsButton from './GenerateAll59AsButton';
import { GROSS_RENT_CERT_TYPE } from '../../constants/affordableCertificationTypes';
import ManageCertificationsLegend from './ManageCertificationsLegend';

// Hooks
import { useManageCertifications } from './hooksV2';
import { useFlags } from 'launchdarkly-react-client-sdk';

const messages = { ...generalMessages, ...componentMessages };

const NAME = 'manage-certifications';

export const ManageCertifications = ({ intl }) => {
  const {
    allColumnsHidden,
    columnOptions,
    count,
    countName,
    dateState,
    disableDownloadAll59AsButton,
    editGrossRentCertificationMutation,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    hasSelectedCertificationType,
    isLoading,
    modalProps,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    propertyAffordablePrograms,
    refetchCertifications,
    rows,
    searchState,
    selectedCertificationType,
    selectedColumns,
    setSelectedCertificationType,
    signed59ADocumentType,
    sortedAndFilteredResults,
    summary,
    totalCount,
    truncatedColumns,
    uploadSigned59AMutation,
  } = useManageCertifications({ NAME });

  const { grossRentEnhancements: grossRentEnhancementsFlag } = useFlags();

  return (
    <DocumentTitle
      data-testid={'manage-certifications'}
      title={'Manage Certifications'}
    >
      <>
        <ManageCertificationsLayout>
          {(tableHeight) => (
            <>
              <TableHeader
                title={'Manage Certifications'}
                id={'tableHeader'}
                actions={
                  selectedCertificationType === GROSS_RENT_CERT_TYPE ? (
                    <GenerateAll59AsButton
                      disabled={disableDownloadAll59AsButton}
                      intl={intl}
                    />
                  ) : undefined
                }
                Tabs={
                  <ManageCertificationsTabs
                    propertyAffordablePrograms={propertyAffordablePrograms}
                    selectedCertificationType={selectedCertificationType}
                    setSelectedCertificationType={setSelectedCertificationType}
                  />
                }
                count={hasSelectedCertificationType ? count : undefined}
                countName={countName}
                totalCount={totalCount}
              />
              {grossRentEnhancementsFlag && (
                <ManageCertificationsLegend
                  certificationType={selectedCertificationType}
                />
              )}
              <CertificationsSummary
                propertyAffordablePrograms={propertyAffordablePrograms}
                summary={summary}
              />
              <CertificationNotes
                sortedAndFilteredResults={sortedAndFilteredResults}
                selectedCertificationType={selectedCertificationType}
              />

              <Stack
                id={'tableContainer'}
                direction={'column'}
                sx={{ position: 'relative', height: '100%', width: '100%' }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography
                  component={'div'}
                  sx={{
                    zIndex: 1,
                    position: 'absolute',
                    top: `calc(${tableHeight} / 2)`,
                    transition: ({ transitions: { create } }) =>
                      create(['opacity']),
                    opacity: hasSelectedCertificationType ? 0 : 1,
                  }}
                >
                  <FormattedMessage {...messages.selectOption} />
                </Typography>
                <VirtualizedTable
                  sx={{
                    opacity: hasSelectedCertificationType ? 1 : 0,
                    transition: ({ transitions: { create } }) =>
                      create(['opacity']),
                    zIndex: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  ReactBeautifulDnD={{
                    DragDropContext,
                    Droppable,
                    Draggable,
                  }}
                  ReactRouterLink={ReactRouterLink}
                  allColumnsHidden={allColumnsHidden}
                  columnOptions={columnOptions}
                  dateState={dateState}
                  filterState={filterState}
                  filterTypeState={filterTypeState}
                  handleColumnChange={handleColumnChange}
                  handleDateSubmit={handleDateSubmit}
                  handleFilterChange={handleFilterChange}
                  handleFilterTypeChange={handleFilterTypeChange}
                  handleSearchSubmit={handleSearchSubmit}
                  handleSortChange={handleSortChange}
                  handleTruncateChange={handleTruncateChange}
                  headers={filteredHeaders}
                  height={tableHeight}
                  isLoading={isLoading}
                  name={name}
                  onCSVButtonClick={
                    hasSelectedCertificationType && count > 0
                      ? onCSVButtonClick
                      : undefined
                  }
                  order={order}
                  orderBy={orderBy}
                  rows={rows}
                  count={count}
                  searchState={searchState}
                  selectedColumns={selectedColumns}
                  truncatedColumns={truncatedColumns}
                />
              </Stack>
            </>
          )}
        </ManageCertificationsLayout>
        <ManageCertificationModals
          {...modalProps}
          signed59ADocumentType={signed59ADocumentType}
          uploadSigned59AMutation={uploadSigned59AMutation}
          editGrossRentCertificationMutation={
            editGrossRentCertificationMutation
          }
          refetchCertifications={refetchCertifications}
          propertyAffordablePrograms={propertyAffordablePrograms}
        />
      </>
    </DocumentTitle>
  );
};

export default injectIntl(ManageCertifications);
