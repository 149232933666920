import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  all,
  any,
  concat,
  equals,
  find,
  isEmpty,
  isNil,
  not,
  pathOr,
  propEq,
} from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubmitButton from '../../../components/SubmitButton';
import Recipients from './Recipients';
import ResidentLettersModal from '../../ManageDocuments/ResidentLettersModal';
import * as manageCommunicationsActions from '../actions';
import {
  getCreatedAt,
  getDocumentTypesOptions,
  getMetricsInformation,
  getTextingPhoneNumber,
} from '../selectors';
import confirm from '../../../components/ConfirmDialogModal';
import Spinner from '../../../components/Spinner';
import messages from '../messages';
import type { GlobalState, Property } from '../../App/types';
import CommunicationInput from './CommunicationInput';
import { validateEmail } from '../../../utils/validations';
import createCommunicationMessages from './messages';
import DocumentTitle from 'react-document-title';
import { getCurrentUser } from '../../App/selectors';
import { promptToaster } from '../../App/actions';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import type { FeatureFlags } from '../../../types';

type Props = {
  intl: any,
  selectedProperty: Property,
  isLoading: boolean,
  communicationBatchGenerated: boolean,
  emailsValidated: boolean,
  phonesValidated: boolean,
  actions: Object,
  history: Object,
  response: Object,
  currentCommunication: Object,
  location: Object,
  selectedResidents: Array<Object>,
  documentTypes: Array<Object>,
  validEmails: Array<any>,
  validPhones: Array<any>,
  metricsInformation: Object,
  createdAt: Date,
  fromEmailValidation: boolean,
  isGetCommunication: boolean,
  currentUser: any,
  textingPhoneNumber?: string,
  flags: FeatureFlags,
};

type State = {
  isModalOpen: boolean,
  documentTypeId: string,
  communicationType: string,
  communicationData: Object,
  validEmails: Array<any>,
  validPhones: Array<any>,
  validEmailsCopied: boolean,
  validPhonesCopied: boolean,
  emailLetter: boolean,
};

export class CreateCommunication extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (
      !props.selectedResidents.length &&
      props.location.pathname !==
        `/property/${props.selectedProperty.id}/view-communication`
    ) {
      navigateToUrlWithSelectedPropertyId('/manage-communications');
    } else if (
      props.location.pathname ===
        `/property/${props.selectedProperty.id}/view-communication` &&
      !props.isGetCommunication
    ) {
      navigateToUrlWithSelectedPropertyId('/communications');
    }
    let emailFromText = '';
    let emailFromOverridden = false;
    if (props.flags.disabledEmailFrom) {
      emailFromText = `${props.selectedProperty.name
        .replace(/\s/g, '')
        .replace(/[^\w\s]/gi, '')}@elmington.io`;
      emailFromOverridden = true;
    }

    this.state = {
      isModalOpen: false,
      documentTypeId: '',
      communicationType:
        props.location.pathname !==
        `/property/${props.selectedProperty.id}/view-communication`
          ? 'EMAIL'
          : '',
      communicationData: {
        smsText: '',
        smsTitle: '',
        emailFromText,
        emailFromOverridden,
        emailSubjectText: '',
        emailTitleText: '',
        emailBodyText: '',
        emailFromValidated: true,
        emailAttachment: { attachmentUrl: '', fileName: '' },
      },
      validEmails: [],
      validPhones: [],
      validEmailsCopied: false,
      validPhonesCopied: false,
      emailLetter: false,
    };
  }

  componentDidMount() {
    this.props.actions.resetCommunicationsGenerated();
    const { location } = this.props;
    if (
      location.pathname !==
      `/property/${this.props.selectedProperty.id}/view-communication`
    ) {
      this.props.actions.getDocumentTypes();
      this.validateRecipients();
    }
  }
  validateRecipients = () => {
    let communicationType = this.state.communicationType;
    let currentRecipients = this.props.selectedResidents;
    let recipientsType = pathOr(
      '',
      ['state', 'recipientType'],
      this.props.location,
    );
    if (
      this.props.location.pathname ===
      `/property/${this.props.selectedProperty.id}/view-communication`
    ) {
      const currentCommunication = this.props.currentCommunication;
      currentRecipients = currentCommunication.recipients;
      communicationType = currentCommunication.communicationType;
      recipientsType = currentCommunication.communicationRecipientType;
    }
    if (communicationType) {
      switch (communicationType) {
        case 'LETTER':
        case 'EMAIL':
          if (!this.state.validEmailsCopied) {
            this.props.actions.getValidateRecipients({
              recipients: currentRecipients.map((recipient) => {
                let customerId = recipient.customerId;
                let isValidEmail = recipient.isValidEmail;
                if (recipientsType === 'Applicant') {
                  if (
                    this.props.location.pathname !==
                    `/property/${this.props.selectedProperty.id}/view-communication`
                  ) {
                    const applicant = recipient.applicants.find(
                      (a) => (a.name = recipient.name),
                    );
                    customerId = pathOr(
                      '',
                      ['applicantCustomer', 'customerId'],
                      applicant,
                    );
                    isValidEmail = pathOr(
                      null,
                      ['applicantCustomer', 'customer', 'isValidEmail'],
                      applicant,
                    );
                  } else {
                    customerId = pathOr('', ['id'], recipient);
                  }
                }
                return {
                  email: recipient.emailAddress,
                  isValidEmail,
                  customerId,
                  prospectId:
                    recipientsType === 'Prospect' ? recipient.id : undefined,
                  propertyId:
                    recipientsType === 'Prospect'
                      ? recipient.propertyId
                      : undefined,
                };
              }),
              communicationType,
              recipientsType,
            });
          }
          break;
        case 'SMS':
          if (!this.state.validPhonesCopied) {
            const recipients = currentRecipients.map(
              (recipient) => recipient.phoneNumber,
            );
            this.props.actions.getValidateRecipients({
              recipients,
              communicationType,
            });
          }
          break;
        default:
          break;
      }
    }
  };

  toggleEmailLetter = (emailLetter) => {
    this.setState({ emailLetter: emailLetter });
  };

  openResidentsLetterModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleFormSubmit = ({ residentLetterId }: Object) => {
    this.setState({ documentTypeId: residentLetterId, isModalOpen: false });
  };

  handleCommunicationTypeChange = (event: Object) => {
    if (
      !this.props.communicationBatchGenerated &&
      this.props.location.pathname !==
        `/property/${this.props.selectedProperty.id}/view-communication` &&
      this.state.communicationType !== event.target.value
    ) {
      let emailFromText = '';
      let emailFromOverridden = false;
      if (this.props.flags.disabledEmailFrom) {
        emailFromText = `${this.props.selectedProperty.name
          .replace(/\s/g, '')
          .replace(/[^\w\s]/gi, '')}@elmington.io`;
        emailFromOverridden = true;
      }
      this.setState({
        communicationType: event.target.value,
        documentTypeId: '',
        communicationData: {
          smsText: '',
          smsTitle: '',
          emailFromText,
          emailFromOverridden,
          emailSubjectText: '',
          emailTitleText: '',
          emailBodyText: '',
          emailFromValidated: true,
          emailAttachment: { attachmentUrl: '', fileName: '' },
        },
      });
    }
  };

  handleCommunicationEmailAttachmentChanges = (emailAttachment: Object) => {
    const communicationData = {
      ...this.state.communicationData,
      emailAttachment,
    };
    this.setState({ communicationData });
  };

  validateFromEmail = () => {
    const newCommunicationData = Object.assign(
      {},
      this.state.communicationData,
    );
    newCommunicationData.emailFromValidated = false;
    if (validateEmail(this.state.communicationData.emailFromText)) {
      this.props.actions.getValidateEmail({
        email: this.state.communicationData.emailFromText,
      });
    }
    this.setState({ communicationData: newCommunicationData });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.phonesValidated && !this.state.validPhonesCopied) {
      this.setState({
        validPhones: this.props.validPhones,
        validPhonesCopied: true,
      });
    }
    if (this.props.emailsValidated && !this.state.validEmailsCopied) {
      this.setState({
        validEmails: this.props.validEmails,
        validEmailsCopied: true,
      });
    }
    if (
      this.props.location.pathname !==
      `/property/${this.props.selectedProperty.id}/view-communication`
    ) {
      if (
        !this.state.communicationData.emailFromValidated &&
        this.props.fromEmailValidation &&
        validateEmail(this.state.communicationData.emailFromText)
      ) {
        const newCommunicationData = Object.assign(
          {},
          this.state.communicationData,
        );
        newCommunicationData.emailFromValidated = true;
        this.setState({ communicationData: newCommunicationData });
      }
      if (prevState.communicationType !== this.state.communicationType) {
        this.validateRecipients();
      }
    }
    if (
      this.props.location.pathname ===
      `/property/${this.props.selectedProperty.id}/view-communication`
    ) {
      if (
        !this.state.communicationType &&
        this.props.currentCommunication.communicationType
      ) {
        this.setState({
          communicationType: this.props.currentCommunication.communicationType,
        });
        this.validateRecipients();
      }
    }
  }

  handleInputTextChange = (
    event: Object,
    index: string,
    capitalize?: boolean,
  ) => {
    const newState = Object.assign({}, this.state);
    if (capitalize) {
      const newValue = event.target.value;
      newState['communicationData'][index] =
        newValue.charAt(0).toUpperCase() + newValue.slice(1);
    } else {
      newState['communicationData'][index] = event.target.value;
    }
    this.setState(newState);
    if (index === 'emailFromText') {
      this.validateFromEmail();
    }
  };

  getDocumentNameById = (id: string) => {
    const doc = find(propEq('value', id))(this.props.documentTypes);
    return pathOr('Resident-Letter', ['text'], doc);
  };

  closeResidentsLetterModal = () => {
    this.setState({ isModalOpen: false });
  };

  generateCommunication = () => {
    const currentCustomerStatus = this.props.location.state.recipientType;

    confirm(this.props.intl.formatMessage(messages.generateConfirmation), {
      intl: this.props.intl,
    }).then(() => {
      let recipients;
      let payload;
      switch (this.state.communicationType) {
        case 'SMS':
          recipients = this.props.selectedResidents
            .map((recipient) => {
              const validPhone = this.state.validPhones.includes(
                recipient.phoneNumber,
              );
              return { id: recipient.id, validPhone };
            })
            .filter((recipients) => recipients.validPhone);
          payload = {
            smsText: this.state.communicationData.smsText,
            smsTitle: this.state.communicationData.smsTitle,
          };
          break;
        case 'EMAIL':
          recipients = this.props.selectedResidents.map((recipient) => {
            const validEmail = this.state.validEmails.includes(
              recipient.emailAddress,
            );
            return { id: recipient.id, validEmail };
          });
          payload = {
            from: this.state.communicationData.emailFromText,
            subject: this.state.communicationData.emailSubjectText,
            title: this.state.communicationData.emailTitleText,
            body: this.state.communicationData.emailBodyText,
            attachment: this.state.communicationData.emailAttachment,
          };
          break;
        default:
          let selectedResidents = this.props.selectedResidents;
          if (
            selectedResidents.every((resident) =>
              Number.isInteger(resident.unitOrder),
            )
          ) {
            selectedResidents = selectedResidents.sort((a, b) =>
              a.unitOrder > b.unitOrder ? 1 : -1,
            );
          }
          recipients = selectedResidents.map((recipient) => recipient.id);
          payload = {
            documentType: this.state.documentTypeId,
            fileName: this.getDocumentNameById(this.state.documentTypeId)
              .split(' ')
              .join('_'),
          };
      }

      this.props.actions.generateCommunicationBatch({
        communicationType: this.state.communicationType,
        customerStatus: currentCustomerStatus,
        recipients,
        emailLetter: this.state.emailLetter,
        payload,
      });
    });
  };

  downloadCommunication = () => {
    const communicationId = pathOr(
      null,
      ['communicationData', 'documentId'],
      this.props.currentCommunication,
    );

    const documentName = pathOr(
      '',
      ['communicationData', 'documentName'],
      this.props.currentCommunication,
    );

    this.props.actions.downloadCommunication(communicationId, documentName);
  };

  goToManageCommunications = () => {
    navigateToUrlWithSelectedPropertyId('/manage-communications');
  };

  shouldBeDisabled = (communicationType: string) => {
    if (
      this.props.location.pathname ===
      `/property/${this.props.selectedProperty.id}/view-communication`
    ) {
      return communicationType !== this.state.communicationType;
    }
    const { location, selectedResidents } = this.props;
    const recipientsType = pathOr('', ['state', 'recipientType'], location);
    if (
      communicationType === 'LETTER' &&
      (recipientsType === 'Prospect' || recipientsType === 'Applicant')
    ) {
      return true;
    }

    if (communicationType === 'SMS') {
      if (recipientsType === 'Prospect' && selectedResidents.length > 1) {
        return true;
      }

      if (this.props.flags['2WayTextingV1'] && !this.props.textingPhoneNumber) {
        return true;
      }
    }

    return this.props.isLoading || this.props.communicationBatchGenerated;
  };

  iconCursorStyle = (communicationType: string) => {
    return {
      cursor: this.shouldBeDisabled(communicationType)
        ? 'not-allowed'
        : 'pointer',
    };
  };

  goToViewCommunications = () => {
    navigateToUrlWithSelectedPropertyId('/communications');
  };

  validateGenerateCommunication = () => {
    const {
      communicationBatchGenerated,
      isLoading,
      phonesValidated,
      emailsValidated,
    } = this.props;
    const { communicationData } = this.state;
    const emailInputsInvalid = any(isEmpty)([
      communicationData.emailFromText,
      communicationData.emailSubjectText,
      communicationData.emailTitleText,
      communicationData.emailBodyText,
    ]);
    const emailInvalid = any(equals(true))([
      emailInputsInvalid,
      not(communicationData.emailFromValidated),
      not(emailsValidated),
    ]);
    const phoneInputsInvalid = any(isEmpty)([
      communicationData.smsText,
      communicationData.smsTitle,
    ]);
    const phoneInvalid = any(equals(true))([
      phoneInputsInvalid,
      not(phonesValidated),
    ]);
    const booleanValid = all(equals(false))([
      isLoading,
      communicationBatchGenerated,
    ]);
    const isInvalid = (arg: any) => isNil(arg) || isEmpty(arg) || arg === false;
    const communicationInvalid = all(isInvalid)([
      this.state.documentTypeId,
      not(phoneInvalid),
      not(emailInvalid),
    ]);
    return not(communicationInvalid) && booleanValid;
  };

  render = () => {
    const {
      intl,
      documentTypes: residentLetters,
      selectedResidents,
      selectedProperty,
      communicationBatchGenerated,
      isLoading,
      response,
      createdAt,
      metricsInformation,
      currentCommunication,
      location,
      currentUser,
    } = this.props;
    let { communicationData, communicationType } = this.state;
    let documentName = '';
    if (this.state.documentTypeId) {
      documentName = this.getDocumentNameById(this.state.documentTypeId);
    }

    const isViewCommunication =
      location.pathname ===
      `/property/${selectedProperty?.id}/view-communication`;
    const recipientsType = isViewCommunication
      ? currentCommunication.communicationRecipientType
      : pathOr('', ['state', 'recipientType'], location);
    let currentCommunicationCreationDate;
    let recipients = [];
    if (isViewCommunication) {
      currentCommunicationCreationDate = pathOr(
        null,
        ['createdAt'],
        currentCommunication,
      );

      const currentCommunicationRecipients = pathOr(
        [null],
        ['recipients'],
        currentCommunication,
      );

      recipients = currentCommunicationRecipients.map((recipient) => {
        const firstName = pathOr('', ['firstName'], recipient);
        const lastName = pathOr('', ['lastName'], recipient);
        const name = pathOr(null, ['name'], recipient);
        const unitNumber = pathOr(
          '',
          ['ac.applicant.household.activeLease.unit.number'],
          recipient,
        );
        const buildingNumber = pathOr(
          '',
          ['ac.applicant.household.activeLease.unit.building.name'],
          recipient,
        );
        return {
          id: pathOr('', ['id'], recipient),
          unitNumber,
          buildingNumber,
          name: name || `${firstName} ${lastName}`,
          applicationId: pathOr('', ['applicationId'], recipient),
          emailAddress: pathOr('', ['emailAddress'], recipient),
          phoneNumber: pathOr('', ['phoneNumber'], recipient),
          residentId: pathOr('', ['recipientId'], recipient),
          communicationStatus: pathOr(null, ['communicationStatus'], recipient),
          assignedUnit: unitNumber,
        };
      });

      documentName = concat(
        pathOr('', ['communicationData', 'documentName'], currentCommunication),
        '.pdf',
      );

      const currentCommunicationData = pathOr(
        {},
        ['communicationData'],
        currentCommunication,
      );

      communicationData = { ...communicationData, ...currentCommunicationData };
      communicationData.smsText = pathOr(
        '',
        ['communicationData', 'smsText'],
        currentCommunication,
      );
      communicationData.smsTitle = pathOr(
        '',
        ['communicationData', 'smsTitle'],
        currentCommunication,
      );
    }
    const status = pathOr('', ['status'], currentCommunication);

    const isAffordable =
      selectedProperty.propertyClass.name === 'Mixed' ||
      selectedProperty.propertyClass.name === 'Affordable';

    let communicationTypeText = '';
    switch (communicationType) {
      case 'SMS':
        communicationTypeText = 'Text (SMS)';
        break;
      case 'EMAIL':
        communicationTypeText = 'Emails';
        break;
      default:
        communicationTypeText = 'Letters';
    }
    const isSubmitEnabled = this.validateGenerateCommunication();
    const title = isViewCommunication
      ? intl.formatMessage(createCommunicationMessages.viewTitle)
      : intl.formatMessage(createCommunicationMessages.title);
    const isNoGenerateCommunication =
      communicationType === 'SMS' || communicationType === 'EMAIL';
    const prospectDisclaimer =
      recipientsType === 'Prospect' && this.props.selectedResidents.length > 1
        ? intl.formatMessage(createCommunicationMessages.prospectDisclaimer)
        : '';

    const loadingCurrentCommunication =
      Object.keys(currentCommunication).length === 0;

    const disableSubmitBtn = isViewCommunication
      ? loadingCurrentCommunication || status === 'IN PROGRESS'
      : not(isSubmitEnabled);

    let viewCommunicationBtnText = '';
    if (isViewCommunication) {
      viewCommunicationBtnText =
        loadingCurrentCommunication || status === 'IN PROGRESS' ? (
          <Spinner small />
        ) : (
          `Download Generated ${communicationTypeText}`
        );
    }

    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';

    const wasLetterEmailed = pathOr(
      false,
      ['communicationData', 'wasLetterEmailed'],
      this.props.currentCommunication,
    );
    const organizationId = currentUser.user.organizationId;
    const selectedPropertyId = selectedProperty.id;

    const showNoPhoneNumberTooltip =
      this.props.flags['2WayTextingV1'] && !this.props.textingPhoneNumber;

    return (
      <DocumentTitle title={title}>
        <Fragment>
          <div className="container-fluid bodywrap">
            <Row>
              <Col xs={12} sm={12}>
                <a
                  className="btn-text"
                  onClick={
                    isViewCommunication
                      ? this.goToViewCommunications
                      : this.goToManageCommunications
                  }
                >
                  <i className="et-chevron-left" />
                  {isViewCommunication
                    ? 'Go Back to View Communication'
                    : 'Go Back to Manage Communication'}
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <h1>
                  {isViewCommunication
                    ? 'View Communication'
                    : 'New Communication'}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={5}>
                <div className="panel unit-information panel-withheader">
                  <div className="panel-head">
                    <Row>
                      <Col xs={12} sm={6}>
                        <h2>Select Method</h2>
                      </Col>
                      <Col xs={12} sm={6}>
                        <label>{prospectDisclaimer}</label>
                      </Col>
                    </Row>
                  </div>
                  <div className="container-fluid">
                    <div className="panel__details">
                      <Row>
                        <Col xs={12} sm={4} className="padtop10 padbottom30">
                          <div
                            className={`radio-with-icons${
                              communicationType === 'EMAIL' ? ' selected' : ''
                            }`}
                          >
                            <i
                              className="et-mail"
                              style={this.iconCursorStyle('EMAIL')}
                              onClick={() =>
                                this.shouldBeDisabled('EMAIL')
                                  ? () => false
                                  : this.handleCommunicationTypeChange({
                                      target: { value: 'EMAIL' },
                                    })
                              }
                            />
                            <label
                              className="radio-inline"
                              style={this.iconCursorStyle('EMAIL')}
                            >
                              <input
                                value="EMAIL"
                                type="radio"
                                checked={communicationType === 'EMAIL'}
                                onChange={this.handleCommunicationTypeChange}
                                disabled={this.shouldBeDisabled('EMAIL')}
                              />
                              Email
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} sm={4} className="padtop10 padbottom30">
                          <div
                            className={`radio-with-icons${
                              communicationType === 'LETTER' ? ' selected' : ''
                            }`}
                          >
                            <i
                              className="et-certified"
                              style={this.iconCursorStyle('LETTER')}
                              onClick={() =>
                                this.shouldBeDisabled('LETTER')
                                  ? () => false
                                  : this.handleCommunicationTypeChange({
                                      target: { value: 'LETTER' },
                                    })
                              }
                            />
                            <label
                              className="radio-inline"
                              style={this.iconCursorStyle('LETTER')}
                            >
                              <input
                                value="LETTER"
                                type="radio"
                                checked={communicationType === 'LETTER'}
                                onChange={this.handleCommunicationTypeChange}
                                disabled={this.shouldBeDisabled('LETTER')}
                              />
                              Letters
                            </label>
                          </div>
                        </Col>
                        <Col xs={12} sm={4} className="padtop10 padbottom30">
                          <Tooltip
                            title={intl.formatMessage(
                              createCommunicationMessages.noPhoneNumber,
                            )}
                            placement="right"
                            variant="light"
                            disableHoverListener={!showNoPhoneNumberTooltip}
                            style={{ width: '100%' }}
                          >
                            <div
                              className={`radio-with-icons${
                                communicationType === 'SMS' ? ' selected' : ''
                              }`}
                            >
                              <i
                                className="et-phone-message"
                                style={this.iconCursorStyle('SMS')}
                                onClick={() =>
                                  this.shouldBeDisabled('SMS')
                                    ? () => false
                                    : this.handleCommunicationTypeChange({
                                        target: { value: 'SMS' },
                                      })
                                }
                              />
                              <label
                                className="radio-inline"
                                style={this.iconCursorStyle('SMS')}
                              >
                                <input
                                  value="SMS"
                                  type="radio"
                                  checked={communicationType === 'SMS'}
                                  onChange={this.handleCommunicationTypeChange}
                                  disabled={this.shouldBeDisabled('SMS')}
                                />
                                Text (SMS)
                              </label>
                            </div>
                          </Tooltip>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <Recipients
                  selectedRecipients={
                    isViewCommunication ? recipients : selectedResidents
                  }
                  communicationType={communicationType}
                  recipientsType={recipientsType}
                  communicationBatchGenerated={communicationBatchGenerated}
                  validEmails={this.state.validEmails}
                  validPhones={this.state.validPhones}
                  response={response}
                  emailsValidated={this.props.emailsValidated}
                  phonesValidated={this.props.phonesValidated}
                  metricsInformation={metricsInformation}
                  isViewCommunication={isViewCommunication}
                  recipientsStatus={pathOr(
                    [],
                    ['communicationData', 'recipientsStatus'],
                    currentCommunication,
                  )}
                  isAllCommercial={isAllCommercial}
                  selectedPropertyId={selectedPropertyId}
                />
              </Col>
              <CommunicationInput
                communicationType={communicationType}
                openResidentsLetterModal={this.openResidentsLetterModal}
                documentName={documentName}
                communicationBatchGenerated={communicationBatchGenerated}
                selectedResidents={
                  isViewCommunication ? recipients : selectedResidents
                }
                handleInputTextChange={this.handleInputTextChange}
                communicationData={communicationData}
                isLoading={isLoading}
                isViewCommunication={isViewCommunication}
                currentCommunicationCreationDate={
                  currentCommunicationCreationDate
                }
                recipientsAmount={recipients.length}
                createdAt={createdAt}
                metricsInformation={metricsInformation}
                toggleEmailLetter={this.toggleEmailLetter}
                wasLetterEmailed={wasLetterEmailed}
                organizationId={organizationId}
                selectedPropertyId={selectedPropertyId}
                toasterFn={this.props.actions.promptToaster}
                handleCommunicationEmailAttachmentChanges={
                  this.handleCommunicationEmailAttachmentChanges
                }
              />
            </Row>
            <Row>
              <Col xs={6}>
                <button
                  onClick={
                    isViewCommunication
                      ? this.goToViewCommunications
                      : this.goToManageCommunications
                  }
                  className="btn btn-default pull-right"
                >
                  {isViewCommunication && communicationType === 'SMS'
                    ? 'Close'
                    : 'Cancel'}
                </button>
              </Col>
              <Col xs={6}>
                <SubmitButton
                  bsStyle="primary"
                  className="pull-left"
                  disabled={disableSubmitBtn}
                  clickHandler={
                    isViewCommunication
                      ? this.downloadCommunication
                      : this.generateCommunication
                  }
                  isSubmitting={isLoading}
                  hidden={isViewCommunication && isNoGenerateCommunication}
                >
                  {isViewCommunication
                    ? viewCommunicationBtnText
                    : `Generate ${communicationTypeText}`}
                </SubmitButton>
              </Col>
            </Row>
          </div>

          <ResidentLettersModal
            intl={intl}
            show={this.state.isModalOpen}
            letters={residentLetters}
            onClose={this.closeResidentsLetterModal}
            onSubmit={this.handleFormSubmit}
            residentLetters={residentLetters}
            titleText="Letters Library"
            buttonText="Select Template"
            isAffordable={isAffordable}
          />
        </Fragment>
      </DocumentTitle>
    );
  };
}

export const mapStateToProps = (state: GlobalState, props: Props) => {
  const { manageCommunications, viewCommunication } = state;
  return {
    selectedResidents: manageCommunications.selectedResidents,
    documentTypes: getDocumentTypesOptions(state),
    isLoading: manageCommunications.isLoading,
    communicationBatchGenerated:
      manageCommunications.communicationBatchGenerated,
    selectedProperty: state.app.selectedProperty,
    response: manageCommunications.response,
    currentCommunication: viewCommunication.currentCommunication,
    isGetCommunication: viewCommunication.isGetCommunication,
    validEmails: manageCommunications.validEmails,
    validPhones: manageCommunications.validPhones,
    emailsValidated: manageCommunications.emailsValidated,
    phonesValidated: manageCommunications.phonesValidated,
    metricsInformation: getMetricsInformation(state, props),
    createdAt: getCreatedAt(state, props),
    fromEmailValidation: manageCommunications.fromEmailValidation,
    currentUser: getCurrentUser(state),
    textingPhoneNumber: getTextingPhoneNumber(state),
  };
};

export function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators(
      {
        ...manageCommunicationsActions,
        promptToaster,
      },
      dispatch,
    ),
  };
}

const InjectedCreateCommunication = withLDConsumer()(
  injectIntl(CreateCommunication),
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedCreateCommunication);
