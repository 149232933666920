import { defineMessages } from 'react-intl';

const messages = defineMessages({
  snapshot: {
    id: 'App.PeopleProfile.Snapshot.Snapshot',
    defaultMessage: 'Snapshot'
  },
  assignedTo: {
    id: 'App.PeopleProfile.Snapshot.AssignedTo',
    defaultMessage: 'Assigned to:'
  },
  prospectCreationDate: {
    id: 'App.PeopleProfile.Snapshot.ProspectCreationDate',
    defaultMessage: 'Prospect creation date: '
  },
  nextScheduledActivity: {
    id: 'App.PeopleProfile.Snapshot.NextScheduledActivity',
    defaultMessage: 'Next scheduled activity: '
  },
  numberOfScheduledContacts: {
    id: 'App.PeopleProfile.Snapshot.NumberOfScheduledContacts',
    defaultMessage: '# of scheduled contacts:'
  },
  numberOfCompletedContacts: {
    id: 'App.PeopleProfile.Snapshot.NumberOfCompletedContacts',
    defaultMessage: '# of completed contacts:'
  },
  provideQuote: {
    id: 'App.PeopleProfile.Snapshot.ProvideQuote',
    defaultMessage: 'Provide quote'
  },
  convertToApplicant: {
    id: 'App.PeopleProfile.Snapshot.ConvertToApplicant',
    defaultMessage: 'Convert to applicant'
  },
  tour: {
    id: 'App.PeopleProfile.Snapshot.Tour',
    defaultMessage: 'Tour:'
  }
});

export default messages;
