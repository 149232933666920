import React from 'react';
import { findIndex, sortBy } from 'ramda';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Panel, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import messages from './messages';
import type { Prospect } from '../../ProspectProfile/types';
import styled from 'styled-components';
import {
  Header,
  Title,
  Divider,
  DetailList,
} from '../../../components/ProfileVisualComponents';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
type Props = {
  currentRecord: Prospect,
  numberOfScheduledContacts: number,
  numberOfCompletedContacts: number,
  users: Array<Object>,
  handleAssignedToChange: Function,
  handleConvertToApplicant: Function,
  history: {
    push: Function,
  },
};

const SnapshotPanel = styled(Panel).attrs({
  className: 'block block-snapshot block-white-shadow',
})``;

const LabelProfileDetails = styled.label.attrs({
  className: 'profile-details',
})``;

export const Snapshot = ({
  currentRecord,
  numberOfScheduledContacts,
  numberOfCompletedContacts,
  users,
  handleAssignedToChange,
  handleConvertToApplicant,
  history,
}: Props) => {
  const assignee = currentRecord.assignedTo
    ? currentRecord.assignedTo
    : { firstName: '', lastName: '' };
  // $FlowFixMe
  const assigneeIndex = findIndex(
    (x) => x.value === currentRecord.assignedToId,
    users,
  );
  const nextActivity = currentRecord.nextActivity || { startTime: undefined };
  const assignees =
    assigneeIndex < 0
      ? sortBy(
          (x) => {
            return x.text.toLowerCase();
          },
          [
            {
              value: currentRecord.assignedToId,
              text: `${assignee.firstName} ${assignee.lastName}`,
            },
          ].concat(users),
        )
      : users;
  return (
    <SnapshotPanel>
      <Header>
        <Title>
          <IconTitle
            icon={<i key="1" className="et-prospect" />}
            message={messages.snapshot}
          />
        </Title>
      </Header>
      <Panel.Body>
        <Row className="row">
          <Col xs={12} md={3}>
            <LabelProfileDetails>
              <FormattedMessage {...messages.assignedTo} />
            </LabelProfileDetails>
          </Col>
          <Col xs={12} md={8}>
            <ElementWithPermissions scope={['prospect-assign']}>
              <Field
                name="assignedToId"
                component="select"
                className="form-control input-sm"
                onChange={handleAssignedToChange}
              >
                {assignees.map((user) => (
                  <option key={user.value} value={user.value}>
                    {user.text}
                  </option>
                ))}
              </Field>
            </ElementWithPermissions>
          </Col>
        </Row>
        <Divider />
        <DetailList>
          <li>
            <FormattedMessage {...messages.prospectCreationDate} />{' '}
            <FormattedDateOrDashes
              value={currentRecord.createdAt}
              format="MMM DD, YYYY"
            />
          </li>
          <li>
            <FormattedMessage {...messages.nextScheduledActivity} />{' '}
            {
              <FormattedDateOrDashes
                value={nextActivity.startTime}
                format="MMM DD, YYYY"
              />
            }
          </li>
          <li>
            <FormattedMessage {...messages.numberOfScheduledContacts} />
            {` ${numberOfScheduledContacts}`}
          </li>
          <li>
            <FormattedMessage {...messages.numberOfCompletedContacts} />
            {` ${numberOfCompletedContacts}`}
          </li>
          <li>
            <FormattedMessage {...messages.tour} />
            {` ${currentRecord.tourStatus}`}
          </li>
        </DetailList>
        <Row>
          <Col xs={12} sm={6}>
            <Button
              variant={'primarySubtle'}
              onClick={() =>
                history.push(
                  `/property/${currentRecord.propertyId}/manage-unit-availability?prospectId=${currentRecord.id}`,
                )
              }
              type="button"
            >
              <FormattedMessage {...messages.provideQuote} />
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              variant={'action'}
              onClick={handleConvertToApplicant}
              type="button"
            >
              <FormattedMessage {...messages.convertToApplicant} />
            </Button>
          </Col>
        </Row>
      </Panel.Body>
    </SnapshotPanel>
  );
};

export default reduxForm({
  form: 'snapshot',
  enableReinitialize: true,
})(Snapshot);
