import React, { Component } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { pathOr, path, isNil, isEmpty } from 'ramda';
import moment from 'moment';
import classnames from 'classnames';
import styled from 'styled-components';

import DashesIfNullOrUndefined from '../DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../FormattedDateOrDashes';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import type { Transaction } from '../../containers/Ledger/types';
import { formatPeriod } from '../../utils/redux-form-helper';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import ModifyTransactionCamPoolModal from './ModifyTransactionCamPoolModal';
import ReverseTransactionModal from './ReverseTransactionModal';

import messages from './messages';

type Props = {
  singleTransaction: Transaction,
  isReversable: boolean,
  onPrint: Function,
  onReverse: Function,
  refreshLedger: Function,
  isOps: boolean,
  intl: Object,
  store: any,
  frNames: string,
  camPools?: Array<Object>,
  promptToaster: Function,
  organizationId: string,
  propertyId: string,
  householdId: string,
};

type StateProps = {
  reverseConfirmationEnabled: boolean,
  showModifyCamPoolFields: boolean,
};

type InjectedProps = {
  show: Function,
  proceed: Function,
  dismiss: Function,
};

const NoPrintAnchor = styled.a`
  @media print {
    :after {
      content: none !important;
    }
    img {
      width: 60% !important;
    }
  }
`;

const CheckImage = (props: { label: string, url: string }) => {
  return (
    <Row className="form-group">
      <Col md={4} xs={12}>
        <label>{props.label}</label>
      </Col>
      <Col md={8} xs={12}>
        <NoPrintAnchor href={props.url} target="_blank" rel="noreferrer">
          <img src={props.url} alt={props.label} style={{ width: '100%' }} />
        </NoPrintAnchor>
      </Col>
    </Row>
  );
};

export class ViewTransactionModal extends Component<
  Props & InjectedProps,
  StateProps,
> {
  constructor(props: StateProps & Props & InjectedProps) {
    super(props);
    this.state = {
      reverseConfirmationEnabled: false,
      showModifyCamPoolFields: false,
    };
  }

  componentDidMount() {
    window.onpopstate = (event) => {
      this.props.dismiss();
    };
  }

  callPrint() {
    this.props.onPrint();
    this.props.proceed({});
  }

  handleShowModifyCamPool = () => {
    this.setState({
      showModifyCamPoolFields: true,
    });
  };

  handleCloseModifyCamPool = (refresh: boolean = false) => {
    this.setState({
      showModifyCamPoolFields: false,
    });

    if (refresh) {
      this.props.refreshLedger();
      this.props.proceed({});
    }
  };

  handleShowReverseTransaction = () => {
    this.setState({
      reverseConfirmationEnabled: true,
    });
  };

  handleCloseReverseTransaction = () => {
    this.setState({
      reverseConfirmationEnabled: false,
    });
  };

  callReverse(
    notes: string,
    selectedOption: ?string,
    camAllocationsId?: string,
  ) {
    this.props.onReverse({
      id: this.props.singleTransaction.id || '',
      ledger: this.props.isOps ? 'ops' : 'security',
      period: this.props.singleTransaction.propertyFiscalPeriod.period || '',
      notes,
      isNsf: selectedOption === 'yes',
      camAllocationsId,
    });
    this.props.proceed({});
  }

  getCreatedByName = (createdByObject: Object) =>
    pathOr('', ['firstName'], createdByObject) +
    ' ' +
    pathOr('', ['lastName'], createdByObject);

  getUpdatedByName = (updatedByObject: Object, updatedAt: any) => {
    const name =
      pathOr('', ['firstName'], updatedByObject) +
      ' ' +
      pathOr('', ['lastName'], updatedByObject);
    const time = moment(updatedAt)
      .format('MMM Do YYYY, h:mm a')
      .toLocaleString();
    return name + ' @ ' + time;
  };

  getTransactionCodeDescription = (ptc: Object) => {
    const code = pathOr('', ['transactionCode', 'code'], ptc);
    const desc = pathOr('', ['transactionCode', 'description'], ptc);
    return code + ' - ' + desc;
  };

  RenderModalHeader = (intl: Object) => {
    return (
      <Modal.Header closeButton>
        <NoPrint>
          <i className="icon et-money" />
          <Modal.Title componentClass="h1">
            {intl.formatMessage(messages.viewTransaction)}
          </Modal.Title>
        </NoPrint>
        <Print printOnly name="viewTransactionPrintHeader">
          <i className="icon et-money" />
          <Modal.Title componentClass="h1">
            {intl.formatMessage(messages.transactionInformation)}
          </Modal.Title>
        </Print>
      </Modal.Header>
    );
  };

  RenderModalFooter = (intl: Object): Object => {
    return (
      <NoPrint>
        <Modal.Footer
          className={classnames({
            disabled:
              this.state.reverseConfirmationEnabled ||
              this.state.showModifyCamPoolFields,
          })}
        >
          <Row>
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={this.props.dismiss}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                bsStyle="primary"
                className="pull-left"
                disabled={false}
                onClick={() => this.callPrint()}
              >
                {intl.formatMessage(messages.print)}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </NoPrint>
    );
  };

  RenderViewBody = (
    intl: Object,
    singleTransaction: Transaction,
    isReversable: boolean,
    isOps: boolean,
    store: Object,
    frNames: string,
    batchId: any,
    camPools?: Array<Object>,
  ) => {
    const camPool = pathOr(null, ['camPool'], singleTransaction);
    const camStartDate = pathOr(null, ['startDate'], camPool);
    const camPoolName = pathOr(null, ['camPoolName'], camPool);
    const hasCamAttached = isOps && !isNil(camStartDate) && !isNil(camPoolName);
    const applyToCamDescription = hasCamAttached
      ? `${moment(camStartDate).format('MM/DD/YYYY')} - ${camPoolName}`
      : null;
    const hasCamPoolsOptions = !isNil(camPools) && !isEmpty(camPools);
    const showEditCamPoolButton =
      hasCamPoolsOptions && singleTransaction.status === 'Posted';

    // If there's no CAM attached to this transaction, and no options for CAM pools
    // to apply, don't show the "Apply to CAM Pool" line.
    const showApplyToCam = hasCamAttached || hasCamPoolsOptions;

    return (
      <Modal.Body>
        <Row className="scrollable-modal__content">
          <div className="container-fluid">
            <Row className="padbottom10">
              <Col md={6} xs={12}>
                <span>
                  <strong>
                    {intl.formatMessage(messages.period)}:{' '}
                    {formatPeriod(
                      singleTransaction.propertyFiscalPeriod.period,
                    )}
                  </strong>
                </span>
              </Col>
              <NoPrint>
                <Col md={6} xs={12} className="text-right">
                  {isReversable ? (
                    <ElementWithPermissions
                      scope={['financial-transaction-reverse']}
                      store={store}
                    >
                      <Button
                        className="btn-tertiary no-margin"
                        onClick={this.handleShowReverseTransaction}
                      >
                        <i className="icon et-pencil" />
                        {intl.formatMessage(messages.reverse)}
                      </Button>
                    </ElementWithPermissions>
                  ) : null}
                </Col>
              </NoPrint>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.transactionStatus)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined data={singleTransaction.status} />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.manual)}</label>
              </Col>
              <Col md={8} xs={12}>
                {singleTransaction.isManual ? (
                  <i className="et-isolated-check" />
                ) : (
                  '---'
                )}
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>Deposit ID: </label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={
                    singleTransaction.depositBatchName ||
                    path(
                      [
                        'payment',
                        'depositBatchDetail',
                        'depositHeader',
                        'name',
                      ],
                      singleTransaction,
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.batchId)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined data={batchId} />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.propertyName)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={singleTransaction.property.name}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.unitNumber)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={
                    // $FlowFixMe
                    pathOr(null, ['unit', 'number'], singleTransaction)
                  }
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.status)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={singleTransaction.customerStatus}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.name)}</label>
              </Col>
              <Col md={8} xs={12}>
                {singleTransaction.payment ? (
                  <DashesIfNullOrUndefined
                    data={
                      // $FlowFixMe
                      pathOr(
                        null,
                        [
                          'payment',
                          'financiallyResponsibleLeaseSignersInHousehold',
                        ],
                        singleTransaction,
                      )
                    }
                  />
                ) : (
                  <DashesIfNullOrUndefined data={frNames} />
                )}
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.subjournal)}</label>
              </Col>
              <Col md={8} xs={12}>
                {singleTransaction.subjournal ? (
                  <DashesIfNullOrUndefined
                    data={singleTransaction.subjournal.description}
                  />
                ) : (
                  'Resident'
                )}
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.transactionType)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={
                    singleTransaction.propertyTransactionCode.transactionCode
                      .transactionType.name
                  }
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.receivedFrom)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={singleTransaction.receivedFrom}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.code)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={this.getTransactionCodeDescription(
                    singleTransaction.propertyTransactionCode,
                  )}
                />
              </Col>
            </Row>
            {showApplyToCam && (
              <Row className="form-group">
                <Col md={4} xs={12}>
                  <label>{intl.formatMessage(messages.applyToCamPool)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <DashesIfNullOrUndefined data={applyToCamDescription} />
                </Col>
                {showEditCamPoolButton && (
                  <Col md={1} xs={12}>
                    <div className="row-remove-btn">
                      <a onClick={this.handleShowModifyCamPool}>
                        <i className="et-pencil padright10" />
                      </a>
                    </div>
                  </Col>
                )}
              </Row>
            )}
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.date)}</label>
              </Col>
              <Col md={8} xs={12}>
                <FormattedDateOrDashes
                  value={moment.utc(singleTransaction.transactionDate)}
                  format="MM/DD/YYYY"
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.documentNumber)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={singleTransaction.documentNumber}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.note)}</label>
              </Col>
              <Col md={8} xs={12}>
                <DashesIfNullOrUndefined
                  data={singleTransaction.transactionNote}
                />
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.transactionAmount)}</label>
              </Col>
              <Col md={8} xs={12}>
                {Number(singleTransaction.transactionAmount).toLocaleString(
                  'en-US',
                  {
                    style: 'currency',
                    currency: 'USD',
                  },
                )}
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.createdBy)}</label>
              </Col>
              <Col md={8} xs={12}>
                {this.getCreatedByName(singleTransaction.createdBy)}
              </Col>
            </Row>
            <Row className="form-group">
              <Col md={4} xs={12}>
                <label>{intl.formatMessage(messages.updatedBy)}</label>
              </Col>
              <Col md={8} xs={12}>
                {this.getUpdatedByName(
                  singleTransaction.updatedBy,
                  singleTransaction.updatedAt,
                )}
              </Col>
            </Row>
            {singleTransaction.domusoTransaction?.metadata?.checkImage && (
              <>
                <CheckImage
                  label={intl.formatMessage(messages.frontCheck)}
                  url={
                    singleTransaction.domusoTransaction.metadata.checkImage
                      .front.url
                  }
                />
                <CheckImage
                  label={intl.formatMessage(messages.backCheck)}
                  url={
                    singleTransaction.domusoTransaction.metadata.checkImage.back
                      .url
                  }
                />
              </>
            )}
          </div>
        </Row>
      </Modal.Body>
    );
  };

  render() {
    let { singleTransaction, intl, isReversable, isOps, store, frNames } =
      this.props;
    // $FlowFixMe
    const batchId = pathOr(
      '---',
      ['batchPaymentDetail', 'header', 'name'],
      singleTransaction,
    );

    const camPool = pathOr(null, ['camPool'], singleTransaction);
    const attachedCamPool = isOps ? camPool : null;

    const showReverseTransaction = this.state.reverseConfirmationEnabled;
    const showModifyCamPool =
      !showReverseTransaction && this.state.showModifyCamPoolFields;
    const showViewBody = !showReverseTransaction && !showModifyCamPool;

    return (
      <form>
        <PrintProvider>
          <Modal
            className="modal transaction"
            backdrop
            bsSize="sm"
            show={this.props.show}
            onHide={this.props.dismiss}
          >
            <Print name="viewTransactionPrint">
              {this.RenderModalHeader(intl)}
              {showReverseTransaction && (
                <ReverseTransactionModal
                  intl={intl}
                  singleTransaction={singleTransaction}
                  callReverse={this.callReverse.bind(this)}
                  onClose={this.handleCloseReverseTransaction}
                  currentCamPool={attachedCamPool}
                  camPools={this.props.camPools}
                />
              )}
              {showModifyCamPool && (
                <ModifyTransactionCamPoolModal
                  intl={intl}
                  onClose={this.handleCloseModifyCamPool}
                  customerOpsLedgerId={singleTransaction.id}
                  currentCamPool={attachedCamPool}
                  camPools={this.props.camPools}
                  promptToaster={this.props.promptToaster}
                  organizationId={this.props.organizationId}
                  propertyId={this.props.propertyId}
                  householdId={this.props.householdId}
                />
              )}
              {showViewBody &&
                this.RenderViewBody(
                  intl,
                  singleTransaction,
                  isReversable,
                  isOps,
                  store,
                  frNames,
                  batchId,
                  this.props.camPools,
                )}
              {this.RenderModalFooter(intl)}
            </Print>
          </Modal>
        </PrintProvider>
      </form>
    );
  }
}

const DecoratedViewTransactionStatusModal = confirmable(ViewTransactionModal);

const dialog = createConfirmation(DecoratedViewTransactionStatusModal);

export const ViewSingleTransactionModal = function ({
  intl,
  singleTransaction,
  isReversable,
  onPrint,
  onReverse,
  refreshLedger,
  isOps,
  store,
  frNames,
  camPools,
  promptToaster,
  organizationId,
  propertyId,
  householdId,
}: Props): Promise<any> {
  return new Promise((resolve, reject) => {
    dialog({
      intl,
      singleTransaction,
      isReversable,
      onPrint,
      onReverse,
      refreshLedger,
      isOps,
      store,
      frNames,
      camPools,
      promptToaster,
      organizationId,
      propertyId,
      householdId,
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};
