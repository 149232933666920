import { FormattedMessage } from 'react-intl';

import messages from '../App/messages';
import { N2W_LOCALE_MAP } from './constants';

export const getBooleanDisplayValue = (value?: boolean = false): ReactNode => {
  return value ? (
    <FormattedMessage {...messages.on} />
  ) : (
    <FormattedMessage {...messages.off} />
  );
};

export const getN2WLocale = (locale: string = ''): string => {
  const subLocale = locale.substring(0, 2);

  return N2W_LOCALE_MAP[subLocale] ?? 'EN_US';
};
