import { useMemo, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import usePropertySpecialsQuery from '../../hooks/data-fetching/usePropertySpecialsQuery';
import usePropertySpecialsMutation from '../../hooks/data-modifying/usePropertySpecialsMutation';

const useUnitSpecials = ({ organizationId, propertyId }) => {
  const [editMode, setEditMode] = useState(false);

  const {
    data: unitSpecials,
    isLoading: isPropertySpecialsLoading,
    refetch: refetchUnitSpecials,
  } = usePropertySpecialsQuery({
    propertyId,
  });

  const { mutate: handleSaveUnitSpecial } = usePropertySpecialsMutation({
    propertySpecials: unitSpecials,
    organizationId,
    propertyId,
    options: {
      onSuccess: () => {
        refetchUnitSpecials();
        setEditMode(false);
        toastr.success('Success', 'Special saved.');
      },
      onError: (e) => {
        toastr.error('Error', e.toString());
      },
    },
  });

  return useMemo(
    () => ({
      editMode,
      unitSpecial: unitSpecials?.length > 0 ? unitSpecials[0] : null,
      isLoading: isPropertySpecialsLoading,
      handleSaveUnitSpecial,
      handleToggleEditMode: () => setEditMode(!editMode),
    }),
    [editMode, unitSpecials, isPropertySpecialsLoading, handleSaveUnitSpecial],
  );
};

export default useUnitSpecials;
