import { useRef, useState, useLayoutEffect, useCallback } from 'react';
import { useResizeObserver } from '@fortress-technology-solutions/fortress-component-library/hooks';
import { getComputedElementStyle } from '../../utils/element-helpers';
import { debounce } from 'lodash';

const TableLayout = ({
  children,
  name,
  initialTableHeight = 'calc(100dvh - 100px)',
}) => {
  const ref = useRef(null);
  // elements that take up space on page
  const appBarRef = useRef(null);
  const homeTabRef = useRef(null);
  const homeTabContentRef = useRef(null);
  const tableSettingsRef = useRef(null);
  const collapseRef = useRef(null);

  const [tableHeight, setTableHeight] = useState(initialTableHeight);

  useLayoutEffect(() => {
    appBarRef.current = document.getElementById('app-bar');
    homeTabRef.current = document.getElementById('home-tab');
    homeTabContentRef.current = document.getElementById('home-tab-content');
    tableSettingsRef.current = document.getElementById('table-settings');
    collapseRef.current = document.getElementById('collapse');
  }, []);

  const calculateHeightCallback = useCallback(
    debounce((target) => {
      let otherElementsHeight = 8;

      const pageElements = [
        appBarRef.current,
        homeTabRef.current,
        tableSettingsRef.current,
      ];

      pageElements.forEach((element) => {
        if (element) {
          otherElementsHeight += element.clientHeight;
          otherElementsHeight += getComputedElementStyle(
            element,
            'padding-top',
            true,
          );
          otherElementsHeight += getComputedElementStyle(
            element,
            'padding-bottom',
            true,
          );
        }
      });

      // add padding from home tab content
      if (homeTabContentRef.current) {
        otherElementsHeight += getComputedElementStyle(
          homeTabContentRef.current,
          'padding-top',
          true,
        );
        otherElementsHeight += getComputedElementStyle(
          homeTabContentRef.current,
          'padding-bottom',
          true,
        );
      }

      // loop through children and set height
      target.childNodes.forEach((child) => {
        if (child.id !== `table-wrapper_${name}`) {
          otherElementsHeight +=
            child.clientHeight +
            getComputedElementStyle(child, 'padding-top', true) +
            getComputedElementStyle(child, 'padding-bottom', true);
        }
      });

      setTableHeight(`calc(100dvh - ${otherElementsHeight}px)`);
    }, 300),
    [name],
  );

  // mutation observer to watch for changes in children
  useLayoutEffect(() => {
    if (!collapseRef.current) {
      return;
    }

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver((mutationsList, observer) => {
      // If the target node or its children's attributes change, do something
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
        } else if (mutation.type === 'attributes') {
          calculateHeightCallback(ref.current);
        }
      }
    });

    observer.observe(collapseRef.current, {
      attributes: true,
      childList: true,
    });

    return () => observer.disconnect();
  }, [calculateHeightCallback]);

  useResizeObserver({
    ref,
    callback: (entry) => calculateHeightCallback(entry.target),
  });

  return (
    <div ref={ref} style={{ height: '100%', overflow: 'hidden' }}>
      {children(tableHeight)}
    </div>
  );
};

export default TableLayout;
