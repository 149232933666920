import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageProspects.Title',
    defaultMessage: 'Fortress - Manage prospects',
  },
  header: {
    id: 'App.ManageProspects.Header',
    defaultMessage: 'Manage Prospects',
  },
  status: {
    id: 'App.ManageProspects.Status',
    defaultMessage: 'Status',
  },
  lastName: {
    id: 'App.ManageProspects.LastName',
    defaultMessage: 'Last Name',
  },
  firstName: {
    id: 'App.ManageProspects.FirstName',
    defaultMessage: 'First Name',
  },
  prospectName: {
    id: 'App.ManageProspects.ProspectName',
    defaultMessage: 'Prospect Name',
  },
  phoneNumber: {
    id: 'App.ManageProspects.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'App.ManageProspects.email',
    defaultMessage: 'Email',
  },
  lastFollowUp: {
    id: 'App.ManageProspects.LastFollowUp',
    defaultMessage: 'Last Activity',
  },
  nextFollowUp: {
    id: 'App.ManageProspects.NextFollowUp',
    defaultMessage: 'Next Activity',
  },
  creationDate: {
    id: 'App.ManageProspects.CreationDate',
    defaultMessage: 'Prospect Created',
  },
  moveInDate: {
    id: 'App.ManageProspects.DesiredMoveInDate',
    defaultMessage: 'Desired Move-In Date',
  },
  assignedTo: {
    id: 'App.ManageProspects.AssignedTo',
    defaultMessage: 'Assigned To',
  },
  actions: {
    id: 'App.ManageProspects.Actions',
    defaultMessage: 'Actions',
  },
  view: {
    id: 'App.ManageProspects.View',
    defaultMessage: 'View',
  },
  addProspect: {
    id: 'App.ManageProspects.AddProspect',
    defaultMessage: '+ Add new prospect',
  },
  newProspect: {
    id: 'App.ManageProspects.NewProspect',
    defaultMessage: 'New Prospect',
  },
  successHeader: {
    id: 'App.ManageProspects.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.ManageProspects.SuccessDescription',
    defaultMessage: 'The prospect was updated.',
  },
  successActivityDescription: {
    id: 'App.ManageProspects.SuccessActivityDescription',
    defaultMessage: 'The activity was added.',
  },
  successEditActivityDescription: {
    id: 'App.ManageProspects.SuccessEditActivityDescription',
    defaultMessage: 'The activity was updated.',
  },
  successDeleteActivityDescription: {
    id: 'App.ManageProspects.SuccessDeleteActivityDescription',
    defaultMessage: 'The activity was deleted.',
  },
  sortAscendingLetters: {
    id: 'App.ManageProspects.SortAscendingLetters',
    defaultMessage: 'A - Z',
  },
  sortDescendingLetters: {
    id: 'App.ManageProspects.SortDescendingLetters',
    defaultMessage: 'Z - A',
  },
  sortAscendingNumbers: {
    id: 'App.ManageProspects.SortAscendingNumbers',
    defaultMessage: '0 - 9',
  },
  sortDescendingNumbers: {
    id: 'App.ManageProspects.SortDescendingNumbers',
    defaultMessage: '9 - 0',
  },
  sortAscendingDates: {
    id: 'App.ManageProspects.SortAscendingDates',
    defaultMessage: 'Newest to Oldest',
  },
  sortDescendingDates: {
    id: 'App.ManageProspects.sortDescendingDates',
    defaultMessage: 'Oldest to Newest',
  },
  searchPlaceholder: {
    id: 'App.ManageProspects.SearchPlaceholder',
    defaultMessage: 'Start typing to search...',
  },
  errorHeader: {
    id: 'App.ManageProspects.ErrorHeader',
    defaultMessage: 'Error performing action',
  },
  chooseOption: {
    id: 'App.ManageProspects.ChooseAnOption',
    defaultMessage: 'Choose an option',
  },
  yellowLegend: {
    id: 'App.ManageProspects.yellowlegend',
    defaultMessage: 'Unassigned or Assignee no longer has access',
  },
  nBeds: {
    id: 'App.ManageProspects.NBeds',
    defaultMessage: '# of Bedrooms',
  },
  noPreference: {
    id: 'App.ManageProspects.NoPreference',
    defaultMessage: 'No Preference',
  },
  texting: {
    id: 'App.ManageProspects.Texting',
    defaultMessage: 'Texting',
  },
  showAllStatusesLabel: {
    id: 'App.ManageProspects.ShowAllStatusesLabel',
    defaultMessage: 'Include Dead and Unqualified Leads',
  },
});

export default messages;
