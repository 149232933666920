import { useMutation, UseMutationOptions } from 'react-query';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import ReportService from '../../services/reportService';
import { isNil } from 'ramda';
import moment from 'moment';
import FileSaver from 'file-saver';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const useDownloadComplianceOverviewReportMutation = ({
  complianceType,
  organizationId,
  searchText,
  currentFilter,
  currentSorting,
  intl,
  options,
}: {
  complianceType: string,
  organizationId: string,
  searchText: string,
  currentFilter: string,
  currentSorting: string,
  intl: any,
  options?: UseMutationOptions,
}) => {
  return useMutation(async (fileType: 'csv' | 'pdf') => {
    const response = await new ReportService().downloadComplianceOverviewReport(
      organizationId,
      complianceType,
      fileType,
      searchText,
      currentFilter,
      currentSorting,
    );

    const fileName = `${intl.formatMessage(messages.compliance)}_${(!isNil(
      messages[complianceType],
    )
      ? intl.formatMessage(messages[complianceType])
      : ''
    ).replace(/\s/g, '_')}_${formatDateDB(moment())}.${fileType}`;

    FileSaver.saveAs(response, fileName);
  }, options);
};

export default useDownloadComplianceOverviewReportMutation;
