import { merge } from 'ramda';
import type { Action, AppState } from './types';
import * as ActionTypes from './constants';

const initialState: AppState = {
  activityTypes: [],
  affordableRelationship: [],
  affordableRelationshipsIsLoading: false,
  allAssignees: [],
  applicantCancellationReasons: [],
  applicantTypes: [],
  floorPlans: [],
  applicantTypesIsLoading: false,
  bankAccountsError: undefined,
  bankAccountsLoaded: false,
  buildInformation: {
    frontEndBuildNumber: process.env.REACT_APP_BUILD_NUMBER,
    deploymentEnvironment: process.env.REACT_APP_DEPLOYMENT_ENV,
  },
  commonAreaLocations: [],
  contactTypes: [],
  countries: [],
  currentUser: undefined,
  employmentStatuses: [],
  entryPermissions: [],
  fiscalPeriodLoaded: false,
  housingSituations: [],
  incomeTypes: [],
  leftNavState: 'collapsed',
  moveOutReasons: [],
  nameSuffixes: [],
  nameSuffixesIsLoading: false,
  navigationOptions: [],
  occupiedUnitsLoaded: false,
  petBreeds: [],
  petBreedsIsLoading: false,
  petTypes: [],
  petTypesIsLoading: false,
  priorityLevels: [],
  prorateMethods: [],
  moveOutProrateMethods: [],
  propertyClasses: [],
  propertyClassesIsLoading: false,
  prospectAssignees: [],
  prospectStatusList: [],
  referralTypes: [],
  relationships: [],
  relationshipsIsLoading: false,
  selectedProperty: undefined,
  selectedPropertyBankAccounts: [],
  selectedPropertyFiscalPeriod: undefined,
  selectedPropertyOccupiedUnits: {
    currentResidentUnits: [],
    priorResidentUnits: [],
  },
  selectedPropertySubjournals: [],
  propertyPaymentMethod: {},
  selectedPropertyTransactionCodes: [],
  showExtendUserSessionModal: false,
  specialNeedsDesignations: [],
  subjournalsLoaded: false,
  states: [],
  transactionCodesLoaded: false,
  unitLocations: [],
  utilityAllowanceTypes: [],
  workOrderAssignees: [],
  workOrderIssues: [],
  workOrderStatusList: [],
  writeOffsByCustomerId: {},
  affordableOptions: {
    complianceStatuses: [],
    rentalAssistanceSources: [],
    utilityAllowances: [],
  },
};

const app = (state: AppState = initialState, action: Action<*>): AppState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_CONTACT_TYPES_SUCCESS: {
      return { ...state, contactTypes: payload };
    }
    case ActionTypes.GET_ALL_REFERRAL_TYPES_SUCCESS: {
      return { ...state, referralTypes: payload };
    }
    case ActionTypes.GET_PROSPECT_ASSIGNEES_SUCCESS: {
      return { ...state, prospectAssignees: payload };
    }
    case ActionTypes.GET_WORK_ORDER_ASSIGNEES_SUCCESS: {
      return { ...state, workOrderAssignees: payload };
    }
    case ActionTypes.GET_ALL_ASSIGNEES_SUCCESS: {
      return { ...state, allAssignees: payload };
    }
    case ActionTypes.GET_ALL_PET_TYPES: {
      return { ...state, petTypesIsLoading: true };
    }
    case ActionTypes.GET_ALL_PET_TYPES_SUCCESS: {
      return { ...state, petTypes: payload, petTypesIsLoading: false };
    }
    case ActionTypes.GET_ALL_PET_TYPES_ERROR: {
      return { ...state, petTypesIsLoading: false };
    }
    case ActionTypes.GET_ALL_PET_BREEDS: {
      return { ...state, petBreedsIsLoading: true };
    }
    case ActionTypes.GET_ALL_PET_BREEDS_SUCCESS: {
      return { ...state, petBreeds: payload, petBreedsIsLoading: false };
    }
    case ActionTypes.GET_ALL_PET_BREEDS_ERROR: {
      return { ...state, petBreedsIsLoading: false };
    }
    case ActionTypes.GET_ALL_PROSPECT_STATUS_SUCCESS: {
      return { ...state, prospectStatusList: payload };
    }
    case ActionTypes.GET_ALL_WORK_ORDER_STATUS_SUCCESS: {
      return { ...state, workOrderStatusList: payload };
    }
    case ActionTypes.GET_ALL_ACTIVITY_TYPES_SUCCESS: {
      return { ...state, activityTypes: payload };
    }
    case ActionTypes.GET_ALL_NAVIGATION_OPTIONS_SUCCESS: {
      return { ...state, navigationOptions: payload };
    }
    case ActionTypes.SELECT_PROPERTY: {
      return { ...state, selectedProperty: payload };
    }
    case ActionTypes.SELECT_PORTFOLIO_SUMMARY: {
      return { ...state, selectedProperty: undefined };
    }
    case ActionTypes.UPDATE_CURRENT_USER: {
      return { ...state, selectedProperty: undefined, currentUser: payload };
    }
    case ActionTypes.LOG_OUT: {
      return { ...state, selectedProperty: undefined, currentUser: undefined };
    }
    case ActionTypes.GET_ALL_NAME_SUFFIXES: {
      return { ...state, nameSuffixesIsLoading: true };
    }
    case ActionTypes.GET_ALL_NAME_SUFFIXES_SUCCESS: {
      return { ...state, nameSuffixes: payload, nameSuffixesIsLoading: false };
    }
    case ActionTypes.GET_ALL_NAME_SUFFIXES_ERROR: {
      return { ...state, nameSuffixesIsLoading: false };
    }
    case ActionTypes.GET_ALL_PROPERTY_CLASSES: {
      return { ...state, propertyClassesIsLoading: true };
    }
    case ActionTypes.GET_ALL_PROPERTY_CLASSES_SUCCESS: {
      return {
        ...state,
        propertyClasses: payload,
        propertyClassesIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_PROPERTY_CLASSES_ERROR: {
      return { ...state, propertyClassesIsLoading: false };
    }
    case ActionTypes.GET_ALL_RELATIONSHIPS: {
      return { ...state, relationshipsIsLoading: true };
    }
    case ActionTypes.GET_ALL_RELATIONSHIPS_SUCCESS: {
      return {
        ...state,
        relationships: payload,
        relationshipsIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_RELATIONSHIPS_ERROR: {
      return { ...state, relationshipsIsLoading: false };
    }
    case ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS: {
      return { ...state, affordableRelationshipsIsLoading: true };
    }
    case ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS_SUCCESS: {
      return {
        ...state,
        affordableRelationship: payload,
        affordableRelationshipsIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS_ERROR: {
      return { ...state, affordableRelationshipsIsLoading: false };
    }
    case ActionTypes.GET_ALL_APPLICANT_TYPES: {
      return { ...state, applicantTypesIsLoading: true };
    }
    case ActionTypes.GET_ALL_APPLICANT_TYPES_SUCCESS: {
      return {
        ...state,
        applicantTypes: payload,
        applicantTypesIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_APPLICANT_TYPES_ERROR: {
      return {
        ...state,
        applicantTypesIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_FLOOR_PLANS: {
      return { ...state, floorPlansIsLoading: true };
    }
    case ActionTypes.GET_ALL_FLOOR_PLANS_SUCCESS: {
      return {
        ...state,
        floorPlans: payload,
        floorPlansIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_FLOOR_PLANS_ERROR: {
      return {
        ...state,
        floorPlansIsLoading: false,
      };
    }
    case ActionTypes.GET_ALL_COUNTRIES_SUCCESS: {
      return { ...state, countries: payload };
    }
    case ActionTypes.GET_ALL_STATES_SUCCESS: {
      return { ...state, states: payload };
    }
    case ActionTypes.GET_ALL_INCOME_TYPES_SUCCESS: {
      return { ...state, incomeTypes: payload };
    }
    case ActionTypes.GET_ALL_SPECIAL_NEEDS_DESIGNATION_SUCCESS: {
      return { ...state, specialNeedsDesignations: payload };
    }
    case ActionTypes.GET_ALL_EMPLOYMENT_STATUS_SUCCESS: {
      return { ...state, employmentStatuses: payload };
    }
    case ActionTypes.GET_ALL_WORK_ORDER_ISSUES_SUCCESS: {
      return { ...state, workOrderIssues: payload };
    }
    case ActionTypes.GET_ALL_UNIT_LOCATIONS_SUCCESS: {
      return { ...state, unitLocations: payload };
    }
    case ActionTypes.GET_ALL_COMMON_AREA_LOCATIONS_SUCCESS: {
      return { ...state, commonAreaLocations: payload };
    }
    case ActionTypes.GET_ALL_ENTRY_PERMISSIONS_SUCCESS: {
      return { ...state, entryPermissions: payload };
    }
    case ActionTypes.GET_ALL_PRIORITY_LEVELS_SUCCESS: {
      return { ...state, priorityLevels: payload };
    }
    case ActionTypes.GET_ALL_MOVE_OUT_REASONS_SUCCESS: {
      return { ...state, moveOutReasons: payload };
    }
    case ActionTypes.GET_ALL_HOUSING_SITUATIONS_SUCCESS: {
      return { ...state, housingSituations: payload };
    }
    case ActionTypes.SHOW_EXTEND_USER_SESSION_MODAL: {
      return { ...state, showExtendUserSessionModal: true };
    }
    case ActionTypes.HIDE_EXTEND_USER_SESSION_MODAL: {
      return { ...state, showExtendUserSessionModal: false };
    }
    case ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY: {
      return { ...state, occupiedUnitsLoaded: false };
    }
    case ActionTypes.GET_ALL_PRORATE_METHODS_SUCCESS: {
      return { ...state, prorateMethods: payload };
    }
    case ActionTypes.GET_ALL_MOVE_OUT_PRORATE_METHODS_SUCCESS: {
      return { ...state, moveOutProrateMethods: payload };
    }
    case ActionTypes.GET_ALL_UTILITY_ALLOWANCE_TYPES_SUCCESS: {
      return { ...state, utilityAllowanceTypes: payload };
    }
    case ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_SUCCESS: {
      return {
        ...state,
        selectedPropertyOccupiedUnits: payload,
        occupiedUnitsLoaded: true,
      };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES: {
      return { ...state, transactionCodesLoaded: false };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES_SUCCESS: {
      return {
        ...state,
        selectedPropertyTransactionCodes: payload,
        transactionCodesLoaded: true,
      };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS: {
      return { ...state, subjournalsLoaded: false };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS_SUCCESS: {
      return {
        ...state,
        selectedPropertySubjournals: payload,
        subjournalsLoaded: true,
      };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD: {
      return { ...state, fiscalPeriodLoaded: false };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD_SUCCESS: {
      return {
        ...state,
        selectedPropertyFiscalPeriod: payload,
        fiscalPeriodLoaded: true,
      };
    }
    case ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS:
      return merge(state, {
        bankAccountsLoaded: false,
        bankAccountsError: undefined,
      });
    case ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS_SUCCESS:
      return merge(state, {
        bankAccountsLoaded: true,
        selectedPropertyBankAccounts: payload,
        bankAccountsError: undefined,
      });
    case ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS_FAILURE:
      return merge(state, { bankAccountsError: payload });
    case ActionTypes.GET_WRITEOFFS_FOR_CUSTOMER_SUCCESS:
      return {
        ...state,
        writeOffsByCustomerId: {
          ...state.writeOffsByCustomerId,
          [payload.customerId]: payload.writeOffs,
        },
      };
    case ActionTypes.ADD_WRITE_OFFS_FOR_CUSTOMER:
      const customerWriteOffs = state.writeOffsByCustomerId[payload.customerId];
      if (!customerWriteOffs)
        return {
          ...state,
          writeOffsByCustomerId: {
            ...state.writeOffsByCustomerId,
            [payload.customerId]: payload.writeOffs,
          },
        };
      return {
        ...state,
        writeOffsByCustomerId: {
          ...state.writeOffsByCustomerId,
          [payload.customerId]: [...customerWriteOffs, ...payload.writeOffs],
        },
      };
    case ActionTypes.GET_ALL_APPLICANT_CANCELLATION_REASONS_SUCCESS:
      return {
        ...state,
        applicantCancellationReasons: payload.applicantCancellationReasons,
      };
    case ActionTypes.GET_COMPLIANCE_STATUSES_SUCCESS: {
      return {
        ...state,
        affordableOptions: {
          ...state.affordableOptions,
          complianceStatuses: payload,
        },
      };
    }
    case ActionTypes.GET_RENTAL_ASSISTANCE_SOURCES_SUCCESS: {
      return {
        ...state,
        affordableOptions: {
          ...state.affordableOptions,
          rentalAssistanceSources: payload,
        },
      };
    }
    case ActionTypes.GET_UTILITY_ALLOWANCES_SUCCESS: {
      return {
        ...state,
        affordableOptions: {
          ...state.affordableOptions,
          utilityAllowances: payload.allowanceDropdowns,
        },
      };
    }
    case ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER_SUCCESS: {
      return {
        ...state,
        propertyPaymentMethod: payload,
      };
    }
    case ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER_ERROR: {
      return {
        ...state,
        propertyPaymentMethod: {},
      };
    }
    default:
      return state;
  }
};

export default app;
