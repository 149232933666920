import { useRef, useState } from 'react';
import { useResizeObserver } from '@fortress-technology-solutions/fortress-component-library/hooks';

const ComplianceOverviewLayout = ({ children, name }) => {
  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState('calc(100dvh - 134px)');

  useResizeObserver({
    ref,
    callback: (entry) => {
      let newTableHeight = 0;
      // loop through children and set height
      entry.target.childNodes.forEach((child) => {
        if (child.id !== `table-wrapper_${name}`) {
          newTableHeight += child.clientHeight;
        }
      });

      setTableHeight(
        `calc(100dvh - ${newTableHeight + 40 + 40 + 52.5 + 32 + 16}px)`,
      );
    },
  });

  return (
    <div ref={ref} style={{ height: '100%', overflow: 'hidden' }}>
      {children(tableHeight)}
    </div>
  );
};

export default ComplianceOverviewLayout;
