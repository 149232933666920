import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import DelinquencyServiceV2 from '../../services/delinquencyServiceV2';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { PROPERTY_PATH_MAP, HEADERS, TOTAL_COLUMNS } from './constantsv2';
import {
  appendFilterTextToCSV,
  processDataToCSV,
} from '../../utils/csv-helpers';
import { cond, equals, always, anyPass, T } from 'ramda';
import { download } from '../../utils/downloadFile';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import ActivityTypeService from '../../services/activityTypeService';
import ActivityService from '../../services/activityService';
import { createActivity } from '../../components/CreateActivityModal';
import peopleProfileMessages from '../PeopleProfile/messages';
import useProspectAssignees from '../../hooks/data-fetching/useProspectAssignees';
import useActivityTypes from '../../hooks/data-fetching/useActivityTypes';
import { buildGrandTotalForCSVExport } from './utilsV2';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export const useDelinquentAndPrepaid = ({
  selectedProperty,
  NAME,
  intl,
  locale,
  store,
}) => {
  const name = useUniqueTableName(NAME);
  const {
    data,
    isLoading,
    refetch: refreshTable,
  } = useFetchDelinquentAndPrepaid({
    selectedProperty,
  });
  const results = useMemo(() => data ?? [], [data]);

  const { showCAMOnlyBalances, showSubsidyOnlyBalances } =
    useGetShowOptionalColumns(results);

  const { prospectAssignees: users } = useProspectAssignees();

  const assigneeOptions = useMemo(
    () =>
      users.map((u) => ({
        value: u.id,
        text: `${u.firstName} ${u.lastName}`,
      })),
    [users],
  );

  const { activityTypes } = useActivityTypes();
  const { activityTypesList } = useMemo(() => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList({
      activityTypes,
      locale,
      intl,
    });

    return { activityTypeService, activityTypesList };
  }, [activityTypes, intl, locale]);

  const { statusOptions, floorPlanOptions, buildingNumberOptions } =
    useGetFilterOptions({
      results,
    });

  const headers = useHeaders({
    statusOptions,
    floorPlanOptions,
    buildingNumberOptions,
    showSubsidyOnlyBalances,
    showCAMOnlyBalances,
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'unitNumber',
    initialOrder: 'ASC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const { organizationId } = selectedProperty;

  const handleCreateActivity = async ({
    intl,
    organizationId,
    refreshTable,
    newActivity,
  }): Promise<void> => {
    try {
      const activityService = new ActivityService();

      await activityService.save(newActivity, organizationId);

      toastr.success(
        intl.formatMessage(peopleProfileMessages.successHeader),
        intl.formatMessage(
          peopleProfileMessages.successActivityCreateDescription,
        ),
      );

      refreshTable();
    } catch (err) {
      toastr.error(
        intl.formatMessage(peopleProfileMessages.errorHeader),
        err.toString(),
      );
    }
  };

  const handleCreateActivityClick = useCallback(
    async (row) => {
      const delinquency = row;
      const prospectInfo = {
        id: delinquency.prospectId,
        firstName: delinquency.firstName,
        lastName: delinquency.lastName,
        createdAt: delinquency.prospectCreatedAt,
      };

      const getStageProps = cond([
        [
          equals('Applicant'),
          always({ stage: 'applicant', urlId: delinquency.applicationId }),
        ],
        [
          anyPass([equals('Current Resident'), equals('Prior Resident')]),
          always({ stage: 'resident', urlId: delinquency.residentId }),
        ],
        [
          anyPass([equals('Prospect'), T]),
          always({ stage: 'prospect', urlId: delinquency.prospectId }),
        ],
      ]);
      const stageProps = getStageProps(delinquency.customerStatus);

      const newActivity = await createActivity({
        intl,
        activityTypesList,
        store,
        prospect: prospectInfo,
        assigneeList: assigneeOptions,
        showViewProfileBtn: true,
        ...stageProps,
      });

      await handleCreateActivity({
        intl,
        organizationId,
        refreshTable,
        newActivity: {
          ...newActivity,
          customerStatus: delinquency.customerStatus ?? 'Prospect',
        },
      });
    },
    [
      intl,
      activityTypesList,
      assigneeOptions,
      store,
      organizationId,
      refreshTable,
    ],
  );

  const rows = useRows({
    sortedAndFilteredResults,
    intl,
    locale,
    store,
    handleCreateActivityClick,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const footerRow = useGetFooterRow({
    filteredHeaders,
    sortedAndFilteredResults,
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    propertyName: name,
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    footerRow,
    results: sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    onCSVButtonClick,
  };
};

export const useFetchDelinquentAndPrepaid = ({ selectedProperty = {} }) => {
  const { organizationId, id } = selectedProperty;

  const delinquencyService = useMemo(() => new DelinquencyServiceV2(), []);
  const abortController = new AbortController();
  const queryKey = [organizationId, id];
  const options = { signal: abortController.signal };
  return useQuery(
    ['DelinquentAndPrepaid', queryKey],
    () =>
      delinquencyService.getAllDelinquencies({
        organizationId,
        propertyId: id,
        options,
      }),
    { enabled: true },
  );
};

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      statusOptions: [],
      floorPlanOptions: [],
      buildingNumberOptions: [],
    };
    const statusOptions = new Set();
    const floorPlanOptions = new Set();
    const buildingNumberOptions = new Set();

    if (results) {
      results.forEach((row) => {
        if (row?.status?.length) {
          statusOptions.add(row.status);
        }
        if (row?.floorPlan?.length) {
          floorPlanOptions.add(row.floorPlan);
        }
        if (row?.buildingNumber?.length) {
          buildingNumberOptions.add(row.buildingNumber);
        }
      });

      options.statusOptions = Array.from(statusOptions)
        .sort()
        .map((status) => ({
          text: status,
          value: status?.toLowerCase(),
        }));

      options.floorPlanOptions = Array.from(floorPlanOptions)
        .sort()
        .map((floorPlan) => ({
          text: floorPlan,
          value: floorPlan?.toLowerCase(),
        }));

      options.buildingNumberOptions = Array.from(buildingNumberOptions)
        .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        .map((buildingNumber) => ({
          text: buildingNumber,
          value: buildingNumber?.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};

const useHeaders = ({
  statusOptions = {},
  floorPlanOptions = {},
  buildingNumberOptions = {},
  showSubsidyOnlyBalances,
  showCAMOnlyBalances,
}) => {
  return useMemo(() => {
    return HEADERS.filter(
      (header) =>
        (header.id !== 'subsidyOnlyBalance' &&
          header.id !== 'CAMOnlyBalance') ||
        (header.id === 'subsidyOnlyBalance' && showSubsidyOnlyBalances) ||
        (header.id === 'CAMOnlyBalance' && showCAMOnlyBalances),
    ).map((header) => {
      if (header.id === 'status') {
        header.filterOptions = statusOptions;
      }
      if (header.id === 'floorPlan') {
        header.filterOptions = floorPlanOptions;
      }
      if (header.id === 'buildingNumber') {
        header.filterOptions = buildingNumberOptions;
      }
      return header;
    });
  }, [
    statusOptions,
    floorPlanOptions,
    buildingNumberOptions,
    showSubsidyOnlyBalances,
    showCAMOnlyBalances,
  ]);
};

const useGetShowOptionalColumns = (rows: Array<any>) => {
  return useMemo(() => {
    const someRowsHaveCAM = rows.some((row) =>
      Object.prototype.hasOwnProperty.call(row, 'CAMOnlyBalance'),
    );
    const someRowsHaveSubsidy = rows.some((row) =>
      Object.prototype.hasOwnProperty.call(row, 'subsidyOnlyBalance'),
    );
    const showCAMOnlyBalances = someRowsHaveCAM;
    const showSubsidyOnlyBalances = someRowsHaveSubsidy || !someRowsHaveCAM;

    return { showCAMOnlyBalances, showSubsidyOnlyBalances };
  }, [rows]);
};

const useGetFooterRow = ({ filteredHeaders, sortedAndFilteredResults }) => {
  return useMemo(() => {
    const totalsColumns = JSON.parse(JSON.stringify(TOTAL_COLUMNS));

    sortedAndFilteredResults.forEach((result) => {
      Object.keys(totalsColumns).forEach((id) => {
        if (result[id]) {
          const addition =
            typeof result[id] === 'string'
              ? parseFloat(result[id])
              : result[id];
          totalsColumns[id].value += addition;
        }
      });
    });

    return filteredHeaders.reduce((object, { id }) => {
      object[id] = totalsColumns[id] ?? '';
      return object;
    }, {});
  }, [filteredHeaders, sortedAndFilteredResults]);
};

export const useRows = ({
  sortedAndFilteredResults,
  handleCreateActivityClick,
}) => {
  return useMemo(
    () =>
      sortedAndFilteredResults?.map((row) => {
        const {
          unitNumber,
          status,
          floorPlan,
          buildingNumber,
          profile,
          residentId,
          applicationId,
          phoneNumber,
          moveInDate,
          moveOutDate,
          collectionsNotes,
          underEviction,
          prepaidBalance,
          delinquentBalance,
          writtenOff,
          subsidyOnlyBalance,
          CAMOnlyBalance,
          residentOnlyBalance,
          rentOnlyBalance,
          current,
          thirtyOneToSixty,
          sixtyOneToNinety,
          ninetyOnePlus,
          depositsHeld,
          depositsOwed,
          lateFees,
          nsfFees,
          unitId,
          floorPlanId,
        } = row;

        let profileLink;
        if (residentId && status === 'Prior Resident') {
          profileLink = getUrlWithSelectedPropertyId(
            `/prior-resident/${residentId}`,
          );
        } else if (residentId) {
          profileLink = getUrlWithSelectedPropertyId(`/resident/${residentId}`);
        } else {
          profileLink = getUrlWithSelectedPropertyId(
            `/application/${applicationId}`,
          );
        }

        return {
          unitNumber: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/unit/${unitId}`),
            value: unitNumber,
          },
          status: {
            variant: 'description',
            value: status,
          },
          floorPlan: {
            variant: 'link',
            to: getUrlWithSelectedPropertyId(`/floorPlan/${floorPlanId}`),
            value: floorPlan,
          },
          buildingNumber: {
            variant: buildingNumber?.length > 10 ? 'description' : 'string',
            value: buildingNumber,
          },
          profile: {
            variant: 'link',
            to: profileLink,
            value: profile,
            csvContent: (profile ?? '').replaceAll(/[,\r\n]/g, ''),
          },
          phoneNumber: {
            variant: 'phoneNumber',
            value: phoneNumber,
          },
          moveInDate: {
            variant: 'date',
            value: moveInDate,
          },
          moveOutDate: {
            variant: 'date',
            value: moveOutDate,
          },
          collectionsNotes: {
            variant: 'description',
            value: collectionsNotes,
            csvContent: (collectionsNotes ?? '').replaceAll(/[,\r\n]/g, ''),
          },
          underEviction: {
            variant: 'boolean',
            value: underEviction,
            iconName: 'AlertInfoIcon',
            color: 'error',
          },
          prepaidBalance: {
            variant: 'currency',
            value: prepaidBalance ?? 0,
          },
          delinquentBalance: {
            variant: 'currency',
            value: delinquentBalance ?? 0,
          },
          writtenOff: {
            variant: 'currency',
            value: writtenOff ?? 0,
          },
          subsidyOnlyBalance: {
            variant: 'currency',
            value: subsidyOnlyBalance ?? 0,
          },
          CAMOnlyBalance: {
            variant: 'currency',
            value: CAMOnlyBalance ?? 0,
          },
          residentOnlyBalance: {
            variant: 'currency',
            value: residentOnlyBalance ?? 0,
          },
          rentOnlyBalance: {
            variant: 'currency',
            value: rentOnlyBalance ?? 0,
          },
          current: {
            variant: 'currency',
            value: current ?? 0,
          },
          thirtyOneToSixty: {
            variant: 'currency',
            value: thirtyOneToSixty ?? 0,
          },
          sixtyOneToNinety: {
            variant: 'currency',
            value: sixtyOneToNinety ?? 0,
          },
          ninetyOnePlus: {
            variant: 'currency',
            value: ninetyOnePlus ?? 0,
          },
          depositsHeld: {
            variant: 'currency',
            value: depositsHeld ?? 0,
          },
          depositsOwed: {
            variant: 'currency',
            value: depositsOwed ?? 0,
          },
          lateFees: {
            variant: 'number',
            value: lateFees === 0 ? '' : lateFees,
          },
          nsfFees: {
            variant: 'number',
            value: nsfFees === 0 ? '' : nsfFees,
          },
          actions: {
            variant: 'menu',
            name: 'actionsMenu',
            iconName: 'BoltIcon',
            options: [
              {
                text: 'Create Activity',
                value: residentId,
                onClick: () => {
                  handleCreateActivityClick(row);
                },
              },
            ],
          },
        };
      }),
    [sortedAndFilteredResults, handleCreateActivityClick],
  );
};

const useCSVPDFExport = ({
  filteredHeaders,
  rows,
  footerRow,
  propertyName,
  hasAnyFilters,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = [];
    for (const { label } of filteredHeaders) {
      if (label === 'Actions') continue;
      csvHeaders.push(
        typeof label === 'string' ? label : label.props?.defaultMessage,
      );
    }

    const csvRows = rows.map((row) => {
      const { profile, collectionsNotes, underEviction } = row;
      const csvRow = {
        ...row,
        profile: {
          ...profile,
          value: profile.csvContent,
        },
        collectionsNotes: {
          ...collectionsNotes,
          value: collectionsNotes.csvContent,
        },
        underEviction: {
          ...underEviction,
          value: underEviction.value ? 'Yes' : '',
          variant: 'description',
        },
      };

      return csvRow;
    });

    const processedCSVRows = processDataToCSV({
      rows: csvRows,
      filteredHeaders,
    });

    const totalsRow = buildGrandTotalForCSVExport({
      totals: TOTAL_COLUMNS,
      filteredHeaders,
      footerRow,
    });
    processedCSVRows.push(totalsRow);

    const csv = appendFilterTextToCSV({
      headers: csvHeaders.join(','),
      rows: processedCSVRows,
      hasAnyFilters,
    });

    download(
      csv,
      `ManageDelinquentAndPrepaid_${propertyName
        ?.split(' ')
        .join('-')}_${formatDateDB(new Date())}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [filteredHeaders, rows, footerRow, hasAnyFilters, propertyName]);

  return { onCSVButtonClick };
};
