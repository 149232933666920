import { injectIntl } from 'react-intl';
import { ManageWorkOrdersTable } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageWorkOrdersV2 } from './hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link as ReactRouterLink } from 'react-router-dom';

function ManageWorkOrders({ intl }) {
  const props = useManageWorkOrdersV2({ intl });

  return (
    <ManageWorkOrdersTable
      ReactBeautifulDnD={{
        DragDropContext,
        Droppable,
        Draggable,
      }}
      ReactRouterLink={ReactRouterLink}
      {...props}
    />
  );
}

export default injectIntl(ManageWorkOrders);
