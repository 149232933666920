import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { head, pathOr, isNil, isEmpty } from 'ramda';
import { FormattedMessage } from 'react-intl';

import confirm from '../../components/ConfirmDialogModal';
import Spinner from '../../components/Spinner';

// eslint-disable-next-line max-len
import AssignTransactionsFromCanceledCamModal from './Sections/ReassignTransactionsModal/assignTransactionsFromCanceledCamModal';

import { MODAL_STATES } from './constants';
import messages from './messages';
import CamStartDateModal from './Sections/CamStartDateModal';
import CamRecordDetails from './Sections/CamRecordDetails';
import type { CamTransaction, CamTabProps } from './types';

import {
  useFetchCamRecords,
  useCreateCamRecord,
  useModifyCamRecord,
  useRemoveCamRecord,
  useStartNewCam,
  useFetchCamTransactions,
} from './hooks';

const CamTab = ({
  selectedProperty,
  householdId,
  intl,
  promptToaster,
  isPriorResident,
  onCamRefresh,
}: CamTabProps) => {
  const { id: propertyId, organizationId } = selectedProperty;

  const [modalState, setModalState] = useState(MODAL_STATES.NONE);

  const [selectedCamRecordId, setSelectedCamRecordId] = useState(null);

  const hooksArgs = {
    intl,
    propertyId,
    organizationId,
    householdId,
    promptToaster,
  };

  const onCamRecordFetchSuccess = (newRecords: Array<Object>) => {
    const currentCam = newRecords.find((cam) => {
      const replacedBy = pathOr(null, ['replacedByCamRecord', 'id'], cam);
      return replacedBy === null;
    });
    const newDefault = currentCam || head(newRecords);
    if (!newDefault) {
      return;
    }

    if (
      !selectedCamRecordId ||
      !newRecords.some((camRecord) => camRecord.id === selectedCamRecordId)
    ) {
      setSelectedCamRecordId(newDefault.id);
    }
  };

  const onCamRecordDeleteSuccess = () => {
    setSelectedCamRecordId(null);
    onCamRefresh();
  };

  const [isLoadingFetch, camRecords, refreshAllCamRecords] = useFetchCamRecords(
    {
      ...hooksArgs,
      onSuccess: onCamRecordFetchSuccess,
    },
  );

  const [isLoadingCreate, createCamRecord] = useCreateCamRecord({
    ...hooksArgs,
    refresh: refreshAllCamRecords,
  });

  const [isLoadingModify, modifyCamRecord] = useModifyCamRecord({
    ...hooksArgs,
    refresh: refreshAllCamRecords,
  });

  const [isLoadingRemove, removeCamRecord] = useRemoveCamRecord({
    ...hooksArgs,
    refresh: refreshAllCamRecords,
    onSuccess: onCamRecordDeleteSuccess,
  });

  const onStartNewCamCompleted = (newCamRecordId = null) => {
    setSelectedCamRecordId(newCamRecordId);
    refreshAllCamRecords();
  };

  const [isLoadingStartNewCam, startNewCam] = useStartNewCam({
    ...hooksArgs,
    onStartNewCamCompleted,
  });

  const onFetchCamTransactionsComplete = (
    transactions: Array<CamTransaction>,
  ) => {
    // If there's no other CAM Records than the one we're deleting, we can't reassign transactions to
    // another CAM. In this case, just cancel the CAM and unassign the transactions from CAM.
    const hasNoTransactions = isNil(transactions) || isEmpty(transactions);
    const hasNoOtherCamRecord =
      isNil(camRecords) ||
      (camRecords.length === 1 &&
        pathOr(null, ['0', 'id'], camRecords) === selectedCamRecordId);

    if (hasNoTransactions || hasNoOtherCamRecord) {
      removeCamRecord(selectedCamRecordId);
    } else {
      setModalState(MODAL_STATES.REASSIGN_TRANSACTIONS);
    }
  };

  const [isLoadingCamTransactions, camTransactions] = useFetchCamTransactions({
    intl,
    organizationId,
    propertyId,
    camRecordsId: selectedCamRecordId,
    promptToaster,
    onComplete: onFetchCamTransactionsComplete,
  });

  const onStartDateModalSubmit = (value) => {
    const camStartDate = pathOr(null, ['camStartDate'], value);
    if (!camStartDate) {
      return; // shouldn't happen since the modal is validated
    }

    const startDate = moment(camStartDate).format('YYYY-MM-DD');
    switch (modalState) {
      case MODAL_STATES.CREATE_NEW_CAM:
        createCamRecord({ startDate });
        break;
      case MODAL_STATES.EDIT_START_DATE:
        modifyCamRecord(selectedCamRecordId, { startDate });
        break;
      case MODAL_STATES.START_NEW_CAM:
        startNewCam(selectedCamRecordId, { startDate });
        break;
      default:
        break;
    }

    setModalState(MODAL_STATES.NONE);
  };

  const submitCamTransactionsModal = async (
    transactionsToReassign: Array<TransactionToModify>,
  ) => {
    removeCamRecord(selectedCamRecordId, transactionsToReassign);
    setModalState(MODAL_STATES.NONE);
  };

  const onCamRecordSelected = (value) => {
    setSelectedCamRecordId(value);
  };

  const onCamRecordCancelClicked = () => {
    confirm(intl.formatMessage(messages.cancelConfirmation)).then(() =>
      removeCamRecord(selectedCamRecordId),
    );
  };

  const onDownloadReconciliationReport = () => {
    /* todo */
  };

  const isLoading =
    isLoadingCreate ||
    isLoadingFetch ||
    isLoadingRemove ||
    isLoadingModify ||
    isLoadingStartNewCam;
  const showCamDetails = !!(
    !isLoading &&
    camRecords &&
    camRecords.length > 0 &&
    selectedCamRecordId
  );
  const showAddStartDateButton = !isLoading && !showCamDetails;
  const selectedCamRecord = camRecords.find(
    (camRecord) => camRecord.id === selectedCamRecordId,
  );
  const camStartDateStr = pathOr(null, ['startDate'], selectedCamRecord);
  const camStartDate = camStartDateStr
    ? moment(camStartDateStr, 'YYYY-MM-DD')
    : null;
  const initialValuesForCamStartDateModal =
    modalState === MODAL_STATES.EDIT_START_DATE ? { camStartDate } : {};

  const showAddStartDateModal = [
    MODAL_STATES.CREATE_NEW_CAM,
    MODAL_STATES.START_NEW_CAM,
    MODAL_STATES.EDIT_START_DATE,
  ].includes(modalState);

  const showCamTransactionsModal =
    modalState === MODAL_STATES.REASSIGN_TRANSACTIONS;

  return (
    <React.Fragment>
      {/* Loading data... */}
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {/* Show the modal to add/edit cam start date */}
      {showAddStartDateModal && (
        <CamStartDateModal
          initialValues={initialValuesForCamStartDateModal}
          modalState={modalState}
          camRecords={camRecords}
          selectedCamRecordId={selectedCamRecordId}
          onHide={() => setModalState(MODAL_STATES.NONE)}
          onSubmit={onStartDateModalSubmit}
        />
      )}
      {/* Show the modal to reassign Transactions to different pools when canceling a pending CAM */}
      {showCamTransactionsModal && (
        <AssignTransactionsFromCanceledCamModal
          intl={intl}
          organizationId={organizationId}
          propertyId={propertyId}
          householdId={householdId}
          onHide={() => setModalState(MODAL_STATES.NONE)}
          onSubmit={submitCamTransactionsModal}
          isLoading={isLoadingCamTransactions}
          transactions={camTransactions}
          camRecordIdToCancel={selectedCamRecordId}
        />
      )}
      {/* No cam records yet, show button to add one */}
      {!isLoading && showAddStartDateButton && (
        <Button
          className="btn btn-tertiary center-block"
          onClick={() => setModalState(MODAL_STATES.CREATE_NEW_CAM)}
        >
          <FormattedMessage {...messages.startNewCAMBtn} />
        </Button>
      )}
      {/* Show CAM details */}
      {showCamDetails && (
        <CamRecordDetails
          camRecords={camRecords}
          selectedCamRecordId={selectedCamRecordId}
          onCamRecordSelected={onCamRecordSelected}
          onShowEditCamRecordModal={() =>
            setModalState(MODAL_STATES.EDIT_START_DATE)
          }
          onCamRecordCancelClicked={onCamRecordCancelClicked}
          onStartNewCamRecordClicked={() =>
            setModalState(MODAL_STATES.START_NEW_CAM)
          }
          onDownloadReconciliationReport={onDownloadReconciliationReport}
          intl={intl}
          propertyId={propertyId}
          organizationId={organizationId}
          householdId={householdId}
          promptToaster={promptToaster}
          isPriorResident={isPriorResident}
          onCamRefresh={onCamRefresh}
        />
      )}
    </React.Fragment>
  );
};

export default CamTab;
