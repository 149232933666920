import React from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useAllWorkOrders } from './hooks';
import { Link as ReactRouterLink } from 'react-router-dom';

function AllWorkOrders({ intl }) {
  const {
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    handleTruncateChange,
    onCSVButtonClick,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    truncatedColumns,
  } = useAllWorkOrders();

  return (
    <DocumentTitle data-test="manage-work-orders" title={'Work Orders'}>
      <div>
        <TableHeader title={'Work Orders'} count={rows?.length ?? 0} />
        <VirtualizedTable
          ReactBeautifulDnD={{
            DragDropContext,
            Droppable,
            Draggable,
          }}
          ReactRouterLink={ReactRouterLink}
          allColumnsHidden={allColumnsHidden}
          columnOptions={columnOptions}
          dateState={dateState}
          filterState={filterState}
          filterTypeState={filterTypeState}
          handleColumnChange={handleColumnChange}
          handleDateSubmit={handleDateSubmit}
          handleFilterChange={handleFilterChange}
          handleFilterTypeChange={handleFilterTypeChange}
          handleSearchSubmit={handleSearchSubmit}
          handleSortChange={handleSortChange}
          handleTruncateChange={handleTruncateChange}
          headers={filteredHeaders}
          height={'calc(100vh - 216px)'}
          onCSVButtonClick={onCSVButtonClick}
          intl={intl}
          isLoading={isLoading}
          name={name}
          order={order}
          orderBy={orderBy}
          rows={rows}
          searchState={searchState}
          selectedColumns={selectedColumns}
          truncatedColumns={truncatedColumns}
        />
      </div>
    </DocumentTitle>
  );
}

export default injectIntl(AllWorkOrders);
