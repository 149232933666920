import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ProspectProfile.ProspectProfileForm.Header',
    defaultMessage: 'Add new prospect',
  },
  headerRequiredFields: {
    id: 'App.ProspectProfile.ProspectProfileForm.HeaderRequiredFields',
    defaultMessage: '(*) Required fields',
  },
  generalRequiredLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.GeneralRequiredLabel',
    defaultMessage: 'Required',
  },
  additionalOccupantsHeader: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Header',
    defaultMessage: 'Additional Occupants',
  },
  startTyping: {
    id: 'App.ProspectProfile.ProspectProfileForm.StartTyping',
    defaultMessage: 'Start typing...',
  },
  source: {
    id: 'App.ProspectProfile.ProspectProfileForm.Source',
    defaultMessage: 'How did you hear about us?',
  },
  assignTo: {
    id: 'App.ProspectProfile.ProspectProfileForm.assignTo',
    defaultMessage: 'Assign Prospect *',
  },
  primaryContact: {
    id: 'App.ProspectProfile.ProspectProfileForm.PrimaryContact',
    defaultMessage: 'Primary Contact',
  },
  wants: {
    id: 'App.ProspectProfile.ProspectProfileForm.Wants',
    defaultMessage: 'Wants',
  },
  notWant: {
    id: 'App.ProspectProfile.ProspectProfileForm.NotWant',
    defaultMessage: "Doesn't Want",
  },
  cancel: {
    id: 'App.ProspectProfile.ProspectProfileForm.Cancel',
    defaultMessage: 'Cancel',
  },
  discard: {
    id: 'App.ProspectProfile.ProspectProfileForm.Discard',
    defaultMessage: 'Discard',
  },
  contactType: {
    id: 'App.PeopleProfile.ProspectProfileForm.ContactType',
    defaultMessage: 'Contact Type',
  },
  contactTypeLabel: {
    id: 'App.PeopleProfile.ProspectProfileForm.ContactTypeLabel',
    defaultMessage: 'Contact Type *',
  },
  referralTypesLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.ReferralTypesLabel',
    defaultMessage: 'How did you hear about us? *',
  },
  save: {
    id: 'App.ProspectProfile.ProspectProfileForm.Save',
    defaultMessage: 'Add Prospect',
  },
  requiredFields: {
    id: 'App.ProspectProfile.ProspectProfileForm.RequiredFields',
    defaultMessage:
      '* At least one of these fields must be provided: first name, last name, email address, phone number or contact type', // eslint-disable-line max-len
  },
  requiredReferralType: {
    id: 'App.ProspectProfile.ProspectProfileForm.RequiredReferralType',
    defaultMessage: 'Referral type is required',
  },
  requiredContactType: {
    id: 'App.ProspectProfile.ProspectProfileForm.RequiredContactType',
    defaultMessage: 'Contact type is required',
  },
  invalidName: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidName',
    defaultMessage: 'Names must have letters or digits only',
  },
  invalidPetTag: {
    id: 'App.ProspectProfile.ProspectProfileForm.invalidPetTag',
    defaultMessage: 'Pet tags must have letters, digits or dashes only',
  },
  invalidEmailAddress: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidEmailAddress',
    defaultMessage: 'Invalid email address',
  },
  invalidPhoneNumber: {
    id: 'App.ProspectProfile.ProspectProfileForm.invalidPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
  phoneNumberAlreadyUsed: {
    id: 'App.ProspectProfile.ProspectProfileForm.phoneNumberAlreadyUsed',
    defaultMessage: 'Phone number is already associated with another prospect.',
  },
  invalidBaths: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidBedsOrBaths',
    defaultMessage: 'Value must be between 0 and 9',
  },
  invalidRange: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidRange',
    defaultMessage: 'From value must be less or equal than To value.',
  },
  invalidDateRange: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidDateRange',
    defaultMessage: 'Initial value needed.',
  },
  invalidDateFormat: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidDateFormat',
    defaultMessage: 'Invalid date format.',
  },
  invalidDateValue: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidDateValue',
    defaultMessage: 'Value must be greater than today.',
  },
  invalidFieldValue: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidFieldValue',
    defaultMessage: 'Field value is not valid',
  },
  fromDateNeeded: {
    id: 'App.ProspectProfile.ProspectProfileForm.FromDateNeeded',
    defaultMessage: 'From date needed.',
  },
  additionalOccupants: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.AdditionalOccupants',
    defaultMessage: 'Additional occupants',
  },
  invalidValue: {
    id: 'App.ProspectProfile.ProspectProfileForm.invalidValue',
    defaultMessage: 'Invalid value',
  },
  invalidMinorAge: {
    id: 'App.ProspectProfile.ProspectProfileForm.InvalidMinorAge',
    defaultMessage: 'The age of a minor must be between 0 and 17 years.',
  },
  deleteConfirmation: {
    id: 'App.ProspectProfile.ProspectProfileForm.DeleteConfirmation',
    defaultMessage:
      'Are you sure you want to discard the information on the form?',
  },
  emptyAssignessListError: {
    id: 'App.ProspectProfile.ProspectProfileForm.EmptyAssignessListError',
    defaultMessage:
      '*There are no assignees. Please contact your system administrator.',
  },
  thankYouForChoosing: {
    id: 'App.ProspectProfile.ProspectProfileForm.ThankYouForChoosing',
    defaultMessage: 'Thank you for choosing',
  },
  thisIs: {
    id: 'App.ProspectProfile.ProspectProfileForm.ThisIs',
    defaultMessage: 'This is',
  },
  howMayIHelp: {
    id: 'App.ProspectProfile.ProspectProfileForm.HowMayIHelp',
    defaultMessage: 'How may I help you?',
  },
  deleteItemConfirmation: {
    id: 'App.ProspectProfile.ProspectProfileForm.DeleteItemConfirmation',
    defaultMessage: 'Are you sure you want to remove this item?',
  },
  introductoryNote: {
    id: 'App.ProspectProfile.ProspectProfileForm.IntroductoryNote',
    defaultMessage: 'Start typing introductory note...',
  },
});

export default messages;
