import moment from 'moment';
import React, {
  ChangeEvent,
  useState,
  FunctionComponent,
  useEffect,
} from 'react';
import {
  Stack,
  Switch,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import styled from 'styled-components';

import DateInput from '../../../components/DateInput';
import { StateSelect } from './StateSelect';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { Input } from './Input';
import { AddressInput } from './AddressInput';
import useIsAffordableProperty from '../../../hooks/useIsAffordableProperty';

import type { Building, State } from '../types';

import messages from '../../App/messages';

type Props = {
  building: Building,
  updateBuilding: Function,
  setSelectedBuilding: Function,
  disabled: boolean,
  states: State[],
  selectedProperty: Object,
  promptToaster: Function,
  intl: Object,
  manageBuildingsPisdFlag: boolean,
  xmlAlternateNameFlag: boolean,
};

const DateInputContainer = styled.div`
  max-width: 80%;
  margin: auto;
  height: 30px;

  input[type='text'],
  input[type='text']:focus {
    z-index: 0;
  }
  .rdtPicker {
    z-index: 0 !important;
    .rdtDays {
      tr th,
      th.down {
        position: initial;
      }
    }
  }
`;
// prettier-ignore
const StyledSwitch = styled(Switch)`
  & > .MuiSwitch-track {
    &:before {
      left: -9px!important;
    }
    &:after {
      left: 8px!important;
    }
  }
`;

export const Row = ({
  building,
  updateBuilding,
  setSelectedBuilding,
  disabled,
  states,
  selectedProperty,
  promptToaster,
  manageBuildingsPisdFlag,
  xmlAlternateNameFlag,
  intl,
}: Props): FunctionComponent<Props> => {
  const [editMode, setEditMode] = useState(false);
  const [streetAddress1, setStreetAddress1] = useState(
    building?.streetAddress1,
  );
  const [city, setCity] = useState(building?.city);
  const [state, setState] = useState(building?.state);
  const [zipCode, setZipCode] = useState(building?.zipCode);
  const [buildingIDNumber, setBuildingIdNumber] = useState(
    building?.buildingIDNumber ?? '',
  );
  const [XMLSiteName, setXMLSiteName] = useState(building?.XMLSiteName ?? '');
  const [placedInServiceDate, setPlacedInServiceDate] = useState(
    building?.placedInServiceDate ?? null,
  );
  const [heraSpecial, setHeraSpecial] = useState(
    building?.heraSpecial ?? false,
  );
  const isAffordableProperty = useIsAffordableProperty(selectedProperty);

  useEffect(() => {
    setStreetAddress1(building?.streetAddress1);
    setCity(building?.city);
    setState(building?.state);
    setZipCode(building?.zipCode);
    setBuildingIdNumber(building?.buildingIDNumber ?? '');
    setPlacedInServiceDate(building?.placedInServiceDate ?? null);
  }, [building]);

  const onEdit = () => {
    if (editMode) {
      const data = {
        streetAddress1: streetAddress1.trim(),
        city: city.trim(),
        state,
        zipCode,
        buildingIDNumber: buildingIDNumber.trim(),
        placedInServiceDate: placedInServiceDate || null,
        XMLSiteName: XMLSiteName ? XMLSiteName.trim() : null,
        heraSpecial: heraSpecial,
      };
      updateBuilding(selectedProperty, building.id, data, promptToaster, intl);
      setSelectedBuilding(null);
    } else {
      setSelectedBuilding(building.id);
    }
    setEditMode(!editMode);
  };

  const address = `${streetAddress1}, ${city}, ${
    state.split('-')[1]
  } ${zipCode}`;

  return (
    <tr>
      <td className={`col-md-${isAffordableProperty ? '1' : '2'}`}>
        {building.name}
      </td>
      <td className="col-md-4">
        {editMode ? (
          <Stack direction={'row'}>
            <AddressInput
              maxWidth="34%"
              value={streetAddress1}
              setValue={setStreetAddress1}
            />
            <AddressInput maxWidth="20%" value={city} setValue={setCity} />
            <StateSelect
              states={states}
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
            <AddressInput
              maxWidth="15%"
              value={zipCode}
              setValue={setZipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </Stack>
        ) : (
          address
        )}
      </td>
      {xmlAlternateNameFlag && isAffordableProperty && (
        <td className="col-md-2">
          {editMode ? (
            <Input
              maxWidth="80%"
              margin="auto"
              value={XMLSiteName}
              setValue={setXMLSiteName}
              maxLength={255}
            />
          ) : (
            XMLSiteName || '---'
          )}
        </td>
      )}
      {manageBuildingsPisdFlag && (
        <td className="col-md-2">
          {editMode ? (
            <DateInputContainer>
              <DateInput
                value={
                  placedInServiceDate
                    ? moment.utc(placedInServiceDate).format('MM/DD/YYYY')
                    : null
                }
                onChange={setPlacedInServiceDate}
              />
            </DateInputContainer>
          ) : placedInServiceDate ? (
            moment.utc(placedInServiceDate).format('MM/DD/YYYY')
          ) : (
            '---'
          )}
        </td>
      )}
      <td className="col-md-2">
        {editMode ? (
          <Input
            maxWidth="80%"
            margin="auto"
            value={buildingIDNumber}
            setValue={setBuildingIdNumber}
          />
        ) : (
          buildingIDNumber || '---'
        )}
      </td>
      {isAffordableProperty && (
        <td className="col-md-1">
          {editMode ? (
            <StyledSwitch
              name="heraSpecial"
              size="small"
              edge="start"
              onLabel={intl.formatMessage(messages.yes)}
              offLabel={intl.formatMessage(messages.no)}
              onChange={(
                evt: ChangeEvent<HTMLInputElement>,
                checked: boolean,
              ) => {
                setHeraSpecial(checked);
              }}
              checked={heraSpecial}
            />
          ) : (
            intl.formatMessage(messages?.[heraSpecial ? 'yes' : 'no'])
          )}
        </td>
      )}
      <td className="col-md-2">
        <ElementWithPermissions scope={['floor-plan-update']}>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={onEdit}
            disabled={disabled}
          >
            <i className={`et-${editMode ? 'floppy' : 'pencil'}`}></i>
          </button>
        </ElementWithPermissions>
      </td>
    </tr>
  );
};
