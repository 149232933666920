import React, { useState } from 'react';
import { isNil } from 'lodash';
import { pathOr, not } from 'ramda';
import { Panel, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import styled from 'styled-components';
import {
  Button,
  Stack,
  Typography,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  useModal,
  Modal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { AlertCriticalIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { red } from '@fortress-technology-solutions/fortress-component-library/design';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { toastr } from 'react-redux-toastr';

import confirm from '../../../components/ConfirmDialogModal';
import XlsxIcon from '../../../components/Icons/xlsx';
import PdfIcon from '../../../components/Icons/pdf';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import {
  Header,
  Divider,
  DetailList,
} from '../../../components/ProfileVisualComponents';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
import {
  useAsyncDownloadFASDocument,
  useGetFinalAccountStatement,
  useGetFinalAccountStatementReversal,
} from './hooks';
import { useFetchPropertyConfig } from '../../App/hooks';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';
import FinalAccountStatementReversalService from '../../../services/finalAccountStatementReversalService';

import messages from './messages';

type Props = {
  isResident: boolean,
  monthsWithLateFees: number,
  doNotRenew: boolean,
  underEviction: boolean,
  users: Array<Object>,
  ledgerBalance: string,
  intl: Object,
  handleAssignedToChange: Function,
  onProcessMoveOutClick: Function,
  handleNoticeToVacateClick: Function,
  handleDoNotRenewClick: Function,
  fasReady: boolean,
  accountFinalized: boolean,
  manualWriteOff: Function,
  handleUnderEvictionClick: Function,
  handleRenewLease: Function,
  currentLease: Object,
  hasOpenTransfer: boolean,
  showEditMoveInDateLink: Function,
  showEditMoveOutDateLink: Function,
  householdInfo: Object,
  newestLease: Object,
  paymentRestrictions: Object,
  isCommercial: boolean,
  showEditPropertyMoveInDateLink: Function,
  autoEmailMonthlyInvoices: boolean,
  handleAutoEmailMonthlyInvoiceClick: Function,
  monthsWithNSFFees: number,
  applicationId: string,
  organizationId: string,
  propertyId: string,
  promptToaster: Function,
  fasComplete: boolean,
  propertyPaymentProvider: string,
  onCreateFinalAccountStatementReversal: Function,
};
const LabelProfileDetails = styled.label.attrs({
  className: 'profile-details',
})``;
const SnapshotPanel = styled(Panel).attrs({
  className: 'block block-snapshot block-white-shadow',
})``;

const onManualWriteOffClick = (onSuccess) =>
  confirm(
    'Are you sure you want to write off any open balances on the Resident ledger?',
  ).then(onSuccess);

const LeaseRenewalStatus = ({ isRenewal, isRenewalComplete }: Object) => {
  if (isRenewal && isRenewalComplete) {
    return 'Completed';
  } else if (isRenewal && not(isRenewalComplete)) {
    return 'Started';
  } else {
    return '---';
  }
};

export const Snapshot = ({
  accountFinalized,
  currentLease,
  doNotRenew,
  fasReady,
  handleAssignedToChange,
  handleDoNotRenewClick,
  handleNoticeToVacateClick,
  handleRenewLease,
  handleUnderEvictionClick,
  hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
  hasReOpenedRDCertifications,
  hasOpenTransfer, // Might remove
  householdInfo,
  intl,
  isResident,
  ledgerBalance,
  manualWriteOff,
  monthsWithLateFees,
  monthsWithNSFFees,
  newestLease,
  onProcessMoveOutClick,
  showEditMoveInDateLink,
  showEditMoveOutDateLink,
  underEviction,
  users,
  onPaymentRestrictionChange,
  paymentRestrictions,
  isCommercial,
  showEditPropertyMoveInDateLink,
  handleAutoEmailMonthlyInvoiceClick,
  autoEmailMonthlyInvoices,
  applicationId,
  organizationId,
  propertyId,
  promptToaster,
  fasComplete,
  propertyPaymentProvider,
  residentInfo,
  onCreateFinalAccountStatementReversal,
}: Props) => {
  if (fasComplete) currentLease.FASDate = moment();
  const { noticeToVacate } = currentLease;
  const anticipatedMoveOutDate = noticeToVacate
    ? noticeToVacate.moveOutDate
    : '';
  const pathArray: Array<any> = ['units', 0, 'unitStatus', 'description'];
  const moveOutDate = !isResident
    ? currentLease.moveOutDate
    : anticipatedMoveOutDate;
  const unitStatus = pathOr('', pathArray, currentLease);
  const isOnNoticeToVacate = unitStatus === 'Occupied / On Notice to Vacate';
  const moveOutDisabled =
    hasOpenTransfer ||
    unitStatus !== 'Occupied / On Notice to Vacate' ||
    !anticipatedMoveOutDate ||
    hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate ||
    hasReOpenedRDCertifications;
  //FinancialFixes
  // leaving this here for now until Judy/Kerri decide how to handle manual writeoffs
  const disableManualWriteoffs = true;
  const [mode, setMode] = useState('');
  const unitNumber = currentLease?.units[0]?.number;
  const handleDownloadDocument = useAsyncDownloadFASDocument(
    organizationId,
    propertyId,
    applicationId,
    mode,
    unitNumber,
    promptToaster,
    intl,
  );
  const {
    fasDocumentInExcelFormat,
    paymentsV1,
    reverseFinalAccountStatement: reverseFinalAccountStatementFlag,
  } = useFlags();
  const { propertyConfig, isLoading } = useFetchPropertyConfig({
    organizationId,
    propertyId,
    flags: { paymentsV1 },
  });
  const shouldAcceptPartialPayment =
    !isLoading && propertyConfig[0]?.shouldAcceptPartialPayment;

  const { data: finalAccountStatement } =
    useGetFinalAccountStatement(residentInfo);

  const {
    data: finalAccountStatementReversal,
    setData: setFinalAccountStatementReversal,
  } = useGetFinalAccountStatementReversal(finalAccountStatement);

  const { open, openModal, closeModal } = useModal();

  const [isModalConfirmLoading, setIsModalConfirmLoading] =
    React.useState(false);

  const reverseFas = React.useCallback(() => {
    const makeRequest = async () => {
      setIsModalConfirmLoading(true);
      setFinalAccountStatementReversal({});
      const finalAccountStatementReversalService =
        new FinalAccountStatementReversalService();
      const result = await finalAccountStatementReversalService
        .create({
          finalAccountStatementId: finalAccountStatement.id,
        })
        .then((result) => {
          setIsModalConfirmLoading(false);
          closeModal();
          toastr.info(
            intl.formatMessage(
              messages.reverseFinalAccountStatementRequestedToastrTitle,
            ),
            intl.formatMessage(
              messages.reverseFinalAccountStatementRequestedToastrMessage,
            ),
          );
          setFinalAccountStatementReversal(result);
          onCreateFinalAccountStatementReversal &&
            onCreateFinalAccountStatementReversal(result);
          return result;
        })
        .catch((error) => {
          setIsModalConfirmLoading(false);
          toastr.error('Error', error.message || error.networkError || error);
          setFinalAccountStatementReversal(null);
        });
      return result;
    };
    makeRequest();
  }, [
    setIsModalConfirmLoading,
    finalAccountStatement,
    closeModal,
    setFinalAccountStatementReversal,
    onCreateFinalAccountStatementReversal,
    intl,
  ]);

  const onReverseFinalAccountStatementClicked = React.useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      <SnapshotPanel>
        <Header>
          <Stack spacing={1}>
            <IconTitle
              icon={<i key="1" className="et-prospect" />}
              message={messages.snapshot}
            />
            {isResident ? (
              <Stack direction={'row'} spacing={2}>
                <span>
                  <div className="custom-checkbox" style={{ marginBottom: 0 }}>
                    <label>
                      <ElementWithPermissions scope={['resident-edit']}>
                        <input
                          type="checkbox"
                          checked={underEviction}
                          onChange={handleUnderEvictionClick}
                          disabled={!isResident || hasOpenTransfer}
                        />
                      </ElementWithPermissions>
                      <span className="custom-check-square" />
                      <FormattedMessage {...messages.underEviction} />
                    </label>
                  </div>
                </span>
                <span>
                  <div className="custom-checkbox" style={{ marginBottom: 0 }}>
                    <label style={{ whiteSpace: 'nowrap' }}>
                      <ElementWithPermissions scope={['resident-edit']}>
                        <input
                          type="checkbox"
                          checked={doNotRenew}
                          onChange={handleDoNotRenewClick}
                          disabled={!isResident || hasOpenTransfer}
                        />
                      </ElementWithPermissions>
                      <span className="custom-check-square" />
                      <FormattedMessage {...messages.doNotRenew} />
                    </label>
                  </div>
                </span>
              </Stack>
            ) : null}

            <Stack direction={'row'} spacing={2}>
              <span>
                <div className="custom-checkbox" style={{ marginBottom: 0 }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={paymentRestrictions.certifiedOnly}
                      disabled={true}
                    />

                    <span className="custom-check-square" />
                    <FormattedMessage {...messages.certifiedOnly} />
                  </label>
                </div>
              </span>
              <span>
                <div className="custom-checkbox" style={{ marginBottom: 0 }}>
                  <label>
                    <input
                      type="checkbox"
                      checked={paymentRestrictions.doNotAccept}
                      disabled={true}
                    />
                    <span className="custom-check-square" />
                    <FormattedMessage {...messages.doNotAccept} />
                  </label>
                </div>
              </span>
            </Stack>
            <Stack spacing={2} direction={'row'}>
              {shouldAcceptPartialPayment &&
                propertyPaymentProvider === 'FORTRESS' &&
                paymentsV1 && (
                  <span>
                    <div
                      className="custom-checkbox"
                      style={{ marginBottom: 0 }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            paymentRestrictions.doNotAcceptPartialPayments
                          }
                          disabled={true}
                        />

                        <span className="custom-check-square" />
                        <FormattedMessage
                          {...messages.doNotAcceptPartialPayments}
                        />
                      </label>
                    </div>
                  </span>
                )}
              {isResident && isCommercial ? (
                <span>
                  <div className="custom-checkbox" style={{ marginBottom: 0 }}>
                    <label>
                      <ElementWithPermissions scope={['auto-monthly-invoice']}>
                        <input
                          type="checkbox"
                          checked={autoEmailMonthlyInvoices}
                          onChange={handleAutoEmailMonthlyInvoiceClick}
                          disabled={!isResident}
                        />
                      </ElementWithPermissions>
                      <span className="custom-check-square" />
                      <FormattedMessage {...messages.autoEmailMonthlyInvoice} />
                    </label>
                  </div>
                </span>
              ) : (
                ''
              )}
            </Stack>
          </Stack>
        </Header>
        <Panel.Body>
          <Row className="row">
            <Col xs={12} md={3}>
              <LabelProfileDetails>
                <FormattedMessage {...messages.assignedTo} />
              </LabelProfileDetails>
            </Col>
            <Col xs={12} md={8}>
              <ElementWithPermissions
                scope={['prospect-assign', 'prospect-update']}
              >
                <Field
                  name="assignedToId"
                  component="select"
                  className="form-control input-sm"
                  onChange={handleAssignedToChange}
                >
                  {users.map((user) => (
                    <option key={user.value} value={user.value}>
                      {user.text}
                    </option>
                  ))}
                </Field>
              </ElementWithPermissions>
            </Col>
          </Row>
          <Divider />
          <DetailList>
            {isResident && (
              <li>
                <span>
                  <FormattedMessage {...messages.unitStatus} />
                </span>{' '}
                {unitStatus}
                <ElementWithPermissions scope={['resident-edit']}>
                  <a
                    className="float-right"
                    onClick={() => handleNoticeToVacateClick()}
                  >
                    <strong>
                      <FormattedMessage {...messages.noticeToVacate} />
                    </strong>
                  </a>
                </ElementWithPermissions>
              </li>
            )}
            {!isResident && (
              <li>
                <ElementWithPermissions scope={['resident-edit']}>
                  <a
                    className="float-right"
                    onClick={() => handleNoticeToVacateClick()}
                  >
                    <strong>
                      <FormattedMessage {...messages.noticeToVacate} />
                    </strong>
                  </a>
                </ElementWithPermissions>
              </li>
            )}
            <li style={{ paddingTop: !isResident ? '25px' : '0px' }}>
              <span>
                <FormattedMessage {...messages.currentLedgerBalance} />
              </span>{' '}
              {intl.formatNumber(ledgerBalance, {
                style: 'currency',
                currency: 'USD',
              })}
              {!isResident && !disableManualWriteoffs && (
                <span className="text-right assign-btns">
                  <ElementWithPermissions scope={['write-off']}>
                    <a
                      onClick={() => onManualWriteOffClick(manualWriteOff)}
                      disabled={!accountFinalized}
                    >
                      Write Off
                    </a>
                  </ElementWithPermissions>
                </span>
              )}
            </li>
            <li>
              <span>
                <FormattedMessage {...messages.numberOfLateFees} />
              </span>{' '}
              {monthsWithLateFees}
            </li>
            <li>
              <span>
                <FormattedMessage {...messages.numberOfNSFFees} />
              </span>{' '}
              {monthsWithNSFFees}
            </li>
            <li>
              <span>Lease Start Date: </span>{' '}
              <FormattedDateOrDashes value={currentLease.startDate} />
            </li>
            <li>
              <span>
                <FormattedMessage {...messages.leaseEndDate} />
              </span>{' '}
              <FormattedDateOrDashes value={currentLease.endDate} />
            </li>
            <li />
            <li>
              <span>
                <FormattedMessage {...messages.moveInDate} />
              </span>{' '}
              <FormattedDateOrDashes value={currentLease.actualMoveInDate} />{' '}
              {showEditMoveInDateLink()}
            </li>
            {isCommercial && (
              <li>
                <span>
                  <FormattedMessage {...messages.rentStartDate} />
                </span>{' '}
                <FormattedDateOrDashes value={currentLease.rentStartDate} />
              </li>
            )}
            <li>
              <span>
                <FormattedMessage {...messages.propertyMoveInDate} />
              </span>{' '}
              <FormattedDateOrDashes
                value={householdInfo.householdMoveInDate}
              />{' '}
              {showEditPropertyMoveInDateLink()}
            </li>
            <li>
              <span>
                {isResident ? (
                  <span>
                    <FormattedMessage {...messages.anticipated} />{' '}
                  </span>
                ) : null}
                <FormattedMessage {...messages.moveOutDate} />
              </span>{' '}
              <FormattedDateOrDashes value={moveOutDate} />{' '}
              {showEditMoveOutDateLink()}
            </li>
            {isResident && (
              <li>
                <span>
                  <FormattedMessage {...messages.leaseRenewalStatus} />
                </span>{' '}
                {LeaseRenewalStatus(newestLease)}
                {!isCommercial && (
                  <ElementWithPermissions
                    scope={['lease-read', 'lease-create']}
                  >
                    <a className="float-right" onClick={handleRenewLease}>
                      <strong>
                        <FormattedMessage {...messages.quoteRenewal} />
                      </strong>
                    </a>
                  </ElementWithPermissions>
                )}
              </li>
            )}
          </DetailList>
          {isResident ? (
            <ElementWithPermissions scope={['resident-move-out']}>
              <Stack spacing={1} alignItems={'center'}>
                <Button
                  variant={'primary'}
                  type="button"
                  onClick={onProcessMoveOutClick}
                  disabled={moveOutDisabled}
                >
                  <FormattedMessage {...messages.processMoveOut} />
                </Button>
                {hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate ||
                (isOnNoticeToVacate && hasReOpenedRDCertifications) ? (
                  <Typography
                    variant={'caption'}
                    align={'center'}
                    maxWidth={450}
                  >
                    {hasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate ? (
                      <FormattedMessage
                        {...messages.disableMoveOutHouseholdRentableItemsAfterMoveOutDate}
                      />
                    ) : null}
                    {isOnNoticeToVacate && hasReOpenedRDCertifications ? (
                      <FormattedMessage
                        {...messages.disableMoveOutOpenReOpenedRDCertifications}
                      />
                    ) : null}
                  </Typography>
                ) : null}
              </Stack>
            </ElementWithPermissions>
          ) : (
            <div className="row">
              {fasDocumentInExcelFormat && accountFinalized && (
                <div className="row">
                  <div className="col-xs-6">
                    <Button
                      onClick={() => {
                        setMode('xlsx');
                        handleDownloadDocument();
                      }}
                      variant="primarySubtle"
                      sx={{
                        width: '140px',
                        float: 'right',
                        '&:hover': {
                          '.xlsx-xPath2': {
                            fill: '#4D73FF',
                          },
                          '.xlsx-xPath1': {
                            fill: 'white',
                          },
                        },
                        '.xlsx-xPath1': {
                          fill: '#4D73FF',
                        },
                        '.xlsx-xPath2': {
                          fill: 'white',
                        },
                      }}
                    >
                      <span className="padright5">
                        <XlsxIcon />
                      </span>
                      <FormattedMessage {...messages.fasExcel} />
                    </Button>
                  </div>
                  <div className="col-xs-6">
                    <Button
                      onClick={() => {
                        setMode('pdf');
                        handleDownloadDocument();
                      }}
                      variant="primarySubtle"
                      sx={{
                        width: '140px',
                        '&:hover': {
                          '.pdf-icon': {
                            fill: 'white',
                          },
                        },
                        '.pdf-icon': {
                          fill: '#4D73FF',
                        },
                      }}
                    >
                      <span className="padright5">
                        <PdfIcon />
                      </span>
                      <FormattedMessage {...messages.fasPdf} />
                    </Button>
                  </div>
                </div>
              )}
              <div className="row">
                <div
                  className="col-xs-12 text-center"
                  style={{ display: 'grid' }}
                >
                  <ElementWithPermissions
                    scope={['final-account-statement-create']}
                  >
                    <button
                      color="green"
                      disabled={
                        !fasReady || !!accountFinalized || currentLease.FASDate
                      }
                      className="btn btn-shout"
                      onClick={() => {
                        handleNoticeToVacateClick(true);
                      }}
                    >
                      <span>
                        <FormattedMessage {...messages.finalAccountStatement} />
                      </span>
                    </button>
                  </ElementWithPermissions>
                  {!isNil(currentLease.FASDate) &&
                    reverseFinalAccountStatementFlag && (
                      <ElementWithPermissions
                        scope={['final-account-statement-reverse']}
                      >
                        <Tooltip
                          title={
                            finalAccountStatementReversal &&
                            !finalAccountStatementReversal.completedAt
                              ? intl.formatMessage(
                                  messages.reverseFinalAccountStatementInProgressTooltip,
                                )
                              : ''
                          }
                          style={{ width: '100%' }}
                        >
                          <span>
                            <button
                              className="btn btn-tertiary"
                              onClick={() =>
                                onReverseFinalAccountStatementClicked()
                              }
                              style={{ marginTop: '0px', width: '100%' }}
                              disabled={
                                finalAccountStatementReversal &&
                                !finalAccountStatementReversal.completedAt
                              }
                            >
                              <span>
                                <FormattedMessage
                                  {...messages.reverseFinalAccountStatementButton}
                                />
                              </span>
                            </button>
                          </span>
                        </Tooltip>
                      </ElementWithPermissions>
                    )}
                </div>
                {!fasReady ? (
                  <h2 className="col-xs-12 text-center">
                    <small className="text-gray">
                      <FormattedMessage
                        {...messages.finalAccountStatementDisabled}
                      />
                    </small>
                  </h2>
                ) : null}
                {accountFinalized ? (
                  <h2 className="col-xs-12 text-center">
                    <small className="text-center text-gray--darker">
                      <FormattedMessage
                        {...messages.finalAccountStatementAlreadyGenerated}
                      />{' '}
                      on {moment(currentLease.FASDate).format('MM/DD/YYYY')}.
                    </small>
                  </h2>
                ) : null}
              </div>
            </div>
          )}
        </Panel.Body>
      </SnapshotPanel>

      <Modal
        open={open}
        onClose={closeModal}
        title={
          <>
            <AlertCriticalIcon sx={{ color: red.dark }} />
            <Typography mr={1} variant="h4" sx={{ color: red.dark }}>
              <FormattedMessage
                {...messages.reverseFinalAccountStatementModalTitle1}
              />
            </Typography>
            <Typography variant="h4">
              <FormattedMessage
                {...messages.reverseFinalAccountStatementModalTitle2}
              />
            </Typography>
          </>
        }
        actionsProps={[
          {
            children: 'Cancel',
            onClick: () => {
              closeModal();
            },
          },
          {
            children: 'Confirm',
            submitButton: true,
            isSubmitting: isModalConfirmLoading,
            onClick: async () => reverseFas(),
          },
        ]}
        ModalProps={{
          sx: {
            '.MuiPaper-root': {
              maxWidth: '428px',
            },
          },
        }}
      >
        <p>
          <FormattedMessage
            {...messages.reverseFinalAccountStatementModalLine1}
          />{' '}
          {moment(currentLease.FASDate).format('YYYY-MM-DD')}.
        </p>
        <p>
          <b>
            <FormattedMessage
              {...messages.reverseFinalAccountStatementModalLine2}
            />
          </b>
        </p>
        <ul>
          <li>
            <FormattedMessage
              {...messages.reverseFinalAccountStatementModalLine3}
            />
          </li>
          <li>
            <FormattedMessage
              {...messages.reverseFinalAccountStatementModalLine4}
            />
          </li>
        </ul>
        <p>
          <b>
            <FormattedMessage
              {...messages.reverseFinalAccountStatementModalLine5}
            />
          </b>
        </p>
        <p>
          <FormattedMessage
            {...messages.reverseFinalAccountStatementModalLine6}
          />
        </p>
      </Modal>
    </>
  );
};

export default reduxForm({
  form: 'snapshot',
  enableReinitialize: true,
})(injectIntl(Snapshot));
