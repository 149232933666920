import { path, pathOr } from 'ramda';

import { get, post, getDownload, put } from '../utils/api';

import type {
  OrganizationId,
  FilterValueAndPagination,
  Configs,
} from '../containers/App/types';
import type { PortalSettings } from '../containers/ManageProperties/PropertyPortalModal/constants';

export default class PropertyService {
  getProperties(
    organizationId: OrganizationId,
    query: ?FilterValueAndPagination,
    options?: Object,
  ) {
    // No Pagination
    // $FlowFixMe
    const enablePagination = pathOr(
      true,
      ['paginationInfo', 'pagination'],
      query,
    );

    const pageNumber =
      path(['paginationInfo', 'pageNumber'], query) &&
      `page=${query.paginationInfo.pageNumber}`;

    const limit =
      path(['paginationInfo', 'limit'], query) &&
      `limit=${query.paginationInfo.limit}`;

    const sortName =
      path(['sorting', 'fieldName'], query) &&
      `sort=${query.sorting.fieldName}`;

    const sortOrder =
      path(['sorting', 'order'], query) && `order=${query.sorting.order}`;

    // $FlowFixMe
    const activeFilters = pathOr([], ['filterValue'], query);
    let filtersList = [];

    // Create filter param string
    Object.keys(activeFilters).map((af) =>
      Object.keys(activeFilters[af]).map((av) =>
        activeFilters[af][av] === true
          ? (filtersList = [...filtersList, `${af}=${av}`])
          : false,
      ),
    );
    const filter = filtersList.join('&');

    const search = path(['searchText'], query) && `query=${query.searchText}`;

    let queryArr = [];

    if (enablePagination) {
      queryArr = [pageNumber, limit, filter, sortName, sortOrder, search];
    } else {
      queryArr = ['pagination=false', filter, sortName, sortOrder, search];
    }

    const queryString = queryArr.filter((param) => param).join('&');

    return get(`/${organizationId}/properties?${queryString}`, options);
  }

  getActiveProperties(organizationId: OrganizationId, options?: Object) {
    return get(`/${organizationId}/properties/active`, options);
  }

  getActivePropertiesWithPropertyClass(
    organizationId: OrganizationId,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/active?withPropertyClassOnly=true`,
      options,
    );
  }

  getPropertyNsfFee(
    organizationId: OrganizationId,
    propertyId: string,
    options?: Object,
  ) {
    return get(`/${organizationId}/${propertyId}/nsffee-details`, options);
  }

  enablePortal(
    organizationId: OrganizationId,
    propertyId: string,
    settings: PortalSettings,
  ) {
    return post(
      `/${organizationId}/properties/${propertyId}/enablePortal`,
      JSON.stringify(settings),
    );
  }

  disablePortal(organizationId: OrganizationId, propertyId: string) {
    return post(`/${organizationId}/properties/${propertyId}/disablePortal`);
  }

  onboardPropertyForPortal(organizationId: OrganizationId, propertyId: string) {
    return post(`/${organizationId}/properties/${propertyId}/onboardPortal`);
  }

  updateOnboardedByUserId(organizationId: OrganizationId, propertyId: string) {
    const body = JSON.stringify({ organizationId });
    return post(
      `/${organizationId}/properties/${propertyId}/onboardedBy`,
      body,
    );
  }

  getOnboardingReport(
    organizationId: OrganizationId,
    propertyId: string,
    groupId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/onboardPortal/${groupId}`,
      options,
    );
  }

  getCSVOnboardingReport(
    organizationId: OrganizationId,
    propertyId: string,
    groupId: string,
    propertyName: string,
    options?: Object,
  ) {
    return getDownload(
      `/${organizationId}/properties/${propertyId}/onboardPortal/${groupId}?format=csv`,
      `onboarding_report_${propertyName}.csv`,
      options,
    );
  }

  getPropertyById(
    organizationId: OrganizationId,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/include-onboarding`,
      options,
    );
  }

  update(organizationId: string, propertyId: string, data: Object) {
    return put(
      `/${organizationId}/properties/${propertyId}`,
      JSON.stringify(data),
    );
  }

  updateGPR(organizationId: string, propertyId: string, data: Object) {
    return put(
      `/${organizationId}/properties/${propertyId}/gpr`,
      JSON.stringify(data),
    );
  }

  getAvgSqftOccupancy(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/avg-sqft-occupancy`, options);
  }

  checkEnableFirstOfMonthPosting(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/properties/${propertyId}/post-mlt`, options);
  }

  postFirstOfMonthMLT(organizationId: string, propertyId: string) {
    return post(`/${organizationId}/properties/${propertyId}/post-mlt`);
  }

  getPropertyMasterAffordablePrograms(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/master-affordable-programs`,
      options || {},
    );
  }

  updateLeaseExpirationLimit(
    organizationId: string,
    propertyId: string,
    value: boolean,
  ) {
    const body = JSON.stringify({ isLeaseExpirationLimitsActive: value });
    return post(
      `/${organizationId}/properties/${propertyId}/lease-expiration-limits`,
      body,
    );
  }

  getOneProperty(organizationId: string, propertyId: string, options?: Object) {
    return get(`/${organizationId}/properties/${propertyId}`, options || {});
  }

  getIsLeaseExpirationLimitsActive(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/limitsActive`,
      options || {},
    );
  }

  getLatestChangeHistoryLog(
    organizationId: string,
    propertyId: string,
    changeName: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/latest-change-history?changeName=${changeName}`,
      options || {},
    );
  }

  updatePropertyConfigs(
    organizationId: string,
    propertyId: string,
    data: Configs,
    options?: Object,
  ) {
    return put(
      `/${organizationId}/properties/${propertyId}/configurations`,
      JSON.stringify(data),
      options,
    );
  }

  getPropertyConfigs(
    organizationId: string,
    propertyId: string,
    options: Object = {},
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/configurations`,
      options,
    );
  }
}
