import { useMemo, useState } from 'react';
import PropertyFeesService from '../../services/propertyFeesService';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import { useQuery } from 'react-query';
import messages from './messages';
import { formatDateDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export const useFetchAllFees = ({ intl }) => {
  const name = useUniqueTableName('manage-fees');
  const selectedProperty = useSelectedProperty();
  const propertyFeesService = useMemo(() => new PropertyFeesService(), []);
  const abortController = new AbortController();
  const options = { signal: abortController.signal };

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedFee, setSelectedFee] = useState({});

  const {
    isLoading,
    data: feesData,
    refetch: refetchFees,
  } = useQuery(
    ['feesData', selectedProperty?.id, selectedProperty?.organizationId],
    () =>
      propertyFeesService.getAllFeesByProperty(
        selectedProperty?.organizationId,
        selectedProperty?.id,
        options,
      ),
    {
      enabled: true,
    },
  );

  const closeModal = () => {
    setSelectedFee({});
    setEditModalOpen(false);
  };

  const handleEditClick = (fee) => {
    setSelectedFee(fee);
    setEditModalOpen(true);
  };

  const parseUpdatedUserName = (updatedBy: {
    firstName: string,
    lastName: string,
  }) => {
    if (updatedBy) {
      return `By ${updatedBy.firstName} ${updatedBy.lastName}`;
    }
    return '';
  };
  const rows = useMemo(() => {
    const chargeAt = {
      floorPlan: intl.formatMessage(messages.floorPlanFee),
      unit: intl.formatMessage(messages.unitFee),
      property: intl.formatMessage(messages.propertyFee),
    };
    if (!feesData) return [];
    const rows = feesData.map((item) => {
      return {
        transactionCode: {
          value: item.feeTransactionCode,
        },
        description: {
          variant: 'description',
          value: item.transactionDescription,
        },
        chargeAt: {
          value: chargeAt[item.chargeAt],
        },
        displayNameOnQuote: {
          value: item.nameOnQuote ? 'Yes' : 'No',
        },
        nameQuote: {
          value: item.nameOnQuote,
        },
        oneTimeCharge: {
          value: item.oneTimeCharged ? 'Yes' : 'No',
        },
        feeAmount: {
          variant: 'currency',
          value: item.feeAmount,
        },
        descriptionAmount: {
          value: item.descriptionAmount,
        },
        lastUpdated: {
          value: (
            <div style={{ textAlign: 'left' }}>
              <div>{formatDateDisplayLocal(item.updatedAt)}</div>
              <div>{parseUpdatedUserName(item.lastUpdatedBy)}</div>
            </div>
          ),
        },
        edit: {
          variant: 'iconButton',
          name: 'editButton',
          iconName: 'PencilIcon',
          onClick: () => handleEditClick(item),
        },
      };
    });
    return rows;
  }, [feesData, intl]);

  return {
    count: rows?.length ?? 0,
    totalCount: feesData?.length ?? 0,
    name,
    isLoading,
    rows,
    isEditModalOpen,
    selectedFee,
    closeModal,
    refetchFees,
  };
};
