/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.Title',
    defaultMessage: 'HUD',
  },
  hudSubsidyType: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.HUDSubsidyType',
    defaultMessage: 'HUD Subsidy Type',
  },
  HUProjectNumber: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.HUDProjectNumber',
    defaultMessage: 'HUD Project Number',
  },
  hudSecondarySubsidyType: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.HUDSecondarySubsidyType',
    defaultMessage: 'HUD Secondary Subsidy Type',
  },
  hudContractNumber: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.HUDContractNumber',
    defaultMessage: 'HUD Contract Number',
  },
  autoSendToTRACS: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.autoSendToTRACS',
    defaultMessage: 'Auto-send to TRACS',
  },
  autoSendToTRACSDescription: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.autoSendToTRACSDescription',
    defaultMessage:
      'When enabled, this feature automatically transmits 50059 and 50059As documents to TRACS upon cert completion. Disabling this feature requires manual document submission.',
  },
  on: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.autoSendToTRACS.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'App.ViewProperty.AffordableSetup.Programs.HUD.autoSendToTRACS.off',
    defaultMessage: 'Off',
  },
});

export default messages;
