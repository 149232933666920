import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageApplications } from './ApplicationsTable_V2.hooks';
import { Link as ReactRouterLink } from 'react-router-dom';

const ApplicationsTable = ({
  permissions,
  currentUserId,
  intl,
  affordablePrograms,
  locale,
  onEditActivity,
  onRecordActivity,
  onScheduleActivity,
  onCreateActivity,
  applicationStatuses,
  organizationId,
  propertyId,
  assigningUnitsMoveInDatesFlag,
  selectedProperty,
}) => {
  const {
    CSVIsExporting,
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleCSVDownload,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
  } = useManageApplications({
    applicationStatuses,
    organizationId,
    propertyId,
    assigningUnitsMoveInDatesFlag,
    currentUserId,
    affordablePrograms,
    locale,
    intl,
    onEditActivity,
    onRecordActivity,
    onScheduleActivity,
    onCreateActivity,
    permissions,
    selectedProperty,
  });

  return (
    <Box
      sx={{
        'button[disabled]': { backgroundColor: 'unset' },
      }}
    >
      <TableHeader title={'Manage Applications'} count={count} />
      <VirtualizedTable
        ReactBeautifulDnD={{
          DragDropContext,
          Droppable,
          Draggable,
        }}
        ReactRouterLink={ReactRouterLink}
        allColumnsHidden={allColumnsHidden}
        columnOptions={columnOptions}
        count={count}
        dateState={dateState}
        filterState={filterState}
        filterTypeState={filterTypeState}
        footerRow={{}}
        handleColumnChange={handleColumnChange}
        handleDateSubmit={handleDateSubmit}
        handleFilterChange={handleFilterChange}
        handleFilterTypeChange={handleFilterTypeChange}
        handleSearchSubmit={handleSearchSubmit}
        handleSortChange={handleSortChange}
        headers={filteredHeaders}
        height={'calc(100vh - 200px)'}
        intl={intl}
        isCSVExportLoading={CSVIsExporting}
        isLoading={isLoading}
        name={name}
        onCSVButtonClick={handleCSVDownload}
        order={order}
        orderBy={orderBy}
        rows={rows}
        searchState={searchState}
        selectedColumns={selectedColumns}
        sx={{ paddingTop: 0 }}
      />
    </Box>
  );
};

ApplicationsTable.defaultProps = {
  affordablePrograms: {},
  applications: [],
  intl: {},
  onEditActivity: () => {},
  onRecordActivity: () => {},
  onScheduleActivity: () => {},
  onCreateActivity: () => {},
};
export default ApplicationsTable;
