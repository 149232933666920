import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export const AssetTableHeader = () => {
  return (
    <div className="row faux-table__head">
      <div className="col-xs-3">
        <div className="row">
          <div className="col-xs-3">
            <label>#</label>
          </div>
          <div className="col-xs-9">
            <label>
              <FormattedMessage {...messages.typeOfAsset} />
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-2 text-center">
        <label>
          <FormattedMessage {...messages.currentlyOwn} />
        </label>
        <div className="row">
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.no} />
            </label>
          </div>
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.yes} />
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-3 col-lg-2">
        <label>
          <FormattedMessage {...messages.value} />
        </label>
      </div>
      <div className="col-xs-3 col-lg-2">
        <label>
          <FormattedMessage {...messages.monthlyIncome} />
        </label>
      </div>
      <div className="col-xs-3">
        <label>
          <FormattedMessage {...messages.over5k} />
        </label>
      </div>
    </div>
  );
};
