import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../ManageUnitAvailability/messages';
import {
  getIsUnitAssignDisabled,
  checkShouldRenderAssignOption,
} from './utils';
import useSearchParams from './hooks.useSearchParams';

const useRows = ({
  applicantToAssignUnit: {
    id: applicantToAssignUnitId,
    propertyClass: applicantPropertyClass,
    applicationStatus: applicantApplicationStatus,
    isCommercial: applicationIsCommercial,
  },
  assignUnitProps: { handleAssignUnitClick, handleUnAssignUnitClick },
  hasManageUnitsUpdatePermission,
  unitQuoteMutation: { mutate: getUnitQuote },
  sortedAndFilteredResults,
}) => {
  const { applicantId, prospectId } = useSearchParams();

  return useMemo(() => {
    return sortedAndFilteredResults.map((result) => {
      const {
        assignedApplicationId,
        isFloorPlanAffordable,
        isFloorPlanCommercial,
      } = result;

      const shouldRenderAssignOption = checkShouldRenderAssignOption({
        applicationId: applicantToAssignUnitId,
        assignedApplicationId,
      });

      const isSelfAssigned = assignedApplicationId === applicantToAssignUnitId;

      const shouldRenderUnAssignOption =
        applicantToAssignUnitId &&
        assignedApplicationId &&
        applicantToAssignUnitId === assignedApplicationId;

      const isUnitAssignDisabled = getIsUnitAssignDisabled({
        assignedApplicationId,
        applicantToAssignUnitId,
        applicantApplicationStatus,
        applicantPropertyClass,
        isFloorPlanAffordable,
        isFloorPlanCommercial,
        isSelfAssigned,
        applicationIsCommercial,
      });

      return {
        unit: {
          variant: 'link',
          value: result.unit,
          to: result.unitLink,
        },
        floorPlan: {
          variant: 'link',
          to: result.floorPlanLink,
          value: result.floorPlan,
        },
        bedsBaths: {
          value: result.bedsBaths,
        },
        sqFeet: {
          variant: 'number',
          value: result.sqFeet,
        },
        status: {
          value: result.status,
        },
        leased: {
          value: result.leased ? 'Yes' : 'No',
        },
        resident: {
          variant: 'link',
          to: result.residentLink,
          value: result.resident,
        },
        marketRent: {
          variant: 'currency',
          value: result.marketRent,
        },
        netMaxRent: {
          variant: 'currency',
          value: result.netMaxRent,
        },
        priorLeasedRent: {
          variant: 'currency',
          value: result.priorLeasedRent,
        },
        currentLeasedRent: {
          variant: 'currency',
          value: result.currentLeasedRent,
        },
        moveOutDate: {
          variant: 'date',
          value: result.moveOutDate,
        },
        moveInReady: {
          variant: 'date',
          value: result.moveInReady,
        },
        moveInReadyStatus: {
          value: result.moveInReadyStatus,
        },
        notes: {
          variant: 'description',
          value: result.notes,
        },
        daysVacant: {
          variant: 'number',
          value: result.daysVacant,
        },
        quotingRent: {
          variant: 'currency',
          value: result.quotingRent,
        },
        otherUnitFeesAvailable: {
          variant: 'currency',
          value: result.otherUnitFeesAvailable,
        },
        applicant: {
          variant: 'link',
          to: result.applicantLink,
          value: result.applicant,
        },
        applicantStatus: {
          value: result.applicantStatus,
        },
        scheduledMoveInDate: {
          variant: 'date',
          value: result.scheduledMoveInDate,
        },
        amenities: {
          value: result.amenities,
        },
        actions: {
          variant: 'menu',
          closeOnClick: true,
          options: [
            {
              text: <FormattedMessage {...messages.quote} />,
              value: 'quote',
              onClick: () =>
                getUnitQuote({
                  unitId: result.unitId,
                  applicantId,
                  prospectId,
                }),
              disabled: isFloorPlanCommercial,
              tooltip: isFloorPlanCommercial
                ? 'Cannot provide quote on commercial unit'
                : null,
            },
            ...(hasManageUnitsUpdatePermission
              ? [
                  ...(shouldRenderUnAssignOption
                    ? [
                        {
                          text: <FormattedMessage {...messages.unassign} />,
                          value: 'unassign',
                          onClick: handleUnAssignUnitClick,
                        },
                      ]
                    : []),
                  ...(shouldRenderAssignOption
                    ? [
                        {
                          text: <FormattedMessage {...messages.assign} />,
                          value: 'assign',
                          disabled: isUnitAssignDisabled,
                          onClick: () => handleAssignUnitClick(result.unitId),
                        },
                      ]
                    : []),
                ]
              : []),
          ],
        },
      };
    });
  }, [
    sortedAndFilteredResults,
    applicantToAssignUnitId,
    applicantApplicationStatus,
    applicantPropertyClass,
    hasManageUnitsUpdatePermission,
    handleUnAssignUnitClick,
    getUnitQuote,
    applicantId,
    prospectId,
    handleAssignUnitClick,
    applicationIsCommercial,
  ]);
};

export default useRows;
