import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_ALL_PRIOR_RESIDENTS } from './constants';
import {
  getAllPriorResidentsSuccess,
  getAllPriorResidentsError
} from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import PriorResidentsService from '../../services/priorResidentsService';

import type { Action, Property, FilterValueAndPagination } from '../App/types';
import { renderNoDataToastr } from '../../utils/redux-form-helper';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllPriorResidentsSaga({
  payload
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const priorResidentsService = new PriorResidentsService();
    const response = yield priorResidentsService.getAllPriorResidents(
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      payload.sorting,
      payload.searchText,
      organizationId,
      selectedProperty.id
    );
    if (response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllPriorResidentsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllPriorResidentsError(err));
  }
}

export function* getAllPriorResidentsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PRIOR_RESIDENTS, fetchGetAllPriorResidentsSaga);
}

export default [getAllPriorResidentsSaga];
