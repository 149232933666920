import type { OrderValue } from '../containers/App/types';
const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class PriorResidentService {
  getAllPriorResidents(
    pageNumber: number,
    limit: number,
    sorting: OrderValue,
    searchText: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    const sort =
      sorting.fieldName === ''
        ? ''
        : `&sort=${sorting.fieldName}&order=${sorting.order}`;
    const query = searchText === '' ? '' : `&query=${searchText}`;
    const queryString = `page=${pageNumber}&limit=${limit}${query}${sort}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prior-residents?${queryString}`,
      options,
    );
  }

  getOne(organizationId: string, propertyId: string, priorResidentId: string) {
    const id = priorResidentId;
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prior-residents/${id}`,
      options,
    );
  }
}

export default PriorResidentService;
