import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pathOr, isNil, isEmpty } from 'ramda';
import messages from './messages';
import { Button, Row, Col } from 'react-bootstrap';
import Spinner from '../../../../components/Spinner';
import ConfirmEstimatesModal from './confirmEstimatesModal';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import PostReconciliationModal from './postReconciliationModal';
import { allocationNameSorting } from '../../utils';
import {
  useFetchReconciliationTypes,
  usePostCAMReconciliation,
  useFetchRetroactiveTransactions,
} from './hooks';
import { CAM_STATUSES_CAN_POST_RECONCILIATION } from '../../constants';
import AssignRetroactiveTransactionsModal from '../ReassignTransactionsModal/assignRetroactiveTransactionsModal';
import type { CamTransaction } from '../../types';

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  householdId: string,
  calculations: Array<Object>,
  calculationsLoading: boolean,
  generateCamCalculations: Function,
  generateCalculationsLoading: boolean,
  confirmCAMEstimates: Function,
  confirmations: Array<Object>,
  allocations: Array<Object>,
  refreshReconciliations: Function,
  status: string,
};

type TransactionToModify = {
  customerOpsLedgerId: string,
  camAllocationsId: string,
};

const MODAL_STATES = {
  NONE: 'none',
  CONFIRM_ESTIMATES: 'confirmEstimates',
  POST_RECONCILIATION: 'postRecon',
  RETROACTIVE_TRANSACTIONS: 'retroactiveTrans',
};

const CamCalculations = (props: Props) => {
  const {
    intl,
    organizationId,
    propertyId,
    householdId,
    selectedCamRecord,
    calculations,
    calculationsLoading,
    generateCamCalculations,
    generateCalculationsLoading,
    confirmCAMEstimates,
    confirmations,
    allocations,
    promptToaster,
    refreshReconciliations,
    status,
  } = props;
  const [modalState, setModalState] = useState(MODAL_STATES.NONE);

  const [reconciliationTypes] = useFetchReconciliationTypes();

  const [postCAMReconciliation, reconciliationsLoading] =
    usePostCAMReconciliation({
      intl,
      organizationId,
      propertyId,
      selectedCamRecord,
      promptToaster,
      onHide: () => setModalState(MODAL_STATES.NONE),
      refreshReconciliations,
    });

  const confirmEstimates = () => {
    confirmCAMEstimates();
    setModalState(MODAL_STATES.NONE);
  };

  const onFetchRetroactiveTransactionsComplete = (
    transactions: Array<CamTransaction>,
  ) => {
    if (isNil(transactions) || isEmpty(transactions)) {
      confirmEstimates();
    } else {
      setModalState(MODAL_STATES.NONE);
      setModalState(MODAL_STATES.RETROACTIVE_TRANSACTIONS);
    }
  };

  const [
    isLoadingRetroactiveTransactions,
    retroactiveTransactions,
    refreshRetroactiveTransactions,
  ] = useFetchRetroactiveTransactions({
    intl,
    organizationId,
    propertyId,
    camRecordsId: pathOr(null, ['id'], selectedCamRecord),
    promptToaster,
    onComplete: onFetchRetroactiveTransactionsComplete,
  });

  const submitRetroactiveModal = async (
    transactionsToReassign: Array<TransactionToModify>,
  ) => {
    await confirmCAMEstimates(transactionsToReassign);
    setModalState(MODAL_STATES.NONE);
  };

  const hasCalculations = !isNil(calculations) && !isEmpty(calculations);
  const hasConfirmations = !isNil(confirmations) && !isEmpty(confirmations);
  const enablePostReconciliation =
    hasCalculations && CAM_STATUSES_CAN_POST_RECONCILIATION.includes(status);

  const enableConfirmEstimates =
    hasCalculations && calculations.length === allocations.length;

  const showRetroactiveModal =
    modalState === MODAL_STATES.RETROACTIVE_TRANSACTIONS;
  const showPostReconciliationModal =
    modalState === MODAL_STATES.POST_RECONCILIATION;
  const showConfirmEstimatesModal =
    modalState === MODAL_STATES.CONFIRM_ESTIMATES;

  return (
    <React.Fragment>
      {showPostReconciliationModal && (
        <PostReconciliationModal
          calculations={calculations}
          reconciliationTypes={reconciliationTypes}
          onHide={() => setModalState(MODAL_STATES.NONE)}
          postCAMReconciliation={postCAMReconciliation}
          reconciliationsLoading={reconciliationsLoading}
        />
      )}
      {showConfirmEstimatesModal && (
        <ConfirmEstimatesModal
          onHide={() => setModalState(MODAL_STATES.NONE)}
          onSubmit={() => {
            refreshRetroactiveTransactions();
          }}
        />
      )}
      {showRetroactiveModal && (
        <AssignRetroactiveTransactionsModal
          intl={intl}
          organizationId={organizationId}
          propertyId={propertyId}
          householdId={householdId}
          onHide={() => setModalState(MODAL_STATES.NONE)}
          onSubmit={submitRetroactiveModal}
          isLoading={isLoadingRetroactiveTransactions}
          transactions={retroactiveTransactions}
        />
      )}

      <Row className="padtop10 padbottom10 padleft5">
        <Col xs={12}>
          <h2>
            <i className="et-money text-blue padright10" />
            <FormattedMessage {...messages.camAllocationsSetup} />
          </h2>
        </Col>
      </Row>
      {calculationsLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!calculationsLoading && (
        <div className="table-scroll table-units-container">
          <table className="table table-fixed-headers table-prospects table-striped">
            <thead className="table-header">
              <tr>
                <th>
                  <FormattedMessage {...messages.primaryCamPool} />
                </th>
                <th>
                  <FormattedMessage {...messages.estimates} />
                </th>
                <th>
                  <FormattedMessage {...messages.monthlyCAMBill} />
                </th>
                <th>
                  <FormattedMessage {...messages.billedYTD} />
                </th>
                <th>
                  <FormattedMessage {...messages.actualsYTD} />
                </th>
                <th>
                  <FormattedMessage {...messages.actualsYTDBudget} />
                </th>
                <th>
                  <FormattedMessage {...messages.annualAllocation} />
                </th>
                <th>
                  <FormattedMessage {...messages.proratedAllocation} />
                </th>
                <th>
                  <FormattedMessage {...messages.estimatedReconciliation} />
                </th>
              </tr>
            </thead>
            <tbody>
              {calculationsLoading || generateCalculationsLoading ? (
                <tr>
                  <td colSpan={9}>
                    <div className="text-center">
                      <Spinner />
                    </div>
                  </td>
                </tr>
              ) : (
                renderCamCalculationsRows(calculations, intl)
              )}
            </tbody>
            <tfoot className="table-footer">
              {renderTableFooter(calculations, intl)}
            </tfoot>
          </table>
        </div>
      )}
      {!calculationsLoading && (
        <React.Fragment>
          {!hasConfirmations ? (
            <Row className="padtop15 padbottom15">
              <Col xs={12}>
                <Button
                  className="btn btn-shout center-block"
                  disabled={!enableConfirmEstimates}
                  onClick={() => setModalState(MODAL_STATES.CONFIRM_ESTIMATES)}
                >
                  <span>
                    <FormattedMessage {...messages.confirm} />
                  </span>
                </Button>
              </Col>
            </Row>
          ) : (
            <Row className="padtop15 padbottom15">
              <Col xs={6}>
                <Button
                  className="btn btn-tertiary btn-default pull-right"
                  disabled={!hasCalculations}
                  onClick={() => generateCamCalculations()}
                >
                  <span>
                    <FormattedMessage {...messages.refreshAndPreview} />
                  </span>
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="btn btn-shout pull-left"
                  disabled={!enablePostReconciliation}
                  onClick={() =>
                    generateCamCalculations(() =>
                      setModalState(MODAL_STATES.POST_RECONCILIATION),
                    )
                  }
                >
                  <span>
                    <FormattedMessage {...messages.postReconciliation} />
                  </span>
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <div className="padleft15 padright15">
                <div>
                  <strong>
                    <FormattedMessage {...messages.noteHeader} />
                  </strong>{' '}
                </div>
                <div>
                  <FormattedMessage {...messages.note1} />
                </div>
                <div>
                  <FormattedMessage {...messages.note2} />
                </div>
                <div>
                  <FormattedMessage {...messages.note3} />
                </div>
              </div>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const renderCamCalculationsRows = (calculations, intl) => {
  if (!calculations || calculations.length === 0) {
    return (
      <tr>
        <td colSpan={9}>
          <DashesIfNullOrUndefined data={'No calculations.'} />
        </td>
      </tr>
    );
  }
  return (
    calculations &&
    calculations
      .sort((calcA, calcB) =>
        allocationNameSorting(calcA.camAllocation, calcB.camAllocation),
      )
      .map((row, i) => {
        const camPoolExceptionName = pathOr(
          pathOr(null, ['camAllocation', 'intacctGLAccountGroup', 'name'], row),
          ['camAllocation', 'camException', 'name'],
          row,
        );
        const approvedBillEstimatesAnually = pathOr(
          null,
          ['approvedBillEstimatesAnually'],
          row,
        );
        const approvedBillFormat =
          approvedBillEstimatesAnually !== null
            ? `${intl.formatNumber(+approvedBillEstimatesAnually, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        const isLeaseAllowForMonthlyEstimates = pathOr(
          false,
          ['camAllocation', 'isLeaseAllowForMonthlyEstimates'],
          row,
        );
        const monthlyCAMBill = pathOr(null, ['monthlyCAMBill'], row);
        const monthlyCAMBillFormat =
          monthlyCAMBill !== null
            ? `${intl.formatNumber(+monthlyCAMBill, {
                style: 'currency',
                currency: 'USD',
              })}${!isLeaseAllowForMonthlyEstimates ? '*' : ''}`
            : null;
        const billedYTD = pathOr(null, ['billedYTD'], row);
        const billedYTDFormat =
          billedYTD !== null
            ? intl.formatNumber(+billedYTD, {
                style: 'currency',
                currency: 'USD',
              })
            : null;
        const actualsYTD = pathOr(null, ['actualsYTD'], row);
        const actualsYTDFormat =
          actualsYTD !== null
            ? `${intl.formatNumber(+actualsYTD, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        const actualsYTDBudget = pathOr(null, ['actualsYTDBudget'], row);
        const actualsYTDBudgetFormat =
          actualsYTDBudget !== null
            ? `${intl.formatNumber(+actualsYTDBudget, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        const annualAllocation = pathOr(null, ['annualAllocation'], row);
        const annualAllocationFormat =
          annualAllocation !== null
            ? `${intl.formatNumber(+annualAllocation, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        const proratedAllocation = pathOr(null, ['proratedAllocation'], row);
        const proratedAllocationFormat =
          proratedAllocation !== null
            ? `${intl.formatNumber(+proratedAllocation, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        const estimatedReconciliation = pathOr(
          null,
          ['estimatedReconciliation'],
          row,
        );
        const estimatedReconciliationFormat =
          estimatedReconciliation !== null
            ? `${intl.formatNumber(+estimatedReconciliation, {
                style: 'currency',
                currency: 'USD',
              })}`
            : null;
        return (
          <tr key={i}>
            <td>
              <DashesIfNullOrUndefined data={camPoolExceptionName} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={approvedBillFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={monthlyCAMBillFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={billedYTDFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={actualsYTDFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={actualsYTDBudgetFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={annualAllocationFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={proratedAllocationFormat} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={estimatedReconciliationFormat} />
            </td>
          </tr>
        );
      })
  );
};

const renderTableFooter = (calculations, intl) => {
  const totals =
    calculations &&
    calculations.reduce(
      (acc, row) => {
        acc.annualAllocation += row.annualAllocation;
        acc.proratedAllocation += row.proratedAllocation;
        acc.actualsYTD += row.actualsYTD;
        acc.actualsYTDBudget += row.actualsYTDBudget;
        acc.approvedBillEstimatesAnually += row.approvedBillEstimatesAnually;
        acc.billedYTD += row.billedYTD;
        acc.estimatedReconciliation += row.estimatedReconciliation;
        acc.monthlyCAMBill += row.monthlyCAMBill;
        return acc;
      },
      {
        annualAllocation: 0,
        proratedAllocation: 0,
        actualsYTD: 0,
        actualsYTDBudget: 0,
        approvedBillEstimatesAnually: 0,
        billedYTD: 0,
        estimatedReconciliation: 0,
        monthlyCAMBill: 0,
      },
    );

  const approvedBillFormat =
    totals.approvedBillEstimatesAnually !== null
      ? `${intl.formatNumber(+totals.approvedBillEstimatesAnually, {
          style: 'currency',
          currency: 'USD',
        })} / ${intl.formatNumber(+totals.approvedBillEstimatesAnually / 12, {
          style: 'currency',
          currency: 'USD',
        })}`
      : null;

  return (
    <tr>
      <td>
        <FormattedMessage {...messages.total} />
      </td>
      <td>
        <DashesIfNullOrUndefined data={approvedBillFormat} />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.monthlyCAMBill, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.billedYTD, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.actualsYTD, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.actualsYTDBudget, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.annualAllocation, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.proratedAllocation, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
      <td>
        <DashesIfNullOrUndefined
          data={intl.formatNumber(+totals.estimatedReconciliation, {
            style: 'currency',
            currency: 'USD',
          })}
        />
      </td>
    </tr>
  );
};

export default CamCalculations;
