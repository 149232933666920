import React from 'react';
import DocumentTitle from 'react-document-title';
import { TableHeader } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { injectIntl } from 'react-intl';
import EditFeeModal from '../../components/EditFeeModal';
import FeesTable from './FeesTable';
import { useFetchAllFees } from './hooks';
import messages from './messages';

function ManageFees({ intl }) {
  const {
    count,
    totalCount,
    name,
    isLoading,
    rows,
    isEditModalOpen,
    selectedFee,
    closeModal,
    refetchFees,
  } = useFetchAllFees({ intl });

  return (
    <DocumentTitle title={'Manage Fees'}>
      <>
        <TableHeader
          title={intl.formatMessage(messages.title)}
          count={count}
          totalCount={totalCount}
        />
        <FeesTable intl={intl} name={name} rows={rows} isLoading={isLoading} />
        {isEditModalOpen && (
          <EditFeeModal
            intl={intl}
            isOpen={isEditModalOpen}
            selectedFee={selectedFee}
            onClose={closeModal}
            refetchFees={refetchFees}
          />
        )}
      </>
    </DocumentTitle>
  );
}

const InjectedManageFees = injectIntl(ManageFees);
export default InjectedManageFees;
