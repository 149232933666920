import type {
  Action,
  CommonArea,
  ContactType,
  ReferralType,
  PetType,
  PetBreed,
  ProspectStatus,
  WorkOrderStatus,
  NavigationOption,
  Property,
  User,
  UserPermission,
  NameSuffix,
  PropertyClass,
  Relationship,
  AffordableRelationship,
  ApplicantType,
  Country,
  State,
  IncomeType,
  EmploymentStatus,
  UnitLocation,
  WorkOrderIssue,
  EntryPermission,
  PriorityLevel,
  Unit,
  PropertyTransactionCode,
  PropertySubjournalType,
  PropertyFiscalPeriod,
  ApplicantCancellationReason,
  ProrateMethod,
  SpecialNeedsDesignation,
  UtilityAllowanceType,
  HousingSituation,
} from './types';
import type { PropertyBankAccount } from '../../services/propertyBankAccountsService';
import * as ActionTypes from './constants';
import type { FloorPlan } from '../ManageFloorPlans/types';

export function getAllContactTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_CONTACT_TYPES,
    payload: undefined,
  };
}

export function getAllContactTypesSuccess(
  contactTypes: Array<ContactType>,
): Action<Array<ContactType>> {
  return {
    type: ActionTypes.GET_ALL_CONTACT_TYPES_SUCCESS,
    payload: contactTypes,
  };
}

export function getAllContactTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_CONTACT_TYPES_ERROR,
    payload: error,
  };
}

export function getAllReferralTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_REFERRAL_TYPES,
    payload: undefined,
  };
}

export function getAllReferralTypesSuccess(
  referralType: Array<ReferralType>,
): Action<Array<ReferralType>> {
  return {
    type: ActionTypes.GET_ALL_REFERRAL_TYPES_SUCCESS,
    payload: referralType,
  };
}

export function getAllReferralTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_REFERRAL_TYPES_ERROR,
    payload: error,
  };
}

export function getProspectAssignees(): Action<any> {
  return {
    type: ActionTypes.GET_PROSPECT_ASSIGNEES,
    payload: undefined,
  };
}

export function getProspectAssigneesSuccess(
  users: Array<User>,
): Action<Array<User>> {
  return {
    type: ActionTypes.GET_PROSPECT_ASSIGNEES_SUCCESS,
    payload: users,
  };
}

export function getProspectAssigneesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_PROSPECT_ASSIGNEES_ERROR,
    payload: error,
  };
}

export function getWorkOrderAssignees(): Action<any> {
  return {
    type: ActionTypes.GET_WORK_ORDER_ASSIGNEES,
    payload: undefined,
  };
}

export function getWorkOrderAssigneesSuccess(
  users: Array<User>,
): Action<Array<User>> {
  return {
    type: ActionTypes.GET_WORK_ORDER_ASSIGNEES_SUCCESS,
    payload: users,
  };
}

export function getWorkOrderAssigneesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_WORK_ORDER_ASSIGNEES_ERROR,
    payload: error,
  };
}

export function getAllAssignees(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_ASSIGNEES,
    payload: undefined,
  };
}

export function getAllAssigneesSuccess(
  users: Array<User>,
): Action<Array<User>> {
  return {
    type: ActionTypes.GET_ALL_ASSIGNEES_SUCCESS,
    payload: users,
  };
}

export function getAllAssigneesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_ASSIGNEES_ERROR,
    payload: error,
  };
}

export function getAllPetTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PET_TYPES,
    payload: undefined,
  };
}

export function getAllPetTypesSuccess(
  petType: Array<PetType>,
): Action<Array<PetType>> {
  return {
    type: ActionTypes.GET_ALL_PET_TYPES_SUCCESS,
    payload: petType,
  };
}

export function getAllPetTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PET_TYPES_ERROR,
    payload: error,
  };
}

export function getAllPetBreeds(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PET_BREEDS,
    payload: undefined,
  };
}

export function getAllPetBreedsSuccess(
  petBreeds: Array<PetBreed>,
): Action<Array<PetBreed>> {
  return {
    type: ActionTypes.GET_ALL_PET_BREEDS_SUCCESS,
    payload: petBreeds,
  };
}

export function getAllPetBreedsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PET_BREEDS_ERROR,
    payload: error,
  };
}

export function getAllProspectStatus(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PROSPECT_STATUS,
    payload: undefined,
  };
}

export function getAllProspectStatusSuccess(
  prospectStatus: Array<ProspectStatus>,
): Action<Array<ProspectStatus>> {
  return {
    type: ActionTypes.GET_ALL_PROSPECT_STATUS_SUCCESS,
    payload: prospectStatus,
  };
}

export function getAllProspectStatusError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PROSPECT_STATUS_ERROR,
    payload: error,
  };
}

export function getAllWorkOrderStatus(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_STATUS,
    payload: undefined,
  };
}

export function getAllWorkOrderStatusSuccess(
  workOrderStatus: Array<WorkOrderStatus>,
): Action<Array<WorkOrderStatus>> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_STATUS_SUCCESS,
    payload: workOrderStatus,
  };
}

export function getAllWorkOrderStatusError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_STATUS_ERROR,
    payload: error,
  };
}

export function getAllActivityTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITY_TYPES,
    payload: undefined,
  };
}

export function getAllActivityTypesSuccess(
  petType: Array<PetType>,
): Action<Array<PetType>> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITY_TYPES_SUCCESS,
    payload: petType,
  };
}

export function getAllActivityTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITY_TYPES_ERROR,
    payload: error,
  };
}

export function getAllNavigationOptions(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_NAVIGATION_OPTIONS,
    payload: undefined,
  };
}

export function getAllNavigationOptionsSuccess(
  options: Array<NavigationOption>,
): Action<Array<NavigationOption>> {
  return {
    type: ActionTypes.GET_ALL_NAVIGATION_OPTIONS_SUCCESS,
    payload: options,
  };
}

export function getAllNavigationOptionsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_NAVIGATION_OPTIONS_ERROR,
    payload: error,
  };
}

export function logOut(): Action<any> {
  return {
    type: ActionTypes.LOG_OUT,
    payload: undefined,
  };
}

export function selectProperty(property: Property): Action<Property> {
  return {
    type: ActionTypes.SELECT_PROPERTY,
    payload: property,
  };
}

export function updateSelectedProperty(): Action<string> {
  return {
    type: ActionTypes.UPDATE_SELECTED_PROPERTY,
    payload: undefined,
  };
}

export function selectPortfolioSummary(): Action<any> {
  return {
    type: ActionTypes.SELECT_PORTFOLIO_SUMMARY,
    payload: undefined,
  };
}

export function updateCurrentUser(
  user: User,
  permissions: Array<UserPermission>,
): Action<{ user: User, permissions: Array<UserPermission> }> {
  return {
    type: ActionTypes.UPDATE_CURRENT_USER,
    payload: { user, permissions },
  };
}

export function getAllNameSuffixes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_NAME_SUFFIXES,
    payload: undefined,
  };
}

export function getAllNameSuffixesSuccess(
  nameSuffixes: Array<NameSuffix>,
): Action<Array<NameSuffix>> {
  return {
    type: ActionTypes.GET_ALL_NAME_SUFFIXES_SUCCESS,
    payload: nameSuffixes,
  };
}

export function getAllNameSuffixesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_NAME_SUFFIXES_ERROR,
    payload: error,
  };
}

export function getAllPropertyClasses(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_CLASSES,
    payload: undefined,
  };
}

export function getAllPropertyClassesSuccess(
  propertyClasses: Array<PropertyClass>,
): Action<Array<PropertyClass>> {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_CLASSES_SUCCESS,
    payload: propertyClasses,
  };
}

export function getAllPropertyClassesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_CLASSES_ERROR,
    payload: error,
  };
}

export function getAllRelationships(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_RELATIONSHIPS,
    payload: undefined,
  };
}

export function getAllAffordableRelationships(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS,
    payload: undefined,
  };
}

export function getAllRelationshipsSuccess(
  relationships: Array<Relationship>,
): Action<Array<Relationship>> {
  return {
    type: ActionTypes.GET_ALL_RELATIONSHIPS_SUCCESS,
    payload: relationships,
  };
}

export function getAllAffordableRelationshipsSuccess(
  affordableRelationships: Array<AffordableRelationship>,
): Action<Array<AffordableRelationship>> {
  return {
    type: ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS_SUCCESS,
    payload: affordableRelationships,
  };
}

export function getAllRelationshipsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_RELATIONSHIPS_ERROR,
    payload: error,
  };
}

export function getAllAffordableRelationshipsError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_AFFORDABLE_RELATIONSHIPS_ERROR,
    payload: error,
  };
}

export function getAllApplicantTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_TYPES,
    payload: undefined,
  };
}

export function getAllApplicantTypesSuccess(
  applicantTypes: Array<ApplicantType>,
): Action<Array<ApplicantType>> {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_TYPES_SUCCESS,
    payload: applicantTypes,
  };
}

export function getAllApplicantTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_TYPES_ERROR,
    payload: error,
  };
}

export function getAllFloorPlans(): * {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS,
    payload: {},
  };
}

export function getAllFloorPlansSuccess(floorPlans: Array<FloorPlan>) {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS_SUCCESS,
    payload: floorPlans,
  };
}

export function getAllFloorPlansFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS_ERROR,
    payload: error,
  };
}

export function getAllCountries(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_COUNTRIES,
    payload: undefined,
  };
}

export function getAllCountriesSuccess(
  countries: Array<Country>,
): Action<Array<Country>> {
  return {
    type: ActionTypes.GET_ALL_COUNTRIES_SUCCESS,
    payload: countries,
  };
}

export function getAllCountriesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_COUNTRIES_ERROR,
    payload: error,
  };
}

export function getAllStates(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_STATES,
    payload: undefined,
  };
}

export function getAllStatesSuccess(
  states: Array<State>,
): Action<Array<State>> {
  return {
    type: ActionTypes.GET_ALL_STATES_SUCCESS,
    payload: states,
  };
}

export function getAllStatesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_STATES_ERROR,
    payload: error,
  };
}

export function getAllIncomeTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_INCOME_TYPES,
    payload: undefined,
  };
}

export function getAllIncomeTypesSuccess(
  incomeTypes: Array<IncomeType>,
): Action<Array<IncomeType>> {
  return {
    type: ActionTypes.GET_ALL_INCOME_TYPES_SUCCESS,
    payload: incomeTypes,
  };
}

export function getAllIncomeTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_INCOME_TYPES_ERROR,
    payload: error,
  };
}

export function getAllSpecialNeedsDesignations(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_SPECIAL_NEEDS_DESIGNATION,
    payload: undefined,
  };
}

export function getAllSpecialNeedsDesignationsSuccess(
  specialNeedsDesignations: Array<SpecialNeedsDesignation>,
): Action<Array<SpecialNeedsDesignation>> {
  return {
    type: ActionTypes.GET_ALL_SPECIAL_NEEDS_DESIGNATION_SUCCESS,
    payload: specialNeedsDesignations,
  };
}

export function getAllSpecialNeedsDesignationsError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_SPECIAL_NEEDS_DESIGNATION_ERROR,
    payload: error,
  };
}

export function getAllEmploymentStatuses(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_EMPLOYMENT_STATUS,
    payload: undefined,
  };
}

export function getAllEmploymentStatusesSuccess(
  statuses: Array<EmploymentStatus>,
): Action<Array<EmploymentStatus>> {
  return {
    type: ActionTypes.GET_ALL_EMPLOYMENT_STATUS_SUCCESS,
    payload: statuses,
  };
}

export function getAllEmploymentStatusesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_EMPLOYMENT_STATUS_ERROR,
    payload: error,
  };
}

export function getAllWorkOrderIssues(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_ISSUES,
    payload: undefined,
  };
}

export function showExtendUserSessionModal(): Action<void> {
  return {
    type: ActionTypes.SHOW_EXTEND_USER_SESSION_MODAL,
    payload: undefined,
  };
}

export function getAllWorkOrderIssuesSuccess(
  issues: Array<WorkOrderIssue>,
): Action<Array<WorkOrderIssue>> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_ISSUES_SUCCESS,
    payload: issues,
  };
}

export function getAllWorkOrderIssuesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_WORK_ORDER_ISSUES_ERROR,
    payload: error,
  };
}

export function getAllUnitLocations(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UNIT_LOCATIONS,
    payload: undefined,
  };
}

export function getAllUnitLocationsSuccess(
  unitLocations: Array<UnitLocation>,
): Action<Array<UnitLocation>> {
  return {
    type: ActionTypes.GET_ALL_UNIT_LOCATIONS_SUCCESS,
    payload: unitLocations,
  };
}

export function getAllUnitLocationsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_UNIT_LOCATIONS_ERROR,
    payload: error,
  };
}

export function getAllCommonAreaLocations(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_COMMON_AREA_LOCATIONS,
    payload: undefined,
  };
}

export function getAllCommonAreaLocationsSuccess(
  commonAreaLocations: Array<CommonArea>,
): Action<Array<CommonArea>> {
  return {
    type: ActionTypes.GET_ALL_COMMON_AREA_LOCATIONS_SUCCESS,
    payload: commonAreaLocations,
  };
}

export function getAllCommonAreaLocationsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_COMMON_AREA_LOCATIONS_ERROR,
    payload: error,
  };
}

export function getAllEntryPermissions(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_ENTRY_PERMISSIONS,
    payload: undefined,
  };
}

export function getAllEntryPermissionsSuccess(
  entryPerms: Array<EntryPermission>,
): Action<Array<EntryPermission>> {
  return {
    type: ActionTypes.GET_ALL_ENTRY_PERMISSIONS_SUCCESS,
    payload: entryPerms,
  };
}

export function getAllEntryPermissionsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_ENTRY_PERMISSIONS_ERROR,
    payload: error,
  };
}

export function getAllPriorityLevels(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PRIORITY_LEVELS,
    payload: undefined,
  };
}

export function getAllPriorityLevelsSuccess(
  priorityLevels: Array<PriorityLevel>,
): Action<Array<PriorityLevel>> {
  return {
    type: ActionTypes.GET_ALL_PRIORITY_LEVELS_SUCCESS,
    payload: priorityLevels,
  };
}

export function getAllPriorityLevelsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PRIORITY_LEVELS_ERROR,
    payload: error,
  };
}

export function getAllMoveOutReasons(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_REASONS,
    payload: undefined,
  };
}

export function getAllMoveOutReasonsSuccess(
  moveOutReasons: Array<Object>,
): Action<Array<PriorityLevel>> {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_REASONS_SUCCESS,
    payload: moveOutReasons,
  };
}

export function getAllMoveOutReasonsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_REASONS_ERROR,
    payload: error,
  };
}

export function getAllHousingSituations(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_HOUSING_SITUATIONS,
    payload: undefined,
  };
}

export function getAllHousingSituationsSuccess(
  housingSituations: Array<Object>,
): Action<Array<HousingSituation>> {
  return {
    type: ActionTypes.GET_ALL_HOUSING_SITUATIONS_SUCCESS,
    payload: housingSituations,
  };
}

export function getAllHousingSituationsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_HOUSING_SITUATIONS_ERROR,
    payload: error,
  };
}

export function hideExtendUserSessionModal(): Action<void> {
  return {
    type: ActionTypes.HIDE_EXTEND_USER_SESSION_MODAL,
    payload: undefined,
  };
}

export function getOccupiedUnitsForSelectedProperty(): Action<void> {
  return {
    type: ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY,
    payload: undefined,
  };
}

export function getOccupiedUnitsForSelectedPropertySuccess(
  units: Unit[],
): Action<Unit[]> {
  return {
    type: ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_SUCCESS,
    payload: units,
  };
}

export function getOccupiedUnitsForSelectedPropertyFailure(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_OCCUPIED_UNITS_FOR_SELECTED_PROPERTY_FAILURE,
    payload: error,
  };
}

export function getSelectedPropertyTransactionCodes(): Action<void> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES,
    payload: undefined,
  };
}

export function getSelectedPropertyTransactionCodesSuccess(
  codes: PropertyTransactionCode[],
): Action<PropertyTransactionCode[]> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES_SUCCESS,
    payload: codes,
  };
}

export function getSelectedPropertyTransactionCodesFailure(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_TRANSACTION_CODES_FAILURE,
    payload: error,
  };
}

export function getSelectedPropertySubjournals(): Action<void> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS,
    payload: undefined,
  };
}

export function getSelectedPropertySubjournalsSuccess(
  subjournals: PropertySubjournalType[],
): Action<PropertySubjournalType[]> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS_SUCCESS,
    payload: subjournals,
  };
}

export function getSelectedPropertySubjournalsFailure(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_SUBJOURNALS_FAILURE,
    payload: error,
  };
}

export function getSelectedPropertyFiscalPeriods(): Action<void> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD,
    payload: undefined,
  };
}

export function getSelectedPropertyFiscalPeriodsSuccess(
  period: PropertyFiscalPeriod,
): Action<PropertyFiscalPeriod> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD_SUCCESS,
    payload: period,
  };
}

export function getSelectedPropertyFiscalPeriodsFailure(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_FISCAL_PERIOD_FAILURE,
    payload: error,
  };
}

export function getSelectedPropertyBankAccounts(): Action<void> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS,
    payload: undefined,
  };
}

export function getSelectedPropertyBankAccountsSuccess(
  payload: PropertyBankAccount[],
): Action<PropertyBankAccount[]> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS_SUCCESS,
    payload,
  };
}

export function getSelectedPropertyBankAccountsFailure(
  payload: Error | string,
): Action<Error | string> {
  return {
    type: ActionTypes.GET_SELECTED_PROPERTY_BANK_ACCOUNTS_FAILURE,
    payload,
  };
}

export function getWriteOffsForCustomer(customerId: string) {
  return {
    type: ActionTypes.GET_WRITEOFFS_FOR_CUSTOMER,
    payload: customerId,
  };
}

export function getWriteOffsForCustomerSuccess(
  customerId: string,
  writeOffs: any[],
) {
  return {
    type: ActionTypes.GET_WRITEOFFS_FOR_CUSTOMER_SUCCESS,
    payload: { customerId, writeOffs },
  };
}

export function getWriteOffsForCustomerError(error: string) {
  return {
    type: ActionTypes.GET_WRITEOFFS_FOR_CUSTOMER_ERROR,
    payload: error,
  };
}

export function addWriteOffsForCustomer(customerId: string, writeOffs: any[]) {
  return {
    type: ActionTypes.ADD_WRITE_OFFS_FOR_CUSTOMER,
    payload: { customerId, writeOffs },
  };
}

export function getAllApplicantCancellationReasons() {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_CANCELLATION_REASONS,
    payload: undefined,
  };
}

export function getAllApplicantCancellationReasonsError(error: string) {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_CANCELLATION_REASONS_ERROR,
    payload: error,
  };
}

export function getAllApplicantCancellationReasonsSuccess(
  applicantCancellationReasons: Array<ApplicantCancellationReason>,
) {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_CANCELLATION_REASONS_SUCCESS,
    payload: { applicantCancellationReasons },
  };
}

export function promptPrintDialog() {
  return {
    type: ActionTypes.PROMPT_PRINT_DIALOG,
    payload: {},
  };
}

export function promptToaster(toasterPayload: Object) {
  return {
    type: ActionTypes.PROMPT_TOASTER,
    payload: { toasterPayload },
  };
}

export function socketConnect() {
  return {
    type: ActionTypes.SOCKET_CONNECT,
    payload: {},
  };
}

export function socketDisconnect() {
  return {
    type: ActionTypes.SOCKET_DISCONNECT,
    payload: {},
  };
}

export function socketReAuthenticate(token: string) {
  return {
    type: ActionTypes.SOCKET_RE_AUTH,
    payload: { token },
  };
}

export function getComplianceStatuses(): Action<any> {
  return {
    type: ActionTypes.GET_COMPLIANCE_STATUSES,
    payload: undefined,
  };
}

export function getComplianceStatusesSuccess(
  complianceStatuses: Array<Object>,
): Action<any> {
  const statuses = complianceStatuses.map((status) => {
    return { value: status.id, text: status.name };
  });
  const dropdowns = [{ value: '', text: 'Choose', disabled: true }].concat(
    statuses,
  );
  return {
    type: ActionTypes.GET_COMPLIANCE_STATUSES_SUCCESS,
    payload: dropdowns,
  };
}

export function getRentalAssistanceSources(): Action<any> {
  return {
    type: ActionTypes.GET_RENTAL_ASSISTANCE_SOURCES,
    payload: undefined,
  };
}

export function getRentalAssistanceSourcesSuccess(
  rentalAssistanceSources: Array<Object>,
): Action<any> {
  const sources = rentalAssistanceSources.map((source) => {
    return { value: source.id, text: source.name };
  });
  const dropdowns = [{ value: '', text: 'Choose' }].concat(sources);
  return {
    type: ActionTypes.GET_RENTAL_ASSISTANCE_SOURCES_SUCCESS,
    payload: dropdowns,
  };
}

export function getUtilityAllowances(): Action<any> {
  return {
    type: ActionTypes.GET_UTILITY_ALLOWANCES,
    payload: undefined,
  };
}

export function getUtilityAllowancesSuccess(
  utilityAllowances: Array<Object>,
): Action<any> {
  const allowances = utilityAllowances.reduce((dropdownValues, allowance) => {
    const {
      publicHousingAuthority: { name },
      publicHousingAuthorityId,
    } = allowance;
    dropdownValues[publicHousingAuthorityId] = {
      value: publicHousingAuthorityId,
      text: name,
    };
    return dropdownValues;
  }, {});
  const allowanceDropdowns = [{ value: '', text: 'Choose' }].concat(
    Object.values(allowances),
  );
  return {
    type: ActionTypes.GET_UTILITY_ALLOWANCES_SUCCESS,
    payload: {
      allowanceDropdowns,
      utilityAllowances,
    },
  };
}

export function getAllProrateMethods(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_PRORATE_METHODS,
    payload: undefined,
  };
}

export function getAllProrateMethodsSuccess(
  prorateMethods: Array<ProrateMethod>,
): Action<Array<ProrateMethod>> {
  return {
    type: ActionTypes.GET_ALL_PRORATE_METHODS_SUCCESS,
    payload: prorateMethods,
  };
}

export function getAllProrateMethodsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PRORATE_METHODS_ERROR,
    payload: error,
  };
}

export function getAllMoveOutProrateMethods(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_PRORATE_METHODS,
    payload: undefined,
  };
}

export function getAllMoveOutProrateMethodsSuccess(moveOutProrateMethods) {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_PRORATE_METHODS_SUCCESS,
    payload: moveOutProrateMethods,
  };
}

export function getAllMoveOutProrateMethodsError(error): Action<any> {
  return {
    type: ActionTypes.GET_ALL_MOVE_OUT_PRORATE_METHODS_ERROR,
    payload: error,
  };
}

export function getAllUtilityAllowanceTypes(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UTILITY_ALLOWANCE_TYPES,
    payload: undefined,
  };
}

export function getAllUtilityAllowanceTypesSuccess(
  specialNeedsDesignations: Array<UtilityAllowanceType>,
): Action<Array<UtilityAllowanceType>> {
  return {
    type: ActionTypes.GET_ALL_UTILITY_ALLOWANCE_TYPES_SUCCESS,
    payload: specialNeedsDesignations,
  };
}

export function getAllUtilityAllowanceTypesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_UTILITY_ALLOWANCE_TYPES_ERROR,
    payload: error,
  };
}

export function getPropertyPaymentProvider(): Action<any> {
  return {
    type: ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER,
    payload: undefined,
  };
}

export function getPropertyPaymentProviderSuccess(
  propertyPaymentProvider: Array<any>,
): Action<Array<any>> {
  return {
    type: ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER_SUCCESS,
    payload: propertyPaymentProvider,
  };
}

export function getPropertyPaymentProviderError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_PROPERTY_PAYMENT_PROVIDER_ERROR,
    payload: error,
  };
}
