const PRIORITY_ARRAY = ['Urgent', 'High', 'Medium', 'Low', 'Warranty'];

const STATUS_ARRAY = [
  // 'Completed',
  // 'Canceled',
  'Open',
  'On Hold',
  'In Progress',
];

const PRIORITY_FILTERS = PRIORITY_ARRAY.map((value) => ({
  text: value,
  value: value.toLowerCase(),
}));

const STATUS_FILTERS = STATUS_ARRAY.map((value) => ({
  text: value,
  value: value.toLowerCase(),
}));
export const HEADERS = [
  {
    id: 'property',
    label: 'Property',
    multiselect: true,
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'status',
    label: 'Status',
    searchable: true,
    sortable: true,
    multiselect: true,
    filterOptions: STATUS_FILTERS,
    dataType: 'string',
  },
  {
    id: 'location',
    label: 'Location',
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'buildingNumber',
    label: 'Building Number',
    searchable: true,
    sortable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'issueDescription',
    label: 'Issue Description',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'assignee',
    label: 'Assignee',
    multiselect: true,
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'priority',
    label: 'Priority',
    searchable: true,
    sortable: true,
    multiselect: true,
    filterOptions: PRIORITY_FILTERS,
    dataType: 'string',
  },
  {
    id: 'created',
    label: 'Created',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'daysOpen',
    label: 'Days Open',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'dueDate',
    label: 'Due Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'requestor',
    label: 'Requestor',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
];

export const PROPERTY_PATH_MAP = {
  property: {
    path: 'property.name',
    dataType: 'string',
  },
  created: {
    path: 'createdAt',
    dataType: 'date',
  },
  status: {
    path: 'statusDesc',
    dataType: 'string',
  },
  issueDescription: {
    path: 'description',
    dataType: 'string',
  },
  assignee: {
    path: 'assignee',
    dataType: 'string',
  },
  priority: {
    path: 'priorityLevelDesc',
    dataType: 'string',
  },
  daysOpen: {
    path: 'daysOpen',
    dataType: 'number',
  },
  dueDate: {
    path: 'dueDate',
    dataType: 'date',
  },
  completed: {
    path: 'finishedDate',
    dataType: 'date',
  },
  requestor: {
    path: 'requestorName',
    dataType: 'string',
  },
  location: {
    path: 'location',
    dataType: 'string',
  },
  buildingNumber: {
    path: 'buildingNumber',
    dataType: 'string',
  },
};

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};
