import DocumentTitle from 'react-document-title';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useFormatMessage from '../../hooks/useFormatMessage';
import { useTableData } from './hooks';
import messages from './messages';

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

function ManageAmenities({
  history,
  match: {
    params: { propertyId },
  },
}) {
  const formatMessage = useFormatMessage();
  const { name, rows, isLoading } = useTableData({ history });

  return (
    <DocumentTitle title={formatMessage(messages.tabTitle)}>
      <Grid container padding={4} spacing={3}>
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={12} md={9} lg={10}>
            <Typography variant="h2">
              {formatMessage(messages.title)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <StyledLink to={`/property/${propertyId}/amenity`}>
              <Button
                variant="shout"
                size="large"
                fullWidth
                sx={{ padding: '10px 15px' }}
              >
                <i
                  className="et-plus"
                  style={{ color: '#fff', marginRight: 10 }}
                />
                {formatMessage(messages.addAmenityButton)}
              </Button>
            </StyledLink>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table
            name={name}
            headers={[
              {
                id: 'amenity',
                label: formatMessage(messages.amenityColumn),
                sortable: false,
              },
              {
                id: 'value',
                label: formatMessage(messages.valueColumn),
                sortable: false,
              },
              {
                id: 'numOfUnits',
                label: formatMessage(messages.numOfUnitsColumn),
                dataType: 'number',
                sortable: false,
              },
              {
                id: 'valueXUnits',
                label: formatMessage(messages.valueXUnitsColumn),
                dataType: 'number',
                sortable: false,
              },
              {
                id: 'edit',
                label: formatMessage(messages.editColumn),
                sortable: false,
              },
            ]}
            rows={rows}
            emptyStateMessage={formatMessage(messages.emptyStateMessage)}
            isLoading={isLoading}
            sx={{ padding: 0 }}
            showSettings={false}
          />
        </Grid>
      </Grid>
    </DocumentTitle>
  );
}

export default ManageAmenities;
