import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useManagePriorResidents } from '../hooksV2';
import messages from '../messages';

const PriorResidentsTable = ({
  intl,
  selectedProperty,
  propertyId,
  organizationId,
  userId,
  priorResidents,
  isSuccess,
  isAllCommercial,
  onPageChange,
  currentPage,
  perPage,
  locale,
}) => {
  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    name,
    searchState,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    handleColumnChange,
    handleTruncateChange,
    truncatedColumns,
    filteredHeaders,
    results,
    isLoading,
    handleCSVButtonClick,
  } = useManagePriorResidents({
    intl,
    isAllCommercial,
    selectedProperty,
    propertyId,
    organizationId,
    userId,
    locale,
    onPageChange,
    currentPage,
    perPage,
  });
  return (
    <Box
      sx={{
        'button[disabled]': { backgroundColor: 'unset' },
      }}
    >
      <TableHeader
        title={intl.formatMessage(messages.title)}
        totalCount={results?.length ?? 0}
        count={results?.length ?? 0}
      />
      <VirtualizedTable
        ReactBeautifulDnD={{
          DragDropContext,
          Droppable,
          Draggable,
        }}
        ReactRouterLink={ReactRouterLink}
        headers={filteredHeaders}
        height={'calc(100vh - 260px)'}
        intl={intl}
        rows={results}
        isLoading={isLoading}
        isAllCommercial={isAllCommercial}
        filterState={filterState}
        filterTypeState={filterTypeState}
        dateState={dateState}
        order={order}
        orderBy={orderBy}
        handleSortChange={handleSortChange}
        handleFilterChange={handleFilterChange}
        handleFilterTypeChange={handleFilterTypeChange}
        handleSearchSubmit={handleSearchSubmit}
        handleDateSubmit={handleDateSubmit}
        name={name}
        searchState={searchState}
        allColumnsHidden={allColumnsHidden}
        columnOptions={columnOptions}
        selectedColumns={selectedColumns}
        handleColumnChange={handleColumnChange}
        handleTruncateChange={handleTruncateChange}
        truncatedColumns={truncatedColumns}
        onCSVButtonClick={handleCSVButtonClick}
      />
    </Box>
  );
};

export default PriorResidentsTable;
