import { useRef, useState } from 'react';
import { useResizeObserver } from '@fortress-technology-solutions/fortress-component-library/hooks';
import { getComputedElementStyle } from '../../utils/element-helpers';

const TableLayout = ({ children, name }) => {
  const ref = useRef(null);
  const [tableHeight, setTableHeight] = useState('calc(100dvh - 204px)');

  useResizeObserver({
    ref,
    callback: (entry) => {
      let newTableHeight = 0;
      // loop through children and set height
      entry.target.childNodes.forEach((child) => {
        if (child.id !== `table-wrapper_${name}`) {
          newTableHeight +=
            child.clientHeight +
            getComputedElementStyle(child, 'padding-top', true) +
            getComputedElementStyle(child, 'padding-bottom', true);
        }
      });

      setTableHeight(`calc(100dvh - ${newTableHeight + 40 + 16 + 8}px)`);
    },
  });

  return (
    <div ref={ref} style={{ height: '100%', overflow: 'hidden' }}>
      {children(tableHeight)}
    </div>
  );
};

export default TableLayout;
