import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import keyBy from 'lodash.keyby';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import usePermissionsHook from '../../../components/ElementWithPermissions/usePermissionsHook';
import { useFetchConversations } from '../../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../../hooks/useHasPermission';
import ApplicationService from '../../../services/applicationService';
import { PROPERTY_PATH_MAP } from './ApplicationsTable_V2.constants';
import {
  buildHeaders,
  buildRows,
  downloadCSV,
} from './ApplicationsTable_V2.utils';
import useUniqueTableName from '../../../hooks/useUniqueTableName';

const INITIAL_STATUSES = [
  '2e8c42a3-86dc-4123-87cb-fc6bf930c1e9',
  '353807d7-11cb-43c0-874e-6a5cdcbcf812',
  'a5325dae-8c6c-490b-84e9-fa966e46de99',
  'e31de468-3a4b-42c1-8c76-02a321fe07c6',
];

const useFetchApplications = ({
  organizationId,
  propertyId,
  pageNumber = 1,
  limit = 5,
  filterState,
  enabled,
}) => {
  const applicationService = useMemo(() => new ApplicationService(), []);

  const queryKey = [
    'applications',
    organizationId,
    propertyId,
    pageNumber,
    limit,
    filterState,
    enabled,
  ];
  const { data, isLoading, isSuccess } = useQuery(
    queryKey,
    () =>
      applicationService.getAllApplicationsPaginate(
        organizationId,
        propertyId,
        pageNumber,
        limit,
        null,
        filterState,
        undefined,
      ),
    { enabled: Boolean(enabled && organizationId && propertyId) },
  );
  const applications = useMemo(() => data?.results ?? [], [data?.results]);

  return {
    applications,
    isLoading,
    isSuccess,
  };
};

export const useManageApplications = ({
  applicationStatuses,
  organizationId,
  propertyId,
  affordablePrograms,
  locale,
  intl,
  onEditActivity,
  onRecordActivity,
  onScheduleActivity,
  onCreateActivity,
  permissions,
  selectedProperty,
}) => {
  const [headers, setHeaders] = useState([]);
  const hasFirstMount = useRef(false);
  const name = useUniqueTableName('manage-applications-table');

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'applicationCode',
  });

  useEffect(() => {
    // set initial statuses after options load
    if (applicationStatuses?.length) {
      if (!hasFirstMount.current && !Object.keys(filterState ?? {}).length) {
        handleFilterChange('applicationStatusId', INITIAL_STATUSES);
      }
      hasFirstMount.current = true;
    }
  }, [applicationStatuses, handleFilterChange, filterState]);

  const { backendFilterState } = useMemo(() => {
    const removeValues = (filters, allowFilters = []) => {
      const parsedFilters = {};
      Object.keys(filters).forEach((filterKey) => {
        if (allowFilters.some((filterToAllow) => filterToAllow === filterKey)) {
          parsedFilters[filterKey] = filters[filterKey];
        }
      });

      return parsedFilters;
    };
    return {
      backendFilterState: removeValues(filterState, [
        'applicationStatusId',
        'unit',
      ]),
    };
  }, [filterState]);

  const { applications, isLoading, isSuccess } = useFetchApplications({
    organizationId,
    propertyId,
    orderBy,
    order,
    filterState: backendFilterState,
    enabled: true,
  });

  const twoWayRecipientIds = useMemo(
    () => applications.map((r) => r.twoWayRecipientIds).flat(),
    [applications],
  );

  const hasCommunicationCreatePermission = useHasPermission(
    'communication-create',
  );
  const showTextingColumn =
    selectedProperty.isTwoWayCommunicationActive &&
    hasCommunicationCreatePermission;

  const [conversations] = useFetchConversations(
    twoWayRecipientIds,
    showTextingColumn,
  );
  const conversationsDictionary = useMemo(() => {
    return keyBy(conversations, (c) => c.recipient.customerId);
  }, [conversations]);

  const results = useMemo(
    () =>
      applications.map((application) => {
        const householdConversationsStatus =
          application.twoWayRecipientIds.reduce(
            (acc, customerId) => {
              const conversation = conversationsDictionary[customerId];

              if (conversation) {
                acc[conversation.conversationStatus] += 1;
              }

              return acc;
            },
            { NEW: 0, UNRESOLVED: 0, RESOLVED: 0 },
          );

        householdConversationsStatus.TOTAL =
          householdConversationsStatus.NEW +
          householdConversationsStatus.UNRESOLVED;

        application.texting = householdConversationsStatus;
        application.unitNumber =
          application?.unitNumber !== '---' ? application?.unitNumber : null;

        return application;
      }),
    [applications, conversationsDictionary],
  );

  const { applicationStatusOptions, assignedToOptions } = useGetFilterOptions({
    applicationStatuses,
    locale,
    results,
  });

  useManageApplicationsHeaders({
    intl,
    affordablePrograms,
    applicationStatusOptions,
    assignedToOptions,
    setHeaders,
    showTextingColumn,
  });

  const propertyPathMap = useMemo(() => PROPERTY_PATH_MAP(locale), [locale]);

  const sortedAndFilteredApplications = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterTypeState,
    filterState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: propertyPathMap,
  });

  const rows = useManageApplicationsRows({
    affordablePrograms,
    mappedApplications: sortedAndFilteredApplications,
    locale,
    intl,
    onEditActivity,
    onRecordActivity,
    onScheduleActivity,
    onCreateActivity,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { CSVIsExporting, handleCSVDownload } =
    useManageApplicationsCSVDownload({
      affordablePrograms,
      filterState,
      locale,
      permissions,
      searchState,
      mappedApplications: sortedAndFilteredApplications,
      headers,
      showTextingColumn,
    });

  return {
    CSVIsExporting,
    allColumnsHidden,
    columnOptions,
    count: rows?.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleCSVDownload,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    intl,
    isLoading,
    isSuccess,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
  };
};

const useGetFilterOptions = ({ applicationStatuses, locale, results }) => {
  return useMemo(() => {
    const options = {
      applicationStatusOptions: [],
      assignedToOptions: [],
    };

    options.applicationStatusOptions = applicationStatuses.map(
      ({ id, name, translations }) => ({
        value: id,
        text: translations[locale] ?? name,
      }),
    );

    if (results?.length) {
      results.forEach(({ assigneeName }) => {
        if (assigneeName?.length) options.assignedToOptions.push(assigneeName);
      });
      options.assignedToOptions = Array.from(new Set(options.assignedToOptions))
        .sort()
        .map((assigneeName) => ({
          text: assigneeName,
          value: assigneeName?.toLowerCase(),
        }));
    }

    return options;
  }, [applicationStatuses, locale, results]);
};

const useManageApplicationsCSVDownload = ({
  affordablePrograms,
  filterState,
  locale,
  permissions,
  searchState,
  mappedApplications,
  headers,
  showTextingColumn,
}) => {
  const [CSVIsExporting, setCSVIsExporting] = useState(false);

  useEffect(() => {
    if (
      CSVIsExporting &&
      Array.isArray(headers) &&
      Array.isArray(mappedApplications)
    ) {
      downloadCSV({
        mappedApplications,
        permissions,
        locale,
        affordablePrograms,
        headers,
        hasAnyFilters:
          Object.keys(filterState ?? {})?.length ||
          Object.keys(searchState ?? {})?.length,
        showTextingColumn,
      });
      setCSVIsExporting(false);
    }
  }, [
    CSVIsExporting,
    affordablePrograms,
    mappedApplications,
    filterState,
    locale,
    permissions,
    searchState,
    headers,
    showTextingColumn,
  ]);

  return {
    CSVIsExporting,
    handleCSVDownload: () => setCSVIsExporting(true),
  };
};

export const useManageApplicationsHeaders = ({
  intl,
  affordablePrograms,
  applicationStatusOptions,
  assignedToOptions,
  setHeaders,
  showTextingColumn,
}) => {
  const { hudProgram, generalAffordableProgram } = affordablePrograms;

  useEffect(() => {
    const headers = buildHeaders({
      intl,
      applicationStatusOptions,
      generalAffordableProgram,
      assignedToOptions,
      hudProgram,
      showTextingColumn,
    });

    setHeaders(headers);
  }, [
    applicationStatusOptions,
    assignedToOptions,
    generalAffordableProgram,
    hudProgram,
    intl,
    setHeaders,
    showTextingColumn,
  ]);
};
const useManageApplicationsRows = ({
  affordablePrograms,
  mappedApplications,
  locale,
  intl,
  onEditActivity,
  onRecordActivity,
  onScheduleActivity,
  onCreateActivity,
}) => {
  const hasActivityReadUpdateDeletePermission = usePermissionsHook({
    scope: ['activity-read', 'activity-update', 'activity-delete'],
  });
  const hasActivityCreatePermission = usePermissionsHook({
    scope: ['activity-create'],
  });
  const { hudProgram, generalAffordableProgram } = affordablePrograms;

  return useMemo(
    () =>
      mappedApplications.length
        ? buildRows({
            mappedApplications,
            generalAffordableProgram,
            hudProgram,
            locale,
            onCreateActivity,
            onEditActivity,
            onRecordActivity,
            onScheduleActivity,
            hasActivityReadUpdateDeletePermission,
            hasActivityCreatePermission,
            intl,
          })
        : [],
    [
      intl,
      hasActivityCreatePermission,
      hasActivityReadUpdateDeletePermission,
      mappedApplications,
      generalAffordableProgram,
      hudProgram,
      locale,
      onCreateActivity,
      onEditActivity,
      onRecordActivity,
      onScheduleActivity,
    ],
  );
};
