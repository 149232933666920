import { FormattedMessage } from 'react-intl';
import componentMessages from './messages';
import generalMessages from '../App/messages';

const messages = { ...generalMessages, ...componentMessages };

export const COMPLIANCE_TYPES: Object = {
  ALL_OPEN_CERTS: 'allOpenCerts',
  NEEDS_REVIEW: 'needsReview',
};
export const HEADERS = [
  {
    id: 'certificationType',
    label: <FormattedMessage {...messages.certificationType} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'propertyName',
    label: <FormattedMessage {...messages.propertyName} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'unitNumber',
    label: <FormattedMessage {...messages.unitNumber} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'applicantName',
    label: <FormattedMessage {...messages.applicantName} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'complianceDocumentType',
    label: <FormattedMessage {...messages.complianceDocumentType} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'uploadDate',
    label: <FormattedMessage {...messages.uploadDateTime} />,
    dataType: 'date',
    sortable: true,
    searchable: false,
  },

  {
    id: 'isReviewed',
    label: <FormattedMessage {...messages.isReviewed} />,
    dataType: 'string',
    sortable: true,
  },
  {
    id: 'programType',
    label: <FormattedMessage {...messages.programType} />,
    dataType: 'string',
    multiselect: true,
    sortable: true,
  },
  {
    id: 'voucherEffectiveDate',
    label: <FormattedMessage {...messages.voucherEffectiveDate} />,
    dataType: 'date',
    sortable: true,
    searchable: false,
  },
  {
    id: 'certHistory',
    label: <FormattedMessage {...messages.certHistory} />,
    dataType: 'string',
    sortable: true,
  },
  {
    id: 'complianceApprovalStatus',
    label: <FormattedMessage {...messages.complianceApprovalStatus} />,
    dataType: 'string',
    multiselect: true,
    sortable: true,
  },
  {
    id: 'isActive',
    label: <FormattedMessage {...messages.isActive} />,
    dataType: 'boolean',
    filterOptions: [
      { value: 'yes', text: <FormattedMessage {...messages.yes} /> },
      { value: 'no', text: <FormattedMessage {...messages.no} /> },
    ],
    sortable: true,
  },
  {
    id: 'isResident',
    label: <FormattedMessage {...messages.isResident} />,
    dataType: 'boolean',
    filterOptions: [
      { value: 'yes', text: <FormattedMessage {...messages.yes} /> },
      { value: 'no', text: <FormattedMessage {...messages.no} /> },
    ],
    sortable: true,
  },
  {
    id: 'isPastDue',
    label: <FormattedMessage {...messages.pastDue} />,
    dataType: 'boolean',
    filterOptions: [
      { value: 'yes', text: <FormattedMessage {...messages.yes} /> },
      { value: 'no', text: <FormattedMessage {...messages.no} /> },
    ],
    sortable: true,
  },
  {
    id: 'isAttentionRequired',
    label: <FormattedMessage {...messages.attentionRequired} />,
    dataType: 'boolean',
    filterOptions: [
      { value: 'yes', text: <FormattedMessage {...messages.yes} /> },
      { value: 'no', text: <FormattedMessage {...messages.no} /> },
    ],
    sortable: true,
  },
];

export const PROPERTY_PATH_MAP = {
  certificationType: {
    path: 'certificationType',
    dataType: 'string',
  },
  propertyName: {
    path: 'propertyName',
    dataType: 'string',
  },
  unitNumber: {
    path: 'unitNumber',
    dataType: 'string',
  },
  applicantName: {
    path: 'applicantName',
    dataType: 'string',
  },
  complianceDocumentType: {
    path: 'complianceDocumentType',
    dataType: 'string',
  },
  uploadDate: {
    path: 'uploadDate',
    dataType: 'date',
  },
  isReviewed: {
    path: 'isReviewed',
    dataType: 'string',
  },
  programType: {
    path: 'programType',
    dataType: 'string',
  },
  voucherEffectiveDate: {
    path: 'voucherEffectiveDate',
    dataType: 'date',
  },
  certHistory: {
    path: 'certHistory',
    dataType: 'string',
  },
  complianceApprovalStatus: {
    path: 'complianceApprovalStatus',
    dataType: 'string',
  },
  isActive: {
    path: 'isActive',
    dataType: 'boolean',
  },
  isResident: {
    path: 'isResident',
    dataType: 'boolean',
  },
  isPastDue: {
    path: 'isPastDue',
    dataType: 'boolean',
  },
  isAttentionRequired: {
    path: 'isAttentionRequired',
    dataType: 'boolean',
  },
};
