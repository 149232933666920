import React, { useMemo } from 'react';
import { Tabs } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  GROSS_RENT_CERT_TYPE,
  INITIAL_CERT_TYPE,
  INTERIM_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
  OTHER,
  OTHER_HUD,
  RECERT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { FormattedMessage } from 'react-intl';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  HUD_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
  RD_PROGRAM_NAME,
} from '../../constants/affordableProgramNames';

const messages = { ...generalMessages, ...componentMessages };
const ManageCertificationsTabs = ({
  propertyAffordablePrograms,
  tabs,
  selectedCertificationType,
  setSelectedCertificationType,
}) => {
  const { iliInterimCert } = useFlags();

  const onChange = (event, value) => {
    setSelectedCertificationType(value);
  };
  const memoizedTabs = useMemo(() => {
    return tabs
      .map((tab) => {
        return {
          ...tab,
        };
      })
      .filter((tab) => {
        if (tab.value === INTERIM_CERT_TYPE) {
          return (
            propertyAffordablePrograms.includes(LIHTC_PROGRAM_NAME) &&
            iliInterimCert
          );
        }

        // RD
        if (OTHER === tab.value) {
          return propertyAffordablePrograms.includes(RD_PROGRAM_NAME);
        }

        if (OTHER_HUD === tab.value) {
          return (
            propertyAffordablePrograms.includes(HUD_PROGRAM_NAME) &&
            !propertyAffordablePrograms.includes(RD_PROGRAM_NAME)
          );
        }

        if (GROSS_RENT_CERT_TYPE === tab.value) {
          return propertyAffordablePrograms.includes(HUD_PROGRAM_NAME);
        }

        return true;
      });
  }, [iliInterimCert, propertyAffordablePrograms, tabs]);

  return (
    <Tabs
      onChange={onChange}
      value={selectedCertificationType}
      tabs={memoizedTabs}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    />
  );
};

ManageCertificationsTabs.defaultProps = {
  tabs: [
    // add and hide the none tab to remove warning
    {
      value: 'none',
      sx: { visibility: 'hidden', width: 0, minWidth: 0, padding: 0 },
    },
    {
      label: <FormattedMessage {...messages.moveIns} />,
      value: MOVE_IN_CERT_TYPE,
    },
    {
      label: <FormattedMessage {...messages.initial} />,
      value: INITIAL_CERT_TYPE,
    },
    {
      label: <FormattedMessage {...messages.recerts} />,
      value: RECERT_CERT_TYPE,
    },
    // INTERIM = OTHER_LIHTC
    {
      label: <FormattedMessage {...messages.interim} />,
      value: INTERIM_CERT_TYPE,
    },
    // RD
    {
      label: <FormattedMessage {...messages.other} />,
      value: OTHER,
    },
    // HUD
    {
      label: <FormattedMessage {...messages.otherHud} />,
      value: OTHER_HUD,
    },
    {
      label: <FormattedMessage {...messages.grCerts} />,
      value: GROSS_RENT_CERT_TYPE,
    },
  ],
};
export default ManageCertificationsTabs;
