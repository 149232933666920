import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';

import {
  useFetchCalculations,
  useGenerateCamCalculations,
  useConfirmCamEstimates,
} from '../CamCalculations/hooks';
import {
  useFetchCamRecordStatus,
  useFetchCamRecordConfirmations,
  useFetchPropertyAvgSqftOccupancy,
} from '../../hooks';
import { useFetchCamAllocations } from '../CamAllocationsSetup/hooks';
import { useFetchCamRecordsMonthlyCharges } from '../CamMonthlyCharges/hooks';
import { useFetchCamReconciliations } from '../CamReconciliationHistory/hooks';

import Divider from '../../../../components/Common/Divider';

import HeaderSection from '../HeaderSection';
import CamAllocationsSetup from '../CamAllocationsSetup';
import CamCalculations from '../CamCalculations';
import CamMonthlyCharges from '../CamMonthlyCharges';
import CamReconciliationHistory from '../CamReconciliationHistory';

import SocketService from '../../../../services/socketService';

type Props = {
  camRecords: Array<Object>,
  selectedCamRecord: Object,
  selectedCamRecordId: string,
  onCamRecordSelected: Function,
  createCamExceptionCallback: Function,
  onShowEditCamRecordModal: Function,
  onCamRecordCancelClicked: Function,
  onStartNewCamRecordClicked: Function,
  onDownloadReconciliationReport: Function,
  onCamRefresh: Function,
  intl: Object,
  propertyId: string,
  organizationId: string,
  householdId: string,
  promptToaster: Function,
  isPriorResident?: boolean,
};

const CamRecordDetails = ({
  camRecords,
  selectedCamRecordId,
  onCamRecordSelected,
  createCamExceptionCallback,
  onShowEditCamRecordModal,
  onCamRecordCancelClicked,
  onStartNewCamRecordClicked,
  onDownloadReconciliationReport,
  onCamRefresh,
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  isPriorResident,
}: Props) => {
  const selectedCamRecord =
    camRecords.find((record) => record.id === selectedCamRecordId) || {};

  const [
    isLoadingAllocations,
    allocations,
    isAllocationsSetUpComplete,
    refresh,
  ] = useFetchCamAllocations({
    intl,
    propertyId,
    organizationId,
    camRecordId: selectedCamRecord.id,
    promptToaster,
  });

  const [monthlyChargesLoading, monthlyCharges, refreshCharges] =
    useFetchCamRecordsMonthlyCharges({
      intl,
      propertyId,
      organizationId,
      camRecordId: selectedCamRecord.id,
      promptToaster,
    });

  const [calculations, calculationsLoading, refreshCalculations] =
    useFetchCalculations({
      intl,
      propertyId,
      organizationId,
      selectedCamRecord,
      promptToaster,
    });

  const [reconciliationsLoading, reconciliations, refreshReconciliations] =
    useFetchCamReconciliations({
      intl,
      propertyId,
      organizationId,
      camRecordId: selectedCamRecordId,
      promptToaster,
    });

  const [generateCamCalculations, generateCalculationsLoading] =
    useGenerateCamCalculations({
      intl,
      propertyId,
      organizationId,
      selectedCamRecordId: selectedCamRecord.id,
      promptToaster,
      refreshCalculations,
      refreshReconciliations,
    });

  const [confirmations, refreshConfirmations] = useFetchCamRecordConfirmations({
    intl,
    propertyId,
    organizationId,
    camRecordId: selectedCamRecordId,
  });

  const [isLoadingStatus, status, refreshStatus] = useFetchCamRecordStatus({
    intl,
    propertyId,
    organizationId,
    camRecordId: selectedCamRecordId,
    promptToaster,
  });

  const onEstimatesConfirmed = () => {
    refreshConfirmations();
    refreshStatus();
    onCamRefresh();
    setTimeout(refreshCharges, 1000);
  };

  const [confirmCAMEstimates] = useConfirmCamEstimates({
    intl,
    propertyId,
    organizationId,
    householdId,
    selectedCamRecord,
    promptToaster,
    onEstimatesConfirmed,
  });

  const [isLoadingAvgSqftOccupancy, avgSqftOccupancy] =
    useFetchPropertyAvgSqftOccupancy({
      intl,
      propertyId,
      organizationId,
      promptToaster,
    });

  useEffect(() => {
    const onCamReconciliationSuccess = () => {
      generateCamCalculations();
      onCamRefresh();
    };

    if (SocketService && SocketService.socket) {
      if (SocketService.socket) {
        SocketService.socket.off('camReconciliation.success');
      }
      SocketService.socket.on(
        'camReconciliation.success',
        onCamReconciliationSuccess,
      );
    }

    return () => {
      if (SocketService.socket) {
        SocketService.socket.off('camReconciliation.success');
      }
    };
  }, [selectedCamRecordId]); // eslint-disable-line

  return (
    <React.Fragment>
      <Row>
        <HeaderSection
          camRecords={camRecords}
          selectedCamRecord={selectedCamRecord}
          selectedCamRecordId={selectedCamRecordId}
          status={status}
          isLoadingStatus={isLoadingStatus}
          avgSqftOccupancy={avgSqftOccupancy}
          isLoadingAvgSqftOccupancy={isLoadingAvgSqftOccupancy}
          onCamRecordSelected={onCamRecordSelected}
          onShowEditCamRecordModal={onShowEditCamRecordModal}
          onCamRecordCancelClicked={onCamRecordCancelClicked}
          onStartNewCamRecordClicked={onStartNewCamRecordClicked}
          isPriorResident={isPriorResident}
        />
      </Row>
      <Row>
        <CamAllocationsSetup
          intl={intl}
          propertyId={propertyId}
          organizationId={organizationId}
          promptToaster={promptToaster}
          selectedCamRecord={selectedCamRecord}
          createCamExceptionCallback={createCamExceptionCallback}
          generateCamCalculations={generateCamCalculations}
          onAllocationUpdated={refreshStatus}
          calculations={calculations}
          confirmations={confirmations}
          isLoadingAllocations={isLoadingAllocations}
          allocations={allocations}
          isAllocationsSetUpComplete={isAllocationsSetUpComplete}
          refresh={refresh}
          isPriorResident={isPriorResident}
        />
      </Row>
      <Divider />
      <Row>
        <CamCalculations
          intl={intl}
          propertyId={propertyId}
          organizationId={organizationId}
          householdId={householdId}
          promptToaster={promptToaster}
          selectedCamRecord={selectedCamRecord}
          calculations={calculations || []}
          calculationsLoading={calculationsLoading}
          generateCamCalculations={generateCamCalculations}
          generateCalculationsLoading={generateCalculationsLoading}
          confirmCAMEstimates={confirmCAMEstimates}
          confirmations={confirmations}
          allocations={allocations}
          refreshReconciliations={refreshReconciliations}
          status={status}
          isPriorResident={isPriorResident}
        />
      </Row>
      <Divider />
      <Row>
        <CamMonthlyCharges
          intl={intl}
          propertyId={propertyId}
          organizationId={organizationId}
          promptToaster={promptToaster}
          isLoading={monthlyChargesLoading}
          monthlyCharges={monthlyCharges}
          refreshCharges={refreshCharges}
          isPriorResident={isPriorResident}
        />
      </Row>
      <Divider />
      <Row>
        <CamReconciliationHistory
          intl={intl}
          selectedCamRecord={selectedCamRecord}
          onDownloadReconciliationReport={onDownloadReconciliationReport}
          reconciliationsLoading={reconciliationsLoading}
          reconciliations={reconciliations}
        />
      </Row>
    </React.Fragment>
  );
};

export default CamRecordDetails;
