import { useMemo, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toastr } from 'react-redux-toastr';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import replace from 'lodash/replace';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  Button,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { BadgeStatus } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

import { HEADERS, PROPERTY_PATH_MAP, STATUSES } from './constants';
import ReportsService from '../../services/reportService';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';
import useUniqueTableName from '../../hooks/useUniqueTableName';

const { IN_PROGRESS, SUCCESSFUL, ERROR } = STATUSES;
const messages = { ...generalMessages, ...componentMessages };
const reportsService = new ReportsService();

export const useManageCentralizedReports = ({
  NAME,
  organizationId,
  userId,
  username,
  openCreateReportModal,
  openViewHistoryModal,
}) => {
  const name = useUniqueTableName(NAME);
  const [report, setReport] = useState(null);
  const [newlyGeneratedReports, setNewlyGeneratedReports] = useState([]);
  const createReport = useCreateReport();
  const { results, isLoading } = useFetchCentralizedReports({
    organizationId,
  });
  const { results: reportsStatuses } = useFetchReportsStatuses({
    organizationId,
    newlyGeneratedReports,
    setNewlyGeneratedReports,
  });

  const finalResults = useMemo(
    () =>
      results.map((result) => {
        const currentReportStatus = reportsStatuses.find(
          ({ fortressReportId }) => fortressReportId === result.id,
        );
        return {
          ...result,
          status: currentReportStatus?.status,
          userId: currentReportStatus?.requestedBy,
          username: currentReportStatus?.requestedByUsername,
          requestedTime: currentReportStatus?.createdAt,
        };
      }),
    [results, reportsStatuses],
  );
  const { statusOptions } = useGetFilterOptions({
    results: finalResults,
  });
  const headers = useHeaders({
    statusOptions,
  });
  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
  });
  const sortedAndFilteredResults = useTableFilterSortData({
    results: finalResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  function initializeCreateReport(row) {
    setReport(row);
    openCreateReportModal();
  }
  async function handleDownload(row) {
    await reportsService.downloadCentralizedReport(
      organizationId,
      row.id,
      row.filename.split('/')[1],
    );
  }
  const rows = useRows({
    sortedAndFilteredResults,
    initializeCreateReport,
    handleDownload,
    openViewHistoryModal,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const handleCreateReport = ({ dateRangeStart, dateRangeEnd }) => {
    setNewlyGeneratedReports([...newlyGeneratedReports, report]);
    createReport({
      dateRangeStart,
      dateRangeEnd,
      organizationId,
      userId,
      username,
      fortressReportId: report.id,
    });
    setReport(null);
  };

  return {
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    handleCreateReport,
    report,
  };
};

export const useFetchCentralizedReports = ({ organizationId }) => {
  const abortController = new AbortController();
  const queryKey = ['CentralizedReports', organizationId];
  const options = { signal: abortController.signal };
  const { data, isLoading } = useQuery(
    queryKey,
    () => reportsService.getCentralizedReports({ options, organizationId }),
    { enabled: true, refetchOnWindowFocus: false },
  );

  return {
    results: data?.results ?? [],
    isLoading,
  };
};

export const useHeaders = ({ statusOptions }) => {
  return useMemo(() => {
    return HEADERS.map((header) => {
      if (header.id === 'status') header.filterOptions = statusOptions;
      return header;
    });
  }, [statusOptions]);
};

export const useRows = ({
  sortedAndFilteredResults,
  initializeCreateReport,
  handleDownload,
  openViewHistoryModal,
}) => {
  const formatMessage = useFormatMessage();
  return useMemo(
    () =>
      sortedAndFilteredResults.map((row) => {
        const { name, type, lastCreated, status, username, requestedTime } =
          row;
        const isNotError =
          !status || [SUCCESSFUL, IN_PROGRESS].includes(status);
        const isSuccessful = status === SUCCESSFUL;
        const reportExists = status === IN_PROGRESS || lastCreated;

        return {
          reports: {
            value: name,
          },
          type: {
            value: type,
          },
          createdBy: {
            value: reportExists && username,
          },
          creationDate: {
            variant: 'date',
            value: reportExists && (isSuccessful ? lastCreated : requestedTime),
          },
          status: {
            value: reportExists ? (
              <BadgeStatus status={camelCase(status)} size="small" />
            ) : (
              <></>
            ),
          },
          download: {
            ...(reportExists
              ? {
                  variant: isNotError ? 'iconButton' : undefined,
                  value: isNotError ? undefined : <></>,
                  iconName: 'DownloadIcon',
                  color: isSuccessful ? 'primary' : 'disabled',
                  onClick: () => handleDownload(row),
                  disabled: !isSuccessful,
                  sx: {
                    width: '100%',
                  },
                }
              : {
                  value: <></>,
                }),
          },
          create: {
            value: (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={() => initializeCreateReport(row)}
                  startIcon={<PlusIcon fontSize="small" />}
                >
                  {formatMessage(messages.create)}
                </Button>
              </Box>
            ),
          },
          viewHistory: {
            value: (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => openViewHistoryModal(row)}
              >
                <Button variant="text" size="small">
                  {formatMessage(messages.viewHistory)}
                </Button>
              </Box>
            ),
          },
        };
      }),
    [
      sortedAndFilteredResults,
      initializeCreateReport,
      handleDownload,
      openViewHistoryModal,
      formatMessage,
    ],
  );
};

export const useCreateReport = () => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const createReport = (data) => {
    const abortController = new AbortController();
    return reportsService.createCentralizedReport(data, {
      signal: abortController.signal,
    });
  };
  const createReportMutation = useMutation(createReport, {
    onSuccess: () => {
      queryClient.invalidateQueries(['CentralizedReports']);
      toastr.info(
        formatMessage(messages.info),
        formatMessage(messages.reportGenerationInProgress),
        {
          timeOut: 10000,
        },
      );
    },
    onError: () => {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.reportNotFound),
      );
    },
  });
  return createReportMutation.mutate;
};

export const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      statusOptions: [],
    };

    if (results) {
      results.forEach(({ status }) => {
        if (status) options.statusOptions.push(status);
      });

      options.statusOptions = Array.from(new Set(options.statusOptions))
        .sort()
        .map((reportStatus) => ({
          text: startCase(replace(reportStatus, '_', ' ').toLowerCase()),
          value: reportStatus.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};

export const useFetchReportsStatuses = ({
  organizationId,
  newlyGeneratedReports,
  setNewlyGeneratedReports,
}) => {
  const formatMessage = useFormatMessage();
  const queryClient = useQueryClient();
  const abortController = new AbortController();
  const queryKey = ['CentralizedReports', 'status'];
  const options = { signal: abortController.signal };
  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      reportsService.getCentralizedReportStatus({
        options,
        organizationId,
      }),
    {
      refetchInterval: 5000,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        queryClient.invalidateQueries(['CentralizedReports', organizationId]);
        data?.forEach((datum) => {
          const reportIsNewlyGenerated = newlyGeneratedReports
            .map((report) => report.id)
            .includes(datum.fortressReportId);

          if (reportIsNewlyGenerated) {
            const reportGenerationIsInProgress = datum.status === IN_PROGRESS;
            const reportGenerationWasSuccessful = datum.status === SUCCESSFUL;
            const reportGenerationFailed = datum.status === ERROR;

            if (reportGenerationIsInProgress) {
              return;
            }

            if (reportGenerationWasSuccessful) {
              toastr.success(
                formatMessage(messages.success),
                formatMessage(messages.reportCreated),
              );
            } else if (reportGenerationFailed) {
              toastr.error(
                formatMessage(messages.error),
                formatMessage(messages.reportNotFound),
              );
            }
            setNewlyGeneratedReports(
              newlyGeneratedReports.filter(
                (r) => r.id !== datum.fortressReportId,
              ),
            );
          }
        });
      },
      onError: () =>
        toastr.error(
          formatMessage(messages.error),
          formatMessage(messages.reportNotFound),
        ),
    },
  );

  return {
    results: useMemo(() => data ?? [], [data]),
    isLoading,
  };
};
