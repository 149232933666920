import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reconciliationHistory: {
    id: 'App.CamTab.CamReconciliations.ReconciliationHistory',
    defaultMessage: 'Reconciliation History',
  },
  reconciliationType: {
    id: 'App.CamTab.CamReconciliations.ReconciliationType',
    defaultMessage: 'Reconciliation Type',
  },
  completedOn: {
    id: 'App.CamTab.CamReconciliations.completedOn',
    defaultMessage: 'Completed On',
  },
  completedBy: {
    id: 'App.CamTab.CamReconciliations.CompletedBy',
    defaultMessage: 'Completed By',
  },
  amount: {
    id: 'App.CamTab.CamReconciliations.Amount',
    defaultMessage: 'Amount',
  },
  report: {
    id: 'App.CamTab.CamReconciliations.Report',
    defaultMessage: 'Report',
  },
  noHistory: {
    id: 'App.CamTab.CamReconciliations.NoHistory',
    defaultMessage: 'No History',
  },
  show: {
    id: 'App.CamTab.CamReconciliations.Show',
    defaultMessage: 'Show',
  },
  hide: {
    id: 'App.CamTab.CamReconciliations.Hide',
    defaultMessage: 'Hide',
  },
});

export default messages;
