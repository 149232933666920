import { createSelector } from 'reselect';
import { sortBy, prop, pathOr } from 'ramda';
import * as propertyHelpers from '../../utils/property-helpers';

import type { GlobalState } from '../App/types';
const getManageCommunicationsState = (state: GlobalState) =>
  state.manageCommunications;
const getViewCommunicationsState = (state: GlobalState) =>
  state.viewCommunication;
const getPathname = (state: GlobalState, props: Object) =>
  pathOr('', ['location', 'pathname'], props);
const getSelectedPropertyId = (state: GlobalState) =>
  pathOr('', ['app', 'selectedProperty', 'id'], state);
const getCurrentLocale = (state: GlobalState) => state.languageProvider.locale;
const filterResidentLettersBatch = (docObject: Object) =>
  docObject.type === 'Resident Letter Batch' ||
  docObject.type === 'Commercial Letter Batch';

const getAppState = (state: GlobalState) => state.app;

export const getDocumentTypesOptions = createSelector(
  [getManageCommunicationsState, getCurrentLocale],
  ({ documentTypes }) => {
    const documentTypesOptions = documentTypes
      .filter(filterResidentLettersBatch)
      .map((letter) => ({
        value: letter.id,
        text: letter?.translations?.en ?? letter?.name,
      }));
    return sortBy(prop('text'))(documentTypesOptions);
  },
);

export const getMetricsInformation = createSelector(
  [
    getManageCommunicationsState,
    getViewCommunicationsState,
    getPathname,
    getSelectedPropertyId,
  ],
  (manageCommunicationState, viewCommunicationState, pathname, propertyId) => {
    let successRecipients = 0;
    let failedRecipients = 0;
    if (pathname === `/property/${propertyId}/view-communication`) {
      // $FlowFixMe
      const recipientsStatus = pathOr(
        [],
        ['currentCommunication', 'communicationData', 'recipientsStatus'],
        viewCommunicationState,
      );
      recipientsStatus.forEach((recipient) => {
        if (recipient.status === 'SENT') {
          successRecipients += 1;
        } else {
          failedRecipients += 1;
        }
      });
    } else {
      // $FlowFixMe
      successRecipients = pathOr(
        0,
        ['response', 'successRecipients', 'length'],
        manageCommunicationState,
      );
      failedRecipients =
        // $FlowFixMe
        pathOr(0, ['selectedResidents', 'length'], manageCommunicationState) -
        successRecipients;
    }
    return { successRecipients, failedRecipients };
  },
);

export const getCreatedAt = createSelector(
  [
    getManageCommunicationsState,
    getViewCommunicationsState,
    getPathname,
    getSelectedPropertyId,
  ],
  (manageCommunicationState, viewCommunicationState, pathname, propertyId) => {
    let createdAt;
    if (pathname === `/property/${propertyId}/view-communication`) {
      // $FlowFixMe
      createdAt = pathOr(
        [],
        ['currentCommunication', 'createdAt'],
        viewCommunicationState,
      );
    } else {
      // $FlowFixMe
      createdAt = pathOr(
        [],
        ['response', 'createdAt'],
        manageCommunicationState,
      );
    }
    return createdAt;
  },
);

export const getTextingPhoneNumber = createSelector(
  getAppState,
  ({ selectedProperty }) => {
    return propertyHelpers.getTextingPhoneNumber(selectedProperty);
  },
);
