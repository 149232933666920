import React, { useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  VirtualizedTable,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useDelinquentAndPrepaid } from './hooksV2';
import withReduxStore from './withReduxStore';

const NAME = 'manage-delinquent-and-prepaid';
const TITLE = 'Manage Delinquent and Prepaid';

const ManageDelinquentAndPrepaid = ({
  selectedProperty,
  intl: injectedIntl,
  locale,
  store,
}) => {
  const intl = useMemo(() => injectedIntl, [injectedIntl]);

  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = useDelinquentAndPrepaid({
    selectedProperty,
    NAME,
    intl,
    locale,
    store,
  });

  return (
    <DocumentTitle data-testid={NAME} title={TITLE}>
      <div>
        <TableHeader title={TITLE} count={count} totalCount={totalCount} />
        <VirtualizedTable
          ReactBeautifulDnD={{
            DragDropContext,
            Droppable,
            Draggable,
          }}
          ReactRouterLink={ReactRouterLink}
          allColumnsHidden={allColumnsHidden}
          columnOptions={columnOptions}
          count={count}
          dateState={dateState}
          filterState={filterState}
          filterTypeState={filterTypeState}
          footerRow={footerRow}
          handleColumnChange={handleColumnChange}
          handleDateSubmit={handleDateSubmit}
          handleFilterChange={handleFilterChange}
          handleFilterTypeChange={handleFilterTypeChange}
          handleSearchSubmit={handleSearchSubmit}
          handleSortChange={handleSortChange}
          headers={filteredHeaders}
          height={'calc(100vh - 216px)'}
          intl={intl}
          isLoading={isLoading}
          name={name}
          onCSVButtonClick={onCSVButtonClick}
          order={order}
          orderBy={orderBy}
          rows={rows}
          searchState={searchState}
          selectedColumns={selectedColumns}
        />
      </div>
    </DocumentTitle>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const { app, languageProvider } = state;
  return {
    selectedProperty: app.selectedProperty,
    locale: languageProvider.locale,
  };
};

export default connect(
  mapStateToProps,
  null,
)(injectIntl(withReduxStore(ManageDelinquentAndPrepaid)));
