import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import CaptionButton from '../../../components/CaptionButton';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import confirm from '../../../components/ConfirmDialogModal';
import { CaptionButtonContainer, ButtonsContainer } from './styled';

import messages from './messages';
import { useQueryClient } from 'react-query';

type Props = {
  applicationId: string,
  doNotRenew: boolean,
  lease: Object,
  removeLease: Function,
  residentId: string,
  selectPreviousLease: Function,
  handleTransferComplete: Function,
  intl: Object,
  underEviction: boolean,
  canComplete: boolean,
  formDirty: boolean,
  householdId: string,
};

export const TransferButtons = ({
  applicationId,
  doNotRenew,
  lease,
  removeLease,
  residentId,
  selectPreviousLease,
  handleTransferComplete,
  intl,
  underEviction,
  canComplete,
  householdId,
}: Props) => {
  const queryClient = useQueryClient();

  const renderConfirmDialog = () => {
    confirm(intl.formatMessage(messages.cancelTransferConfirmationHeader), {
      body: intl.formatMessage(messages.cancelTransferConfirmationBody),
    }).then(() => {
      selectPreviousLease();
      removeLease(applicationId, lease.id, residentId);
      queryClient.invalidateQueries([
        'household',
        'lease',
        'validateTransferLease',
        householdId,
      ]);
    });
  };

  const disableCompleteTransfer = underEviction || doNotRenew || !canComplete;

  const getCompleteRenewalHelpBlockMessage = () => {
    if (underEviction) return messages.completeDisabledUnderEviction;
    if (doNotRenew) return messages.completeDisabledDoNotRenew;
    if (!canComplete) return messages.completeDisabled;
    return messages.completeDisabled;
  };

  return (
    <Col md={6}>
      <ButtonsContainer className="pull-right padtop20 padbottom10">
        <CaptionButtonContainer>
          <ElementWithPermissions scope={['resident-move-out']}>
            <CaptionButton
              className="btn btn-tertiary up10"
              type="button"
              onClick={handleTransferComplete}
              disabled={disableCompleteTransfer}
              caption={
                disableCompleteTransfer && (
                  <FormattedMessage {...getCompleteRenewalHelpBlockMessage()} />
                )
              }
            >
              <FormattedMessage {...messages.completeTransferButton} />
            </CaptionButton>
          </ElementWithPermissions>
        </CaptionButtonContainer>
        <CaptionButtonContainer>
          <ElementWithPermissions scope={['lease-transfer-start']}>
            <Button
              className="btn btn-callto up10"
              onClick={renderConfirmDialog}
            >
              <FormattedMessage {...messages.cancelTransferButton} />
            </Button>
          </ElementWithPermissions>
        </CaptionButtonContainer>
      </ButtonsContainer>
    </Col>
  );
};

export default injectIntl(TransferButtons);
