import { flatten, pathOr } from 'ramda';
import { useState, useEffect } from 'react';
import AffordableCertificationsService from '../../../services/affordableCertificationsService';
import { parseCertFilters } from '../utils';
import { mapActiveCertsData } from './utils';

import {
  GROSS_RENT,
  CERTIFICATION_TYPES,
  OTHER_HUD,
  OTHER_LIHTC,
} from '../constants';

const defaultState = {
  rows: [],
  statistics: {},
  summary: {},
  isLoading: true,
  signed59ADocumentType: '',
};

export const useFetchAsyncCertifications = (
  certificationType: string,
  organizationId: string,
  propertyId: string,
  searchText: string,
  filter: string,
  displayAll: boolean,
) => {
  const [certificationData, setCertificationData] = useState(defaultState);

  useEffect(() => {
    setCertificationData(defaultState);
  }, [certificationType]);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchCertifications = async () => {
      const searchTextQuery = searchText ? { searchText } : {};
      const parsedFilters = parseCertFilters(JSON.parse(filter));

      const affordableRecertificationsService =
        new AffordableCertificationsService();
      const options = { signal: abortController.signal };
      if (certificationType === CERTIFICATION_TYPES.RECERT) {
        const { results, statistics } =
          await affordableRecertificationsService.getRecerts(
            organizationId,
            propertyId,
            options,
            searchTextQuery,
            parsedFilters,
            displayAll,
          );
        const mappedRecerts = results.map((recert) => {
          return {
            ...recert,
            id: recert?.currentCertificationId ?? '',
            lastCertifiedDateRaw: recert?.lastCertifiedRaw,
            recertDueDate: recert?.recertDue,
          };
        });
        const rows = flatten(mappedRecerts);

        setCertificationData({
          ...defaultState,
          rows,
          statistics,
          isLoading: false,
        });
      } else if (certificationType === GROSS_RENT) {
        const res = await affordableRecertificationsService.getGrossRentCerts(
          organizationId,
          propertyId,
          options,
          searchTextQuery,
          parsedFilters,
        );
        const rows = pathOr([], ['data', 'grossRentCerts'], res);
        const signed59ADocumentType = pathOr(
          [],
          ['data', 'signed59ADocumentType'],
          res,
        );
        setCertificationData({
          ...defaultState,
          rows,
          isLoading: false,
          signed59ADocumentType,
        });
      } else if (
        [
          CERTIFICATION_TYPES.MOVE_IN,
          CERTIFICATION_TYPES.INITIAL,
          OTHER_HUD,
          OTHER_LIHTC,
        ].includes(certificationType)
      ) {
        const results =
          await affordableRecertificationsService.getActiveCertifications(
            organizationId,
            propertyId,
            certificationType,
            options,
            searchTextQuery,
            parsedFilters,
          );
        const rows = mapActiveCertsData(results?.result ?? []);
        const summary = results?.summary ?? {};
        setCertificationData({
          ...defaultState,
          rows,
          summary,
          isLoading: false,
        });
      }
    };

    if (subscribed && organizationId && propertyId && certificationType) {
      setCertificationData({
        ...certificationData,
        isLoading: true,
      });
      fetchCertifications();
    }

    return () => {
      subscribed = false;
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter,
    searchText,
    certificationType,
    organizationId,
    propertyId,
    displayAll,
  ]);

  return certificationData;
};
