// $FlowFixMe
import { useCallback, useEffect, useState } from 'react';
import LeaseService from '../../../services/leaseService';

export const useAsyncMonthlyLeaseTransactions = (leaseId: string) => {
  const [subscribed, setSubscribed] = useState(true);
  const [fetch, setFetch] = useState(true);
  const [allMonthlyTransactions, setMonthlyTransactions] = useState(null);

  const updateTransactionsList = () => {
    setFetch(true);
  };

  const fetchLeaseMonthlyTransactions = useCallback(async () => {
    setSubscribed(true);
    if (leaseId || fetch) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const leaseService = new LeaseService();
      const options = { signal: abortController.signal };
      const monthlyTransactionsRes = leaseId
        ? await leaseService.getMonthlyLeaseTransactionsForResident(
            leaseId,
            options,
          )
        : [];
      if (subscribed) {
        setMonthlyTransactions(monthlyTransactionsRes);
        setFetch(false);
      }
    }
  }, [leaseId, fetch, subscribed]);

  useEffect(() => {
    fetchLeaseMonthlyTransactions();
    return () => {
      setSubscribed(false);
    };
  }, [fetchLeaseMonthlyTransactions]);

  return {
    allMonthlyTransactions: allMonthlyTransactions || [],
    updateTransactionsList,
  };
};
